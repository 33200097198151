import React, { useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import * as authThunks from "../../redux/thunks/authThunk";
import { useDispatch } from "react-redux";
import * as registerFormUiAction from "../../redux/reducer/ui/registerUserUiDux";

import "react-phone-input-2/lib/style.css";

const Register = (props) => {
  const dispatch = useDispatch();

  const [mobileValidation, setMobileValidation] = useState("");
  const [formValues, setFormValues] = useState({ mobile: null });
  const [formErrors, setFormErrors] = useState({});

  const handleMobileChange = (number) => {
    dispatch(registerFormUiAction.clearUi());
    setFormValues({ ...formValues, mobile: number });
    setFormErrors({
      ...formErrors,
      ...validateForm("mobile", number),
    });
  };

  const validateForm = (name, value) => {
    switch (name) {
      case "mobile": {
        let error = null;
        if (!value) {
          error = "Mobile number is required!";
        }
        return {
          mobile: error,
        };
      }

      default:
        break;
    }
  };

  const isNotValidated = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues).filter((value) => !!value).length < 1,
    [formErrors, formValues]
  );

  const nextStepHandler = () => {
    const data = {
      phoneNumber: formValues?.mobile,
    };

    dispatch(authThunks.sendOtp(data)).then((res) => {
      if (res.success) {
        props.onClickNext();
      }
    });
  };

  return (
    <>
      <div className="signInWrapper">
        <div className="modal_content form_wrapper">
          <span className="bannerBox"></span>
          <div
            className={`master-form-group long ${
              mobileValidation ? "master-input-error" : ""
            }`}
          >
            <label htmlFor="mobile">Enter Your Mobile No.</label>
            <PhoneInput
              name="mobile"
              id="mobile"
              placeholder=""
              value={`&nbsp; ${formValues.mobile}`}
              onChange={handleMobileChange}
              className={`form-input ${
                formErrors.mobile ? "master-input-error" : ""
              }`}
              onFocus={() => setMobileValidation("")}
            />

            {formErrors.mobile && (
              <div className="message-error">
                <span className="text-danger1">{formErrors.mobile}</span>
              </div>
            )}
          </div>
        </div>

        <div className="master-form-group scheduleBox">
          <button
            onClick={nextStepHandler}
            disabled={isNotValidated}
            className="form_button"
          >
            SEND OTP
          </button>
          <div className="cartTerms">
            <p>
              By proceeding, you agree to our <br />
              <a href="/">T&amp;C, Privacy Cancellation policy</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
