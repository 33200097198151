import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { Row, Col } from "react-bootstrap";

import img1 from "assets/images/Group.png";

import * as profileThunks from "redux/thunks/profileThunk";

import Navbar from "common/Navbar";
import FaqDetails from "containers/FAQs/FaqDetails/FaqDetails";
import CouponAlert from "components/CouponAlert/CouponAlert";

import "./faq.css";

const Faq = () => {
  const dispatch = useDispatch();

  const { faqs } = useSelector((state) => state.entities.profile);

  useEffect(() => {
    dispatch(profileThunks.getFaqs());
  }, [dispatch]);

  return (
    <div>
      <CouponAlert />
      <Navbar />
      <div className="headerFaq">
        <div className="mainContainer">
          <div className="imgContainer">
            <img src={img1} alt="QA Pic"loading="lazy" />
          </div>
        </div>

        <div>
          <h2
            className="heading"
            style={{
              textAlign: "center",
              margin: "20px",
              wordSpacing: "5px",
              fontSize: "46px",
            }}
          >
            Frequently Asked Questions
          </h2>
          <p
            className="text"
            style={{
              textAlign: "center",
              color: "#667085",
              fontWeight: "bold",
            }}
          >
            The FAQs cover the basic questions customers tend to ask about your
            particular products, services, company, brand, and policies.
          </p>
        </div>
      </div>

      <div className="faq-container">
        {faqs && faqs.length > 1 ? (
          <AccordionItem>
            <Row className="faq-row m-4">
              {faqs.map((curItem, index) => (
                <React.Fragment key={curItem.id}>
                  {index % 2 === 0 ? (
                    <Col md={6}>
                      <FaqDetails {...curItem} />
                    </Col>
                  ) : (
                    <Col md={6}>
                      <FaqDetails {...curItem} />
                    </Col>
                  )}
                </React.Fragment>
              ))}
            </Row>
          </AccordionItem>
        ) : (
          "No FAQs to show."
        )}
      </div>
    </div>
  );
};

export default Faq;
