export function getStaticSlots(x) {
  const currentDate = new Date();
  const slotsData = [];

  for (let i = 0; i < x; i++) {
    // Create a new date for each day, starting from currentDate
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i);

    // Convert the date to IST by adding the UTC offset
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
    const dateInIST = new Date(date.getTime() + ISTOffset);

    const formattedDate = dateInIST.toISOString().split("T")[0];
    const timeSlots = [];

    for (let j = 8; j <= 18; j++) {
      // Format the time in 24-hour format
      const formattedTime = `${j < 10 ? "0" : ""}${j}:00`;
      const id = i * 100 + (j - 8) + 1;

      timeSlots.push({
        name: formattedTime,
        id: id,
        disabled: false,
      });
    }

    slotsData.push({
      date: formattedDate,
      time: timeSlots,
    });
  }

  return slotsData;
}
