import CustomModal from 'common/CustomModal'
import React from 'react'

const DeleteAccount = ({
    deleteAccountConfirmPopup,
    handleModalClose,
    handleAccountDeleteYes,
    handleAccountDeleteNo,
}) => {
  return (
    <CustomModal
    show={deleteAccountConfirmPopup}
    onClose={handleModalClose}
    className="customPopup innerPopup authPopup"
  >
    <>
      <div className="modal_content form_wrapper">
        <div className="innerContent">
          <p>Are you sure you want to delete your account?</p>
        </div>
      </div>
      <div className="master-form-group scheduleBox">
        <button onClick={handleAccountDeleteYes} className="form_button">
          Yes
        </button>
        <button onClick={handleAccountDeleteNo} className="form_button">
          No
        </button>
      </div>
    </>
  </CustomModal>
  )
}

export default DeleteAccount