import React, { memo } from 'react'

const PrepForOccasion = ({generateProductCollection,collectionId8}) =>  (
    <div
    className="wrapperContainer moreServices"
    style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
  >
    <h2 className="heading">Prep For An Occasion</h2>
    <div className="categorySelection catergory-overflow">
      {generateProductCollection(collectionId8, "eight")}
    </div>
  </div>
  )


export default memo(PrepForOccasion)