import React, { useState, useEffect } from "react";
import Navbar from "common/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";
import PageLayout from "common/PageLayout";
import shoppingcart from "../assets/images/shoppingcart.svg";
import CustomModal from "common/CustomModal";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-responsive-tabs/styles.css";
import * as profileThunk from "../redux/thunks/profileThunk";
import { useDispatch, useSelector } from "react-redux";
import * as productThunk from "../redux/thunks/productThunk";
import * as registerFormUiAction from "../redux/reducer/ui/registerUserUiDux";
import warning from "../assets/images/warning.svg";
// import appstoreicon from "../assets/images/app-store-icon.webp";
// import googleplayicon from "../assets/images/play-store-icon.webp"
import appstoreicon from "../assets/images/appleplay.webp";
import googleplayicon from "../assets/images/googleplay.webp"

import CouponAlert from "./CouponAlert/CouponAlert";
import { IpAddressTracking } from "utils/helper";

const CategoryListing = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [detailPopUp, setDetailPopUp] = useState(false);
  const [cartPopUp, setCartPopUp] = useState(false);
  const [calenderPopUp, setCalenderPopUp] = useState(false);
  const [signupPopup, setSignupPopup] = useState(false);
  const { isFetching, error } = useSelector((state) => state.ui.product);

  const params = useParams();
  const [prodObj, setProdObj] = useState({
    productId: "",
    sessionId: "",
    date: "",
    time: "",
  });

  const id = params.id;
  // const slug = params.slug;
  // const slugArr = slug.split("-")
  // const id = slugArr[slugArr.length - 1]

  useEffect(
    () => {
      dispatch(productThunk.getProductList(id));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      dispatch(productThunk.getProductCatId(id));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      dispatch(productThunk.getProductCollectionList(id));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const token1 = localStorage.getItem("token");

  const { productDetails, productCollectionList, productSessionList } =
    useSelector((state) => state.entities.product);

  const cartHanlder = async (id) => {
    setProdObj((prevState) => ({
      ...prevState,
      productId: id,
    }));

    // call sessesion api handler
    getSessionList(id);
  };

  const getSessionList = (id) => {
    dispatch(productThunk.getProductSessionById(id))
      .then((res) => {
        console.log(res, "get session success");
        setCartPopUp(true);
        setDetailPopUp(false);
      })
      .catch((error) => {
        console.log(error, "get session error ");
      });
  };

  const handleModalClose = () => {
    setDetailPopUp(false);
    setCartPopUp(false);
    setCalenderPopUp(false);
  };
  const detailOverviewHandler = (id) => {
    const prodId = id;
    navigate(`/product-details/${id}`);
    generateSlider();
  };

  const categoryName = localStorage.getItem("categoryName");

  useEffect(() => {
    const bars = document.querySelectorAll(".progress-bar__outter-line");
    const COUNT_STARS = 12;
    bars.forEach((el) => {
      let rating = el.dataset.rating;
      let percent = (100 * rating) / COUNT_STARS;
      console.log(percent);
      el.querySelector(".progress-bar__inner-line").style.width = `${percent}%`;
    });
  }, []);

  const handleAddToCart = async() => {
    const res = await IpAddressTracking();

    localStorage.setItem("prodObj", JSON.stringify(prodObj));
    let productId = {
      productId: prodObj.productId,
      sessionId: prodObj.sessionId,
      ipInfo: res ? res : null,
    };
    dispatch(profileThunk.addToCart(productId))
      .then((res) => {
        console.log(res, "product add success");
        navigate("/cart");
      })
      .catch((error) => {
        console.log(error, "product add error");
      });
  };

  const signupModalHandler = () => {
    setSignupPopup(true);
  };

  const signupModaCloselHandler = () => {
    setSignupPopup(false);
  };

  const closeErrorHandler = () => {
    dispatch(registerFormUiAction.clearUi());
  };

  const generateSlider = () => {
    const renderedItems = productDetails?.image?.map((item) => (
      <div key={item} className="slider_content">
        <img className="img-responsive" src={item} alt="model 1" loading="lazy"/>
      </div>
    ));

    return renderedItems;
  };

  const [isActive, setIsActive] = useState(true);

  const sessionSelectionHandler = () => {
    if (prodObj !== null) {
      setIsActive(false);
    }
  };

  const { productCollection } = useSelector((state) => state.entities.product);
  const userGender = localStorage.getItem("userGender");

  useEffect(
    () => {
      if (userGender === "male") {
        dispatch(productThunk.getProductCollectionByGender("male"));
      } else {
        dispatch(productThunk.getProductCollectionByGender("female"));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [stickyClass, setStickyClass] = useState("relative");

  // useEffect(() => {
  //   window.addEventListener("scroll", stickNavbar);

  //   return () => {
  //     window.removeEventListener("scroll", stickNavbar);
  //   };
  // }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setStickyClass("fixed") : setStickyClass("relative");
    }
  };

  const generateSliderOne = (value) => {
    const filteredCollection =
      productCollection &&
      productCollection.filter((item) => item.type === value);

    const collectionDetail = filteredCollection[0]?.collectionDetail;
    const renderedItems = collectionDetail?.map((item) => (
      <li
        key={item.id}
        onClick={(e) => {
          localStorage.setItem("bestProductId", item?.id);
          localStorage.setItem("collectionName", item?.title);
          navigate(`/collection-listing/${item.id}`);
          window.location.reload();
          window.scroll(0, 0);
        }}
      >
        <img className="img-responsive" src={item.image} alt="model 1" loading="lazy"/>
      </li>
    ));

    return renderedItems;
  };

  return (
    <>
      {error ? (
        <div className="customErrorMessage">
          <p>
            <img src={warning} alt="warning" loading="lazy"/>
            Error! {error}.
          </p>
          <span onClick={closeErrorHandler}>x</span>
        </div>
      ) : (
        ""
      )}
      <CouponAlert/>

      <CustomModal
        show={cartPopUp}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper">
          <div className="master-form-group  addBox session">
            <p>Select Session</p>

            <ul className="mid-1-check ">
              {productSessionList.map((item, index) => (
                <li
                  key={item.id}
                  className="chkinput"
                  onClick={() => {
                    setProdObj((prevState) => ({
                      ...prevState,
                      sessionId: item.sessionId,
                    }));
                    sessionSelectionHandler();
                  }}
                >
                  <label htmlFor={item.id}>
                    <input type="radio" id={item.id} name="session" value="" />
                    <span>
                      <b>{item.count} session</b>₹{Math.ceil(item.perSession)}{" "}
                      per session
                    </span>
                  </label>
                  <div className="couponConatiner">
                    <div className="price">
                      <p> ₹{item.finalPrice}</p>
                    </div>

                    <div className="coupon">
                      {item.discount > 0 ? (
                        <>
                          <p>₹ Save {item.discount}%</p>
                          <p>
                            <s> ₹{item.price}</s>
                          </p>
                        </>
                      ) : (
                        <p className="no-discount"></p>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="master-form-group scheduleBox " id="categoryButton">
            <button
              className={`form_button ${isActive ? "disabled" : ""}`}
              onClick={handleAddToCart}
            >
              SELECT SESSION
            </button>
          </div>
        </div>
      </CustomModal>

      <Navbar onSignup={signupPopup} onSignupClose={signupModaCloselHandler} />

      <div className="wrapper cartListingPage">
        <h1>{categoryName}</h1>
        <h6>
          <span onClick={() => navigate("/")}>Home</span> <span>/</span>{" "}
          <span onClick={() => window.location.reload()}>{categoryName}</span>
        </h6>
        <PageLayout>
          <div className="left">
            <div className="cartItemDetails listStyles">
              <ul>
                {productCollectionList?.map(
                  ({
                    name,
                    description,
                    id,
                    image,
                    rating,
                    price,
                    duration,
                  }) => (
                    <li key={id}>
                      <div className="description itemListingPage">
                        <div className="info">
                          <h5>{name}</h5>
                          <span>
                            <Icon path={mdiStar} size={0.8} />
                            {rating}
                          </span>
                          <p>
                            ₹ {price}
                            <span> • {duration} mins</span>{" "}
                          </p>
                        </div>

                        <span
                          dangerouslySetInnerHTML={{ __html: description }}
                        ></span>

                        <div className="action">
                          <span
                            onClick={() => {
                              detailOverviewHandler(id);
                              localStorage.setItem(
                                "productDetailHeading",
                                productDetails.name
                              );
                            }}
                          >
                            View Details
                          </span>
                          {token1 ? (
                            <span onClick={() => cartHanlder(id)}>
                              Add To Cart
                            </span>
                          ) : (
                            <span onClick={signupModalHandler}>
                              Add To Cart
                            </span>
                          )}
                        </div>
                      </div>
                      <div className=" category">
                        <span>
                          <img src={image} alt="hair reduction" loading="lazy"/>
                        </span>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>

          <div className="right">
            <div className={`discounts  ${stickyClass}  `}>
              <ul>
                <li className="active">
                  <div className="left">
                    <img src={shoppingcart} alt="shopping cart icon" loading="lazy"/>
                    <h5>
                      Introductory App pricing
                      <br />
                      <span>
                        Flat 20% discount on 6 sessions or more when you
                        download the Avataar Skincare App.
                      </span>
                      <div className="app-icons-container">
                      <a
                        href="https://apps.apple.com/in/app/avataar-skincare/id6468362044"
                        target="_blank"
                        rel="noopener noreferrer"
                        onclick="return gtag_report_conversion('https://apps.apple.com/in/app/avataar-skincare/id6468362044');"
                        // class="d-inline-block"
                      >
                        <img
                          src={appstoreicon}
                          alt="App Store"
                          class="w-100"
                          loading="lazy"
                        />
                      </a>

                      <a
                        href="https://play.google.com/store/apps/details?id=com.avataarskin.app"
                        target="_blank"
                        rel="noopener noreferrer"
                        onclick="return gtag_report_conversion('https://play.google.com/store/apps/details?id=com.avataarskin.app');"
                        // class="d-inline-block"
                      >
                        <img
                          src={googleplayicon}
                          alt="Google Store"
                          class="w-100 icon-class"
                          loading="lazy"
                        />
                      </a>
                      </div>
                    </h5>
                  </div>
                </li>
              </ul>
              <h4 className="heading">Best Package Deals</h4>
              <ul className="listpagePackages">
                {generateSliderOne("slider_grid_view")}
              </ul>
            </div>
          </div>
        </PageLayout>
      </div>
    </>
  );
};

export default CategoryListing;
