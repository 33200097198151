import { createAction, createReducer } from "redux-act";
export const setCartList = createAction("CART/SET_CART_LIST");
export const setCouponDetails = createAction("CART/SET_COUPON_DETAILS");
export const setCouponList = createAction("CART/SET_COUPON_LIST");
export const setCities = createAction("CART/SET_CITIES")

const handlers = {
  [setCartList](state, payload) {
    return {
      ...state,
      category: payload,
    };
  },

  [setCouponDetails](state, payload) {
    return {
      ...state,
      couponDetails: payload,
    };
  },
  [setCouponList](state, payload) {
    return {
      ...state,
      couponList: payload,
    };
  },
  [setCities](state, payload) {
    return {
      ...state,
      cities: payload
    }
  }
};

export default createReducer(handlers, {});
