import React from "react";
import Navbar from "common/Navbar";
import CouponAlert from "components/CouponAlert/CouponAlert";

const ContactUs = () => {
  return (
    <>
      <CouponAlert />
      <Navbar />
      <div className="wrapper">
        <div className="wrapperContainer p-4">
          <header class="entry-header">
            <h1 class="entry-title text-center">Contact Us</h1>
          </header>
          <div class="entry-content">
            <br />

            <p style={{ fontSize: "22px" }}>
              Thank you for your interest in{" "}
              <b>Avataar Skincare Technologies</b>. We value your feedback,
              inquiries, and suggestions. Please feel free to get in touch with
              us using the following contact information:
            </p>
            <br />

            <h2 class="has-text-align-center">
              <strong>CONTACT INFORMATION</strong>
            </h2>

            <p style={{ fontSize: "20px" }}>
              <br />
              Misya Beauty Tech Private Limited
              <br />
              2, Shambhu Dyal Marg,
              <br />
              Okhla Phase III, Bahapur,
              <br />
              New Delhi, Delhi 110065
            </p>
            <br />
            <h2 class="has-text-align-center">
              <strong>CONTACT US</strong>
            </h2>
            <br />
            <p style={{ fontSize: '20px' }}>For any booking related queries -&nbsp;<a href="tel: +91 9266546444" style={{ textDecoration: 'underline' }}> +91 9266546444</a></p>
            <p style={{ fontSize: '20px' }}>WhatsApp Support- <a href="https://wa.me/9266546004" style={{ textDecoration: 'underline' }}>+91 9266546004</a></p>

            <p style={{ fontSize: '20px' }}>

              Email - <a href="mailto:admin@avataarskin.com">admin@avataarskin.com</a>
            </p>


          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;