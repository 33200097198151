import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import blogData from "../blogData";
import Navbar from "common/Navbar";
import Container from "react-bootstrap/Container";
import MetaTags from "components/MetaTags";

function BlogDetail() {
  const [metaData,setMetaData]=useState();
  // const [blogs,setBlogs]=useState();
  const [blogDetail, setBlogDetail] = useState();
  const [date, setDate] = useState();
  const [cta, setCta] = useState();
  const params = useParams();

  useEffect(()=>{
    
  },[])

  useEffect(() => {
    const id = params.id;
    // eslint-disable-next-line eqeqeq
    const blogBody = blogData.find((data) => data.slug == id);
    const sanitizedHTML = blogBody.data
    setMetaData({metaTitle:blogBody?.metaTitle,metaDescription:blogBody?.metaDescription})
    setCta(blogBody.cta);
    setBlogDetail(sanitizedHTML);
    setDate(blogBody.date);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar />
      <MetaTags
        metaTitle={metaData?.metaTitle}
        metaDescription={metaData?.metaDescription}
      />
      {blogDetail && date && (
        <Container>
          <div style={{ paddingLeft: "160px" }}>Posted On: {date}</div>
          <div dangerouslySetInnerHTML={{ __html: blogDetail }} />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => (window.location.href = "/")}
              type="button"
              className="btn btn-dark"
              style={{
                marginBottom: "20px",
                padding: "10px 20px",
                marginTop: "20px",
              }}
            >
              Explore Service
            </button>
          </div>
        </Container>
      )}
    </>
  );
}

export default BlogDetail;