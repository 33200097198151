import React, { useEffect, useState } from "react";
import blogData from "../blogData";
import BlogCard from "components/BlogCard";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navbar from "common/Navbar";
import CouponAlert from "components/CouponAlert/CouponAlert";


const Blog = () => {
  const [blogs] = useState(blogData);

  useEffect(()=>{
    // setBlogs()
  },[])
 

  return (
    <>
    <CouponAlert/>
      <Navbar />
      <div className="wrapper">
        <h2 className="heading" style={{ textAlign: "center", margin: "20px" }}>
          Blog
        </h2>
      </div>

      <Container>
        <Row>
          {blogs.map((item) => (
            <BlogCard detail={item} />
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Blog;