import React from 'react'
import CustomModal from 'common/CustomModal';

import discountCoupon from "assets/images/discountCoupon.svg";

const CouponModal = ({ couponListPopup,
    handleCouponChange,
    handleModalClose,
    handleTouch,
    formErrors,
    formValues4,
    error,
    appliedCoupon,
    notAppliedCoupon,
    error1,
    couponList,
    active,
    cart,
    selectCouponHandler,
    selectPreCouponHandler }) => {
    const total = cart.category?.total;
    const couponLists = couponList.sort((a, b) => a.discount - b.discount);


    const filteredCouponList = couponList.filter(
        item => !['AVATAAR10', 'AVATAAR15', 'AVATAAR20'].includes(item.code)
    ).sort((a, b) => a.discount - b.discount);

    const allCoupons = filteredCouponList.map((coupon) => {
        return Number(total) >= coupon.minCartDiscount && Number(total) <= coupon.maxCartDiscount ? {
            ...coupon,
            disable: false
        } : {
            ...coupon,
            disable: true
        }

    })
    console.log("appliedCoupon", appliedCoupon)

    return (
        <CustomModal
            show={couponListPopup}
            onClose={handleModalClose}
            className="customPopup innerPopup"
        >
            <div className="modal_content form_wrapper couponModal">
                <h4>Coupons &amp; Offers</h4>
                <div
                    className={`master-form-group ${formErrors.couponValue ? "master-input-error" : ""
                        }`}
                >
                    <input
                        type="text"
                        name="couponValue"
                        placeholder="Enter Coupon Code"
                        className={`form-input ${formErrors.couponValue ? "master-input-error" : ""
                            }`}
                        value={formValues4.couponValue}
                        onChange={handleCouponChange}
                        onBlur={handleTouch}
                    />
                    {formErrors.couponValue ? (
                        <div className="message-error">
                            <span className="text-danger1">{formErrors.couponValue}</span>
                        </div>
                    ) : (
                        ""
                    )}

                    {error ? (
                        <div className="message-error">
                            <span className="text-danger1">{error}</span>
                        </div>
                    ) : (
                        ""
                    )}
                    {notAppliedCoupon ? (
                        <>
                            {error1 ? (
                                <span className="couponError">{error1}</span>
                            ) : (
                                <span className="appliedCoupon">Applied!</span>
                            )}
                        </>
                    ) : (
                        <span
                            className={`${active ? "active" : "disabled"}`}
                            onClick={selectCouponHandler}
                        >
                            Apply
                        </span>
                    )}
                </div>
                {allCoupons?.map((item) => (
                    <div key={item.id} className="couponBox">
                        <span>
                            <img src={discountCoupon} alt="coupon" loading="lazy" />
                        </span>
                        <div className="couponDetails">
                            <span>USE CODE {item.code}</span>
                            {item.discountType === "percent" ? (
                                <span>{item.discount}% discount</span>
                            ) : (
                                <span>
                                    Get Flat ₹{parseInt(item.discount,10).toLocaleString("en-in")} off on Min. Cart: ₹{parseInt(item.minCartDiscount,10).toLocaleString("En-in")} and Max. Cart: ₹{parseInt(item.maxCartDiscount,10).toLocaleString("en-in")}
                                </span>
                            )}

                            <a href='/terms-conditions'>
                                <span>View T&amp;C</span>
                            </a>
                        </div>

                        {
                            !item.disable ? <span
                                className={"apply-button"}
                                onClick={() => {


                                    selectPreCouponHandler();
                                    localStorage.setItem("couponCode", item.code);


                                }}
                            >
                                Apply
                            </span> : ""
                        }

                        {/* <span>Applied</span> */}
                    </div>
                ))}
            </div>
        </CustomModal>
    )
}

export default CouponModal