import React, { memo } from 'react'
import { StackedCarousel } from 'react-stacked-carousel'

import ReviewCard from 'components/ReviewCard'

const Reviews = ({ reviews }) => (
    <>
        <h2 className="heading">What our clients say about us</h2>

        {reviews ? (
            <div className="reviewBox">
                <div className="mainReviews">
                    <div>
                        <StackedCarousel
                            autoRotate={false}
                            containerClassName="containerReviews"
                            cardClassName="cardReviews"
                            leftButton={<span className="leftButton">{"<"}</span>}
                            rightButton={<span className="rightButton">{">"}</span>}
                        >
                            {reviews.map((review) => (
                                <div className="singleCard">
                                    <ReviewCard review={review} />
                                </div>
                            ))}
                        </StackedCarousel>
                    </div>
                </div>
            </div>
        ) : (
            ""
        )}
    </>
)


export default memo(Reviews)