import * as registerUserUiActions from "../reducer/ui/registerUserUiDux";
import * as userActions from "../reducer/userDux";
import * as forgotPasswordActions from "../reducer/forgotPasswordDux";
import * as userUiActions from "../reducer/ui/userUiDux";
import * as registerActions from "../reducer/registerDux";
import { post, get } from "../../utils/httpUtils";
import { setAuthorizationToken } from "../../utils/httpUtils";

export const sendOtp = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("users/sign-in", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      dispatch(registerUserUiActions.setFetching(false));
      dispatch(registerActions.setFormData(data));
      dispatch(registerActions.authenticate(response.token));
      return {
        success: true,
      };
    }
  });
};

export const resendOtp = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("users/resend-otp", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      dispatch(registerUserUiActions.setFetching(false));
      dispatch(registerActions.authenticate(response.token));
      return {
        success: true,
      };
    }
  });
};

export const verifyUser = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("users/verify-otp", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));
      return {
        success: false,
      };
    } else {
      localStorage.setItem("token", response.token);
      localStorage.setItem("loggedIn", response.id);
      dispatch(registerActions.isExisting(response.isExisting));
      localStorage.setItem("isExisting", JSON.stringify(response.isExisting));
      dispatch(registerActions.setToken(response.token));
      setAuthorizationToken(response.token);
      dispatch(registerUserUiActions.setFetching(false));
      dispatch(registerActions.setFormData(data));

      dispatch(getUserDetails()).then((res) => {
        if (!res.success) {
          dispatch(userUiActions.setFetching(false));
          return {
            success: false,
          };
        }
      });

      return {
        success: true,
      };
    }
  });
};


export const emailLogin = (data) => (dispatch) => {
  dispatch(userUiActions.setFetching(true));

  return post("users/login-email", data).then((response) => {
    if (!!response.error) {
      dispatch(userUiActions.setError(response.message));
      dispatch(userUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      localStorage.setItem("token", response.token);
      setAuthorizationToken(response.token);
      dispatch(registerActions.setToken(response.token));
      dispatch(registerActions.isExisting(response.isExisting));
      dispatch(userUiActions.setFetching(false));

      dispatch(getUserDetails()).then((res) => {
        if (!res.success) {
          dispatch(userUiActions.setFetching(false));
          return {
            success: false,
          };
        }
      });
      dispatch(userUiActions.setFetching(false));

      return {
        success: true,
        isExisting: response.isExisting,
      };
    }
  });
};

export const getUserDetails = () => (dispatch) => {
  return get("users/info").then((response) => {
    if (!!response.error) {
      dispatch(userUiActions.setError(response.message));

      return {
        success: false,
      };
    } else {
      dispatch(userActions.setData(response));
      localStorage.setItem("user", JSON.stringify(response));

      return {
        success: true,
      };
    }
  });
};

export const getVerifiedUser = () => (dispatch) => {
  return get("users/is-verified/v1").then((response) => {
    if (!!response.error) {
      dispatch(userUiActions.setError(response.message));

      return {
        success: false,
      };
    } else {
      localStorage.setItem("isVerified", response.isVerified);
      return {
        success: true,
      };
    }
  });
};

export const forgotPinSendOtp = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("users/forgetpin-send-otp", data).then((response) => {
    if (!!response.error) {
      dispatch(userUiActions.setError(response.message));
      dispatch(userUiActions.setCode(response.code));
      dispatch(userUiActions.setFetching(false));
      return {
        success: false,
      };
    } else {
      dispatch(userUiActions.setFetching(false));
      dispatch(forgotPasswordActions.setToken(response.token));
      dispatch(forgotPasswordActions.setFormData(data));
      // dispatch(userUiActions.authenticate(response.token))
      return {
        success: true,
      };
    }
  });
};

export const userInfo = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("users/info", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      dispatch(registerActions.setUserData(data));

      return {
        success: true,
      };
    }
  });
};

export const userPreTreatmentInfo = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("users/pre-treatment", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      dispatch(registerActions.setUserData(data));

      return {
        success: true,
      };
    }
  });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("isExisting");
  localStorage.removeItem("fullName");
  localStorage.removeItem("phoneNumber");
  localStorage.removeItem("postID");
  localStorage.removeItem("userId");
  setAuthorizationToken(false);
  dispatch(userActions.setData(null));
  dispatch(registerActions.isExisting(null));
  dispatch(registerActions.setToken(null));
};
