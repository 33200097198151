import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "common/PageLayout";
import BackButton from "./../assets/images/arrow-left.svg";
import PostSuccess from "../assets/images/post-success.svg";
import Navbar from "common/Navbar";

const AddPost = () => {
  const axios = require("axios");
  let navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState("");

  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.type.includes("image")) {
      setMediaType("image");
    } else {
      setMediaType("video");
    }
    setFile(selectedFile);
    console.log(selectedFile, selectedFile);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("userfile", selectedFile);
      try {
        const response = await axios.post(
          "https://crm.avataarskin.com/app/api/s3-upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setUploadedUrl(response.data?.data?.file_url);
        console.log(
          "File uploaded successfully:",
          response.data?.data?.file_url
        );
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const user = useSelector((state) => state.user);
  const loggedIn = localStorage.getItem("loggedIn");

  const redirectToPost = () => {
    navigate("/avataar-club");
  };

  const handleAddPost = async () => {
    if (uploadedUrl !== "" && title !== "" && tags !== "") {
      let formData = {
        type: "app_club_add_post",
        title: title,
        user_id: loggedIn,
        description: tags,
        media_type: mediaType,
        media_url: uploadedUrl,
      };

      try {
        const response = await axios.post(
          "https://crm.avataarskin.com/app/api/",
          formData
        );
        setShowSuccess(true);
        console.log(response, "add post success response");
      } catch (error) {
        console.log("add post error");
      }
    }
  };

  return (
    <>
      <Navbar />

      <div className="wrapper myBookings clubPage pageHeading postPage">
        <div className="add-post-back">
          {!showSuccess ? (
            <span className="post-back" onClick={() => redirectToPost()}>
              <img src={BackButton} alt="back" loading="lazy"/>
              Post
            </span>
          ) : (
            <span>Post Success</span>
          )}
        </div>

        <PageLayout>
          {!showSuccess ? (
            <div
              className="postPageInner"
              style={{ flexDirection: "column", margin: "0 auto" }}
            >
              <div className="add-post-wrapper">
                <label htmlFor="media-upload" className="placeholder-label">
                  {file ? (
                    <>
                      {file.type.includes("image") ? (
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Selected Image"
                          loading="lazy"
                        />
                      ) : (
                        <video src={URL.createObjectURL(file)} controls />
                      )}
                    </>
                  ) : (
                    "ADD IMAGE / VIDEO"
                  )}
                </label>
                {/* <span>{uploadedUrl}</span> */}
                <input
                  id="media-upload"
                  type="file"
                  accept="image/*, video/*"
                  onChange={handleFileUpload}
                />
              </div>
              <div className="add-post-input-wrapper">
                <label className="label" htmlFor="title">
                  Title
                </label>
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  id="title"
                  type="text"
                  placeholder="Title *"
                  required
                />
                <label className="label" htmlFor="description">
                  Description
                </label>
                <textarea
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  id="title"
                  type="text"
                  placeholder="Add Caption"
                  required
                  rows="6"
                />
              </div>
              <div className="add-post-publish-btn">
                <button onClick={() => handleAddPost()}>Publish</button>
              </div>
            </div>
          ) : (
            <div className={{ flexDirection: "column", margin: "0 auto" }}>
              <div className="success-wrapper">
                <div className="post-succes-img-wrapper">
                  <img src={PostSuccess} alt="post success" loading="lazy"/>
                  <h4>Post Successful</h4>
                </div>

                <div className="add-post-publish-btn">
                  <button onClick={() => redirectToPost()}>Continue</button>
                </div>
              </div>
            </div>
          )}
        </PageLayout>
      </div>
    </>
  );
};

export default AddPost;
