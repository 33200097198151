import { get, post, remove, patch } from "../../utils/httpUtils";
import * as forgotPasswordUiActions from "../reducer/ui/forgotPasswordUiDux";
import * as cartActions from "../reducer/cartDux";

export const getCartList = () => (dispatch) => {
  return get("carts").then((response) => {
    if (!!response.error) {
      // dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(cartActions.setCartList(response));
      return {
        success: true,
      };
    }
  });
};

export const deleteCartItem = (productId, cartId) => (dispatch) => {
  let data = {
    productId: productId,
  };
  return remove(`carts/${cartId}`, data).then((response) => {
    if (!!response.error) { 
      // dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(cartActions.setCartList(response));
      return {
        success: true,
      };
    }
  });
};

export const cancelBooking = (id) => (dispatch) => {
  return patch(`orders/cancel-schedule/${id}`).then((response) => {
    if (!!response.error) {
      // dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      // dispatch(cartActions.setCartList(response));
      return {
        success: true,
      };
    }
  });
};

export const couponDetails = (data) => (dispatch) => {
  return patch("carts/apply-coupon", data).then((response) => {
    if (!!response.error) {
      dispatch(forgotPasswordUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(cartActions.setCouponDetails(response));
      return {
        success: true,
      };
    }
  });
};

export const getCouponList = () => (dispatch) => {
  return get("carts/get-coupon").then((response) => {
    if (!!response.error) {
      // dispatch(cartUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(cartActions.setCouponList(response));
      return {
        success: true,
      };
    }
  });
};

export const getCities = () => (dispatch) => {
  return get("carts/cities").then((response) => {
    if (!!response.error) {
      return {
        success: false,
      }
    } else {
      dispatch(cartActions.setCities(response));
      return {
        success: true
      }
    }
  })
}
