import { createAction, createReducer } from "redux-act";
export const setUserAddress = createAction("PROFILE/SET_USER_ADDRESS");
export const setUserAddresssId = createAction("PROFILE/SET_USER_ADDRESS_ID");
export const setUserNewAddress = createAction("PROFILE/SET_USER_NEW_ADDRESS");
export const setUpdatedAddresss = createAction("PROFILE/SET_UPDATED_ADDRESS");
export const setUserBookings = createAction("PROFILE/SET_USER_BOOKINGS");
export const setBookingDetails = createAction("PROFILE/SET_BOOKINGS_DETAILS");
export const setFaqs = createAction("PROFILE/SET_FAQS")
export const setRegionalCity = createAction("PROFILE/SET_REGIONAL_CITY")
export const setNotificationList = createAction(
  "PROFILE/SET_NOTIFICATIONS_LIST"
);
export const setCurrentLocation = createAction("PROFILE/SET_CURRENT_LOCATION");
export const setCurrrentCordinates = createAction(
  "PROFILE/SET_CURRENT_CORDINATES"
);
export const setClubPostResponse = createAction(
  "PROFILE/SET_CLUB_POST_RESPONSE"
);
export const setClubPostCommentResponse = createAction(
  "PROFILE/SET_CLUB_POST_COMMENT_RESPONSE"
);
export const setClubPostSendCommentResponse = createAction(
  "PROFILE/SET_CLUB_POST_SEND_COMMENT_RESPONSE"
);

export const setManualLocation = createAction("PROFILE/SET_MANUAL_LOCATION");

const handlers = {
  [setUserAddress](state, payload) {
    return {
      ...state,
      userAddress: payload,
    };
  },
  [setFaqs](state, payload){
    return {
      ...state,
      faqs: payload
    }
  },

  [setUserBookings](state, payload) {
    return {
      ...state,
      myBookings: payload,
    };
  },

  [setBookingDetails](state, payload) {
    return {
      ...state,
      bookingDetails: payload,
    };
  },

  [setUserAddresssId](state, payload) {
    return {
      ...state,
      userAddressId: payload,
    };
  },

  [setRegionalCity](state, payload){
    return {
      ...state,
      regionalCity: payload
    }
  },

  [setNotificationList](state, payload) {
    return {
      ...state,
      notificationsList: payload,
    };
  },

  [setCurrentLocation](state, payload) {
    return {
      ...state,
      currentLocation: payload,
    };
  },

  [setManualLocation](state, payload) {
    return {
      ...state,
      manualLocation: payload,
    };
  },

  [setUpdatedAddresss](state, payload) {
    return {
      ...state,
      updatedAddress: payload,
    };
  },

  [setCurrrentCordinates](state, payload) {
    return {
      ...state,
      userCordinate: payload,
    };
  },

  [setClubPostResponse](state, payload) {
    return {
      ...state,
      clubPosts: payload,
    };
  },

  [setClubPostCommentResponse](state, payload) {
    return {
      ...state,
      clubPostsComments: payload,
    };
  },

  [setClubPostSendCommentResponse](state, payload) {
    return {
      ...state,
      clubPostsSendComments: payload,
    };
  },

  

  [setUserNewAddress](state, payload) {
    return {
      ...state,
      newAddress: payload,
    };
  },
};

export default createReducer(handlers, {});
