import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import searchIcon from "../assets/images/searchIcon.svg";
import cartIcon from "../assets/images/cartIcon.svg";
import userProfile from "../assets/images/user.svg";
import homeIco from "../assets/images/homeIco.svg";
import bookingIco from "../assets/images/bookingIco.svg";
import profileIco from "../assets/images/profileIco.svg";
import map_pin from "../assets/images/map_pin.svg";
import Wallet from "../assets/images/wallet.svg";
import CustomModal from "../common/CustomModal";
import "react-phone-input-2/lib/style.css";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import * as authThunks from "../redux/thunks/authThunk";
import { useDispatch, useSelector } from "react-redux";
import * as profileThunks from "../redux/thunks/profileThunk";
import * as registerFormUiAction from "../redux/reducer/ui/registerUserUiDux";
import * as cartThunk from "../redux/thunks/cartThunk";
import * as productThunk from "../redux/thunks/productThunk";
import warning from "../assets/images/warning.svg";
import * as productUiAction from "../redux/reducer/ui/productUiDux";
import * as profileActions from "../redux/reducer/profileDux";
import menIcon from "../assets/images/menIcon.svg";
import womenIcon from "../assets/images/womenIcon.svg";
import { IpAddressTracking } from "utils/helper";
import Razorpay from "../assets/images/razorpay.png";
import axios from "axios";
import * as moment from "moment";
import AdvancePaymentSuccess from "components/Modals/AdvancePaymentSuccess";
import AddWallet from "components/Modals/AddWallet";
import DeleteAccount from "components/Modals/deleteAccount";
import AddAnotherAddress from "components/Modals/addAnotherAddress";
import AddNewAddress from "components/Modals/AddNewAddress";
import OTPVerification from "components/Modals/OTPVerification";
import EditProfile from "components/Modals/EditProfile";
import { FaDownload } from 'react-icons/fa';
import { generateAndDownloadInvoice } from "pages/GeneateInvoicepdf";

const loadRazorpayScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const Navbar = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  var userData = JSON.parse(localStorage.getItem("user"));
  var userName;

  useEffect(
    () => {
      if (token1) {
        dispatch(profileThunks.getAddress());
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (token1) {
        dispatch(profileThunks.getNotifications());
      }
    }, // eslint-disable-next-line
    []
  );

  const existing1 = localStorage.getItem("isExisting");

  const userId=localStorage.getItem("loggedIn");
  const { token } = useSelector((state) => state.entities.registerForm);
  const user = useSelector((state) => state.user);

  const { category } = useSelector((state) => state.entities.cart);
  const { searchedProduct } = useSelector((state) => state.entities.product);
  const { notificationsList, currentLocation } = useSelector(
    (state) => state.entities.profile
  );
  const cartCountItem = category?.products;

  useEffect(
    () => {
      if (token1) {
        dispatch(cartThunk.getCartList());
      }
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (token1) {
        dispatch(authThunks.getUserDetails());
      }
    }, // eslint-disable-next-line
    []
  );

  const token1 = localStorage.getItem("token");

  useEffect(
    () => {
      if (userData?.name === null && existing1 === "false") {
        setProfilePopup(true);
      }
    }, // eslint-disable-next-line
    []
  );

  const userInformation = useSelector(
    (state) => state.user
  );
  const initialValues = { mobile: null, countryCode: "in", otp: "" };
  const initialValues5 = { otp: "" };
  const initialValues1 = { fullName: userInformation && userInformation.name ? userInformation.name : "", gender: userInformation && userInformation.gender ? userInformation.gender : "" };
  const initialValues2 = {
    sunTime: null,
    sunscreen: "",
    existingProduct: "",
    medication: "",
    pcod: "",
    conditions: [],
    pregnancy: "",
  };

  const initialValues3 = {
    pincode: "",
    state: "",
    address: "",
    type1: "",
    city: "",
    town: "",
  };

  const initialValues6 = {
    pincode: "",
    state: "",
    address: "",
    type1: "",
    city: "",
    town: "",
  };

  const initialValues4 = {
    type: "",
  };

  const [mobileValidation, setMobileValidation] = useState("");
  // const { isFetching, error } = useSelector(state => state.ui.user);
  const [formValues, setFormValues] = useState(initialValues);
  const [formValues1, setFormValues1] = useState(initialValues1);
  const [formValues2] = useState(initialValues2);
  const [formValues4, setFormValues4] = useState(initialValues4);
  const [formValues5, setFormValues5] = useState(initialValues5);
  const [formValues6, setFormValues6] = useState(initialValues6);
  const [autoAddressBtn, setAutoAddressBtn] = useState(false);
  const [fetchAddressText, setFetchAddressText] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [credits, setCredits] = useState(1000);
  const [showPayments, setShowPayments] = useState(false);
  const [addVendorId, setVendorId] = useState(null);
  const [walletInvoice, setWalletInvoice] = useState(false);
  const [walletInvoicePayment,setWalletInvoicePayment]=useState([])
  const { isFetching, error } = useSelector((state) => state.ui.registerForm);
  const { userAddress, userAddressId } = useSelector(
    (state) => state.entities.profile
  );

  const [formErrors, setFormErrors] = useState({});
  const [signupPopup, setSignupPopup] = useState(false);
  const [userProfilelMenu, setUserProfilelMenu] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [profilePopup, setProfilePopup] = useState(false);
  const [addAnotherAddressPopup, setAddAnotherAddressPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [userTempData, setUserTempData] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState(false);
  const [addTempData, setAddTempData] = useState(false);
  const [typeCheckedOne] = useState(false);
  const [typeCheckedTwo] = useState(false);
  const [addNewAddressPopup, setAddNewAddressPopup] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [editProfilePopup, setEditProfilePopup] = useState(false);
  const [resendOTP, setResendOtp] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [genderMale, setGenderMale] = useState(false);
  const [genderFemale, setGenderFemale] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(false);
  const [activeCategory, setActiveCategory] = useState("")
  const [deleteAccountConfirmPopup, setDeleteAccountConfirmPopup] =
    useState(false);

  const loginStatus = localStorage.getItem("loginStatus");

  if (error === "Invalid Otp") {
    setTimeout(() => {
      dispatch(registerFormUiAction.setError(false));
      dispatch(productUiAction.setError(false));
    }, 4000);
  }

  useEffect(() => {
    if (userInformation && userInformation.name) {
      setFormValues1({
        fullName: userInformation.name,
        gender: userInformation.gender ? userInformation.gender : ""
      })
    }
  }, [userInformation])


  useEffect(() => {
    if (user?.gender === "male") {
      setGenderMale(true);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user?.gender === "female") {
      setGenderFemale(true);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleModalClose = () => {
    setSignupPopup(false);
    setOtpPopup(false);
    setProfilePopup(false);
    setAddressPopup(false);
    setAddNewAddressPopup(false);
    setAddAnotherAddressPopup(false);
    setEditProfilePopup(false);
    setShowCredits(false);
    setDeleteAccountConfirmPopup(false);
    setShowPayments(false);
    setPaymentDone(false);
    setWalletInvoice(false);
    dispatch(authThunks.getUserDetails());
    setCredits(1000)
  };

  const id = Number(formValues2?.type);

  const handleMobileChange = (number) => {
    setFormValues({
      ...formValues,
      mobile: number.target.value,
      // countryCode: country.dialCode,
    });
    setFormErrors({
      ...formErrors,
      ...validateForm("mobile", number.target.value),
    });
  };

  const handlePinChange = (number) => {
    setFormValues5({ ...formValues5, otp: number });
    setFormErrors({
      ...formErrors,
      ...validateForm("otp", number),
    });
  };

  const validateForm = (name, value) => {
    switch (name) {
      case "mobile": {
        let error = null;

        if (!value) {
          error = "This field is required!";
        }

        if (
          value.length !== 10
        ) {
          error = "The mobile number must be 10 digits";
        }

        return {
          mobile: error,
        };
      }
      case "otp": {
        let error = null;

        if (value.length < 4) {
          error = "Please enter 4 digit OTP ";
        }

        return {
          otp: error,
        };
      }

      default:
        break;
    }
  };

  const isNotValidated = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues).filter((value) => !!value).length < 2,
    [formErrors, formValues]
  );

  const isNotValidated1 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues1).filter((value) => !!value).length < 2,
    [formErrors, formValues1]
  );

  const isNotValidated5 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues5).filter((value) => !!value).length < 1,
    [formErrors, formValues5]
  );

  const isNotValidated2 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues6).filter((value) => !!value).length < 1,
    [formErrors, formValues6]
  );

  const isNotValidated6 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues6).filter((value) => !!value).length < 5,
    [formErrors, formValues6]
  );

  const isNotValidated3 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues4).filter((value) => !!value).length < 1,
    [formErrors, formValues4]
  );

  useEffect(() => {
    return () => {
      setMobileValidation();
      setFormValues(initialValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginStatus === "skipped") {
      setSignupPopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ediProfileHandler = () => {
    setEditProfilePopup(true);
  };

  const profileMenuHandler = () => {
    setUserProfilelMenu(!userProfilelMenu);
  };

  const savedAddressHandler = () => {
    dispatch(profileThunks.getAddressId(id));
    setAddAnotherAddressPopup(false);
    setUpdatedAddress(false);
  };

  const handleSubmitUpdate = () => {
    const data = {
      address: addTempData.address,
      city: addTempData.city,
      state: addTempData.state,
      pincode: addTempData.pincode,
      type: formValues1.type1,
      isPrimary: true,
    };

    dispatch(profileThunks.updateAddress(id, data)).then((response) => {
      if (response.success) {
        setUpdatedAddress(true);
      }
    });
  };

  const navSignUpHandler = () => {
    setSignupPopup(true);
  };

  const handleResendOtp = () => {
    setResendOtp(true);
    dispatch(registerFormUiAction.clearUi());
    setSignupPopup(false);

    const data = {
      phoneNumber: formValues?.mobile,
    };

    dispatch(authThunks.sendOtp(data)).then((res) => {
      if (res.success) {
        setOtpPopup(true);
      }
    });

    localStorage.setItem("phoneNumber", formValues.mobile);

    setTimeout(() => { setResendOtp(false) }, 2000)
  };

  const preTreatmentHandler = () => {
    const data = {
      name: formValues1.fullName.trim(),
      gender: formValues1.gender,
    };

    localStorage.setItem("fullName", formValues1.fullName.trim());

    dispatch(authThunks.userInfo(data)).then((res) => {
      if (res.success) {
        setProfilePopup(false);
      }
    });
  };

  const preTreatmentHandler2 = () => {
    const data = {
      name: formValues1.fullName.trim(),
      gender: formValues1.gender || user?.gender,
    };

    localStorage.setItem("fullName", formValues1.fullName.trim());

    dispatch(authThunks.userInfo(data)).then((res) => {
      if (res.success) {
        setUpdatedProfile(true);
        setUserProfilelMenu(false);
      }
    });
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    let field = value;

    setGenderMale(false);
    setGenderFemale(false);

    setFormValues1({ ...formValues1, [name]: field });
  };

  const handleValueChange3 = (e) => {
    const { name, value } = e.target;
    let field = value;
    setFormValues4({ ...formValues4, [name]: field });
  };

  const handleChange1 = (e) => {
    let { name, value } = e.target;
    value = value;

    setFormValues1({ ...formValues1, [name]: value });
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
  };

  const handleTouch = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
  };

  const [timer, setTimer] = useState(30);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const nextStepHandler = () => {
    dispatch(registerFormUiAction.clearUi());
    setSignupPopup(false);

    const data = {
      phoneNumber: formValues?.mobile,
    };

    dispatch(authThunks.sendOtp(data)).then((res) => {
      if (res.success) {
        setOtpPopup(true);
      }
    });

    localStorage.setItem("phoneNumber", formValues.mobile);
  };

  const logOutHandler = () => {
    setUserProfilelMenu(false);
    dispatch(authThunks.logout());
    navigate("/");
  };

  const profileSetupHandler = () => {
    const data = {
      otp: formValues5.otp,
      token: token,
    };

    dispatch(authThunks.verifyUser(data)).then((res) => {
      if (res.success) {
        setOtpPopup(false);
        setSignupPopup(false);
        window.location.reload();
        userName = userData.name;
      }
    });
  };

  const deleteAccountHandler = () => {
    setDeleteAccountConfirmPopup(true);
  };

  const handleAccountDeleteYes = () => {
    dispatch(profileThunks.deleteUser());
    handleModalClose();
  };

  const handleAccountDeleteNo = () => {
    handleModalClose();
  };

  const handleAddAddressHandler = () => {
    setAddressPopup(false);
    setAddAnotherAddressPopup(false);
    dispatch(profileThunks.getAddressId(id));
  };

  const handleAddSubmit = async () => {
    const res = await IpAddressTracking();

    const data = {
      building: formValues6?.address,
      formattedAddress: currentLocation?.data?.data?.formatted_address,
      city: formValues6.city || currentLocation?.data?.data?.city,
      state: formValues6.state || currentLocation?.data?.data?.state,
      placeId: currentLocation?.data?.data?.place_id,
      area: currentLocation?.data?.data?.area,
      compoundCode: currentLocation?.data?.data?.compound_code,
      postalCode:
        formValues6.pincode || "" + currentLocation?.data?.data?.postal_code,
      isPrimary: true,
      type: "home",
      ipInfo: res ? res : null,
    };

    dispatch(profileThunks.addAdress(data)).then((response) => {
      if (response.success) {
        setAddNewAddressPopup(false);
      }
    });
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    setFormValues6({ ...formValues6, [name]: value });
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
  };

  const addNewAddressHandler = () => {
    setAddressPopup(false);
    setAddNewAddressPopup(true);
  };

  useEffect(() => {
    dispatch(productThunk.searchProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handleCategoryClick = (category) => {
    localStorage.setItem("category_selected", category)
  }

  useEffect(() => {
    const category = localStorage.getItem("category_selected")
    setActiveCategory(category)
  }, [activeCategory]);

  const timerRef = useRef(null); // Use useRef to persist the timer

  const searchBarHandler = (e) => {
    const { value } = e.target;
    setSearchProduct(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const name = e.target.value;
      dispatch(productThunk.searchProduct(name));
      setShowSearchResult(true);
    }, 500);
  };

  const closeErrorHandler = () => {
    window.location.reload();
    dispatch(registerFormUiAction.clearUi());
    dispatch(productUiAction.clearUi());
  };

  const updatedProfileHandler = () => {
    setEditProfilePopup(false);
    window.location.reload();
  };

  const getCurrentLocationHandler = () => {
    setAutoAddressBtn(true);
    setFetchAddressText(true);
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        dispatch(profileActions.setCurrrentCordinates(location.coords));

        localStorage.setItem(
          "latitude",
          JSON.stringify(location.coords.latitude)
        );
        localStorage.setItem(
          "longitude",
          JSON.stringify(location.coords.longitude)
        );
      });
    }
  };

  const handleCreditsSubmit = () => {
    setShowPayments(true);
    setShowCredits(false)
  }

  useEffect(() => {
    if (addVendorId) {
      loadRazorpayScript('https://checkout.razorpay.com/v1/checkout.js');
      razorpay();
    }
  }, [addVendorId])

  const handleCreditsPayment = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/add-credit-vendor`,
        {
          amount: parseInt(credits, 10),
          paymentFrom: 'razorpay',
          status: 'initiated',
        },
      )
      setVendorId(response.data.id)
    }
    catch (error) {
      console.log(error)
    }
  }

  const razorpay = async () => {
    const reponse1 = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/create-razorpay-order`,
      {
        amount: parseInt(credits, 10),
        vendorPaymentId: addVendorId
      })
    const options = {
      key_id: 'rzp_test_63uXsHrRF4yBqV',
      amount: credits * 100,
      currency: 'INR',
      name: 'Avataar Skincare',
      description: 'Payment for Premium Skincare Services',
      image: "https://partner-app-s3.s3.ap-south-1.amazonaws.com/logo+(3).png",
      order_id: reponse1.data.id,
      handler: async (response) => {
        localStorage.setItem('razorpayId', response.razorpay_order_id)
        const paymentData = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        };

        const result = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/payments/verify-payment`,
          paymentData,
        );

        const crmUpdateResponse = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/payments/update-credit-vendor`,
          {
            "vendorCreditId": addVendorId,
            "status": "success",
            "paymentId": response.razorpay_payment_id,
          }
        );

        const crmMessage = crmUpdateResponse.data.message;

        const addAdvancePaymentCredits = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/payments/add-advance-payment`,
          {
            amount: parseInt(credits, 10),
            modeOfPayment: "card",
            dateOfPayment: moment(new Date()).format("YYYY-MM-DD"),
            orderId: localStorage.getItem("razorpayId")
          }
        );
        if (addAdvancePaymentCredits.data.addedPayment) {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/payments/get-advance-payment`)
          setCurrentBalance(response.data.balance)
          setPaymentDone(true);
        }
      },


      prefill: {
        name: user.name,
        email: '',

      },
      notes: {
        address: 'Avataar Skincare Services',
      },
      theme: {
        color: "#937940",
      },

    }

    const rzp = (window).Razorpay(options)
    rzp.open();
  }
  
  const fetchWalletPaymentInvoice = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/get-credit-wallet-payment/${userId}`);
      setWalletInvoicePayment(res.data);
    } catch (error) {
      console.error('Error fetching fetchWallet PaymentInvoice  payment invoice:', error);
    }
  };
useEffect(()=>{
  fetchWalletPaymentInvoice()
},[walletInvoice])

  // const handleDownloadClick = () => {
  //   // setSelectedPayment(payment);
  //   invoiceRef.current?.generateAndDownloadInvoice(walletInvoice);
  // };
  const handleDownloadClick = (payment) => {
    generateAndDownloadInvoice(payment);
};

  return (
    <>
      {error && (
        <div className="customErrorMessage">
          <p>
            <img src={warning} alt="warning" loading="lazy" />
            Error! {error}.
          </p>
          <span onClick={closeErrorHandler}>x</span>
        </div>
      )}

      <AdvancePaymentSuccess
        handleModalClose={handleModalClose}
        paymentDone={paymentDone}
        credits={credits}
      />

      <CustomModal
        show={signupPopup || props.show || props.onSignup}
        onClose={() => {
          handleModalClose();
          props.onSignupClose();
        }}
        className="customPopup authPopup"
      >
        <div className="modal_content form_wrapper signInWrapper">
          <span className="bannerBox"></span>
          <div
            className={`master-form-group long ${mobileValidation ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="mobile">Enter Your Mobile No.</label>
            <input
              type="number"
              name="mobile"
              id="mobile"
              placeholder=""
              maxLength={10}
              value={`${formValues.mobile}`}
              onChange={handleMobileChange}
              className={`form-input ${formErrors.mobile ? "master-input-error" : ""
                }`}
              onFocus={() => setMobileValidation("")}

            />
            {formErrors.mobile && (
              <div className="message-error">
                <span className="text-danger1">{formErrors.mobile}</span>
              </div>
            )}
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button
            onClick={() => {
              nextStepHandler();
              props.onSignupClose(false);
            }}
            disabled={isNotValidated}
            className="form_button"
          >
            SEND OTP
          </button>
          <div className="cartTerms">
            <p>
              By proceeding, you agree to our <br />
              <a href="/">T&amp;C, Privacy Cancellation policy</a>
            </p>
          </div>
        </div>
      </CustomModal>

      <OTPVerification
        otpPopup={otpPopup}
        error={error}
        formErrors={formErrors}
        formValues5={formValues5}
        handleModalClose={handleModalClose}
        handlePinChange={handlePinChange}
        handleResendOtp={handleResendOtp}
        isNotValidated5={isNotValidated5}
        profileSetupHandler={profileSetupHandler}
        resendOTP={resendOTP}
        timer={timer}
      />

      <CustomModal
        show={profilePopup}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <div className="modal_content form_wrapper basic">
          <span>Profile Info</span>
          <div
            className={`master-form-group ${formErrors.fullName ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="fullName">
              Full Name<span className="fieldRequired">*</span>
            </label>
            {existing1 === "false" ? (
              <input
                type="text"
                name="fullName"
                className={`form-input ${formErrors.fullName ? "master-input-error" : ""
                  }`}
                value={formValues1.fullName}
                onChange={handleChange1}
                onBlur={handleTouch}
              />
            ) : (
              <input
                type="text"
                name="fullName1"
                className={`form-input ${formErrors.fullName ? "master-input-error" : ""
                  }`}
                defaultValue={userName}
                value={formValues1.fullName}
                onChange={handleChange1}
                onBlur={handleTouch}
              />
            )}

            {formErrors.fullName && (
              <div className="message-error">
                <span className="text-danger1">{formErrors.fullName}</span>
              </div>
            )}
          </div>

          <div
            className={`master-form-group ${formErrors.fullName ? "master-input-error" : ""
              }`}
          >
            <span className="label">Gender</span>
            <ul className="mid-1-check">
              <li className="chkinput">
                <label htmlFor="male">
                  <input
                    type="radio"
                    id="male"
                    onChange={handleValueChange}
                    name="gender"
                    value="male"
                  />
                  <span>Male</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="female">
                  <input
                    type="radio"
                    id="female"
                    onChange={handleValueChange}
                    name="gender"
                    value="female"
                  />
                  <span>Female</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="other">
                  <input
                    type="radio"
                    id="other"
                    onChange={handleValueChange}
                    name="gender"
                    value="other"
                  />
                  <span>Other</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button
            onClick={preTreatmentHandler}
            disabled={isNotValidated1}
            className="form_button"
          >
            SAVE
          </button>
        </div>
      </CustomModal>

      <CustomModal
        show={addressPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper">
          <div className="master-form-group long">
            <p>Select Address</p>
            <div className="addBox">
              <span onClick={addNewAddressHandler}>Add address</span>
              {userAddress.length !== 0 ? (
                <ul className="mid-1-check">
                  {userAddress.map(
                    (
                      { id, type, building, area, city, state, postalCode },
                      index
                    ) => (
                      <li key={[id]} className="chkinput">
                        <label htmlFor={[id]}>
                          <input
                            type="radio"
                            id={[id]}
                            onChange={handleValueChange3}
                            name="type"
                            value={[id]}
                          />
                          <span>
                            <b>{type}</b>
                            {building}, {area}, {city}, {state} {postalCode}{" "}
                          </span>
                        </label>
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <p className="addAddText">
                  You don't have an address added, please add an addresss to
                  continue!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button
            onClick={handleAddAddressHandler}
            disabled={isNotValidated3}
            className="form_button"
          >
            SELECT ADDRESS
          </button>
        </div>
      </CustomModal>

      <AddAnotherAddress
        addAnotherAddressPopup={addAnotherAddressPopup}
        formErrors={formErrors}
        formValues={formValues}
        handleModalClose={handleModalClose}
        handleTouch={handleTouch}
        savedAddressHandler={savedAddressHandler}
        updatedAddress={updatedAddress}
        addTempData={addTempData}
        handleSubmitUpdate={handleSubmitUpdate}
        handleValueChange={handleValueChange}
        mobileValidation={mobileValidation}
        setAddTempData={setAddTempData}
        setUserTempData={setUserTempData}
        typeCheckedOne={typeCheckedOne}
        typeCheckedTwo={typeCheckedTwo}
        userTempData={userTempData}
      />

      <AddNewAddress
        addNewAddressPopup={addNewAddressPopup}
        fetchAddressText={fetchAddressText}
        formErrors={formErrors}
        formValues6={formValues6}
        getCurrentLocationHandler={getCurrentLocationHandler}
        handleChange3={handleChange3}
        handleModalClose={handleModalClose}
        handleTouch={handleTouch}
        map_pin={map_pin}
        autoAddressBtn={autoAddressBtn}
        currentLocation={currentLocation}
        handleAddSubmit={handleAddSubmit}
        isNotValidated2={isNotValidated2}
        isNotValidated6={isNotValidated6}
      />

      <AddWallet
        credits={credits}
        currentBalance={currentBalance}
        handleModalClose={handleModalClose}
        setCredits={setCredits}
        showCredits={showCredits}
        handleCreditsSubmit={handleCreditsSubmit}
      />

      <CustomModal
        show={showPayments}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <div style={{ padding: "32px", height: "300px" }}>
          <ul>
            <li style={{ fontWeight: "bold" }}>Select Payment Gateway</li>
          </ul>
          <ul>
            <li style={{
              cursor: "pointer", border: "1px dashed #cba05b", padding: "8px 16px", borderRadius: "10px",
              background: "rgba(255,255,255,1)",
              background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(203,173,91,0.6) 100%)"
            }} onClick={handleCreditsPayment}>
              <img src={Razorpay} className="razpayImage" loading="lazy" />
              Razorpay
            </li>
          </ul>

        </div>
      </CustomModal>

    <CustomModal
        show={walletInvoice}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <div
          style={{
            padding: '32px',
            height: '300px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            overflowY: 'auto'
          }}
        >
          <h3 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>
            Wallet Invoice
          </h3>
          {walletInvoicePayment?.map((payment, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '12px 0',
                borderBottom: index !== walletInvoicePayment.length - 1 ? '1px solid #ddd' : 'none',
              }}
            >
               <span style={{ marginRight: '10px', flexGrow: 1, fontSize: '16px', color: '#555' }}>
                {payment.userName}
              </span>
              <span style={{ marginRight: '10px', flexGrow: 1, fontSize: '16px', color: '#555' }}>
                {payment.amount}
              </span>
              <button
                onClick={() => handleDownloadClick(payment)}
                style={{
                  cursor: 'pointer',
                  color: '#fff',
                  backgroundColor: 'gray',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: '14px',
                  fontSize: '14px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                Download Invoice
              </button>

            </div>
          ))}
        </div>
      </CustomModal>

      <DeleteAccount
        deleteAccountConfirmPopup={deleteAccountConfirmPopup}
        handleAccountDeleteNo={handleAccountDeleteNo}
        handleAccountDeleteYes={handleAccountDeleteYes}
        handleModalClose={handleModalClose}
      />

      <EditProfile
        editProfilePopup={editProfilePopup}
        formErrors={formErrors}
        formValues={formValues}
        formValues1={formValues1}
        handleChange1={handleChange1}
        handleModalClose={handleModalClose}
        updatedProfile={updatedProfile}
        updatedProfileHandler={updatedProfileHandler}
        genderFemale={genderFemale}
        genderMale={genderMale}
        handleValueChange={handleValueChange}
        mobileValidation={mobileValidation}
        preTreatmentHandler2={preTreatmentHandler2}
        user={user}
      />

      <div className="NavbarWrapper">

        <span
          onClick={() => { navigate("/"); window.location.reload(); }}
          style={{
            display: "inline-block",
            maxWidth: "190px",
            maxHeight: "90px",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            loading="lazy"
          />
        </span>

        <div className="searchWrapper">
          <div className="searchContainer">
            <span>
              <img src={searchIcon} alt="seach icon" loading="lazy" />
            </span>
            <input
              type="text"
              placeholder="Search for treatments"
              onChange={searchBarHandler}
              value={searchProduct}
            ></input>
          </div>
          {showSearchResult && searchedProduct.length === 0 && searchProduct !== "" &&
            <div style={{
              position: "absolute",
              top: "50px",
              width: "100%",
              backgroundColor: "#f4f1f1",
              zIndex: "99",
              padding: "16px 16px 0px",
              fontWeight: "bold"
            }}>
              <p>
                NO RESULTS FOUND
              </p>
            </div>}
          {showSearchResult && searchedProduct.length > 0 && searchProduct !== "" && (
            <div className="searchBoxWrapper">
              <ul>
                {searchedProduct?.map(({ name, id, slug }) => (
                  <li
                    key={[id]}
                    onClick={() => {
                      navigate(`/laser-treatments/${slug}`);
                      // handleSearchHistory(id, name);
                      localStorage.setItem("categoryId", id);
                      localStorage.setItem("categoryName", name);
                      window.location.reload();
                    }}
                  >
                    <span>{name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {token1 && (
          <ul>
            <li className="mobNav" onClick={() => window.location.reload()}>
              <NavLink to="/" className="sidebar-list-item">
                <span className="mobIcon">
                  <img src={homeIco} alt="homeIcon" loading="lazy" />
                </span>
                <span>Home</span>
              </NavLink>
            </li>
            <li className="mobNav">
              <NavLink
                className="nav-link"
                to="/women"
                reloadDocument={true}
                onClick={() => handleCategoryClick("women")}
              >
                <span className="mobIcon">
                  <img src={womenIcon} alt="womenIcon" loading="lazy" />
                  <span>Women</span>
                </span>
              </NavLink>
            </li>
            <li className="mobNav">
              <NavLink
                className="nav-link"
                to="/men"
                reloadDocument={true}
                onClick={() => handleCategoryClick("men")}
              >
                <span className="mobIcon">
                  <img src={menIcon} alt="menIcon" loading="lazy" />
                  <span>Men</span>
                </span>
              </NavLink>
            </li>

            <li className="mobNav">
              <NavLink to={`/my-bookings`} className="sidebar-list-item">
                <span className="mobIcon">
                  <img src={bookingIco} alt="homeIcon" loading="lazy" />
                </span>
                <span>My Bookings</span>
              </NavLink>
            </li>

            <li className="webNav">
              <NavLink
                to="/women"
                reloadDocument={true}
                onClick={() => handleCategoryClick("women")}
              >
                <span
                  className={`${activeCategory === 'women' ? "navCategoryIcon" : ""}`}
                >
                  Women
                </span>
              </NavLink>
            </li>
            <li className="webNav">
              <NavLink
                to="/men"
                reloadDocument={true}
                onClick={() => handleCategoryClick("men")}
              >
                <span
                  className={`${activeCategory === 'men' ? "navCategoryIcon" : ""}`}
                >
                  Men
                </span>
              </NavLink>
            </li>

            <li className="webNav">
              <NavLink to="/Blog">
                <span>Blog</span>
              </NavLink>
            </li>

            <li className="webNav">
              <NavLink to="https://clinic.avataarskin.com" target="_blank">
                <span>Avataar Clinic</span>
              </NavLink>
            </li>

            <li className="mobNav">
              <NavLink to={`/my-profile`} className="sidebar-list-item">
                <span className="mobIcon">
                  <img src={profileIco} alt="homeIcon" loading="lazy" />
                </span>
                <span onClick={() => window.location.href = "/my-profile"}>Profile</span>
              </NavLink>
            </li>
          </ul>
        )}

        {!token1 && (
          <ul className={`${!token1 ? "notLoggedIn" : ""}`}>
            <li className="mobNav">
              <NavLink to="/" className="sidebar-list-item">
                <span>Home</span>
              </NavLink>
            </li>
            <li className="webNav">
              <NavLink to="/women" reloadDocument={true} onClick={() => handleCategoryClick("women")}>
                <span className={`${activeCategory === 'women' ? 'navCategoryIcon' : ""}`}>Women</span>
              </NavLink>
            </li>
            <li className="webNav">
              <NavLink to="/men" reloadDocument={true} onClick={() => handleCategoryClick("men")}>
                <span className={`${activeCategory === 'men' ? 'navCategoryIcon' : ""}`}>Men</span>
              </NavLink>
            </li>
            <li className="webNav">
              <NavLink to="/Blog">
                <span>Blog</span>
              </NavLink>
            </li>
            <li className="webNav">
              <NavLink to="https://clinic.avataarskin.com">
                <span>Avataar Clinic</span>
              </NavLink>
            </li>
          </ul>
        )}

        <div className="userRelatedContainer">
          {token1 ? (
            <>
              <span
                onClick={() => window.location.href = "/cart"}
                className="loggedInAccount"
              >
                <img src={cartIcon} alt="cart Icon" loading="lazy" />
                {!!cartCountItem?.length && (
                  <span className="cartCount">{cartCountItem?.length}</span>
                )}
              </span>

              <img src={Wallet} alt="cart Icon" className="walletImg1" onClick={async () => {
                setShowCredits(true);
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/payments/get-advance-payment`)
                setCurrentBalance(response.data.balance);
              }} loading="lazy" />

              <span
                className="onlyWebItem"
                onClick={() => {
                  profileMenuHandler();
                }}
              >
                <img src={userProfile} alt="user profile icon" loading="lazy" />
              </span>
              {userProfilelMenu && (
                <div className="userProfileMenu">
                  <ul>
                    <li onClick={ediProfileHandler}>
                      <span>Edit Profile</span>
                      <span>
                        <Icon path={mdiChevronRight} size={1} />
                      </span>
                    </li>
                    <li
                      onClick={() => {
                        window.location.href = `/my-bookings`;
                      }}
                    >
                      <span>My Bookings</span>
                      <span>
                        <Icon path={mdiChevronRight} size={1} />
                      </span>
                    </li>
                    <li
                      onClick={() => {
                        setWalletInvoice(true)
                      }}
                    >
                      <span>Wallet Invoice</span>
                      <span>
                        <Icon path={mdiChevronRight} size={1} />
                      </span>
                    </li>
                    <li onClick={() => window.open("https://g.page/r/Ceg956j-dvfCEBM/review", '_blank')}>
                      <span>Rate Avataar Skincare</span>
                      <span>
                        <Icon path={mdiChevronRight} size={1} />
                      </span>
                    </li>
                    <li>
                      <span onClick={deleteAccountHandler}>Delete Account</span>
                    </li>
                    <li>
                      <span onClick={logOutHandler} style={{ flex: 0 }}>Logout</span>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <span onClick={navSignUpHandler} style={{ textWrap: "wrap" }}>Sign Up / Login</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;

