import CustomModal from 'common/CustomModal'
import React from 'react'

const PreTreatmentForm = ({
    preFillLoading,
    preTreatmentForm,
    handleChange,
    handleModalClose,
    handleSubmit,
    handleValueChange2,
    formErrors,
    formValues2,
    error
}) => {
  return (
    <CustomModal
        show={preTreatmentForm}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <div className={`modal_content form_wrapper basic ${preFillLoading ? "pe-none" : ""}`}>
          {/* {preFillLoading && <Loader/>} */}
          <h4>Pre-Treatment Form</h4>

          <div
            className={`master-form-group ${formErrors.fullName ? "master-input-error" : ""
              }`}
          >
            <span className="label">
              1. How much time do you spend in the sun?
            </span>

            <ul className="mid-1-check">
              <li className="chkinput">
                <label htmlFor="lessOne">
                  <input
                    type="radio"
                    id="lessOne"
                    name="sunTime"
                    onChange={handleValueChange2}
                    value="< 1 Hour"
                    checked={formValues2.sunTime.answer === "< 1 Hour"}
                  />
                  <span>&#60; 1 Hour</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="oneThree">
                  <input
                    type="radio"
                    id="oneThree"
                    name="sunTime"
                    onChange={handleValueChange2}
                    value="1-3 Hours"
                    checked={formValues2.sunTime.answer === "1-3 Hours"}
                  />
                  <span>1-3 Hours</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="moreThree">
                  <input
                    type="radio"
                    id="moreThree"
                    name="sunTime"
                    onChange={handleValueChange2}
                    value="More than 3 Hours"
                    checked={formValues2.sunTime.answer === "More than 3 Hours"}
                  />
                  <span>2. More than 3 hours</span>
                </label>
              </li>
            </ul>
          </div>
          <div
            className={`master-form-group  ${formErrors.suntime ? "master-input-error" : ""
              }`}
          >
            <span className="label">Do you wear sunscreen regularly?</span>
            <ul className="mid-1-check">
              <li className="chkinput">
                <label htmlFor="yes">
                  <input
                    type="radio"
                    id="yes"
                    name="sunScreen"
                    value="yes"
                    onChange={handleValueChange2}
                    checked={formValues2.sunScreen.answer === "yes"}
                  />
                  <span>Yes</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="no">
                  <input
                    type="radio"
                    id="no"
                    name="sunScreen"
                    value="no"
                    onChange={handleValueChange2}
                    checked={formValues2.sunScreen.answer === "no"}
                  />
                  <span>No</span>
                </label>
              </li>
            </ul>
          </div>
          <div
            className={`master-form-group  ${formErrors.using ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="existingProduct">
              3.  What products are you using currently?
            </label>
            <input
              type="text"
              name="existingProduct"
              id="existingProduct"
              className={`form-input ${formErrors.using ? "master-input-error" : ""
                }`}
              value={formValues2.existingProduct.answer}
              onChange={handleChange}
            />
            {formErrors.using ? (
              <div className="message-error">
                <span className="text-danger1">
                  {formErrors.existingProduct}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`master-form-group  ${formErrors.using ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="medication">
              4. What medication are you currently taking?
            </label>
            <input
              type="text"
              name="medication"
              className={`form-input ${formErrors.medication ? "master-input-error" : ""
                }`}
              value={formValues2.medication.answer}
              onChange={handleChange}
            />
            {formErrors.medication ? (
              <div className="message-error">
                <span className="text-danger1">{formErrors.medication}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`master-form-group  ${formErrors.hypothyroidism ? "master-input-error" : ""
              }`}
          >
            <span className="label">5. Do you have Thyroidism?</span>
            <ul className="mid-1-check">
              <li className="chkinput">
                <label htmlFor="one">
                  <input
                    type="radio"
                    id="one"
                    name="hypothyroidism"
                    value="yes"
                    onChange={handleValueChange2}
                    checked={formValues2.hypothyroidism.answer === "yes"}
                  />
                  <span>Yes</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="two">
                  <input
                    type="radio"
                    id="two"
                    name="hypothyroidism"
                    value="no"
                    onChange={handleValueChange2}
                    checked={formValues2.hypothyroidism.answer === "no"}
                  />
                  <span>No</span>
                </label>
              </li>
            </ul>
          </div>
          <div
            className={`master-form-group  ${formErrors.pcod ? "master-input-error" : ""
              }`}
          >
            <span className="label">6. Do you have PCOD/PCOS?</span>
            <ul className="mid-1-check">
              <li className="chkinput">
                <label htmlFor="three">
                  <input
                    type="radio"
                    id="three"
                    name="pcod"
                    value="yes"
                    onChange={handleValueChange2}
                    checked={formValues2.pcod.answer === "yes"}
                  />
                  <span>Yes</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="four">
                  <input
                    type="radio"
                    id="four"
                    name="pcod"
                    value="no"
                    onChange={handleValueChange2}
                    checked={formValues2.pcod.answer === "no"}
                  />
                  <span>No</span>
                </label>
              </li>
            </ul>
          </div>

          <div
            className={`master-form-group ${formErrors.using ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="conditions">
              7. Do you have/had any skin or medical conditions? If any ,please
              mention.
            </label>
            <input
              type="text"
              name="conditions"
              className={`form-input ${formErrors.conditions ? "master-input-error" : ""
                }`}
              value={formValues2.conditions.answer}
              onChange={handleChange}
            />
            {formErrors.using ? (
              <div className="message-error">
                <span className="text-danger1">{formErrors.conditions}</span>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className={`master-form-group  ${formErrors.pregnancy ? "master-input-error" : ""
              }`}
          >
            <span className="label">8. Are you pregnant? (only for women)</span>
            <ul className="mid-1-check">
              <li className="chkinput">
                <label htmlFor="five">
                  <input
                    type="radio"
                    id="five"
                    name="pregnancy"
                    value="yes"
                    onChange={handleValueChange2}
                    checked={formValues2.pregnancy.answer === "yes"}
                  />
                  <span>Yes</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="six">
                  <input
                    type="radio"
                    id="six"
                    name="pregnancy"
                    value="no"
                    onChange={handleValueChange2}
                    checked={formValues2.pregnancy.answer === "no"}
                  />
                  <span>No</span>
                </label>
              </li>
            </ul>
          </div>

          <div
            className={`master-form-group  ${formErrors.lactating ? "master-input-error" : ""
              }`}
          >
            <span className="label ">9. Are you lactating? (only for women)</span>
            <ul className="mid-1-check">
              <li className="chkinput">
                <label htmlFor="seven">
                  <input
                    type="radio"
                    id="seven"
                    name="lactating"
                    value="yes"
                    onChange={handleValueChange2}
                    checked={formValues2.lactating.answer === "yes"}
                  />
                  <span>Yes</span>
                </label>
              </li>
              <li className="chkinput">
                <label htmlFor="eight">
                  <input
                    type="radio"
                    id="eight"
                    name="lactating"
                    value="no"
                    onChange={handleValueChange2}
                    checked={formValues2.lactating.answer === "no"}
                  />
                  <span>No</span>
                </label>
              </li>
            </ul>
          </div>

          <div
            className={`master-form-group  ${formErrors.using ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="familyMedicalCondition">
              10. Do anyone have a history of any skin /medical condition in your
              family? If yes, please mention.
            </label>
            <input
              type="text"
              name="familyMedicalCondition"
              className={`form-input ${formErrors.using ? "master-input-error" : ""
                }`}
              value={formValues2.familyMedicalCondition.answer}
              onChange={handleChange}
            />
            {formErrors.using ? (
              <div className="message-error">
                <span className="text-danger1">
                  {formErrors.familyMedicalCondition}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className={`master-form-group  ${formErrors.using ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="allergy">
              11. Do you have any known allergies( food,drug etc) ? If yes please
              mention.
            </label>
            <input
              type="text"
              name="allergy"
              className={`form-input ${formErrors.using ? "master-input-error" : ""
                }`}
              value={formValues2.allergy.answer}
              onChange={handleChange}
            />
            {formErrors.using ? (
              <div className="message-error">
                <span className="text-danger1">{formErrors.allergy}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {error && error !== "Unauthorized" ? (
          <div className="password-error">
            <span className="text-danger1">{error}</span>
          </div>
        ) : null}

        <div className="master-form-group scheduleBox">
          <button
            // disabled={isNotValidated2}
            onClick={handleSubmit}
            className="form_button"
          >
            {!preFillLoading ? "SUBMIT" : "SUBMITTING"}

          </button>
        </div>
      </CustomModal>
  )
}

export default PreTreatmentForm