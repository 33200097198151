import React from "react";
import PopupStar from "../../assets/images/PopupStar.svg";
import doneicon from "../../assets/images/done-icon.svg";
import CustomModal from "common/CustomModal";

const ThanksFeedbackModal = ({ handleModalClose, showThanksModal }) => {
  return (
    <div>
      <CustomModal
        show={showThanksModal}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper feedsSubmitted">
          <div className="starImage">
            <span>
              <img src={PopupStar} alt="PopupStar" loading="lazy"/>
            </span>
            <span>Thank You For Your Feedback</span>
          </div>

          <div className="aftercare">
            <h4>Aftercare tips</h4>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy"/>
              </span>
              <p>Moisturise your feet regularly</p>
            </div>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy"/>
              </span>
              <p>Trim your long nails regularly to prevent breakages</p>
            </div>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy"/>
              </span>
              <p>Give your cuticles an oil massage for nourisment regularly</p>
            </div>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button
            onClick={() => {
              handleModalClose();
            }}
            className="form_button"
          >
            CONTINUE
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default ThanksFeedbackModal;
