import moment from "moment";
import axios from "axios";


export const isLoggedIn = () => {
  return localStorage.getItem("token") ? true : false;
};

export const converTimeFormat = (time) => {
  let originalTime = time;
  var dateObj = new Date();

  var timeComponents = originalTime.split(":");
  var hours = parseInt(timeComponents[0]);
  var minutes = parseInt(timeComponents[1].slice(0, 2));

  if (originalTime.includes("PM") && hours < 12) {
    hours += 12; // Convert to 24-hour format if PM
  } else if (originalTime.includes("AM") && hours === 12) {
    hours = 0; // Convert 12AM to 0 hours
  }

  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);

  // Get the formatted time string in the desired format (HH:MM:SS)
  var formattedTime = dateObj.toTimeString().slice(0, 8);

  return formattedTime;
};

export const converDateFormat = (date) => {
  var dateObj = new Date(date);

  var year = dateObj.getFullYear();
  var month = dateObj.getMonth() + 1; // Months are zero-based, so we add 1
  var day = dateObj.getDate();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  var formattedDate = year + "-" + month + "-" + day;

  return formattedDate;
};

export const FilterSlots = (slotsDate) => {
  for (let i = 0; i < slotsDate.length; i++) {
    const timeArr = slotsDate[i].time;
    const currentDate = moment().format("YYYY-MM-DD");
    const date = slotsDate[i].date
    for (let j = 0; j < timeArr.length; j++) {
      const time = timeArr[j].name;
      // Specify the format explicitly when parsing the time string
      const parsedTime = moment(time, "HH:mm");
      const currentTime = moment();
      if (currentDate === date && parsedTime.isBefore(currentTime)) {
        timeArr[j].disabled = true;
      }
      const futureTime = moment().add(2.5, 'hours').format("HH:mm");
      if (currentDate === date && parsedTime.isAfter(futureTime)) {
        timeArr[j].disabled = false
      }
      const fivePM = moment('17:00', 'HH:mm');
      if (currentDate === date && parsedTime.isAfter(fivePM)) {
        timeArr[j].disabled = true
      }

    }
  }
  return slotsDate;
};

export const getFormattedAddress = async (manualAddress) => {
  const place_id = manualAddress.place_id;

  const response = await axios.get(
    `${process.env.REACT_APP_CRM_API}/?type=search_location_by_address_id&place_id=${place_id}`
  );

  if (response.data.data.status === "OK") {
    const result = response.data.data.results[0];
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://crm.avataarskin.com/app/api/?type=search_location_by_coordinates&latlng=${result.geometry.location.lat},${result.geometry.location.lng}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data,
    };
    const locationData = await axios.request(config)
    return {
      latLongObj: result?.geometry?.location,
      googleObj: locationData?.data?.data
    }
  }
};

export const addOrdinalSuffix = (num) => {
  if (num === 0) {
    return "Ground";
  }
  if (num === 1) {
    return `${num}st`;
  } else if (num === 2) {
    return num + "nd";
  } else if (num === 3) {
    return num + "rd";
  } else {
    return num + "th";
  }
}


export const stateHandler = (city) => {
  if (city === "Mumbai" || city === "Pune") {
    return "Maharashtra"
  }
  if (city === "Noida" || city === "Greater Noida" || city === "Ghaziabad") {
    return "Uttar Pradesh"
  }
  if (city === "Gurgaon") {
    // || city === "Faridabad"
    return "Haryana"
  }
  if ( city === "Faridabad") {
    return "Delhi"
  }
  if (city === "Ahmedabad") {
    return "Gujarat"
  }
  if (city === "Indore") {
    return "Madhya Pradesh"
  }
  if (city === "Chandigarh" || city === "Mohali" || city === "Panchkula" || city === "Ludhiana") {
    return "Chandigarh"
  }
  if (city === "Hyderabad") {
    return "Telangana"
  }
  if (city === "Bangalore") {
    return "Karnataka"
  }
  return "Delhi"
}

export function addHoursToTime(time, hoursToAdd, minutesToAdd) {
  console.log("see argssss", time, hoursToAdd, minutesToAdd)
  // Split the time string into hours and minutes
  let [hours, minutes] = time?.split(':').map(Number);

  // Add the hours and minutes
  hours += hoursToAdd;
  minutes += minutesToAdd;

  // Handle minutes overflow
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes %= 60;
  }

  // Ensure the hours are within 0-23 range
  hours %= 24;

  // Format the hours and minutes to ensure leading zeros if necessary
  hours = String(hours).padStart(2, '0');
  minutes = String(minutes).padStart(2, '0');

  // Return the new time string
  return `${hours}:${minutes}`;
}

export function subtractHoursFromTime(
  time,
  hoursToSubtract,
  minutesToSubtract,
) {
  // Split the time string into hours and minutes
  // console.log("see argssss subbbbbbbb",time, hoursToAdd, minutesToAdd)
  let [hours, minutes] = time?.split(':').map(Number);

  // Subtract the hours and minutes
  hours -= hoursToSubtract;
  minutes -= minutesToSubtract;

  // Handle minutes underflow
  if (minutes < 0) {
    hours -= Math.ceil(Math.abs(minutes) / 60);
    minutes = ((minutes % 60) + 60) % 60;
  }

  // Ensure the hours are within 0-23 range
  if (hours < 0) {
    hours += 24;
  }

  // Format the hours and minutes to ensure leading zeros if necessary
  hours = String(hours).padStart(2, '0');
  minutes = String(minutes).padStart(2, '0');

  // Return the new time string
  return `${hours}:${minutes}`;
}

export const IpAddressTracking = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    const response1 = await fetch(`https://apiip.net/api/check?ip=${data.ip}&accessKey=9d786bb7-8b75-4c72-891c-2187e6a3fd97`)
    const result = await response1.json();
    const obj = {
      ip: result.ip,
      captial: result.capital,
      userAgent: result.userAgent,
      latitude: result.latitude,
      longitude: result.longitude,
      connection: result.connection,
      postalCode: result.postalCode,
      city: result.city
    }
    return obj;
  } catch (error) {
    console.error('Error fetching IP address:', error);
  }
}


export const isDateOutsideNext7Days = (selectedDate) => {
  console.log(selectedDate)
  // Get the start of today (current date)
  const today = moment().startOf('day');

  // Get the start of the 7th day from today (7 days ahead, not including today)
  const sevenDaysFromNow = moment().add(6, 'days').endOf('day');

  // Check if the selected date is before today or after 7 days from now
  return moment(selectedDate).isBefore(today) || moment(selectedDate).isAfter(sevenDaysFromNow);
}