// eslint-disable-next-line
export default {
  ui: {
    user: {
      isFetching: false,
      error: null,
    },
    userData: {
      isFetching: false,
      error: null,
    },
    registerForm: {
      isFetching: false,
      error: null,
      code: null,
    },
    forgotPassword: {
      isFetching: false,
      error1: null,
      code: null,
    },
    resetPassword: {
      isFetching: false,
      error: null,
    },
  },
  entities: {
    registerForm: {
      formData: {},
      userData: {},
      isAuthenticated: false,
      token: null,
      existing: false,
    },
    forgotPassword: {
      formData: {},
      token: null,
    },
    resetPassword: {
      token: null,
    },
    profile: {
      userAddress: [{}],
      newAddress: {},
      userAddressId: {},
      updatedAddress: [{}],
      myBookings: [{}],
      bookingDetails: {},
      notificationsList: [{}],
      currentLocation: {},
      manualLocation: {},
      userCordinate: {},
      clubPosts: [{}],
      clubPostsComments: [{}],
      clubPostsSendComments: {},
    },
    product: {
      category: [],
      productList: [{}],
      productCategoryId: null,
      productDetails: [{}],
      setProductCatId: {},
      productSessionList: [],
      productCollection: [{}],
      productSlots: [],
      machineSlots: [],
      searchedProduct: [{}],
      productCollectionList: [{}],
      paymentUrl: {},
      collectionDetails: {}
    },
    cart: {
      cartList: [],
      couponDetails: [{}],
      couponList: [{}],
    },
  },
  user: null,
};
