import CustomModal from 'common/CustomModal'
import React from 'react'

const ConsentForm = ({
    consentPopup,
    handleConsentSubmit,
    handleModalClose,
    handleValueChange,
    formErrors,
    formValues
}) => {
  return (
    <CustomModal
    show={consentPopup}
    onClose={handleModalClose}
    className="customPopup innerPopup"
  >
    <div className="modal_content form_wrapper consentForm">
      <h4>CONSENT</h4>
      <div className="consent_Description">
        <p>
          <ul>
            <li>
              1. I hereby consent and authorize Avataar Skincare
              Technologies to perform the booked skincare procedure. I have
              voluntarily chosen to undergo this treatment/procedure after
              the nature and purpose of this treatment have been explained
              to me, along with the risks involved.
            </li>
            <li>
              2. Although it is impossible to list every potential risk and
              complication, I have been informed of possible benefits,
              risks, and complications. I also recognize there are no
              guaranteed results and that independent results are dependent
              upon age, skin condition, and lifestyle, and that there is the
              possibility I may require further treatments of the treated
              areas to obtain the expected results at an additional cost
            </li>
            <li>
              3. I have read and understand the post-treatment home care
              instructions. I understand how important it is to follow all
              instructions given to me for post-treatment care. In the event
              that may have additional questions or concerns regarding my
              treatment or suggested home product/post-treatment care, I
              will consult the aesthetician immediately
            </li>
            <li>
              4. I have also, to the best of my knowledge, given an accurate
              account of my medical history, including all known allergies
              or prescription drugs or products I am currently ingesting or
              using topically. I have read and fully understand this
              agreement and all information detailed above. I understand the
              procedure and accept the risks.
            </li>
            <li>
              5. All of my questions have been answered to my satisfaction
              and I consent to the terms of this agreement. I do not hold
              the provider or Avataar Skincare Technologies responsible for
              any of my present conditions, but not disclosed at the time of
              this skin care procedure, which may be affected by the
              treatment performed today.
            </li>
          </ul>
        </p>
      </div>
    </div>
    <div className="master-form-group scheduleBox">
      <div className="master-form-group customCheckbox customRadioBox">
        <ul className="mid-1-check squareCheck checkTerms">
          <li className="chkinput">
            <label htmlFor="accept">
              <input
                type="checkbox"
                id="accept"
                className={`form-input ${formErrors.mobile ? "master-input-error" : ""
                  }`}
                onChange={handleValueChange}
                name="accept"
                value={formValues.accept}
              />
              <span>
                By ticking the checkbox, You consent to the clauses
                mentioned above..not falling form
              </span>
            </label>
            {formErrors.accept ? (
              <div className="message-error">
                <span className="text-danger1">{formErrors.accept}</span>
              </div>
            ) : (
              ""
            )}
          </li>
        </ul>
      </div>
      <button onClick={handleConsentSubmit} className="form_button">
        SUBMIT
      </button>
    </div>
  </CustomModal>
  )
}

export default ConsentForm