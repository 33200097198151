import { createAction, createReducer } from "redux-act";
export const setProductCategory = createAction("PRODUCT/SET_PRODUCT_CATEGORY");
export const setProductCatId = createAction("PRODUCT/SET_PRODUCT_CAT_ID");
export const setProductList = createAction("PRODUCT/SET_PRODUCT_LIST");
export const setProduct = createAction("PRODUCT/SET_PRODUCT");
export const setSearchedProduct = createAction("PRODUCT/SET_SEARCHED_PRODUCT");
export const setSessionList = createAction("PRODUCT/SET_SESSION_LIST");
export const setPaymentUrl = createAction("PRODUCT/SET_PAYMENT_URL");
export const setProductReviews = createAction("PRODUCT/SET_PRODUCT_REVIEWS");
export const setCollectionDetails = createAction("PRODUCT/SET_COLLECTION-DETAILS")
export const setProductCollectionList = createAction(
  "PRODUCT/SET_PRODUCT_COLLECTION_LIST"
);
export const setProductCollection = createAction(
  "PRODUCT/SET_PRODUCT_COLLECTION"
);
export const setProductSlots = createAction("PRODUCT/SET_PRODUCTS_SLOTS");
export const setProductMachineSlots = createAction(
  "PRODUCT/SET_PRODUCT_MACHINE_SLOTS"
);

const handlers = {
  [setProductCategory](state, payload) {
    return {
      ...state,
      category: payload,
    };
  },
  [setProductCatId](state, payload) {
    return {
      ...state,
      productCategoryId: payload,
    };
  },
  [setProductList](state, payload) {
    return {
      ...state,
      productList: payload,
    };
  },
  [setProduct](state, payload) {
    return {
      ...state,
      productDetails: payload,
    };
  },
  [setSearchedProduct](state, payload) {
    return {
      ...state,
      searchedProduct: payload,
    };
  },
  [setSessionList](state, payload) {
    return {
      ...state,
      productSessionList: payload,
    };
  },
  [setProductCollectionList](state, payload) {
    return {
      ...state,
      productCollectionList: payload,
    };
  },
  [setPaymentUrl](state, payload) {
    return {
      ...state,
      paymentUrl: payload,
    };
  },

  [setProductSlots](state, payload) {
    return {
      ...state,
      productSlots: payload,
    };
  },
  [setProductReviews](state,payload){
    return{
      ...state,
      reviews:payload,

    }
  },

  [setProductMachineSlots](state, payload) {
    return {
      ...state,
      machineSlots: payload,
    };
  },

  [setProductCollection](state, payload) {
    return {
      ...state,
      productCollection: payload,
    };
  },
  [setCollectionDetails](state, payload) {
    return {
      ...state,
      collectionDetails: payload,
    }
  }
};

export default createReducer(handlers, {});
