import * as productActions from "../reducer/productDux";
import * as productUiActions from "../reducer/ui/productUiDux";
import * as registerUserUiActions from "../reducer/ui/registerUserUiDux";
import { post, get } from "../../utils/httpUtils";

export const getProductCategory = () => async (dispatch) => {
  const response = await get("products/categories");
  if (!!response.error) {
    dispatch(productUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setProductCategory(response));
    return {
      success: true,
    };
  }
};

export const getProductReviews = () => async (dispatch) => {
  const response = await get("products/reviews");
  if (!!response.error) {
    dispatch(productUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setProductReviews(response));
    return {
      success: true,
    };
  }
};

export const getProductCatId = () => async (dispatch, id) => {
  const response = await get(`products/categories/${id}`);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setProductCatId(response));
    return {
      success: true,
    };
  }
};

export const getProductCollectionList = (slug) => async (dispatch) => {
  const response = await get(`products/collections-slug/${slug}`);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setProductCollectionList(response));
    return {
      success: true,
    };
  }
};

export const getProductList = (id) => async (dispatch) => {
  const response = await get(`products/list?categoryId=${id}`);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setProductList(response));
    return {
      success: true,
    };
  }
};

export const getProduct = (slug) => async (dispatch) => {
  const response = await get(`products/details/${slug}`);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setProduct(response));
    return {
      success: true,
    };
  }
};

export const searchProduct = (productName) => async (dispatch) => {
  const response = await get(`products/search?name=${productName}`);
  if (!!response.error) {
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setSearchedProduct(response));
    return {
      success: true,
    };
  }
};

export const addSearchHistory = (data) => async (dispatch) => {
  const response = await post(`products/search-history`, data);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    return {
      success: true,
    };
  }
};

export const getProductSessionById = (id) => async (dispatch) => {
  const response = await get(`products/session/${id}`);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setSessionList(response));
    return {
      success: true,
    };
  }
};

export const getProductCollectionByGender = (value) => async (dispatch) => {
  const response = await get(`products/collections?gender=${value}`);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(productActions.setProductCollection(response));
    return {
      success: true,
    };
  }
};

export const getPaymentUrl = (data) => async (dispatch) => {
  const response = await post("orders/order-detail-payment", data);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    dispatch(productActions.setPaymentUrl(response));
    return {
      success: true,
    };
  }
};


export const getPaymentUrlForPackages = (data) => async (dispatch) => {
  const response = await post("orders/package-payment", data);
  console.log(data);
  console.log(response);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    dispatch(productActions.setPaymentUrl(response));
    return {
      success: true,
    };
  }
};

export const productSlots = (data) => async (dispatch) => {
  const response = await post("products/time-slots", data);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    dispatch(productActions.setProductSlots(response));
    return {
      success: true,
    };
  }
};

export const bookingScheduleSlots = (data) => async (dispatch) => {
  const response = await post("orders/create-session-schedule", data);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    // dispatch(productActions.setProductSlots(response));
    return {
      success: true,
    };
  }
};


export const upcomingBookingScheduleSlots = (data) => async (dispatch) => {
  const response = await post("orders/create-pending-session-schedule", data);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    // dispatch(productActions.setProductSlots(response));
    return {
      success: true,
    };
  }
};

export const bookingRescheduleSlots = (data) => async (dispatch) => {
  const response = await post("orders/update-schedule-date", data);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    // dispatch(productActions.setProductSlots(response));
    return {
      success: true,
    };
  }
};

export const bookingRescheduleStatus = (data) => async (dispatch) => {
  const response = await post("orders/update-schedule-status", data);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(response.message));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    // dispatch(productActions.setProductSlots(response));
    return {
      success: true,
    };
  }
};

export const getCollectionDetailsBySlug = (slug) => async (dispatch) => {
  const response = await get(`products/collection-details/${slug}`);
  if (!!response.error) {
    dispatch(registerUserUiActions.setError(false));
    return {
      success: false,
    };
  } else {
    dispatch(registerUserUiActions.setFetching(false));
    dispatch(productActions.setCollectionDetails(response));
    return {
      success: true,
    };
  }
};
