import { createAction, createReducer } from "redux-act";
export const setFormData = createAction("REGISTER/SET_FORM_DATA");
export const resetFormData = createAction("REGISTER/RESET_FORM_DATA");
export const authenticate = createAction("REGISTER/AUTHENTICATE");
export const setToken = createAction("PASSWORD/SET_TOKEN");
export const isExisting = createAction("REGISTER/SET_ISEXISTING");
export const setUserData = createAction("REGISTER/SET_USER_DATA");
export const setNewPin = createAction("PASSWORD/SET_NEW_PIN");
export const setUserAddress = createAction("USERS/SET_USER_ADDRESS");

const handlers = {
  [setFormData](state, payload) {
    return {
      ...state,
      formData: payload,
    };
  },
  [resetFormData](state) {
    return {
      ...state,
      formData: {},
    };
  },
  [setUserData](state, payload) {
    return {
      ...state,
      userData: payload,
    };
  },
  [authenticate](state, payload) {
    return {
      ...state,
      token: payload,
    };
  },
  [setToken](state, payload) {
    return {
      ...state,
      token: payload,
    };
  },
  [isExisting](state, payload) {
    return {
      ...state,
      existing: payload,
    };
  },
  [setUserAddress](state, payload) {
    return {
      ...state,
      address: payload,
    };
  },
};

export default createReducer(handlers, {});
