import { createAction, createReducer } from "redux-act";
export const setUserRating = createAction("FEEDBACK/SET_USER_RATING");
export const saveUserRating = createAction("FEEDBACK/SAVE_USER_RATING");

const handlers = {
  [setUserRating](state, payload) {
    return {
      ...state,
      rating: payload,
    };
  },
  [saveUserRating](state, payload) {
    return {
      ...state,
      userRating: payload,
    };
  },
};

export default createReducer(handlers, {});
