import React, { useState, useRef } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { getFormattedAddress } from "utils/helper";

const GoogleAutocomplete = ({
  setAddressFromGoogle,
  addressState,
  setSearchHandle,
  setAddressState,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const timerRef = useRef(null); // Use useRef to persist the timer
  const city = localStorage.getItem('citySelected');
  const handleChange1 = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    clearTimeout(timerRef.current); // Clear the previous timer
    timerRef.current = setTimeout(() => {
      fetchSuggestions(value);
    }, 500);
  };

  const fetchSuggestions = async (query) => {
    const apiUrl1 = `${process.env.REACT_APP_BACKEND_URL}/auto-complete-api/${city}?input=${query}`;
    try {
      const response = await fetch(apiUrl1);
      const data = await response.json();
      if (data.status) {
        const counter = localStorage.getItem("autocomplete_api")
        if (counter) {
          const value = Number(counter) + 1
          localStorage.setItem("autocomplete_api", value)
        } else {
          localStorage.setItem("autocomplete_api", 1)
        }
        setSuggestions(data.predictions.length > 0 && searchValue !== "" ? data.predictions : [{ description: "No Result found" }]);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleClick = async (place) => {
    const result = await getFormattedAddress(place)
    console.log(result, "Acbsvbhh")
    setAddressFromGoogle(result)
    setSearchValue(place.description);
    setSuggestions([]);
    localStorage.setItem("searchAddress", place.description);
  };

  const handleClear = () => {
    console.log("dhjbcvefk")
    setSearchValue("");
    setAddressState({
      ...addressState,
      building: "",
      floor: "",
      area: "",
      postalCode: "",
      lift: "",
      type: "home",
    })
    setSuggestions([]);
    setSearchHandle(true)
    addressState.formattedAddress = "";
  };

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        name="address"
        placeholder="Search Building/Flat/Area *"
        onChange={handleChange1}
        value={searchValue}
        autoComplete="off"
        className="form-input"
        style={{ marginBottom: "0" }}
      />
      {!searchValue && <div style={{ color: "#828282", fontSize: "12px" }}>Please select your location first*</div>}
      {(searchValue || addressState.formattedAddress.split(',').slice(2).join(',')) && (
        <button onClick={handleClear} style={{ position: 'absolute', right: '5px', top: '35%', background: '#e7e7e7', border: 'none', cursor: 'pointer', color: "gray" }}>
          <i className="fa fa-times"></i>
        </button>
      )}
      <ListGroup style={{ position: 'absolute', top: '70px', left: '0', zIndex: '1' }}>
        {suggestions &&
          suggestions.map((suggestion) => (
            <ListGroupItem
              key={suggestion.place_id}
              onClick={() => handleClick(suggestion)}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </ListGroupItem>
          ))}
      </ListGroup>
    </div>
  );
};

export default GoogleAutocomplete;