import React, { useState } from 'react'

const SEOContent = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div className='seoContent'>
            <p className='seoTitle'>Welcome to Avataar Skin – Where Beauty Meets Science</p>
            <p>
                At Avataar Skin,we know that real beauty is all about feeling good and confident in your own skin. Since we started, our goal has been to provide exceptional care to our patients, with a dedicated team working hard to offer the best solutions and customized treatment plans to meet your unique needs.
            </p>
            <p>
                As a leading <strong style={{ textDecoration: "underline" }}>laser and skin clinic</strong>, we specialize in a wide range of services including skin enhancement, anti-aging treatments, hair restoration, and more. Our expertise extends to pioneering techniques in laser hair reduction, medi-facials, and permanent makeup, reflecting our status as a premier <strong style={{ textDecoration: "underline" }}>cosmetic skin clinic.</strong>
            </p>
            {!isExpanded && <p onClick={handleReadMore} style={{ textDecoration: "underline", cursor: "pointer", color: "#cba05b" }}>Read More</p>}
            {isExpanded &&
                <>
                    <p>
                        Our team of highly qualified and skilled skin specialists is committed to delivering modern, effective treatments. At Avataar Skin, we use the latest techniques to ensure precise and satisfying results.<br/>
                    </p>
                    <p> Our AI skin analysis tool, helps to uncover issues and discover customized solutions by delving deeply into the particular requirements of your skin. Enjoy a free consultation with our dermatologists to develop a personalized treatment plan. Plus, with our convenient at-home service, you can enjoy top-notch skincare from the comfort of your own home. Experience the perfect blend of expertise and convenience with Avataar Skin, where achieving radiant, healthy skin has never been easier. We are proud to be your trusted <strong style={{ textDecoration: "underline" }}>skin & cosmetic clinic.</strong>
                    </p>
                    <p className='seoTitle'>Avataar Skin: Transform Your Beauty Routine with Premier At-Home Skincare Solutions</p>
                    <p>Everyone dreams of achieving stunning skin and flawless hair, and at Avataar Skin, we make that dream a reality. Our clinic is your ultimate destination for all your skin and hair care needs, delivered right to your doorstep.
                    </p>
                    <p>
                        We are committed to understanding your individual needs, recommending the ideal treatments, and crafting a personalized plan to help you achieve your desired results. Whether you need <strong style={{ textDecoration: "underline" }}>skin laser treatment near me</strong>, are looking for a  <strong style={{ textDecoration: "underline" }}>cosmetic clinic near me</strong>, or searching for a <strong style={{ textDecoration: "underline" }}>dermatologist near me</strong>, our at-home services provide top-notch care with unmatched convenience.
                    </p>
                    <p>
                        From anti-aging treatments and skin enhancement to hair restoration, our expert services are designed to help you maintain a youthful and vibrant appearance for years to come. We also specialize in <strong style={{ textDecoration: "underline" }}>laser hair reduction</strong>, permanent makeup, and medi-facials.
                    </p>
                    <p>
                        Our team of highly skilled, experienced, and qualified dermatologists in Delhi utilizes the latest techniques to ensure a truly exceptional and satisfying experience. With Avataar Skin, enjoy professional skincare and haircare without ever leaving your home.
                    </p>
                    <p className='seoTitle'>Why is Avataar Skin the best skin clinic in India?</p>
                    <p>
                        Who wouldn’t want to flaunt flawless skin and silky hair? At Avataar Skin Clinic, we understand this aspiration thoroughly. Our clinic is dedicated to providing exceptional skincare and haircare solutions, delivered conveniently at your home.
                    </p>
                    <p>
                        Our highly skilled dermatologists in India are focused on understanding your unique needs and offering the best treatments tailored specifically for you. With advanced training and state-of-the-art equipment, our specialists ensure remarkable results.
                    </p>
                    <p>
                        We offer a comprehensive range of treatments, including:
                    </p>
                    <li style={{ color: "#212529" }}>
                        Anti-aging treatments to keep you looking youthful.
                    </li>
                    <li>
                        Skin treatments for stubborn acne and other concerns.
                    </li>
                    <li>
                        Laser hair removal to achieve smooth, hair-free skin.
                    </li>
                    <li>
                        Hair restoration treatments addressing issues like dandruff, hair thinning, and hair loss.
                    </li>
                    <li>
                        Body Slimming treatments to target stubborn fat 
                    </li>
                    <li>
                        Body treatments like body brightening, body polish, body detan
                    </li>
                    <li>
                        Scar removal for pigmentation, skin moles, warts, and skin tags.
                    </li>
                    <li>
                        Permanent face makeup and medi-facials for a flawless appearance.
                    </li>
                    <p style={{ marginTop: "16px" }}>
                        Our services include  <strong style={{ textDecoration: "underline" }}>hydrafacial in Mumbai, hydrafacial in Gurgaon</strong>, and the convenience of <strong style={{ textDecoration: "underline" }}>hydrafacial at home</strong>. If you’re searching for a <strong style={{ textDecoration: "underline" }}>skin laser clinic near me</strong>, our at-home service covers major locations such as Delhi, Gurgaon, Noida, Greater Noida, Faridabad, Ghaziabad, Indore, Ahmedabad, Ludhiana, Bangalore, Hyderabad, Mumbai, Pune, Chandigarh, Mohali, and Panchkula.
                    </p>
                    <p>
                        Booking your appointment is simple—contact us via email, phone call, or text message. If you prefer to visit us in person,feel free to check out our accessible locations in Delhi, Gurgaon, Noida, Greater Noida, Faridabad, Ghaziabad, Indore, Ahmedabad, Ludhiana, Bangalore, Hyderabad, Mumbai, Pune, Chandigarh, Mohali, and Panchkula. Avataar Skin Clinic is your comprehensive solution for all skin and hair concerns, offering unparalleled convenience and care.
                    </p>
                    <p className='seoTitle'>What Makes Us Different from Others?</p>
                    <p>
                        At Avataar Skin, we focus on providing the best care with a strong commitment to ethical practices. Our goal is to offer top-quality treatments for a variety of skin concerns, all at affordable prices.
                    </p>
                    <p>
                        We use the latest technology to ensure that you get the most effective and up-to-date treatments available. Our clinic is equipped with advanced equipment, and we stay current with the newest methods to deliver the best results.
                    </p>
                    <p>
                    Our AI skin analysis tool accurately assesses your skin's condition, while our dermatologists provide expert guidance with a free consultation to develop a tailored treatment plan.
                    </p>
                    <p>
                        Our team is dedicated to treating you with kindness and understanding. We genuinely care about improving your skin and overall well-being.
                    </p>
                    <p>
                        What sets us apart is our ability to offer a wide range of services all in one place. From skincare to haircare, everything you need is conveniently available under one roof at Avataar Skin.
                    </p>
                    <p className='seoTitle'>Transform Your Skincare with Avataar Skin’s At-Home Clinic Services</p>
                    <p>
                        Treat yourself to professional skincare with Avataar Skin’s exclusive at-home clinic services. We bring expert skincare treatments directly to your doorstep, ensuring the highest standards of safety and care. Enjoy personalized, clinic-quality solutions without leaving your home and give your skin the attention it truly deserves.
                    </p>
                    <p>
                        Explore Our Premium Skincare Treatments:
                    </p>
                    <p className='seoSubTitle'>
                        The Treatments That Define Our Expertise: Explore Our Most Booked Services
                    </p>
                    <p className='seoText'>
                        Ultra Hydration
                    </p>
                    <p>
                        <a href="https://avataarskin.com/advanced-laser-treatments/ultra-hydration-for-women">hydrating facial treatment</a>: Deeply moisturize and rejuvenate your skin with our ultra-hydration treatments at home, designed to provide intense hydration and a youthful glow.
                    </p>
                    <p className='seoText'>
                        Body Contouring
                    </p>
                    <p>
                        <a href="https://avataarskin.com/advanced-laser-treatments/body-contouring-for-women">Body Contouring</a>: Shape and define your body with our advanced body contouring treatments at home, helping you achieve a more toned and sculpted look.
                    </p>
                    <p className='seoText'>
                        Anti-Aging
                    </p>
                    <p>
                        <a href="https://avataarskin.com/advanced-laser-treatments/anti-ageing-for-women">Anti-Aging:</a> Turn back the clock with our anti aging facial treatments at home. We target wrinkles, fine lines, and sagging skin to help you maintain a youthful appearance.
                    </p>
                    <p className='seoText'>
                        Tan Removal
                    </p>
                    <p>
                        <a href="https://avataarskin.com/advanced-laser-treatments/tan-removal-for-women">Tan Removal:</a> Remove unwanted tan and restore your natural skin tone with our effective laser tan removal treatments at home.

                    </p>
                    <p className='seoText'>
                        Pigmentation Reduction
                    </p>
                    <p>
                        <a href="https://avataarskin.com/advanced-laser-treatments/pigmentation-reduction-for-women">Pigmentation Reduction: </a>Reduce the appearance of pigmentation and achieve a more even skin tone with our specialized pigmentation reduction treatments at home.Our advanced laser treatment for pigmentation ensures effective and lasting results, giving you a flawless complexion.

                    </p>
                    <p className='seoText'>
                        Acne Control
                    </p>
                    <p>
                        <a href="https://avataarskin.com/advanced-laser-treatments/acne-control-for-women">Acne Control: </a>Manage and reduce acne breakouts with our targeted acne control treatments at home, leaving your skin clear and blemish-free.
                    </p>
                    <p className='seoSubTitle'>Avataarskin’s Premier Treatments: Explore Our Best Sellers</p>
                    <p className='seoText'>
                        Full Body Laser Hair Reduction
                    </p>
                    <p>
                        Achieve smooth, hair-free skin all over your body with our full body laser hair reduction service.Achieve effortlessly smooth, hair-free skin with our full body laser hair reduction service at home. For the best results, visit our <a href='https://avataarskin.com/laser-treatments/full-body-laser-hair-reduction'><strong style={{ color: "black" }}>full body laser hair removal</strong></a> cost, where advanced technology ensures effective and long-lasting hair removal.
                    </p>
                    <p className='seoText'>
                        Full Face Laser Hair Reduction
                    </p>
                    <p>
                        Enjoy a hair-free, flawless complexion with our full face laser hair reduction treatments at home. Experience exceptional results while learning about the <a href='https://avataarskin.com/laser-treatments/full-face-laser-hair-reduction-for-women'><strong style={{ color: "black" }}>laser treatment for face cost </strong></a>at our clinic. We offer transparent pricing and effective solutions to help you achieve smooth, radiant skin.
                    </p>
                    <p className='seoText'>
                        Bikini Line Laser Hair Reduction
                    </p>
                    <p>
                        Get beach-ready with our bikini line laser hair reduction, providing smooth, hair-free skin in sensitive areas at home. Explore our <a href='https://avataarskin.com/laser-treatments/bikini-line-laser-hair-reduction-for-women'><strong style={{ color: "black" }}>bikini laser hair removal cost in India</strong></a> to find out how affordable achieving silky skin can be.
                    </p>
                    <p className='seoText'>
                        Insta Collagen Boost Facial
                    </p>
                    <p>
                        Boost your skin's collagen production and achieve a youthful, radiant glow with our <a href='https://avataarskin.com/laser-treatments/insta-collagen-boost-facial-for-women'><strong style={{ color: "black" }}>Insta Collagen Boost Facial</strong></a>.
                    </p>
                    <p className='seoText'>
                        Abdomen Inch Loss
                    </p>
                    <p>
                        Target stubborn abdominal fat and achieve a slimmer waistline with our <a href='https://avataarskin.com/laser-treatments/abdomen-inch-loss-for-women'><strong style={{ color: "black" }}>abdomen inch loss treatments</strong></a> at home.
                    </p>
                    <p className='seoText'>
                        Back Polish
                    </p>
                    <p>
                        Exfoliate and rejuvenate your back with our luxurious back polish treatments at home, leaving your skin smooth and glowing.
                    </p>
                    <p className='seoText'>
                        Glutathione IV Treatment
                    </p>
                    <p>
                        Brighten your skin and improve your overall health with our Glutathione IV treatments. Known for their powerful antioxidant properties, these treatments can rejuvenate your complexion and give you a radiant glow. Our specialized <a href='https://avataarskin.com/laser-treatments/glutathione-for-women'><strong style={{ color: "black" }}>IV treatment for skin</strong></a> helps enhance your body's natural defenses, making you feel healthier and more vibrant. Experience the transformative benefits of Glutathione IV therapy today.
                    </p>
                    <p className='seoSubTitle'>LASER HAIR REDUCTION + FACIALS</p>
                    <p>
                        Combine the benefits of laser hair reduction and advanced facials for a comprehensive skincare solution.
                    </p>
                    <p className='seoText'>
                        Advanced Laser Facial
                    </p>
                    <p>
                        Experience the best in facial rejuvenation with our advanced laser facials. These treatments are perfect for addressing fine lines, wrinkles, acne scars, and pigmentation. Refresh and revitalize your skin, achieving a smoother and more radiant complexion with our <a href='https://avataarskin.com/advanced-laser-treatments/advanced-laser-facial-for-women'><strong style={{ color: "black" }}>advanced facials</strong></a>.
                    </p>
                    <p className='seoText'>GLUTATHIONE + FACIALS</p>
                    <p>
                        Enhance your skin's brightness and health with our combination of Glutathione treatments and advanced facials.
                    </p>
                    <p className='seoText'>
                        Full Arms/Legs Brightening
                    </p>
                    <p>
                        Achieve brighter, more even-toned arms and legs with our specialized brightening treatments.
                    </p>
                    <p className='seoText'>
                        DETAN + GLUTATHIONE
                    </p>
                    <p>
                        Remove tan and enhance your skin's radiance with our combination of DETAN and Glutathione treatments.
                    </p>
                    <p className='seoText'>
                        FAT BURN + INCH LOSS
                    </p>
                    <p>
                        Burn fat and lose inches with our targeted fat burn and inch loss treatments, helping you achieve your body goals.
                    </p>
                    <p className='seoText'>
                        Upper Body Slimming
                    </p>
                    <p>
                        Shape and tone your upper body with our advanced slimming treatments, designed to target stubborn fat and improve your physique.
                    </p>
                    <hr className="hrLine" />
                    <p className='seoSubTitle' style={{ marginTop: "16px" }}>
                        Our Comprehensive Services
                    </p>
                    <p className='seoText'>
                        <a href='https://avataarskin.com/advanced-laser-treatments/advanced-laser-facial-for-women'>
                            Advanced Laser Facials
                        </a>
                    </p>
                    <p>
                        Experience the cutting-edge in facial rejuvenation with our advanced laser facials. Our treatments target deep layers of your skin to promote collagen production, reduce wrinkles, and leave you with a radiant glow.
                    </p>
                    <p className='seoText'>
                        <a href='https://avataarskin.com/advanced-laser-treatments/laser-hair-reduction-for-women'>
                            Laser Hair Reduction
                        </a>
                    </p>
                    <p>
                        Say goodbye to unwanted hair with our laser hair reduction treatments. Safe and effective for all skin types, our services ensure smooth, hair-free skin with minimal discomfort and long-lasting results.
                    </p>
                    <p className='seoText'>
                        <a href='https://avataarskin.com/advanced-laser-treatments/slimming-for-women'>
                            Slimming Treatments
                        </a>
                    </p>
                    <p>
                        Achieve your body goals with our advanced slimming treatments. Using the latest technologies, we help you contour your body, reduce stubborn fat, and achieve a slimmer, more toned physique.
                    </p>
                    <p className='seoText'>
                        <a href='https://avataarskin.com/advanced-laser-treatments/chemical-peel-not-active-110'>
                            Chemical Peels
                        </a>
                    </p>
                    <p>
                        Our chemical peels are designed to exfoliate and rejuvenate your skin. By removing the outermost layer of dead skin cells, we reveal a brighter, more youthful complexion and improve skin texture and tone.
                    </p>
                    <p className='seoText'>
                        <a href='https://avataarskin.com/advanced-laser-treatments/permanent-makeup-for-women'>
                            Permanent Makeup
                        </a>
                    </p>
                    <p>
                        Wake up flawless every day with our permanent makeup services. From perfectly shaped eyebrows to defined lips, our expert artists provide natural-looking enhancements that save you time and effort.
                    </p>
                    <p className='seoText'>
                        <a href='https://avataarskin.com/advanced-laser-treatments/skin-rejuvenation-for-women'>
                            Skin Rejuvenation
                        </a>
                    </p>
                    <p>
                        Reinvigorate your skin with our comprehensive skin rejuvenation treatments. We address issues such as dullness, uneven skin tone, and fine lines, giving you a fresh and youthful appearance.
                    </p>
                    <p className='seoText'>
                        <a href='http://localhost:3000/advanced-laser-treatments/prp-treatment-not-active-153'>
                            Invasive Treatments
                        </a>
                    </p>
                    <p>
                        For those seeking more intensive results, our invasive treatments offer powerful solutions for skin tightening, wrinkle reduction, and overall skin rejuvenation.
                    </p>
                    <p className='seoText'>
                        <a href='http://localhost:3000/advanced-laser-treatments/hair-treatment-for-women'>
                            Hair Treatments
                        </a>
                    </p>
                    <p>
                        Combat hair loss and promote healthy hair growth with our specialized hair treatments. We provide effective solutions for thinning hair, dandruff, and scalp health to ensure your locks look their best.
                    </p>
                    {isExpanded && <p onClick={handleReadMore} style={{ textDecoration: "underline", cursor: "pointer", color: "#cba05b" }}>Read Less</p>}
                </>}
        </div>
    )
}

export default SEOContent