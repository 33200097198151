import CustomModal from 'common/CustomModal';
import React from 'react'
import PhoneInput from 'react-phone-input-2';
import scheduleCompleteIcon from "assets/images/scheduleCompleteIcon.svg";


const EditProfile = ({
    editProfilePopup,
    handleModalClose,
    updatedProfile,
    updatedProfileHandler,
    formErrors,
    formValues,
    formValues1,
    handleChange1,
    mobileValidation,
    genderFemale,
    genderMale,
    handleValueChange,
    preTreatmentHandler2,
    user

}) => {
    return (
        <CustomModal
            show={editProfilePopup}
            onClose={() => {
                handleModalClose();
                window.location.reload();
            }}
            className="customPopup innerPopup authPopup"
        >
            {updatedProfile ? (
                <>
                    <div className="modal_content form_wrapper">
                        <div className="innerContent">
                            <span>
                                <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy" />
                            </span>
                            <p>Profile Successfully Updated!</p>
                        </div>
                    </div>
                    <div className="master-form-group scheduleBox">
                        <button onClick={updatedProfileHandler} className="form_button">
                            CONTINUE
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="modal_content form_wrapper basic ediProfile">
                        <div className={`master-form-group4 long `}>
                            <h4>EDIT PROFILE</h4>
                        </div>
                        <div className="address-form">
                            <div className="basic-info">
                                <div
                                    className={`master-form-group ${formErrors.fullName ? "master-input-error" : ""
                                        }`}
                                >
                                    <span className="label">Full Name</span>
                                    <input
                                        type="text"
                                        name="fullName"
                                        contentEditable
                                        placeholder="Name *"
                                        className={`form-input ${formErrors.fullName ? "master-input-error" : ""
                                            }`}
                                        onChange={handleChange1}
                                        value={formValues1.fullName}
                                    />
                                    {formErrors.firstName && (
                                        <div className="message-error">
                                            <span className="text-danger1">
                                                {formErrors.firstName}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`master-form-group long ${mobileValidation ? "master-input-error" : ""
                                        }`}
                                >
                                    <span className="label">Mobile Number</span>
                                    <PhoneInput
                                        name="mobile"
                                        id="mobile"
                                        readonly={true}
                                        contentEditable={false}
                                        disabled
                                        country={formValues.countryCode}
                                        value={`+91 ${user?.phoneNumber} `}
                                        className={`form-input ${formErrors.mobile ? "master-input-error" : ""
                                            }`}
                                    />
                                    {formErrors.mobile && (
                                        <div className="message-error">
                                            <span className="text-danger1">
                                                {formErrors.mobile}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={`master-form-group editGender${formErrors.fullName ? "master-input-error" : ""
                                        }`}
                                >
                                    <span className="label">Gender</span>
                                    <ul className="mid-1-check">
                                        <li className="chkinput">
                                            <label
                                                htmlFor="male"
                                                className={`${genderMale ? "checked" : ""}`}
                                            >
                                                <input
                                                    type="radio"
                                                    id="male"
                                                    onChange={handleValueChange}
                                                    name="gender"
                                                    value="male"
                                                />
                                                <span>Male</span>
                                            </label>
                                        </li>
                                        <li className="chkinput">
                                            <label
                                                htmlFor="female"
                                                className={`${genderFemale ? "checked" : ""}`}
                                            >
                                                <input
                                                    type="radio"
                                                    id="female"
                                                    onChange={handleValueChange}
                                                    name="gender"
                                                    value="female"
                                                />
                                                <span>Female</span>
                                            </label>
                                        </li>
                                        <li className="chkinput">
                                            <label htmlFor="other">
                                                <input
                                                    type="radio"
                                                    id="other"
                                                    onChange={handleValueChange}
                                                    name="gender"
                                                    value="other"
                                                />
                                                <span>Other</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="master-form-group scheduleBox">
                        <button onClick={preTreatmentHandler2} className="form_button">
                            UPDATE PROFILE
                        </button>
                    </div>
                </>
            )}
        </CustomModal>
    )
}

export default EditProfile