import React from 'react'

import PopupStar from "assets/images/PopupStar.svg";
import doneicon from "assets/images/done-icon.svg";
import CustomModal from 'common/CustomModal'
import EmojiRating from 'components/EmojiRating';

import "./completed.css";

const Completed = ({
  rateUs,
  handleModalClose,
  productName1,
  sessionScheduleId,
  recommendationRating,
  handleTherapistRatingChange,
  handleRecommendationRatingChange,
  handleSubmit,
  therapistRating,
  success
}) => {
  return (
    <>
      <CustomModal
        show={rateUs}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div>
          <p className="heading1 text-center">We would love to hear your feedback!</p>
          <p className="headingSession text-center">{productName1!=="" && `${productName1}, Session-${sessionScheduleId}`}</p>
          <div className="contentWrap">

            <div>
              <p className='question'>On a scale of 1-10, how likely are you to recommend Avataar Skincare to a friend or colleague?</p>
              <input
                type="range"
                max={10} min={0}
                value={recommendationRating} step={1}
                onChange={handleRecommendationRatingChange}
                className="slider" /> &nbsp;
              {recommendationRating}
            </div>

            <div>
              <p className='question'>How would you rate the professionalism and responsiveness of our therapist?</p>
            <EmojiRating handleTherapistRatingChange={handleTherapistRatingChange}/>
              
            </div>


            <button onClick={handleSubmit} type="button"
              className={`${therapistRating === null ? "disableButton" : "submitButton"}`}
              disabled={therapistRating === null ? true : false}>Submit</button>

          </div>
        </div>
      </CustomModal>
      <CustomModal
        show={success}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper feedsSubmitted">
          <div className="starImage">
            <span>
              <img src={PopupStar} alt="PopupStar" width={100} height={100} loading="lazy"/>
            </span>
            <span>Thank You For Your Feedback</span>
          </div>

          <div className="aftercare">
            <h4>Aftercare tips</h4>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy" />
              </span>
              <p>Moisturise your feet regularly</p>
            </div>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy"/>
              </span>
              <p>Trim your long nails regularly to prevent breakages</p>
            </div>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy" />
              </span>
              <p>Give your cuticles an oil massage for nourisment regularly</p>
            </div>
          </div>
          <div className="master-form-group scheduleBox">
          <button
            onClick={() => window.open("https://g.page/r/Ceg956j-dvfCEBM/review", '_blank')
          }
            className="form_button"
          >
            Rate us on Google
          </button>
        </div>
        </div>
      </CustomModal></>
  )
}

export default Completed