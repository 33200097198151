import { combineReducers } from "redux";
import user from "./userDux";
import userUi from "./ui/userUiDux";
import profileUi from "./ui/userUiDux";
import productUi from "./ui/productUiDux";
import registerForm from "./registerDux";
import forgotPasswordUi from "./ui/forgotPasswordUiDux";
import forgotPassword from "./forgotPasswordDux";
import registerUserUi from "./ui/registerUserUiDux";
import resetPasswordUi from "./ui/resetPasswordUiDux";
import resetPassword from "./resetPasswordDux";
import profile from "./profileDux";
import feedback from "./feedbackDux";
import product from "./productDux";
import cart from "./cartDux";

export default combineReducers({
  ui: combineReducers({
    user: userUi,
    registerForm: registerUserUi,
    forgotPassword: forgotPasswordUi,
    resetPassword: resetPasswordUi,
    profile: profileUi,
    product: productUi,
    userData: userUi,
  }),
  entities: combineReducers({
    registerForm,
    forgotPassword,
    resetPassword,
    profile,
    product,
    cart,
    feedback,
  }),
  user,
});
