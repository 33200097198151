import axios from 'axios';
import Navbar from 'common/Navbar';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import appstoreicon from "assets/images/app-store-icon.webp";
import googleplayicon from "assets/images/play-store-icon.webp"
import PageLayout from 'common/PageLayout';
import shoppingcart from "assets/images/shoppingcart.svg";
import CustomModal from 'common/CustomModal';
import { IpAddressTracking } from 'utils/helper';


const PackageDetails = () => {
    const { id } = useParams();

    const [cartPopUp, setCartPopUp] = useState(false);
    const [signupPopup, setSignupPopup] = useState(false);
    const [packageId, setPackageId] = useState();
    const [particularPackage, setParticularPackage] = useState();
    const [packageProducts, setPackageProducts] = useState([]);
    const [selectedSessions, setSelectedSessions] = useState({});
    const [bodyParts, setBodyParts] = useState({});
    const [sessionIds, setSessionIds] = useState({});
    const [stickyClass] = useState("relative");

    const token1 = localStorage.getItem("token");

    const signupModalHandler = () => {
        setSignupPopup(true);
    };

    const signupModaCloselHandler = () => {
        setSignupPopup(false);
    };
    const handleOpenCart = async (id) => {
        setPackageId(id);
        setCartPopUp(true);
    }


    const handleAddToCart = async () => {
        let output = [];

        if (particularPackage?.bodyParts) {
            let totalSelectedSessions = 0;
            for (const key in selectedSessions) {
                if (selectedSessions.hasOwnProperty(key)) {
                    totalSelectedSessions += selectedSessions[key] || 0;
                }
            }
            if (totalSelectedSessions < particularPackage?.numberOfSessions) {
                alert(`You have to select ${particularPackage?.numberOfSessions} sessions to add to cart`);
                return;
            }
            if (totalSelectedSessions > particularPackage?.numberOfSessions) {
                alert("Total number of sessions exceeded the maximum allowed.");
                return;
            }
            for (const key in sessionIds) {
                if (sessionIds.hasOwnProperty(key)) {
                    const productId = parseInt(key);
                    const sessionId = sessionIds[key];
                    const bodyPart = bodyParts[key];

                    output.push({
                        productId: productId,
                        sessionId: sessionId,
                        bodyParts: [bodyPart]
                    });
                }
            }
        }
        else {
            output = packageProducts.map((item) => ({ productId: item.productId, sessionId: item.productsessionId }));
        }
        const res = await IpAddressTracking();
        const body = {
            packageId: parseInt(packageId, 10),
            productSessions: output,
            ipInfo: res ? res : null,
        }

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/carts/package`, body);
        if (response.status === 200 || response.status === 201) {
            window.location.href = "/cart"
        }
    }

    const handleSessionChange = (productId, sessions, sessionId) => {
        if (sessions) {
            // When selected
            setSelectedSessions(prev => ({
                ...prev,
                [productId]: sessions
            }));
            setSessionIds(prev => ({
                ...prev,
                [productId]: sessionId
            }));
        } else {
            // When deselected
            setSelectedSessions(prev => {
                const updatedSessions = { ...prev };
                delete updatedSessions[productId];
                return updatedSessions;
            });
            setSessionIds(prev => {
                const updatedSessionIds = { ...prev };
                delete updatedSessionIds[productId];
                return updatedSessionIds;
            });
        }
    };

    const handleInputChangeHipe = (productId, bodypart) => {
        setBodyParts({
            ...bodyParts,
            [productId]: bodypart
        });
    }

    const handleModalClose = () => {
        setCartPopUp(false);
    };

    const discountedPercent = (originalPrice, discountedPrice) => {
        return parseInt(((originalPrice - discountedPrice) / originalPrice) * 100);
    }

    useEffect(async () => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/packages/${id}`);
        const { data } = res;
        setParticularPackage(data.packageDetail)
        setPackageProducts(data.packageDetail.products)
    }, [])


    return (
        <div>
            <CustomModal
                show={cartPopUp}
                onClose={handleModalClose}
                className="customPopup innerPopup"
            >
                <div className="modal_content form_wrapper">
                    <div className="master-form-group  addBox session">
                        <p>Package Products</p>
                        {particularPackage?.bodyParts && <button
                            disabled={Object.keys(selectedSessions).length === 0}
                            onClick={() => {
                                setSelectedSessions({});
                                setSessionIds({});
                                setBodyParts({});
                                const checkboxes = document.querySelectorAll('.particularSession input[type="checkbox"]');
                                checkboxes.forEach(checkbox => checkbox.checked = false);
                            }}
                            style={{
                                border: "none",
                                borderRadius: "20px",
                                backgroundColor: "black",
                                color: "white",
                                padding: "10px 24px",
                            }}>RESET</button>}
                        {!particularPackage?.bodyParts &&
                            <div style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", width: "100%" }}>
                                <div className='prices' style={{ paddingBottom: "24px", paddingTop: "24px", alignSelf: "end" }}>
                                    <div className='finalPrice'>
                                        ₹{particularPackage?.finalPrice}
                                    </div>
                                    <div>
                                        {particularPackage?.discount > 0 ? (
                                            <div className='discountPrice'>
                                                <div className='saveAmount'>Save {discountedPercent(particularPackage?.price, particularPackage?.finalPrice)}%</div>
                                                <s className='numberofSessions' style={{ color: "#a9a9a9" }}> ₹{particularPackage?.price}</s>

                                            </div>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                </div>
                                <ul className='detailsContainer'>
                                    {packageProducts.map((item) => (
                                        <div
                                            key={item.productId}
                                            className='particularSession'
                                        >
                                            <label htmlFor={item.productId}>
                                                <span>
                                                    <b>{item.name} - {item.gender}</b>
                                                    <div className='numberofSessions'>Number Of Sessions: {item.numberOfSessions}</div>
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </ul>

                            </div>
                        }

                        {particularPackage?.bodyParts &&
                            <ul className='detailsContainer'>
                                <label style={{ fontWeight: "bolder" }}>Maximum Number of Sessions to be booked: {particularPackage.numberOfSessions}</label>
                                {packageProducts.map((item) => (
                                    <div
                                        key={item.id}
                                        className='particularSession'
                                    >
                                        <label htmlFor={item.id} style={{ width: "100%" }}>
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    id={item.id}
                                                    name={item.name}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            handleSessionChange(item.id, 1, item.productSessions[0]?.sessionId);
                                                        }
                                                        else {
                                                            handleSessionChange(item.id, false);
                                                        }
                                                    }}
                                                />
                                                <b>&nbsp;&nbsp;{item.name} - {item.gender}</b>
                                            </span>
                                            <div>
                                                {selectedSessions[item.id] && (
                                                    <div style={{ display: 'flex', justifyContent: "space-between", marginLeft: "24px" }}>
                                                        <div>
                                                            <label style={{ fontSize: "14px" }}>Number of Sessions: </label>&nbsp;
                                                            <select
                                                                style={{ border: "none", borderBottom: "1px solid black" }}
                                                                value={selectedSessions[item.id]}
                                                                onChange={(e) => handleSessionChange(item.id, Number(e.target.value), item.productSessions[e.target.value - 1]?.sessionId)}
                                                            >
                                                                {Array.from({ length: item.maxSessions }, (_, i) => i + 1).map((number) => (
                                                                    <option key={number} value={number}>{number}</option>
                                                                ))}
                                                            </select>
                                                            <div style={{ marginTop: "10px", paddingLeft: "56px" }}>
                                                                <label style={{ fontSize: "14px" }}>Body Parts: </label>&nbsp;

                                                                <select
                                                                    value={bodyParts[item.id] || ''}
                                                                    onChange={(e) => handleInputChangeHipe(item.id, e.target.value)}
                                                                    style={{ border: "none", borderBottom: "1px solid black" }}
                                                                >
                                                                    <option value="" disabled>Select Options</option>
                                                                    {particularPackage?.bodyParts?.map((part, index) => (
                                                                        <option key={index} value={part}>{part}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='prices' style={{ width: "max-content" }}>
                                                            <div className='finalPrice' style={{ color: "black" }}>
                                                                ₹{item.productSessions[selectedSessions[item.id] - 1]?.finalPrice}
                                                            </div>

                                                            <div>
                                                                {item.productSessions[selectedSessions[item.id] - 1]?.discount > 0 ? (
                                                                    <div className='discountPrice'>
                                                                        <div className='saveAmount'>₹ Save {item.productSessions[selectedSessions[item.id] - 1]?.discount}%</div>
                                                                        <s className='numberofSessions' style={{ color: "#a9a9a9" }}> ₹{item.productSessions[selectedSessions[item.id] - 1]?.price}</s>
                                                                    </div>
                                                                ) : (
                                                                    <p></p>
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>

                                                )}
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </ul>}
                    </div>

                    <div className="master-form-group scheduleBox " id="categoryButton">
                        <button
                            className={`form_button`}
                            onClick={handleAddToCart}
                        >
                            ADD TO CART
                        </button>
                    </div>
                </div>
            </CustomModal>
            <Navbar onSignup={signupPopup} onSignupClose={signupModaCloselHandler} />
            <div className="wrapper productDetailPage pageHeading">
                <h2>{particularPackage?.packageName}</h2>
                <h6>
          <span onClick={() => window.location.href=("/")}>Home</span> <span>/</span>
          <span onClick={() => window.location.href=("/packages")}>Packages</span>{" "}
          <span>/</span>
          <span onClick={() => window.location.reload()}>
            {particularPackage?.packageName}
          </span>
        </h6>

                <PageLayout>
                    <div className='left'>
                        <img src={particularPackage?.image} alt="overView Placeholder" loading="lazy" className='packagesImage'/>
                        <div className="action">
                            {token1 ? (
                                <span onClick={() => handleOpenCart(id)}>
                                    Add To Cart
                                </span>
                            ) : (
                                <span onClick={signupModalHandler}>Add To Cart</span>
                            )}
                        </div>
                        <div className="overviewList new">
                            <div className="cartItemDetails listStyles ">
                                <h4>OVERVIEW</h4>
                                <div className="description">
                                    <div
                                        className="overview-section"
                                        dangerouslySetInnerHTML={{ __html: particularPackage?.packageDescription }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className={`discounts  ${stickyClass}  `}>
                            <ul>
                                <li className="active">
                                    <div className="left" leftIcon>
                                        <img
                                            src={shoppingcart}
                                            alt="shopping cart icon"
                                            className="cartImg"
                                            loading="lazy"
                                        />
                                        <h5>
                                            Introductory App pricing
                                            <br />
                                            <span>
                                                Flat 20% discount on 6 sessions or more when you
                                                download the Avataar Skincare App.
                                            </span>
                                            <div className="app-icons-container">
                                                <a
                                                    href="https://apps.apple.com/in/app/avataar-skincare/id6468362044"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onclick="return gtag_report_conversion('https://apps.apple.com/in/app/avataar-skincare/id6468362044');"
                                                >
                                                    <img
                                                        src={appstoreicon}
                                                        alt="App Store"
                                                        class="mw-70 image-class"
                                                        loading="lazy"
                                                    />
                                                </a>

                                                <a
                                                    href="https://play.google.com/store/apps/details?id=com.avataarskin.app"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onclick="return gtag_report_conversion('https://play.google.com/store/apps/details?id=com.avataarskin.app');"
                                                >
                                                    <img
                                                        src={googleplayicon}
                                                        alt="Google Store"
                                                        class="mw-70 image-class"
                                                        loading="lazy"
                                                    />
                                                </a>
                                            </div>
                                        </h5>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </PageLayout>
            </div>
        </div>
    )
}

export default PackageDetails