import React from "react";
import CustomModal from "common/CustomModal";
import scheduleCompleteIcon from "../../assets/images/scheduleCompleteIcon.svg";

function SessionScheduleSuccessModal({
  successModal,
  handleModalClose,
  scheduleSuccessHandler,
}) {
  return (
    <div>
      <CustomModal
        show={successModal}
        onClose={handleModalClose}
        className="customPopup innerPopup orderSuccess"
      >
        <div className="modal_content form_wrapper feedsSubmitted">
          <div className="innerContent">
            <span>
              <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy"/>
            </span>
            <p>Session schedule successfully</p>
            <span>
              We will confirm your booking for the scheduled time in a moment.
              Please reachout to us at +91 9810339778 for any further queries.
            </span>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button onClick={scheduleSuccessHandler} className="form_button">
            CONTINUE
          </button>
        </div>
      </CustomModal>
    </div>
  );
}

export default SessionScheduleSuccessModal;
