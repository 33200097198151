import React from "react";
import "./AnalyseSkin.css";

import SkinAnalysisPng from "../assets/images/SkinAnalysis.png";
import faceRecognition from "../assets/images/face-recognition.svg";
import { IpAddressTracking } from "utils/helper";
import axios from "axios";

export const AnalyseSkin = () => {
  const onClick = async () => {
    const res = await IpAddressTracking();
    try {
      const response=await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/save-ip-details`, {
        type: "ai-view",
        ipInfo: res
      })
      console.log(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="wrapperContainer">
      <h2 className="heading" style={{marginTop:"16px"}}>Decode Your Skin Issues with AI Precision</h2>
      <div className="skinAnalysisContainer">
        <div className="skinAnalysisImageContainer">
          <img
            src={SkinAnalysisPng}
            alt="skin analysis"
            style={{
              display: "block",
              margin: "20px auto",
            }}
            loading="lazy"
          />
        </div>

        <div className="skinAnlaysisTextContainer">
          <p className="skinAnalysisText">
            Uncover your skin's root causes with our AI skin analyzer for a
            personalized path to radiant skin
          </p>

          <p className="skinAnalysisSubText">
            Your customized skincare analysis is just one click away.
          </p>

          <div className="analysisBtnContainer" onClick={onClick}>
            <a
              href="https://saas.haut.ai/b2c/first-entrance?app_id=10455&company_id=b106d488-152f-4cd4-a2fd-ee3fdf1730aa&utm_campaign=b106d488-152f-4cd4-a2fd-ee3fdf1730aa-Avataar&company_cid=HSSN-0123"
              target="_blank"
              rel="noopener noreferrer"
              className="analysisBtn"
            >
              <div className="analysisSvgContainer">
                <div>
                  <img
                    className="analysisSvg"
                    src={faceRecognition}
                    alt="faceRecognition"
                    loading="lazy"
                  />
                </div>

                <div>
                  <span className="analysisBtnText">Take Facial Test</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
