import Navbar from 'common/Navbar'
import React, { useState } from 'react'
import styles from "./style.module.css";
import OwlCarousel from 'react-owl-carousel'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-responsive-tabs/styles.css";
import scheduleCompleteIcon from "assets/images/scheduleCompleteIcon.svg";

import Reviews from 'containers/HomePage/Reviews';
import reviewsData from 'reviewsData';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CustomModal from 'common/CustomModal';

const LeadForm = () => {
    const [activeFaqIndex, setActiveFaqIndex] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const { slug } = useParams();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        pinCode: '',
        slug: slug,
    });


    const handleFaqClick = (index) => {
        setActiveFaqIndex(index === activeFaqIndex ? null : index);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/lead-form`,
            formData,
        );
        if (result.status === 201) {
            setSubmitted(true)
        }
    };

    const options = {
        items: 1,
        responsiveClass: true,
    };

    const generateVideoImgSlider = () => {
        let sliderArray = images;

        return sliderArray.map((item, index) => {
            {
                return (
                    <div className="slider_content">
                        <div className="">
                            <span>
                                <img key={index} src={item} alt="overView Placeholder" loading="lazy" />
                            </span>
                        </div>
                    </div>
                );
            }
        });
    };

    const images = [
        "https://avataarskincare.imgix.net/product-data-images/F+LHR+chin+shave+2.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://avataarskincare.imgix.net/product-data-images/F+LHR+abs+Ecg+gel+2.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://avataarskincare.imgix.net/product-data-images/F+LHR+arms+laser+probe+2.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://avataarskincare.imgix.net/product-data-images/F+LHR+Chin+SS.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    ];

    const faqs1 = [{
        name: "What is Laser Hair Reduction",
        description: "Lasers can only reduce hair growth, they cannot eliminate hair entirely. You can expect an 80- 90% reduction in growth over 6 or more sessions depending on your body, medical history, and hormones. You might need subsequent maintenance sessions        "
    },
    {
        name: "What is the key factor for achieving results",
        description: "For optimal results, lasers target the melanin in pigmented hair follicles to arrest growth, the treatment is ineffective on white or gray hair. Individuals of all skin types can undergo treatment although best outcomes are achieved when there is a notable contrast between hair and skin color    "
    },
    {
        name: "Under what circumstances should the treatment be avoided",
        description: "Avoid the treatment in case you are pregnant, have body implants, or menstruating"
    },
    {
        name: "Is the treatment painful",
        description: "We offer treatment with painless diode technology. However, you might feel pricks on a few occasions during the treatment which will be more amplified in pigmented areas",
    },
    {
        name: "Are there any side effects",
        description: "The Treatment is 100% safe, there are no permanent side effects. However, if your skin is sensitive you might face itching, redness, or bumps which will subside over a few hours/days"
    },
    {
        name: "How to prepare for the treatment",
        description: "Prior to your treatment session, refrain from waxing, plucking, or bleaching, ensure 3-4 weeks of full hair growth, avoid using makeup, lotion, or deodorant on the treatment area, steer clear of chemical peels or aesthetic treatments for at least 15 days, and minimize caffeine intake to minimize sensitivity"
    }
    ]

    return (
        <div>
            <Navbar />

            <CustomModal
                show={submitted}
                onClose={() => window.location.reload()}
                className="customPopup innerPopup orderSuccess"
            >
                <div className="modal_content form_wrapper feedsSubmitted">
                    <div className="innerContent">
                        <span>
                            <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy" />
                        </span>
                        <span>
                            Thank you for your Query. We Will get back to you soon
                        </span>
                    </div>
                </div>
                <div className="master-form-group scheduleBox">
                    <button onClick={() => window.location.reload()} className="form_button">
                        CONTINUE
                    </button>
                </div>
            </CustomModal>

            <div className={styles.container}>
                <p className={styles.heading}>Effortless, Pain-Free Hair Reduction at Home<br />
                    Say Goodbye to Unwanted Hair with Avataar Skincare’s Advanced Laser Technology.
                </p>

                <p className={styles.subHeading}>
                    Achieve Silky Smooth Skin with Our Safe and Effective At-Home Laser Hair Reduction<br />
                    Enjoy Professional-Grade Results Without Leaving Your Comfort Zone.
                </p>
                <OwlCarousel
                    className={`owl-theme ${styles.image}`}
                    loop={true}
                    autoplay={true}
                    responsiveClass={true}
                    {...options}
                >
                    {generateVideoImgSlider()}
                </OwlCarousel>
                <div className={styles.values}>
                    <p className={styles.valueHeading}>Why Choose Avataar Skincare?</p>
                    <li><strong>Clinically Proven Technology</strong>: Experience long-lasting results with our US FDA-approved laser.</li>
                    <li><strong>Convenience at Your Home</strong>: No more salon visits; get professional results in the privacy of your home.</li>
                    <li><strong>Safe for All Skin Types</strong>: Gentle on the skin, effective on all dark color hair types </li>
                    <li><strong>Affordable Luxury</strong>: Premium treatment without the premium price tag.</li>
                    <li><strong>Free Doctor Consultation</strong>: Get a free virtual doctor consultation without leaving your home</li>
                    <li><strong>Tech That Keeps You On Track</strong>: Seamlessly Manage Your Sessions with the Avataar App</li>
                </div>
                <div className={styles.buttons}>
                    <span onClick={() => window.open("https://calendly.com/d/ckj2-skd-6pt", "_blank")}
                        style={{
                            backgroundColor: "black",
                            padding: "8px 12px",
                            borderRadius: "10px",
                            color: "white",
                            cursor: "pointer",
                            alignSelf: "center",
                            width: "max-content",
                            marginTop: "20px"
                        }}>
                        Book Your Free Consultation Now
                    </span>
                    <span onClick={() => window.location.href = "/"}
                        style={{
                            backgroundColor: "black",
                            padding: "8px 12px",
                            borderRadius: "10px",
                            color: "white",
                            cursor: "pointer",
                            alignSelf: "center",
                            width: "max-content",
                            marginTop: "20px"
                        }}>
                        Shop Now for Special Offers
                    </span>
                </div>
                <div className={styles.form}>
                    <p className={styles.heading}>Claim Your Free Consultation</p>
                    <form onSubmit={handleSubmit} className={styles.leadForm}>
                        <div className={styles.formGroup}>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="phone">Phone</label>
                            <input
                                type="number"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="pincode">Pincode</label>
                            <input
                                type="text"
                                id="pinCode"
                                name="pinCode"
                                value={formData.pinCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <button type="submit" className={styles.submitButton}>Submit</button>
                    </form>
                </div>
                <Reviews reviews={reviewsData} />

                <div className={styles.faqSection}>
                    <h4 className={styles.faqTitle}>FREQUENTLY ASKED QUESTIONS</h4>
                    <div className={styles.faqList}>
                        {faqs1.map((item, index) => (
                            <div key={index} className={styles.faqItem}>
                                <div
                                    className={styles.faqName}
                                    onClick={() => handleFaqClick(index)}
                                >
                                    {item.name}
                                </div>
                                {activeFaqIndex === index && (
                                    <div className={styles.faqDescription}>
                                        {item.description}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeadForm
