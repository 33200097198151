import React, { useEffect, useState, useMemo, useCallback } from "react";
import Navbar from "common/Navbar";
import { mdiArrowLeft, mdiChevronRight } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import CustomModal from "../common/CustomModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Icon from "@mdi/react";
import * as authThunks from "../redux/thunks/authThunk";
import { useDispatch, useSelector } from "react-redux";
import * as profileThunks from "../redux/thunks/profileThunk";
import scheduleCompleteIcon from "../assets/images/scheduleCompleteIcon.svg";
import * as profileActions from "../redux/reducer/profileDux";
import map_pin from "../assets/images/map_pin.svg";
import { IpAddressTracking } from "utils/helper";

const Profile = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(
    () => { }, // eslint-disable-next-line
    []
  );

  const initialValues = { mobile: null, countryCode: "in", otp: "" };
  const initialValues1 = { fullName: "", gender: "" };
  const initialValues2 = {
    pincode: "",
    state: "",
    address: "",
    type1: "",
    city: "",
    town: "",
  };
  const initialValues3 = { type: "" };

  const [mobileValidation, setMobileValidation] = useState("");
  const [formValues, setFormValues] = useState(initialValues);
  const [formValues1, setFormValues1] = useState(initialValues1);
  const [formValues2, setFormValues2] = useState(initialValues2);
  const [formValues3, setFormValues3] = useState(initialValues3);
  const [addNewAddressPopup, setAddNewAddressPopup] = useState(false);

  const [updatedAddress, setUpdatedAddress] = useState(false);

  const [addTempData, setAddTempData] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [addAnotherAddressPopup, setAddAnotherAddressPopup] = useState(false);
  const [typeCheckedOne, setTypeCheckedOne] = useState(false);
  const [typeCheckedTwo, setTypeCheckedTwo] = useState(false);
  const [deleteAccountConfirmPopup, setDeleteAccountConfirmPopup] =
    useState(false);

  const { userAddress } = useSelector((state) => state.entities.profile);
  const id = Number(formValues3?.type);
  console.log(id);

  const handleModalClose = () => {
    setAddressPopup(false);
    setAddAnotherAddressPopup(false);
    setAddNewAddressPopup(false);
    setEditProfilePopup(false);
    setDeleteAccountConfirmPopup(false);
  };

  const validateForm = (name, value) => {
    switch (name) {
      case "mobile": {
        let error = null;

        if (!value) {
          error = "This field is required!";
        }

        return {
          mobile: error,
        };
      }

      default:
        break;
    }
  };

  const user = useSelector((state) => state.user);
  const [editProfilePopup, setEditProfilePopup] = useState(false);
  const [genderMale, setGenderMale] = useState(false);
  const [genderFemale, setGenderFemale] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(false);

  useEffect(() => {
    if (user?.gender === "male") {
      setGenderMale(true);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user?.gender === "female") {
      setGenderFemale(true);
    }
    // eslint-disable-next-line
  }, [user]);

  const isNotValidated1 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues1).filter((value) => !!value).length < 2,
    [formErrors, formValues1]
  );

  const isNotValidated2 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues2).filter((value) => !!value).length < 5,
    [formErrors, formValues2]
  );

  const isNotValidated4 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues2).filter((value) => !!value).length < 1,
    [formErrors, formValues2]
  );

  const isNotValidated3 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues3).filter((value) => !!value).length < 1,
    [formErrors, formValues3]
  );

  useEffect(() => {
    return () => {
      // dispatch(userUiAction.clearUi())
      setMobileValidation();
      setFormValues(initialValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ediProfileHandler = () => {
    setEditProfilePopup(true);
  };

  const handleSubmitUpdate = () => {
    const data = {
      address: addTempData.address,
      city: addTempData.city,
      state: addTempData.state,
      pincode: addTempData.pincode,
      type: formValues2?.type,
      isPrimary: true,
    };

    dispatch(profileThunks.updateAddress(id, data)).then((response) => {
      if (response.success) {
        setUpdatedAddress(true);
      }
    });
  };

  const handleSubmit = async() => {
    const res = await IpAddressTracking();

    const data = {
      building: formValues2?.address,
      formattedAddress: currentLocation?.data?.data?.formatted_address,
      city: formValues2.city || currentLocation?.data?.data?.city,
      state: formValues2.state || currentLocation?.data?.data?.state,
      placeId: currentLocation?.data?.data?.place_id,
      area: currentLocation?.data?.data?.area,
      compoundCode: currentLocation?.data?.data?.compound_code,
      postalCode:
        formValues2.pincode || "" + currentLocation?.data?.data?.postal_code,
      isPrimary: true,
      type: "home",
      ipInfo: res ? res : null,
    };

    // if (!isNotValidated1) {
    dispatch(profileThunks.addAdress(data)).then((response) => {
      if (response.success) {
        setAddNewAddressPopup(false);
      }
    });
    // }
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    let field = value;
    console.log(field);

    setFormValues2({ ...formValues2, [name]: field });
  };

  const deleteAccountHandler = () => {
    setDeleteAccountConfirmPopup(true);
  };

  const handleAccountDeleteYes = () => {
    dispatch(profileThunks.deleteUser());
    handleModalClose();
  };

  const handleAccountDeleteNo = () => {
    handleModalClose();
  };

  const handleValueChange1 = (e) => {
    const { name, value } = e.target;
    let field = value;
    console.log(field);

    setGenderMale(false);
    setGenderFemale(false);

    setFormValues1({ ...formValues1, [name]: field });
  };

  const handleValueChange2 = (e) => {
    const { name, value } = e.target;
    let field = value;
    console.log(field);

    setFormValues3({ ...formValues3, [name]: field });
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    setFormValues1({ ...formValues1, [name]: value });
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
    console.log(value);
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormValues2({ ...formValues2, [name]: value });
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
    console.log(value);
  };
  const handleTouch = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
  };

  const [timer, setTimer] = useState(30);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);


  const handleAddAddressHandler = () => {
    setAddressPopup(false);
    setAddAnotherAddressPopup(false);

    dispatch(profileThunks.getAddressId(id));
  };

  const logOutHandler = () => {
    dispatch(authThunks.logout());
    navigate("/");
    // alert("jiiii
  };

  const savedAddressHandler = () => {
    dispatch(profileThunks.getAddress());
    dispatch(profileThunks.getAddressId(id));
    setAddAnotherAddressPopup(false);
    setUpdatedAddress(false);
  };

  const addNewAddressHandler = () => {
    setAddNewAddressPopup(true);
    setAddressPopup(false);
  };

  const updatedProfileHandler = () => {
    setEditProfilePopup(false);
    window.location.reload();
  };

  const preTreatmentHandler2 = () => {
    const data = {
      name: formValues1.fullName.trim() || user?.name.trim(),
      gender: formValues1.gender || user?.gender,
    };

    localStorage.setItem("fullName", formValues1.fullName.trim());

    dispatch(authThunks.userInfo(data)).then((res) => {
      if (res.success) {
        setUpdatedProfile(true);
      }
    });
  };

  const [autoAddressBtn, setAutoAddressBtn] = useState(false);
  const [fetchAddressText, setFetchAddressText] = useState(false);
  const { currentLocation } = useSelector((state) => state.entities.profile);

  const getCurrentLocationHandler = () => {
    setAutoAddressBtn(true);
    setFetchAddressText(true);
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        // if (location) dispatch(getUserData(location.coords));
        dispatch(profileActions.setCurrrentCordinates(location.coords));

        console.log(location.coords.latitude);
        localStorage.setItem(
          "latitude",
          JSON.stringify(location.coords.latitude)
        );
        localStorage.setItem(
          "longitude",
          JSON.stringify(location.coords.longitude)
        );
      });
    }
  };

  return (
    <>
      <CustomModal
        show={addressPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper">
          <div className="master-form-group long">
            <p>Select Address</p>
            <div className="addBox">
              <span onClick={addNewAddressHandler}>Add address</span>
              {userAddress.length !== 0 ? (
                <ul className="mid-1-check">
                  {userAddress.map(
                    (
                      { id, type, building, area, city, state, postalCode },
                      index
                    ) => (
                      <li key={[id]} className="chkinput">
                        <label htmlFor={[id]}>
                          <input
                            type="radio"
                            id={[id]}
                            onChange={handleValueChange2}
                            name="type"
                            value={[id]}
                          />
                          <span>
                            <b>{type}</b>
                            {building}, {area}, {city}, {state} {postalCode}{" "}
                          </span>
                        </label>
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <p className="addAddText">
                  You don't have an address added, please add an addresss to
                  continue!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button
            onClick={handleAddAddressHandler}
            disabled={isNotValidated3}
            className="form_button"
          >
            SELECT ADDRESS
          </button>
        </div>
      </CustomModal>

      <CustomModal
        show={deleteAccountConfirmPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <>
          <div className="modal_content form_wrapper">
            <div className="innerContent">
              <p>Are you sure you want to delete your account?</p>
            </div>
          </div>
          <div className="master-form-group scheduleBox">
            <button onClick={handleAccountDeleteYes} className="form_button">
              Yes
            </button>
            <button onClick={handleAccountDeleteNo} className="form_button">
              No
            </button>
          </div>
        </>
      </CustomModal>

      <CustomModal
        show={addAnotherAddressPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        {updatedAddress ? (
          <>
            <div className="modal_content form_wrapper">
              <div className="innerContent">
                <span>
                  <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy"/>
                </span>
                <p>Address Successfully Updated!</p>
              </div>
            </div>
            <div className="master-form-group scheduleBox">
              <button onClick={savedAddressHandler} className="form_button">
                CONTINUE
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="modal_content form_wrapper">
              <div className={`master-form-group4 long `}>
                <h4>EDIT ADDRESS</h4>
              </div>
              <div className="address-form">
                
                <div className="addressBox">
                  <p>Address</p>
                  <div className="formWrapper">
                    <div
                      className={`master-form-group ${formErrors.pincode ? "master-input-error" : ""
                        }`}
                    >
                      <input
                        type="number"
                        name="pincode"
                        placeholder="Pincod *"
                        className={`form-input ${formErrors.pincode ? "master-input-error" : ""
                          }`}
                        onChange={(e) => {
                          setAddTempData({
                            ...addTempData,
                            pincode: e.target.value,
                          });
                        }}
                        // onChange={handleChange}
                        value={addTempData.pincode}
                        onBlur={handleTouch}
                      />
                      {formErrors.pincode ? (
                        <div className="message-error">
                          <span className="text-danger1">
                            {formErrors.pincode}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={`master-form-group ${formErrors.state ? "master-input-error" : ""
                        }`}
                    >
                      <input
                        type="text"
                        name="state"
                        placeholder="State *"
                        className={`form-input ${formErrors.state ? "master-input-error" : ""
                          }`}
                        onChange={(e) => {
                          setAddTempData({
                            ...addTempData,
                            state: e.target.value,
                          });
                        }}
                        // onChange={handleChange}
                        value={addTempData.state}
                        onBlur={handleTouch}
                      />
                      {formErrors.state ? (
                        <div className="message-error">
                          <span className="text-danger1">
                            {formErrors.state}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className={`master-form-group ${formErrors.address ? "master-input-error" : ""
                      }`}
                  >
                    <input
                      type="text"
                      name="address"
                      placeholder="Address(House No, Building, Street, Area) *"
                      className={`form-input ${formErrors.address ? "master-input-error" : ""
                        }`}
                      onChange={(e) => {
                        setAddTempData({
                          ...addTempData,
                          address: e.target.value,
                        });
                      }}
                      // onChange={handleChange}
                      value={addTempData.address}
                      onBlur={handleTouch}
                    />
                    {formErrors.address ? (
                      <div className="message-error">
                        <span className="text-danger1">
                          {formErrors.address}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`master-form-group ${formErrors.town ? "master-input-error" : ""
                      }`}
                  >
                    <input
                      type="text"
                      name="town"
                      placeholder="Location/Town *"
                      className={`form-input ${formErrors.town ? "master-input-error" : ""
                        }`}
                      onChange={(e) => {
                        setAddTempData({
                          ...addTempData,
                          town: e.target.value,
                        });
                      }}
                      // onChange={handleChange}
                      value={addTempData.town}
                      onBlur={handleTouch}
                    />
                    {formErrors.town ? (
                      <div className="message-error">
                        <span className="text-danger1">{formErrors.town}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`master-form-group ${formErrors.city ? "master-input-error" : ""
                      }`}
                  >
                    <input
                      type="text"
                      name="city"
                      placeholder="City/District *"
                      className={`form-input ${formErrors.city ? "master-input-error" : ""
                        }`}
                      onChange={(e) => {
                        setAddTempData({
                          ...addTempData,
                          city: e.target.value,
                        });
                      }}
                      // onChange={handleChange}
                      value={addTempData.city}
                      onBlur={handleTouch}
                    />
                    {formErrors.city ? (
                      <div className="message-error">
                        <span className="text-danger1">{formErrors.city}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="addBox">
                    <p>Type Of Address *</p>
                    <ul className="mid-1-check">
                      <li className="chkinput">
                        <label htmlFor="home">
                          <input
                            type="radio"
                            id="home"
                            name="type"
                            onChange={handleValueChange}
                            value="home"
                            defaultChecked={typeCheckedOne}
                          />
                          <span>
                            <b>Home</b>
                          </span>
                        </label>
                      </li>
                      <li className="chkinput">
                        <label htmlFor="office">
                          <input
                            type="radio"
                            id="office"
                            name="type"
                            onChange={handleValueChange}
                            value="office"
                            defaultChecked={typeCheckedTwo}
                          />
                          <span>
                            <b>Work</b>
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="master-form-group scheduleBox">
              <button onClick={handleSubmitUpdate} className="form_button">
                UPDATE ADDRESS
              </button>
            </div>
          </>
        )}
      </CustomModal>

      <CustomModal
        show={addNewAddressPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <>
          <div className="modal_content form_wrapper">
            <div className={`master-form-group4 long `}>
              <h4>ADD ADDRESS</h4>
            </div>
            <div className="address-form">
              <p className="locationPicker" onClick={getCurrentLocationHandler}>
                <span>
                  <img src={map_pin} alt="map_pin" loading="lazy"/>
                </span>
                Get my current location
              </p>
              {fetchAddressText ? (
                <span>please wait while we are fetching your location...</span>
              ) : (
                ""
              )}
              <div className="addressBox">
                <p>Address</p>

                <div
                  className={`master-form-group ${formErrors.address ? "master-input-error" : ""
                    }`}
                >
                  <input
                    type="text"
                    name="address"
                    placeholder="Address(House No, Building/Flat) *"
                    className={`form-input ${formErrors.address ? "master-input-error" : ""
                      }`}
                    onChange={handleChange2}
                    value={formValues2.address}
                    onBlur={handleTouch}
                  />
                  {formErrors.address ? (
                    <div className="message-error">
                      <span className="text-danger1">{formErrors.address}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`master-form-group ${formErrors.town ? "master-input-error" : ""
                    }`}
                >
                  <input
                    type="text"
                    name="town"
                    placeholder="Street, Area *"
                    className={`form-input ${formErrors.town ? "master-input-error" : ""
                      }`}
                    onChange={handleChange2}
                    value={
                      formValues2.town || currentLocation?.data?.data?.area
                    }
                    onBlur={handleTouch}
                  />
                  {formErrors.town ? (
                    <div className="message-error">
                      <span className="text-danger1">{formErrors.town}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className={`master-form-group ${formErrors.pincode ? "master-input-error" : ""
                    }`}
                >
                  <input
                    type="number"
                    name="pincode"
                    placeholder="Pincode *"
                    className={`form-input ${formErrors.pincode ? "master-input-error" : ""
                      }`}
                    onChange={handleChange2}
                    value={
                      formValues2.pincode ||
                      currentLocation?.data?.data?.postal_code
                    }
                    onBlur={handleTouch}
                  />
                  {formErrors.pincode ? (
                    <div className="message-error">
                      <span className="text-danger1">{formErrors.pincode}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="formWrapper">
                  <div
                    className={`master-form-group ${formErrors.city ? "master-input-error" : ""
                      }`}
                  >
                    <input
                      type="text"
                      name="city"
                      placeholder="City/District *"
                      className={`form-input ${formErrors.city ? "master-input-error" : ""
                        }`}
                      onChange={handleChange2}
                      value={
                        formValues2.city || currentLocation?.data?.data?.city
                      }
                      onBlur={handleTouch}
                    />
                    {formErrors.city ? (
                      <div className="message-error">
                        <span className="text-danger1">{formErrors.city}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`master-form-group ${formErrors.state ? "master-input-error" : ""
                      }`}
                  >
                    <input
                      type="text"
                      name="state"
                      placeholder="State *"
                      className={`form-input ${formErrors.state ? "master-input-error" : ""
                        }`}
                      onChange={handleChange2}
                      value={
                        formValues2.state || currentLocation?.data?.data?.state
                      }
                      onBlur={handleTouch}
                    />
                    {formErrors.state ? (
                      <div className="message-error">
                        <span className="text-danger1">{formErrors.state}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                
              </div>
            </div>
          </div>
          <div className="master-form-group scheduleBox">
            {autoAddressBtn ? (
              <button
                onClick={handleSubmit}
                disabled={isNotValidated4}
                className="form_button"
              >
                SAVE ADDRESS
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                disabled={isNotValidated2}
                className="form_button"
              >
                SAVE ADDRESS
              </button>
            )}
          </div>
        </>
      </CustomModal>

      <CustomModal
        show={editProfilePopup}
        onClose={() => {
          handleModalClose();
          window.location.reload();
        }}
        className="customPopup innerPopup authPopup"
      >
        {updatedProfile ? (
          <>
            <div className="modal_content form_wrapper">
              <div className="innerContent">
                <span>
                  <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy"/>
                </span>
                <p>Profile Successfully Updated!</p>
              </div>
            </div>
            <div className="master-form-group scheduleBox">
              <button onClick={updatedProfileHandler} className="form_button">
                CONTINUE
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="modal_content form_wrapper basic ediProfile">
              <div className={`master-form-group4 long `}>
                <h4>EDIT PROFILE</h4>
              </div>
              <div className="address-form">
                <div className="basic-info">
                  <div
                    className={`master-form-group ${formErrors.fullName ? "master-input-error" : ""
                      }`}
                  >
                    <span className="label">Full Name</span>
                    <input
                      type="text"
                      name="fullName"
                      contentEditable
                      placeholder="Name *"
                      className={`form-input ${formErrors.fullName ? "master-input-error" : ""
                        }`}
   
                      onChange={handleChange3}
                      value={formValues1.fullName || user?.name}
                    />
                    {formErrors.firstName ? (
                      <div className="message-error">
                        <span className="text-danger1">
                          {formErrors.firstName}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`master-form-group long ${mobileValidation ? "master-input-error" : ""
                      }`}
                  >
                    <span className="label">Mobile Number</span>
                    <PhoneInput
                      name="mobile"
                      id="mobile"
                      readonly={true}
                      contentEditable={false}
                      disabled
                      country={formValues.countryCode}
                      placeholder=""
                      // onChange={(e) => {
                      //     setUserTempData({...userTempData, setFormValues(mobile: number)})
                      // }}
                      value={`+91 ${user?.phoneNumber} `}
                      className={`form-input ${formErrors.mobile ? "master-input-error" : ""
                        }`}
                    // onFocus={() => setMobileValidation("")}
                    />
                    {formErrors.mobile ? (
                      <div className="message-error">
                        <span className="text-danger1">
                          {formErrors.mobile}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className={`master-form-group editGender${formErrors.fullName ? "master-input-error" : ""
                      }`}
                  >
                    <span className="label">Gender</span>
                    <ul className="mid-1-check">
                      <li className="chkinput">
                        <label
                          htmlFor="male"
                          className={`${genderMale ? "checked" : ""}`}
                        >
                          <input
                            type="radio"
                            id="male"
                            onChange={handleValueChange1}
                            name="gender"
                            value="male"
                          />
                          <span>Male</span>
                        </label>
                      </li>
                      <li className="chkinput">
                        <label
                          htmlFor="female"
                          className={`${genderFemale ? "checked" : ""}`}
                        >
                          <input
                            type="radio"
                            id="female"
                            onChange={handleValueChange1}
                            name="gender"
                            value="female"
                          />
                          <span>Female</span>
                        </label>
                      </li>
                      <li className="chkinput">
                        <label htmlFor="other">
                          <input
                            type="radio"
                            id="other"
                            onChange={handleValueChange}
                            name="gender"
                            value="other"
                          />
                          <span>Other</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="master-form-group scheduleBox">
              <button onClick={preTreatmentHandler2} className="form_button">
                UPDATE PROFILE
              </button>
            </div>
          </>
        )}
      </CustomModal>

      <Navbar
        show={showLoginModal}
        closeHandler={() => setShowLoginModal(false)}
      />
      <div className="wrapper NavbarWrapper profilePage">
        <span onClick={() => navigate(-1)} className="backArrow">
          <span>
            <Icon path={mdiArrowLeft} size={1} />
          </span>
          Profile
        </span>
        <div className="userProfileMenu">
          <ul>
            <li onClick={ediProfileHandler}>
              <span>Edit Profile</span>
              <span>
                <Icon path={mdiChevronRight} size={1} />
              </span>
            </li>
            <li onClick={() => navigate(`/my-bookings`)}>
              <span>My Bookings</span>
              <span>
                <Icon path={mdiChevronRight} size={1} />
              </span>
            </li>
            <li
              onClick={(e) => {
                window.location.href = "https://clinic.avataarskin.com";
              }}
            >
              <span>Avataar Clinic</span>
              <span>
                <Icon path={mdiChevronRight} size={1} />
              </span>
            </li>
            <li onClick={() => window.open("https://g.page/r/Ceg956j-dvfCEBM/review", '_blank')}>
              <span>Rate Avataar Skincare</span>
              <span>
                <Icon path={mdiChevronRight} size={1} />
              </span>
            </li>

            <li>
              <span onClick={deleteAccountHandler}>Delete Account</span>
              <span>
                <Icon path={mdiChevronRight} size={1} />
              </span>
            </li>
            <li>
              <span onClick={logOutHandler} style={{ flex: "0" }}>Logout</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Profile;
