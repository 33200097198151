import React from "react";
import "../styles/review.css";
import Rating from "react-rating";

function ReviewCard(props) {
  const { initial, name, review, rating } = props.review;

  return (
    <>
      <div className="review-card">
        <div className="initials-container">
          <div>
            <span className="initialBox">{initial}</span>
          </div>
        </div>

        <h5 className="nameCard">{name}</h5>
        <div>
          <Rating
            initialRating={rating}
            emptySymbol={<span className="rating-empty">&#9734;</span>}
            fullSymbol={<span className="rating-full">&#9733;</span>}
            readonly
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <p className="textReviews">
            {review.split(" ").slice(0, 60).join(" ")}
          </p>
        </div>
      </div>
    </>
  );
}

export default ReviewCard;
