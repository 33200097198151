import React, { memo } from 'react'

const HowItWorks = ({ token1, setScheduleMeet, signupModalHandler }) => (
    <div className="wrapperContainer howItWorks mb-10">
        <h2 className="heading">how avatAar works</h2>
        <div className="workSection">
            <div className="workVideo">
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/GHR7rgMMeNY"

                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>
            <div className="workContent">
                <p>
                    At Avataar Skincare we bring world-class skin treatment at the
                    comfort of your home. <br />
                    <br />
                    Book <span className="gold">FREE</span> virtual{" "}
                    <span className="gold">CONSULTATION</span> to speak to our
                    experts
                </p>

                {token1 ? (
                    <span
                    className="scheduleButton"
                    onClick={() => {
                        setScheduleMeet(true);
                    }}
                >
                    SCHEDULE NOW
                </span>
                ) : (
                    <span
                        className="scheduleButton"
                        onClick={() => {
                            signupModalHandler(true);
                        }}
                    >
                        SCHEDULE NOW
                    </span>
                )}
            </div>
        </div>
    </div>
)


export default memo(HowItWorks)