import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ metaTitle, metaDescription , keywords}) => {
    return (
        <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={keywords}></meta>
        </Helmet>
    );
};

export default MetaTags;
