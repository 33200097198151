import CustomModal from 'common/CustomModal';
import React from 'react'
import scheduleCompleteIcon from "assets/images/scheduleCompleteIcon.svg";
import PhoneInput from 'react-phone-input-2';


const AddAnotherAddress = ({
    addAnotherAddressPopup,
    handleModalClose,
    handleTouch,
    formErrors,
    formValues,
    updatedAddress,
    savedAddressHandler,
    setAddTempData,
    setUserTempData,
    userTempData, 
    mobileValidation,
    addTempData,
    handleValueChange,
    handleSubmitUpdate,
    typeCheckedOne,
    typeCheckedTwo
}) => {
    return (
        <CustomModal
            show={addAnotherAddressPopup}
            onClose={handleModalClose}
            className="customPopup innerPopup authPopup"
        >
            {updatedAddress ? (
                <>
                    <div className="modal_content form_wrapper">
                        <div className="innerContent">
                            <span>
                                <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy" />
                            </span>
                            <p>Address Successfully Updated!</p>
                        </div>
                    </div>
                    <div className="master-form-group scheduleBox">
                        <button onClick={savedAddressHandler} className="form_button">
                            CONTINUE
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="modal_content form_wrapper">
                        <div className={`master-form-group4 long `}>
                            <h4>EDIT ADDRESS</h4>
                        </div>
                        <div className="address-form">
                            <div className="basic-info">
                                <p>Basic Info1</p>
                                <div
                                    className={`master-form-group ${formErrors.fullName ? "master-input-error" : ""
                                        }`}
                                >
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name *"
                                        className={`form-input ${formErrors.fullName ? "master-input-error" : ""
                                            }`}
                                        onChange={(e) => {
                                            setUserTempData({
                                                ...userTempData,
                                                name: e.target.value,
                                            });
                                        }}
                                        value={userTempData.name}
                                    />
                                    {formErrors.firstName ? (
                                        <div className="message-error">
                                            <span className="text-danger1">
                                                {formErrors.firstName}
                                            </span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div
                                    className={`master-form-group long ${mobileValidation ? "master-input-error" : ""
                                        }`}
                                >
                                    <PhoneInput
                                        name="mobile"
                                        id="mobile"
                                        readOnly
                                        country={formValues.countryCode}
                                        value={`+91 ${userTempData.phoneNumber} `}
                                        className={`form-input ${formErrors.mobile ? "master-input-error" : ""
                                            }`}
                                    />
                                    {formErrors.mobile && (
                                        <div className="message-error">
                                            <span className="text-danger1">
                                                {formErrors.mobile}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="addressBox">
                                <p>Address</p>
                                <div className="formWrapper">
                                    <div
                                        className={`master-form-group ${formErrors.pincode ? "master-input-error" : ""
                                            }`}
                                    >
                                        <input
                                            type="number"
                                            name="pincode"
                                            placeholder="Pincod *"
                                            className={`form-input ${formErrors.pincode ? "master-input-error" : ""
                                                }`}
                                            onChange={(e) => {
                                                setAddTempData({
                                                    ...addTempData,
                                                    pincode: e.target.value,
                                                });
                                            }}
                                            value={addTempData.pincode}
                                            onBlur={handleTouch}
                                        />

                                        {formErrors.pincode && (
                                            <div className="message-error">
                                                <span className="text-danger1">
                                                    {formErrors.pincode}
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className={`master-form-group ${formErrors.state ? "master-input-error" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            name="state"
                                            placeholder="State *"
                                            className={`form-input ${formErrors.state ? "master-input-error" : ""
                                                }`}
                                            onChange={(e) => {
                                                setAddTempData({
                                                    ...addTempData,
                                                    state: e.target.value,
                                                });
                                            }}
                                            value={addTempData.state}
                                            onBlur={handleTouch}
                                        />
                                        {formErrors.state && (
                                            <div className="message-error">
                                                <span className="text-danger1">
                                                    {formErrors.state}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`master-form-group ${formErrors.address ? "master-input-error" : ""
                                        }`}
                                >
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Address(House No, Building, Street, Area) *"
                                        className={`form-input ${formErrors.address ? "master-input-error" : ""
                                            }`}
                                        onChange={(e) => {
                                            setAddTempData({
                                                ...addTempData,
                                                address: e.target.value,
                                            });
                                        }}
                                        value={addTempData.address}
                                        onBlur={handleTouch}
                                    />

                                    {formErrors.address && (
                                        <div className="message-error">
                                            <span className="text-danger1">
                                                {formErrors.address}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`master-form-group ${formErrors.town ? "master-input-error" : ""
                                        }`}
                                >
                                    <input
                                        type="text"
                                        name="town"
                                        placeholder="Location/Town *"
                                        className={`form-input ${formErrors.town ? "master-input-error" : ""
                                            }`}
                                        onChange={(e) => {
                                            setAddTempData({
                                                ...addTempData,
                                                town: e.target.value,
                                            });
                                        }}
                                        value={addTempData.town}
                                        onBlur={handleTouch}
                                    />

                                    {formErrors.town && (
                                        <div className="message-error">
                                            <span className="text-danger1">{formErrors.town}</span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`master-form-group ${formErrors.city ? "master-input-error" : ""
                                        }`}
                                >
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="City/District *"
                                        className={`form-input ${formErrors.city ? "master-input-error" : ""
                                            }`}
                                        onChange={(e) => {
                                            setAddTempData({
                                                ...addTempData,
                                                city: e.target.value,
                                            });
                                        }}
                                        value={addTempData.city}
                                        onBlur={handleTouch}
                                    />
                                    {formErrors.city && (
                                        <div className="message-error">
                                            <span className="text-danger1">{formErrors.city}</span>
                                        </div>
                                    )}
                                </div>

                                <div className="addBox">
                                    <p>Type Of Address *</p>
                                    <ul className="mid-1-check">
                                        <li className="chkinput">
                                            <label htmlFor="home">
                                                <input
                                                    type="radio"
                                                    id="home"
                                                    name="type"
                                                    onChange={handleValueChange}
                                                    value="home"
                                                    defaultChecked={typeCheckedOne}
                                                />
                                                <span>
                                                    <b>Home</b>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="chkinput">
                                            <label htmlFor="office">
                                                <input
                                                    type="radio"
                                                    id="office"
                                                    name="type"
                                                    onChange={handleValueChange}
                                                    value="office"
                                                    defaultChecked={typeCheckedTwo}
                                                />
                                                <span>
                                                    <b>Work</b>
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="master-form-group scheduleBox">
                        <button onClick={handleSubmitUpdate} className="form_button">
                            UPDATE ADDRESS
                        </button>
                    </div>
                </>
            )}
        </CustomModal>
    )
}

export default AddAnotherAddress