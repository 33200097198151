import React from 'react'
import {
    mdiChevronRight,
} from "@mdi/js";
import Icon from "@mdi/react";

import editIcon from "assets/images/editIcon.svg";
import homeAddress from "assets/images/homeAddress.svg";
import discountCoupon from "assets/images/discountCoupon.svg";

const RightContainer = ({ userId,
    cart,
    appliedCoupon,
    appliedCoupon2,
    coupleDetailsHandler,
    couponDetails,
    addressHandler,
    haveAddress,
    cartList,
    userAddressId,
    addAnotherAddressHandler,
    paymentGatewayHandler,
    CouponListCount,
    setPayableAmount,
    setCreditUsed,
    setCouponDiscount
}) => {

    const isPaid = cart && cart.category && cart.category.isPaid ? cart.category.isPaid : false
    const advanceBalance = cart && cart.category && cart.category.isPaid ? cart.category.advanceBalance : 0
    let cartTotal = cart && cart.category && cart.category.total ? cart.category.total : 0
    let couponTotal = couponDetails && couponDetails.total ? couponDetails.total : 0
    let showCouponTotalWithAdvance = couponTotal, showCartTotalWithAdvance = cartTotal
    if (isPaid && advanceBalance > 0) {
        showCouponTotalWithAdvance = Number(advanceBalance) - Number(couponTotal)
        if (showCouponTotalWithAdvance < 0) {
            showCouponTotalWithAdvance = Math.abs(showCouponTotalWithAdvance)
        }
        else if (showCouponTotalWithAdvance > 0) {
            showCouponTotalWithAdvance = 0
            showCartTotalWithAdvance = Number(advanceBalance) - Number(cartTotal)
        }
        if (showCartTotalWithAdvance < 0) {
            showCartTotalWithAdvance = Math.abs(showCartTotalWithAdvance)
        }
        else if (showCartTotalWithAdvance > 0) {
            showCartTotalWithAdvance = 0
        }
    }

    if(cartTotal> advanceBalance){
        setCreditUsed(advanceBalance);
    }else{
        setCreditUsed(cartTotal);
    }

    setPayableAmount(appliedCoupon || appliedCoupon2 ? isPaid ? showCouponTotalWithAdvance : couponTotal : isPaid ? showCartTotalWithAdvance : cartTotal)
    setCouponDiscount(appliedCoupon || appliedCoupon2 ? couponDetails.couponDiscount : 0)

    return (
        <div className="right">
            <div className="cartAmountDetails">
                <ul>
                    <li>
                        <h5>Payment summary</h5>
                        <ul>
                            <li>
                                <span>User ID</span>
                                <span>{userId} </span>
                            </li>
                            <li>
                                <span>Item total</span>
                                <span>₹ {cart?.category?.total} </span>
                            </li>
                            {
                                isPaid ? <li>
                                    <span>Wallet Balance</span>
                                    <span>₹ {advanceBalance} </span>
                                </li> : ""
                            }
                            <li>
                                <span>Taxes @ 9% SGST</span>
                                <span>All inclusive</span>
                            </li>
                            <li>
                                <span>Taxes @ 9% CGST</span>
                                <span>All inclusive</span>
                            </li>
                            {appliedCoupon || appliedCoupon2 ? (
                                <li>
                                    <span>Applied Coupon Discount</span>
                                    <span> ₹ {couponDetails.couponDiscount}</span>
                                </li>
                            ) : (
                                ""
                            )}

                            {appliedCoupon || appliedCoupon2 ? (
                                <li>
                                    <span>TOTAL</span>
                                    <span>₹ {isPaid ? showCouponTotalWithAdvance : couponTotal}</span>
                                </li>
                            ) : (
                                <li>
                                    <span>TOTAL</span>
                                    <span>₹ {isPaid ? showCartTotalWithAdvance : cartTotal}</span>
                                </li>
                            )}
                        </ul>
                    </li>
                    <li>
                        <span>
                            <img src={discountCoupon} alt="discount coupon" loading="lazy" />
                            Coupons and offers
                        </span>
                        <span onClick={coupleDetailsHandler}>
                            {CouponListCount} offers{" "}
                            <Icon path={mdiChevronRight} size={1} />
                        </span>
                    </li>
                </ul>

                {haveAddress ? (
                    <>
                        {userAddressId !== null ? (
                            <>
                                <div className="master-form-group scheduleBox cartBottomBox">
                                    <div className="bottomAddressBar">
                                        <p>
                                            <span>
                                                <img src={homeAddress} alt="home address" loading="lazy" />
                                                <b>&nbsp;{userAddressId.type}</b>&nbsp;-{" "}
                                            </span>
                                            <span>
                                                {userAddressId.formattedAddress}
                                            </span>
                                            <span onClick={addAnotherAddressHandler}>
                                                <img src={editIcon} alt="edit icon" loading="lazy" />
                                            </span>
                                        </p>
                                    </div>
                                    <div className="carPayOption">

                                        <>
                                            <button
                                                onClick={paymentGatewayHandler}
                                                className="form_button"

                                            >
                                                PLACE ORDER <span>{cartList.price}</span>
                                            </button>
                                        </>
                                        {/* )} */}
                                    </div>
                                    <div className="cartTerms">
                                        <p>
                                            By proceeding, you agree to our <br />
                                            <a href="/">
                                                T&amp;C, Privacy Cancellation policy
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <div className="master-form-group scheduleBox">
                        <button onClick={addressHandler} className="form_button">
                            ADD/SELECT ADDRESS
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default RightContainer