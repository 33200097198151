import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";

import * as productThunk from "redux/thunks/productThunk";
import * as profileActions from "redux/reducer/profileDux";

import HomeScreenPopup from "common/HomeScreenPopup";
import Navbar from "common/Navbar";
import { AnalyseSkin } from "components/AnalyseSkin";
import MetaTags from "components/MetaTags";
import CouponAlert from "components/CouponAlert/CouponAlert";
import { converDateFormat, converTimeFormat } from "utils/helper";
import { token } from "utils/httpUtils";
import reviewsData from "reviewsData";

import Certifications from "./Certifications";
import Reviews from "./Reviews";
import PerpForOccasions from "./PerpForOccasions";
import ScheduleConsultation from "./Modals/scheduleConsultation";
import ConsultationConfirmation from "./Modals/consultationConfirmation";
import HowItWorks from "./HowItWorks";
import ServicesGrid from "./ServicesGrid";
import SharkTankBanner from "./SharkTankBanner";
import BodyConditions from "./BodyConditions";
import PackageCarasuel from "./PackageCarasuel";
import ProductCarousel from "./ProductCarousel";

import "styles/review.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-stacked-carousel/dist/index.css";
import SEOContent from "./SEOContent";


const Home = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [scheduleMeet, setScheduleMeet] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [scheduleComplete, setScheduleComplete] = useState(false);
    const [userProfilelMenu, setUserProfilelMenu] = useState(false);
    const [signupPopup, setSignupPopup] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [formErrors] = useState({});
    const [data, setData] = useState(null);

    const [activeOption, setActiveOption] = useState(null);
    const [formValues, setFormValues] = useState({
        classTime: null,
        classDay: null,
    });
    const [prodObj, setProdObj] = useState({
        date: "",
        time: "",
    });

    const { productCollection, productSlots } = useSelector((state) => {
        return state.entities.product;
    });
    const user = useSelector((state) => state.user);
    const [reviews] = useState(reviewsData);
    const token1 = localStorage.getItem("token");

    var urlPath = window.location.href.split("=");
    var genderValue = urlPath[1];
    localStorage.setItem("userGender", genderValue);

    const collectionId2 = productCollection[1]?.id;
    const collectionId3 = productCollection[3]?.id;
    const collectionId5 = productCollection[5]?.id;
    const collectionId8 = productCollection[8]?.id;

    const handleModalClose = () => {
        setScheduleMeet(false);
        setScheduleComplete(false);
    };

    const showDetailsHandle = (dayStr) => {
        let formatedDate = converDateFormat(dayStr);
        setData(formatedDate);
        setProdObj((prevState) => ({
            ...prevState,
            date: formatedDate,
        }));
        const data = {
            appointmentDate: formatedDate,
        };
        dispatch(productThunk.productSlots(data));
        setShowDetails(true);
    };

    const options = {
        stagePadding: 200,
        items: 1,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                stagePadding: 0,
            },
            600: {
                stagePadding: 0,
            },
            1024: {
                stagePadding: 200,
            },
        },
    };

    const options2 = {
        stagePadding: 200,
        items: 1,
        responsiveClass: true,
        loop: true,
        autoplay: true,
        rewind: false,
        autoplayTimeout: 5000,
        center: true,
        rewindNav: false,

        responsive: {
            0: {
                stagePadding: 0,
            },

            600: {
                stagePadding: 0,
            },

            1024: {
                stagePadding: 370,
                margin: 25,
            },
        },
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        let field = converTimeFormat(value);
        setFormValues({ ...formValues, [name]: field });
        setProdObj((prevState) => ({
            ...prevState,
            time: field,
        }));
    };

    const generateProductCollection = (id, grid) => {
        const filteredCollection = productCollection.filter(
            (item) => item.id === id
        );
        const collectionDetail = filteredCollection[0]?.collectionDetail;
        const renderedItems = collectionDetail?.map((item) => (
            <div
                id={item.id}
                className="Category"
                onClick={(e) => {
                    navigate(`/advanced-laser-treatments/${item.slug}`);
                    localStorage.setItem("collectionName", e.currentTarget.title);
                    localStorage.setItem("categoryId", item.id);
                    window.scroll(0, 0);
                }}
                title={item.title}
                key={item.id}
            >
                <span>
                    <img src={item.image} alt={item.title} loading="lazy"/>
                </span>
                <span>{item.title}</span>
            </div>
        ));

        const rows = [];
        const chunkSize = grid === "eight" ? 4 : 3;
        for (let i = 0; i < renderedItems?.length; i += chunkSize) {
            const chunk = renderedItems.slice(i, i + chunkSize);
            rows.push(
                <div className="collection-row" key={i}>
                    {chunk}
                </div>
            );
        }

        return rows;
    };

    const generateConditionsSlider = (id) => {
        const filteredCollection = productCollection.filter(
            (item) => item.id === id
        );
        const collectionDetail = filteredCollection[0]?.collectionDetail;
        const renderedItems = collectionDetail?.map((item) => (
            <div
                key={item.id}
                className="Category"
                onClick={(e) => {
                    navigate(`/advanced-laser-treatments/${item.slug}`);
                    localStorage.setItem("collectionName", e.currentTarget.title);
                    localStorage.setItem("categoryId", item.id);
                    window.scroll(0, 0);
                }}
                title={item.title}
                style={{ margin: "10px" }}
            >
                <span>
                    <img src={item.image} alt={item.title} loading="lazy"/>
                </span>
                <span className="conditionTitle">{item.title}</span>
            </div>
        ));

        return (
            <OwlCarousel
                className="owl-theme owl-carousel"
                items={1}
                responsiveClass={true}
                loop={true}
                autoplay={true}
                autoplayTimeout={5000}
                responsive={{
                    0: {
                        items: 3,
                    },
                    600: {
                        items: 4,
                    },
                    1024: {
                        items: 5,
                    },
                }}
            >
                {renderedItems}
            </OwlCarousel>
        );
    };

    const generateBestPackageSlider = (value) => {
        const filteredCollection = productCollection.filter(
            (item) => item.type === value
        );

        const collectionDetail = filteredCollection[0]?.collectionDetail;
        const renderedItems = collectionDetail?.map((item) => (
            <div
                key={item.productId}
                className="packagesContainer"
                onClick={(e) => {
                    localStorage.setItem("bestProductId", item.productId);
                    navigate(`/laser-treatments/${item.slug}`);
                    window.scroll(0, 0);
                }}
            >
                <div className="bestPackage">
                    <img src={item.image} alt="model 1" loading="lazy"/>
                </div>
            </div>
        ));

        return renderedItems;
    };

    const generateSlider = (value) => {
        const filteredCollection =
            productCollection &&
            productCollection.filter((item) => item.type === value);

        const collectionDetail = filteredCollection[0]?.collectionDetail;
        const renderedItems = collectionDetail?.map((item) => (
            <div
                key={item.id}
                className="slider_content"
                onClick={(e) => {
                    if (item?.id === 194 || item?.id === 195 || item?.id === 192) {
                        window.open(
                            "https://lp.avataarskin.com/botox-fillers/index.html",
                            "_blank"
                        );
                    } else if (item?.id === 193) {
                        window.open(
                            "https://lp.avataarskin.com/hair-transplant/index.html",
                            "_blank"
                        );
                    } else {
                        localStorage.setItem("bestProductId", item?.id);
                        localStorage.setItem("collectionName", item?.title);
                        navigate(`/advanced-laser-treatments/${item.slug}`);
                        window.scroll(0, 0);
                    }
                }}
            >
                <img className="img-responsive" src={item.image} alt="model 1" loading="lazy" />
            </div>
        ));

        return renderedItems;
    };

    const checkConditionData = (id) => {
        const filteredCollection = productCollection.filter(
            (item) => item.id === id
        );
        const collection = filteredCollection[0];
        return Object.keys(collection).length > 0;
    };

    const handlerUserProfileOption = () => {
        if (userProfilelMenu) {
            setUserProfilelMenu(!userProfilelMenu);
        }
    };

    const signupModalHandler = () => {
        localStorage.setItem("showMeet", true);
        setSignupPopup(true);
    };

    const signupModaCloselHandler = () => {
        setSignupPopup(false);
    };

    const handleClickOnSharkBanner = () => {
        const element = document.getElementsByClassName("wrapperContainer Services")[0]
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
            });
        }
    }

    const handleCloseModal = () => {
        localStorage.setItem('isPopupSeen', true);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (localStorage.getItem('isPopupSeen')) {
            setIsModalOpen(false);
        } else {
            setTimeout(() =>
                setIsModalOpen(true), 3000
            );
        }
    }, []);

    useEffect(
        () => {
            if (localStorage.getItem("showMeet") && token1) {
                setScheduleMeet(true);
                localStorage.removeItem("showMeet");
            }
            dispatch(productThunk.getProductCategory());
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        dispatch(productThunk.getProductReviews());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => {
            if (token !== null) {
                const axios = require("axios");

                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: "https://crm.avataarskin.com/app/api/?type=get_app_club_posts",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };

                axios
                    .request(config)
                    .then((response) => {
                        setTimeout(() => {
                            if (response.status === 200) {
                                dispatch(profileActions.setClubPostResponse(response));
                            } else {
                                alert("application error!!!");
                            }
                        }, 1000);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(
        () => {
            const gender = localStorage.getItem("category_selected")
            if (gender === "men") {
                dispatch(productThunk.getProductCollectionByGender("male"));
                setActiveOption(2);
            } else {
                dispatch(productThunk.getProductCollectionByGender("female"));
                setActiveOption(1);
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <CouponAlert />

            {(() => {
                switch (activeOption) {
                    case 1:
                        return (
                            <MetaTags
                                metaTitle="Avataar Skin | Advanced Laser Treatments & Facials"
                                metaDescription="Discover Avataar Skin's advanced laser treatments and facials. Achieve radiant, youthful skin with our expert care. Book your personalized session today!"
                                keywords="laser and skin clinic,cosmetic skin clinic,skin & cosmetic clinic,skin laser treatment near me,cosmetic clinic near me,Dermatologist near me,near by skin clinic
                                "
                            />
                        );
                    case 2:
                        return (
                            <MetaTags
                                metaTitle="Avataar Skin | Advanced Laser Treatments & Facials"
                                metaDescription="Discover Avataar Skin's advanced laser treatments and facials. Achieve radiant, youthful skin with our expert care. Book your personalized session today!"
                                keywords="laser and skin clinic,cosmetic skin clinic,skin & cosmetic clinic,skin laser treatment near me,cosmetic clinic near me,Dermatologist near me,near by skin clinic"
                            />
                        );
                    default:
                        return (
                            <MetaTags
                                metaTitle="Avataar Skin | Advanced Laser Treatments & Facials"
                                metaDescription="Discover Avataar Skin's advanced laser treatments and facials. Achieve radiant, youthful skin with our expert care. Book your personalized session today!"
                                keywords="laser and skin clinic,cosmetic skin clinic,skin & cosmetic clinic,skin laser treatment near me,cosmetic clinic near me,Dermatologist near me,near by skin clinic"
                            />
                        );
                }
            })()}

            {isModalOpen && <HomeScreenPopup onClose={handleCloseModal} />}

            <ScheduleConsultation
                handleModalClose={handleModalClose}
                scheduleMeet={scheduleMeet}
                />

            <ConsultationConfirmation />

            <Navbar
                onSignup={signupPopup}
                onSignupClose={signupModaCloselHandler}
                onProfileClick={handlerUserProfileOption}
            />

            <div
                className="wrapper HomeWrapper"
                onClick={() => {
                    setUserProfilelMenu(false);
                }}
            >
                <SharkTankBanner handleClickOnSharkBanner={handleClickOnSharkBanner} isMobileView={isMobileView} />

                <AnalyseSkin />

                <ProductCarousel generateSlider={generateSlider}
                    options={options}
                    productCollection={productCollection}
                    view="banner_view" />

                {/* treatments just for you */}
                <ServicesGrid
                    collection={collectionId2}
                    generateProductCollection={generateProductCollection}
                    heading="Treatments just for you"
                    numberOfItems="eight" />

                <ProductCarousel generateSlider={generateSlider}
                    options={options}
                    productCollection={productCollection}
                    view="new_treatments_banner_view" />

                <HowItWorks setScheduleMeet={setScheduleMeet} signupModalHandler={signupModalHandler} token1={token1}/>

                <ServicesGrid
                    collection={collectionId3}
                    generateProductCollection={generateProductCollection}
                    heading="Most Booked Services"
                    numberOfItems="six" />

                <PackageCarasuel
                    generateFunction={generateBestPackageSlider}
                    options={options2}
                    productCollection={productCollection}
                    heading="Best Sellers"
                    className="sellerOutter"
                    view="best_sellers_view" />

                <BodyConditions
                    checkConditionData={checkConditionData}
                    collectionId5={collectionId5}
                    generateConditionsSlider={generateConditionsSlider} />

                {/* best packages */}
                <PackageCarasuel
                    generateFunction={generateSlider}
                    options={options}
                    productCollection={productCollection}
                    heading="Best Package Deals"
                    className="centerSlider"
                    view="slider_grid_view"
                    mainClassName="bestPackages" />

                <PerpForOccasions
                    collectionId8={collectionId8}
                    generateProductCollection={generateProductCollection} />

                <Reviews reviews={reviews} />
                <SEOContent/>
                <Certifications />
            </div>
        </>
    );
};

export default Home;
