import React, { memo } from 'react'
import { Carousel } from 'react-bootstrap'

import Frame1web from "assets/images/banner1.png";
import Frame2web from "assets/images/Frame2Testweb.png";
import Frame3web from "assets/images/Frame3newweb.png"
import MobileTest1 from "assets/images/banner1mobile.png";
import MobileTest2 from "assets/images/Frame2Test.png";
import MobileTest3 from "assets/images/Frame 48096160.png";

const SharkTankBanners = ({ isMobileView, handleClickOnSharkBanner }) => (
    <div className="wrapperContainer p-0">
        <Carousel interval={2000} controls={false}>
            <Carousel.Item>
                <img
                    className="d-block w-100 pb-2"
                    src={isMobileView ? MobileTest1 : Frame1web}
                    alt="First slide"
                    loading="lazy"
                />
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100 pb-2"
                    loading="lazy"
                    // src={Frame31}
                    src={isMobileView ? MobileTest2 : Frame2web}
                    alt="First slide"
                    onClick={handleClickOnSharkBanner}
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 pb-2"
                    src={isMobileView ? MobileTest3 : Frame3web}
                    alt="First slide"
                    onClick={handleClickOnSharkBanner}
                    loading="lazy"
                />
            </Carousel.Item>



        </Carousel>
    </div>
)


export default memo(SharkTankBanners)