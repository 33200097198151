import React, { useEffect, useState } from 'react'

export const WixPage = () => {
    const [route, setRoute] = useState();
    useEffect(() => {
        setRoute(window.location.pathname);
    }, [])
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src={route === "/lhr-female" ? "https://rajatsingh1.wixsite.com/website-1/lhr-female" :
                    route === "/lhr-male" ? "https://rajatsingh1.wixsite.com/website-1/lhr-male" :
                        route === "/wl-male" ?
                            "https://rajatsingh1.wixsite.com/website-1/wl-male" :
                            route === "/wl-female" ? "https://rajatsingh1.wixsite.com/website-1/wl-female" :
                                route === "/pigmentation-female" ? "https://rajatsingh1.wixsite.com/website-1/pigmentation-female" :
                                    route === "/iv-female" ? "https://rajatsingh1.wixsite.com/website-1/iv-female" :
                                        route === "/hair-treatment-male" ? "https://rajatsingh1.wixsite.com/website-1/hair-treatment-male" :
                                            route === "/hair-treatment-female" ? "https://rajatsingh1.wixsite.com/website-1/hair-treatment-female" :
                                                route === "/acne-female" ? "https://rajatsingh1.wixsite.com/website-1/acne-female" : "https://rajatsingh1.wixsite.com/website-1/anti-aging-female"}
                title="WIX Website"
                style={{ width: '100%', height: '100%', border: 'none' }}
            />
        </div>
    );
}

export default WixPage
