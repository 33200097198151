import { createAction, createReducer } from "redux-act";
export const setFetching = createAction("PRODUCT/SET_FETCHING");
export const setError = createAction("PRODUCT/SET_ERROR");
export const clearUi = createAction("PRODUCT/CLEAR_UI");

const handlers = {
  [setFetching](state, payload) {
    return {
      ...state,
      isFetching: payload,
    };
  },

  [setError](state, payload) {
    return {
      ...state,
      error: payload,
    };
  },

  [clearUi]() {
    return {
      isFetching: false,
      error: null,
      code: null,
    };
  },
};

export default createReducer(handlers, {});
