import React, { useState } from 'react'
import axios from 'axios';

import avataarNewLogo from 'assets/images/avataarNewLogo.svg';
import sharkTankPopupSvg from "assets/dco.png"
import sharkTankPopup from "assets/doc.png"


import CustomModal from 'common/CustomModal'
import DynamicButton from 'common/DynamicButton';


const ScheduleConsultation = ({
    scheduleMeet,
    handleModalClose,
}) => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        city: "",
        detail: "",
        email: "",
        detail2: ""
    });
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            "name": formData.name,
            "phone": formData.phone,
            "status": "PENDING",
            "city": formData.city,
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders/app-consultant`, data);
            localStorage.setItem('consultation', true);
            window.location.reload();


        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    return (
        <CustomModal
            show={scheduleMeet}
            onClose={handleModalClose}
            className="customPopup"
        >
            <div className="modal fade show d-block" role="dialog" style={{ backdropFilter: 'blur(5px)' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ padding: '36px',maxWidth:"900px" }} role="document">
                    <div className="modal-content" style={{ boxShadow: 'none', border: 'none' }}>
                        <div className="modal-body p-0">
                            <div className="row m-0" style={{ transform: "rotate(0deg)" }}>
                                <div className="col-lg-6 order-2 order-lg-1 p-3 p-lg-3" style={{ background: '#B9aa99', borderRadius: "8px", transform: "rotate(0deg)" }}>
                                    <div className="d-flex justify-content-center align-items-center mb-3 flex-column">
                                        <img src={avataarNewLogo} alt="Logo" className="img-fluid d-block d-lg-none" style={{ maxWidth: '35%', marginBottom: '10px' }} loading="lazy"/>
                                        <img src={avataarNewLogo} alt="Logo" className="img-fluid d-none d-lg-block" style={{ maxWidth: '50%', marginBottom: '10px' }} loading="lazy"/>
                                        <div className="text-black" style={{fontWeight:"bolder", textAlign:"center", fontSize:"18px"}}>
                                            BOOK FREE VIDEO CONSULTATION TO TALK TO OUR EXPERTS
                                            <br />
                                        </div>
                                    </div>
                                    <form>
                                        <div>
                                            <div className='form-group'>
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    name="name"
                                                    type="text"
                                                    required
                                                    pattern="^(?!\s).*"
                                                    title="First character cannot be a blank space"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className='form-control mb-3 rounded-3'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor="phone">Phone Number</label>
                                                <input
                                                    name="phone"
                                                    type="tel"
                                                    pattern="[5-9][0-9]{9}"
                                                    required
                                                    minLength="10"
                                                    maxLength="10"
                                                    title="Please enter a 10-digit phone number starting with a digit between 5 and 9"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    className='form-control mb-3 rounded-3'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor="city">City</label>
                                                <input
                                                    name="city"
                                                    type="text"
                                                    required
                                                    pattern="^(?!\s).*"
                                                    title="First character cannot be a blank space"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    className='form-control mb-3 rounded-3'
                                                />
                                            </div>
                                        </div>
                                        <DynamicButton label={'SCHEDULE NOW'} onClick={handleSubmit} />
                                    </form>

                                </div>
                                <div className="col-lg-6 order-1 order-lg-2 p-0" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', overflow: "hidden", }}>
                                    <div className="d-lg-none" style={{ backgroundImage: `url(${sharkTankPopup})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '230px', }} />
                                    <div style={{ backgroundImage: `url(${sharkTankPopupSvg})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100%' }} />
                                </div>
                                <div className="d-flex justify-content-end order-3 position-fixed top-0 end-0 p-2">
                                    <button type="button" className="btn btn-dark text-black font-weight-bold rounded-2" style={{ backgroundColor: 'white', padding: "4px 8px" }} onClick={handleModalClose}>
                                        <strong>&times;</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default ScheduleConsultation