import React, { useState, useEffect } from "react";
import Navbar from "common/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";
import PageLayout from "common/PageLayout";
import shoppingcart from "../assets/images/shoppingcart.svg";
import CustomModal from "common/CustomModal";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Tabs from "react-responsive-tabs";
import "react-responsive-tabs/styles.css";
import * as profileThunk from "../redux/thunks/profileThunk";
import { useDispatch, useSelector } from "react-redux";
import * as productThunk from "../redux/thunks/productThunk";
import * as productUiAction from "../redux/reducer/ui/productUiDux";
import warning from "../assets/images/warning.svg";
import appstoreicon from "../assets/images/app-store-icon.webp";
import googleplayicon from "../assets/images/play-store-icon.webp"
import MetaTags from "./MetaTags";
import CouponAlert from "./CouponAlert/CouponAlert";
import { IpAddressTracking } from "utils/helper";

const ProductDetails = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [cartPopUp, setCartPopUp] = useState(false);
  const [signupPopup, setSignupPopup] = useState(false);
  const { isFetching, error } = useSelector((state) => state.ui.product);
  const [paymentLoader, setPaymentLoader] = useState(true);

  const params = useParams();
  const [prodObj, setProdObj] = useState({
    productId: "",
    sessionId: "",
    date: "",
    time: "",
  });

  const slug = params.slug;

  useEffect(
    () => {
      dispatch(productThunk.getProduct(slug));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setPaymentLoader(false);
    }, 4000);
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const token1 = localStorage.getItem("token");

  const { productList, productDetails, productSessionList } = useSelector(
    (state) => state.entities.product
  );

  const howWork = productDetails?.howitworks;
  const overViewList = productDetails?.description;

  const cartHanlder = async (id) => {
    setProdObj((prevState) => ({
      ...prevState,
      productId: id,
    }));

    getSessionList(id);

  };

  const getSessionList = (id) => {
    dispatch(productThunk.getProductSessionById(id))
      .then((res) => {
        setCartPopUp(true);
      })
      .catch((error) => {
        console.log(error, "get session error ");
      });
  };

  const handleModalClose = () => {
    setCartPopUp(false);
  };


  const options = {
    items: 1,
    responsiveClass: true,
    video: true,
  };

  const faqs1 = productDetails?.faq;

  const getTabs = () => {
    return faqs1?.map(({ name, description }, index) => ({
      title: name,
      getContent: () => description,
      key: index,
      tabClassName: "tab",
      panelClassName: "panel",
    }));
  };

  useEffect(() => {
    const bars = document.querySelectorAll(".progress-bar__outter-line");
    const COUNT_STARS = 12;
    bars.forEach((el) => {
      let rating = el.dataset.rating;
      let percent = (100 * rating) / COUNT_STARS;
      el.querySelector(".progress-bar__inner-line").style.width = `${percent}%`;
    });
  }, []);
  const handleAddToCart = async (updatedProdObj) => {
    const res = await IpAddressTracking();

    localStorage.setItem("prodObj", JSON.stringify(updatedProdObj));
    let productId = {
      productId: updatedProdObj.productId,
      sessionId: updatedProdObj.sessionId,
      ipInfo: res ? res : null,
    };
    dispatch(profileThunk.addToCart(productId))
      .then((res) => {
        navigate("/cart");
              })
      .catch((error) => {
        console.log(error, "product add error");
      });
  };

  const signupModalHandler = () => {
    setSignupPopup(true);
  };

  const signupModaCloselHandler = () => {
    setSignupPopup(false);
  };

  const generateVideoImgSlider = () => {
    let sliderArray = productDetails?.video[0] !== null?[...productDetails?.video, ...productDetails?.image]:[...productDetails?.image];

    return sliderArray.map((item, index) => {
      if (item?.includes("youtube.com")) {
        return (
          <div className="slider_content">
            <div className="container">
              <iframe
                title="productVideo"
                key={index}
                className="video-iframe responsive-iframe"
                src={`${item}?rel=0&enablejsapi=1&mute=1`}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className="slider_content">
            <div className="">
              <span>
                <img key={index} src={item} alt="overView Placeholder" loading="lazy"/>
              </span>
            </div>
          </div>
        );
      }
    });
  };

  const hanldleSliderChange = () => {
    var iframes = document.getElementsByTagName("iframe");
    // Iterate over each iframe
    for (var i = 0; i < iframes.length; i++) {
      var iframe = iframes[i];

      // Check if the iframe is a YouTube or Vimeo video
      if (iframe.src.includes("youtube.com")) {
        // Pause the video in the iframe
        if (iframe.contentWindow && iframe.contentWindow.postMessage) {
          iframe.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          );
        }
      }
    }
  };

  const closeErrorHandler = () => {
    dispatch(productUiAction.clearUi());
  };

  const [isActive, setIsActive] = useState(true);

  const sessionSelectionHandler = () => {
    if (prodObj !== null) {
      setIsActive(false);
    }
  };

  const collectionName = localStorage.getItem("collectionName");

  const { productCollection } = useSelector((state) => state.entities.product);
  const userGender = localStorage.getItem("userGender");

  useEffect(
    () => {
      if (userGender === "male") {
        dispatch(productThunk.getProductCollectionByGender("male"));
      } else {
        dispatch(productThunk.getProductCollectionByGender("female"));
      }

      // generateSliderOne()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [stickyClass] = useState("relative");


  const generateSliderOne = (value) => {
    const filteredCollection =
      productCollection &&
      productCollection.filter((item) => item.type === value);

    const collectionDetail = filteredCollection[0]?.collectionDetail;
    const renderedItems = collectionDetail?.map((item) => (
      <li
        key={item.id}
        onClick={(e) => {
          localStorage.setItem("bestProductId", item?.id);
          localStorage.setItem("collectionName", item?.title);
          navigate(`/advanced-laser-treatments/${item.slug}`);
          window.location.reload();
          window.scroll(0, 0);
        }}
      >
        <img className="img-responsive" src={item.image} alt="model 1" loading="lazy" />
      </li>
    ));

    return renderedItems;
  };

  return (
    <>
      {error ? (
        <div className="customErrorMessage">
          <p>
            <img src={warning} alt="warning" loading="lazy"/>
            Error! {error}.
          </p>
          <span onClick={closeErrorHandler}>x</span>
        </div>
      ) : (
        ""
      )}
      <MetaTags
        metaTitle={slug.includes("hydration-boost-facial") ? "Transform Your Skin: Best Hydrafacial in Delhi!" : slug.includes("double-derma-boost") ? "Smooth Skin Awaits: Laser Facial Treatment Near Me" : productDetails?.metaTitle}
        metaDescription={slug.includes("hydration-boost-facial") ? "Experience the ultimate skin transformation with Avataar's Hydration Boost Facial. The best Hydrafacial in Delhi, now available at home. Book your session today!" : slug.includes("double-derma-boost") ? "Unveil flawless skin with Avataar's Double Derma Boost Facial. Enjoy our laser facial treatment near you for effective hair removal. Book your appointment now!" : productDetails?.metaDescription}
        keywords={slug.includes("hydration-boost-facial") ? "hydrafacial at home, best hydrafacial in delhi" : slug.includes("double-derma-boost") ? "best laser treatment for facial hair removal, facial hair removal laser treatment near me, facial laser treatment near me" : ""}
      />
      <CouponAlert />
      <CustomModal
        show={cartPopUp}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper">
          <div className="master-form-group  addBox session">
            <p>Select Session</p>

            <ul className="mid-1-check ">
              {productSessionList.map((item, index) => (
                <li
                  key={item.id}
                  className="chkinput"
                  onClick={async() => {
                    setProdObj((prevState) => ({
                      ...prevState,
                      sessionId: item.sessionId,
                    }));
                    const updatedProdObj = {
                      ...prodObj,
                      sessionId: item.sessionId,
                    };
                
                    await handleAddToCart(updatedProdObj);
                    sessionSelectionHandler();
                  }}
                >
                  <label htmlFor={item.id}>
                    <input type="radio" id={item.id} name="session" value="" />
                    {item.count > 1 ? (
                      <span>
                        <b>{item.count} sessions</b>₹
                        {Math.ceil(item.perSession)} per session
                      </span>
                    ) : (
                      <span>
                        <b>{item.count} session</b>₹{Math.ceil(item.perSession)}{" "}
                        per session
                      </span>
                    )}
                  </label>
                  <div className="couponConatiner">
                    <div className="price">
                      <p>₹ {item.finalPrice}</p>
                    </div>
                    <div className="coupon">
                      {item.discount > 0 ? (
                        <p>₹ Save {item.discount}%</p>
                      ) : (
                        <p className="no-discount"></p>
                      )}
                      {item.discount > 0 ? (
                        <p>
                          <s>₹{item.price}</s>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="master-form-group scheduleBox ">
            <button
              className={`form_button ${isActive ? "disabled" : ""}`}
              onClick={handleAddToCart}
            >
              SELECT SESSION
            </button>
          </div> */}
        </div>
      </CustomModal>

      <Navbar onSignup={signupPopup} onSignupClose={signupModaCloselHandler} />

      <div className="wrapper productDetailPage pageHeading">
        <h2>{productDetails.name}</h2>

        <h6>
          <span onClick={() => navigate("/")}>Home</span> <span>/</span>
          <span onClick={() => navigate(-1)}>{productDetails.categoryName}</span>{" "}
          <span>/</span>
          <span onClick={() => window.location.reload()}>
            {productDetails.name}
          </span>
        </h6>
        <PageLayout>
          <div className="left">
            {paymentLoader ? (
              <div className="fetching_oval">
                <img
                  src="https://miro.medium.com/v2/resize:fit:1132/1*eQ7Jn1t9AqaBXOWYmW7mHQ.gif"
                  alt=""
                  loading="lazy"
                />
              </div>
            ) : (
              <>
                {Object.keys(productDetails).length > 1 ? (
                  <OwlCarousel
                    onChange={hanldleSliderChange}
                    className="owl-theme"
                    {...options}
                  >
                    {generateVideoImgSlider()}
                  </OwlCarousel>
                ) : (
                  "Loading..."
                )}
              </>
            )}

            <div className="modalWrapper">
              <div className="header">
                <div className="info">
                  <span>
                    <Icon path={mdiStar} size={0.8} />
                    {productDetails.rating}
                  </span>
                  <p>
                    ₹{productDetails.price}
                    <span> • {productDetails.duration} mins</span>{" "}
                  </p>
                </div>

                <div className="action">
                  {token1 ? (
                    <span onClick={() => cartHanlder(productDetails.id)}>
                      Add To Cart
                    </span>
                  ) : (
                    <span onClick={signupModalHandler}>Add To Cart</span>
                  )}
                </div>
              </div>
              <div className="overviewList new">
                <div className="cartItemDetails listStyles ">
                  <h4>OVERVIEW</h4>
                  <div className="description">
                    <div
                      className="overview-section"
                      dangerouslySetInnerHTML={{ __html: overViewList }}
                    />
                  </div>
                </div>
              </div>

              <div className="wrapper refer new">
                {paymentLoader ? (
                  <div className="fetching_oval">
                    <img
                      src="https://miro.medium.com/v2/resize:fit:1132/1*eQ7Jn1t9AqaBXOWYmW7mHQ.gif"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                ) : (
                  <div className="wrapperContainer">
                    <h4 className="heading">How it works</h4>
                    <div
                      className="howitworks"
                      dangerouslySetInnerHTML={{ __html: howWork }}
                    />
                  </div>
                )}
              </div>

              <div className="aftercareTips new">
                <div className="cartItemDetails listStyles ">
                  <h4>AFTERCARE TIPS</h4>

                  <div className="description">

                    <div
                      dangerouslySetInnerHTML={{ __html: productDetails.tips }}
                    />

                  </div>
                </div>
              </div>
            </div>

            {slug.includes("hydration-boost") &&
              <p style={{ backgroundColor: "#f5f5f6", padding: "8px 16px" }}>
                Transform your skin with Avataar Skin's Hydration Boost Facial for Women, celebrated as Delhi's premier Hydrafacial. This cutting-edge laser treatment deeply hydrates and rejuvenates, delivering vital moisture and nutrients directly to your skin cells. Whether you're battling dullness, fine lines, or longing for a dewy complexion, our treatment caters to all skin types.<br /><br />
                The best part? Experience this luxury in the comfort of your home with our expert at-home service. Pamper yourself with professional care without leaving your doorstep.<br /><br />
                Book your Hydration Boost Facial today and discover the magic of radiant, youthful skin with Avataar Skin.
              </p>}

            {slug.includes("double-derma-boost-facial-for-women") &&<p style={{ backgroundColor: "#f5f5f6", padding: "8px 16px" }}>
              Experience the transformative benefits of Avataar Skin's Double Derma Boost Facial for Women. Our advanced laser treatment is meticulously crafted to not only remove facial hair effectively but also to rejuvenate your skin. With meticulous precision and gentle care, we ensure visible results that enhance your natural beauty. Whether your goal is effective facial hair removal or revitalizing your skin's youthful glow, Avataar Skin promises exceptional care and outstanding outcomes. Step into smoother, more radiant skin today with our expertly designed facial treatment.
            </p>}

            {slug.includes("double-derma-boost-facial-for-men") &&<p style={{ backgroundColor: "#f5f5f6", padding: "8px 16px" }}>
              Experience the transformative benefits of Avataar Skin's Double Derma Boost Facial for Men. Our advanced laser treatment is meticulously crafted to not only remove facial hair effectively but also to rejuvenate your skin. With meticulous precision and gentle care, we ensure visible results that enhance your natural beauty. Whether your goal is effective facial hair removal or revitalizing your skin's youthful glow, Avataar Skin promises exceptional care and outstanding outcomes. Step into smoother, more radiant skin today with our expertly designed facial treatment.
            </p>}

            <div className="faqTabs new tabStyle">
              <div className="cartItemDetails listStyles ">
                <h4>FREQUENTLY ASKED QUESTIONS</h4>
                <Tabs items={getTabs()} />
              </div>
            </div>
          </div>

          <div className="right">
            <div className={`discounts  ${stickyClass}  `}>
              <ul>
                <li className="active">
                  <div className="left" leftIcon>
                    <img
                      src={shoppingcart}
                      alt="shopping cart icon"
                      className="cartImg"
                      loading="lazy"
                    />
                    <h5>
                      Introductory App pricing
                      <br />
                      <span>
                        Flat 20% discount on 6 sessions or more when you
                        download the Avataar Skincare App.
                      </span>
                      <div className="app-icons-container">
                        <a
                          href="https://apps.apple.com/in/app/avataar-skincare/id6468362044"
                          target="_blank"
                          rel="noopener noreferrer"
                          onclick="return gtag_report_conversion('https://apps.apple.com/in/app/avataar-skincare/id6468362044');"
                        >
                          <img
                            src={appstoreicon}
                            alt="App Store"
                            class="mw-70 image-class"
                            loading="lazy"
                          />
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.avataarskin.app"
                          target="_blank"
                          rel="noopener noreferrer"
                          onclick="return gtag_report_conversion('https://play.google.com/store/apps/details?id=com.avataarskin.app');"
                        >
                          <img
                            src={googleplayicon}
                            alt="Google Store"
                            class="mw-70 image-class"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </h5>
                  </div>
                </li>
              </ul>

              <h4 className="heading">Best Package Deals</h4>
              <ul className="listpagePackages">
                {generateSliderOne("slider_grid_view")}
              </ul>
            </div>
          </div>
        </PageLayout>
      </div>
    </>
  );
};

export default ProductDetails;