import CustomModal from 'common/CustomModal'
import React from 'react'

const AddCityModal = ({
    regionalCity,
    regionalCityModal,
    handleCitySelection,
    // handleCitySubmit,
    handleModalClose,
    cities }) => {
    return (
        <CustomModal
            show={regionalCityModal}
            onClose={handleModalClose}
            className="custom_modal customPopup innerPopup authPopup"
        >

            <div className="modal_content form_wrapper addressPopup">
                <div className={`master-form-group4 long `}>
                    <h4>ADD City</h4>
                </div>
                <div className="address-form">
                    <div className="container">
                        <div className="row">
                            {/* Map through the cities array and render city components */}
                            {cities && cities.map((city) => (
                                // <div key={city.id} className="col-lg-2 col-md-4 col-12 d-flex flex-column">
                                <div key={city.id} className="col-lg-3 col-md-4 col-4 mb-2">

                                    {/* justify-content-start align-items-center */}
                                    <span className={`city-class ${regionalCity === city.name ? 'bg-class' : ''}`} onClick={() => handleCitySelection(city.name)}>
                                        <img src={city.image} className="city-img cursor-pointer" alt={city.name} loading="lazy"/>
                                        <span className="city-text">{city.name}</span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* <div className="row m-2">
                    <div className="col-lg-12">
                        <div className="master-form-group scheduleBox">
                            <button
                                onClick={handleCitySubmit}
                                disabled={false}
                                className="form_button"
                            >
                                SAVE CITY
                            </button>
                        </div>
                    </div>
                </div> */}
            </div>
        </CustomModal>
    )
}

export default AddCityModal