import React from 'react'
import OwlCarousel from "react-owl-carousel";

const ProductCarousel = ({productCollection,generateSlider,options,view}) => {
  return (
    <div className="centerSlider mb-10 customSlider">
                    {productCollection?.length > 1 && (
                        <OwlCarousel
                            className="owl-theme owl-carousel"
                            id="carousel-1"
                            {...options}
                        >
                            {generateSlider(view)}
                        </OwlCarousel>
                    )}
                </div>
  )
}

export default ProductCarousel