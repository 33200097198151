import React, { useState, useEffect } from "react";
import Navbar from "common/Navbar";
import CustomModal from "../../common/CustomModal";
import homeImage from "../../assets/images/home.svg";
import clockImage from "../../assets/images/clock.svg";
import infoIcon from "../../assets/images/infoIcon.svg";
import GoogleMapReact from "google-map-react";
import { useDispatch, useSelector } from "react-redux";
import * as profileThunk from "../../redux/thunks/profileThunk";
import * as moment from "moment";

const Ongoing = () => {
  let dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(profileThunk.getBookingDetails(currenSessionId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { bookingDetails } = useSelector((state) => state.entities.profile);
  const currenSessionId = localStorage.getItem("sessionId");

  const [trackPopup, setTrackPopup] = useState(false);

  const handleModalClose = () => {
    setTrackPopup(false);
  };

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  return (
    <>
      <CustomModal
        show={trackPopup}
        onClose={handleModalClose}
        className="customPopup authPopup"
      >
        <div className="modal_content form_wrapper">
          <div className="trackContainer">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            ></GoogleMapReact>
                   </div>
        </div>
      </CustomModal>

      <Navbar />
      <div className="wrapper">
        <div className="wrapperContainer">
          <div className="innerContainer productStatusDiv">
                        <div className="bookingDetails ">
              <h4>Booking Details</h4>
              <div className="bookingInner">
                <div className="address">
                  <span className="home_image">
                    <img src={homeImage} alt="Home" loading="lazy"/>
                  </span>
                  <span>
                    <b>{bookingDetails.addressType}</b>,{" "}
                    {bookingDetails.address} {bookingDetails.addressCity} <br />
                    {bookingDetails.addressState},
                    {bookingDetails.addressPincode}
                  </span>
                </div>
                <div className="date">
                  <span className="clock_image font_p">
                    <img src={clockImage} alt="Clock" loading="lazy"/>
                  </span>
                  <span>
                    {moment(
                      bookingDetails.appointmentAt,
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    )
                      .utcOffset("+00:00")
                      .format(`ddd, MMM DD • hh:mm A`)}
                  </span>
                </div>
                <div className="buttonBox">
                  <button>
                    <a
                      href="https://api.whatsapp.com/send/?phone=919810339778&amp;text=I+want+Skin+care+Treatment.&amp;type=phone_number&amp;app_absent=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>RESCHEDULE</span>
                    </a>
                  </button>
                  <button className="bd5">
                    <span>CANCEL</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="sessionTracker">
              <h4>Session Tracker</h4>
              <div className="wrapper refer">
                <div className="wrapperContainer">
                  <ul className="session">
                    <li className="active">
                      <div className="referList referList1">
                        <span className="list1">1</span>
                        <div className="listContent">
                          <span className="list2">Start Session</span>
                          <span>
                            Share the OTP to the professional for the treatment
                            to begin
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="referList ">
                        <span>2</span>
                        <div className="listContent">
                          <span>Session Underway</span>
                          <span>Hope you enjoy your session</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="referList ">
                        <span>3</span>
                        <div className="listContent">
                          <span>End Session</span>
                          <span>
                            Share the OTP to the professional for the treatment
                            to end{" "}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="referList ">
                        <span>4</span>
                        <div className="listContent">
                          <span>Provide Feedback</span>
                          <span>
                            Help us improve your experience by providing
                            feedback
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="referTerms">
                    <ul>
                      <li>
                        <a
                          href="/terms-conditions"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li>
                        <a href="/privacy-policies" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="policyContainer">
              <div className="policy_heading">
                <h4>Cancellation policy</h4>
              </div>
              <div className="policyNote">
                <ul>
                  <li>
                    <span className="infoIcon">
                      <img src={infoIcon} alt="Info_Icon" loading="lazy"/>
                    </span>
                    <span>
                      No charge if you reschedule or cancel until 3 hours before
                      the service
                    </span>
                  </li>
                  <li>
                    <span className="infoIcon">
                      <img src={infoIcon} alt="Info_Icon" loading="lazy"/>
                    </span>
                    <span>
                      Once a professional is assigned, a non-refundable
                      cancellation fee of Rs. 100 will be charged.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bookingStatusWrapper">
            <div className="statusPreviewBox">
              <h5>Full Body Laser Hair Reduction</h5>
              <span>Sat, Mar 04 at 8:00 PM</span>
              <div className="innerBox ongoing">
                <span>Rachel Is On The Way</span>
                <span onClick={() => setTrackPopup(true)}>TRACK</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ongoing;
