import { createAction, createReducer } from "redux-act";
export const setData = createAction("USER/SET_DATA");
export const setUserToken = createAction("USER/SET_USER_TOKEN");

const handlers = {
  [setData](state, payload) {
    return payload;
  },

  [setUserToken](state, payload) {
    return {
      ...state,
      token: payload,
    };
  },
};

export default createReducer(handlers, {});
