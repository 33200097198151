import { createAction, createReducer } from "redux-act";
export const setFormData = createAction("PASSWORD/SET_FORM_DATA");
export const resetFormData = createAction("PASSWORD/RESET_FORM_DATA");
export const authenticate = createAction("PASSWORD/AUTHENTICATE");
export const setToken = createAction("PASSWORD/SET_TOKEN");

const handlers = {
  [setFormData](state, payload) {
    return {
      ...state,
      formData: payload,
    };
  },
  [resetFormData](state) {
    return {
      ...state,
      formData: {},
    };
  },
  [setToken](state, payload) {
    return {
      ...state,
      token: payload,
    };
  },
  // [authenticate](state, payload) {
  //     return {
  //         ...state,
  //         token: payload
  //     }
  // }
};

export default createReducer(handlers, {});
