import React from "react";
import ReactDOM from "react-dom/client";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import reducer from "./redux/reducer";
import initialStates from "./redux/initialStates";
import App from "./App";
import { composeWithDevTools } from "redux-devtools-extension";
import { setAuthorizationToken } from "./utils/httpUtils";
import { getUserDetails } from "./redux/thunks/authThunk";

// CSS imports
import "./styles/home.css";
import "./styles/cart.css";
import "./styles/CategoryListing.css";
import "./assets/fonts/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/reset.css";
import "./styles/responsive.css";
//facebook SDK integration 
import './utils/facebookInit';

export const store = createStore(
  reducer,
  initialStates,
  composeWithDevTools(applyMiddleware(thunk))
);
if (localStorage.token) {
  setAuthorizationToken(localStorage.token);
  // prevent someone from manually setting a key of 'token' in localStorage
  try {
    store.dispatch(getUserDetails());
  } catch (e) {
    store.dispatch(getUserDetails(null));
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
