import { createAction, createReducer } from "redux-act";
export const setFetching = createAction("PROFILE/SET_FETCHING");
export const setError = createAction("PROFILE/SET_ERROR");

const handlers = {
  [setFetching](state, payload) {
    return {
      ...state,
      isFetching: payload,
    };
  },

  [setError](state, payload) {
    return {
      ...state,
      error: payload,
    };
  },
};

export default createReducer(handlers, {});
