import Navbar from 'common/Navbar'
import React from 'react'
import { useParams } from 'react-router-dom';
import MetaTags from 'components/MetaTags';

const CityWiseSEOContent = () => {
    const { slug } = useParams();
    let city = slug?.split('-');
    city = city[city?.length - 1];

    const title = (city) => {
        if (city === "delhi") {
            return "Best Laser Hair Removal at Home in Delhi | Avataar Skin"
        }
        else if (city === "mumbai") {
            return "Best Laser Hair Removal at Home in Mumbai | Avataar Skin"
        }
        else if (city === "pune") {
            return "Best Laser Hair Removal at Home in Pune | Avataar Skin"
        }
        else if (city === "bangalore") {
            return "Best Laser Hair Removal at Home in Bangalore | Avataar Skin"
        }
        else if (city === "hyderabad") {
            return "Best Laser Hair Removal at Home in Hyderabad | Avataar Skin"
        }
        else if (city === "chandigarh") {
            return "Best Laser Hair Removal at Home in Chandigarh | Avataar Skin"
        }
        else if (city === "mohali") {
            return "Best Laser Hair Removal at Home in Mohali | Avataar Skin"
        }
        else if (city === "panchukla") {
            return "Best Laser Hair Removal at Home in Panchkula | Avataar Skin"
        }
        else if (city === "gurgaon") {
            return "Best Laser Hair Removal at Home in Gurgaon | Avataar Skin"
        }
        else if (city === "noida") {
            return "Best Laser Hair Removal at Home in Noida | Avataar Skin"
        }
        else if (city === "faridabad") {
            return "Best Laser Hair Removal at Home in Faridabad | Avataar Skin"
        }
        else if (city === "ghaziabad") {
            return "Best Laser Hair Removal at Home in Ghaziabad | Avataar Skin"
        }
        else if (city === "indore") {
            return "Best Laser Hair Removal at Home in Indore | Avataar Skin"
        }
        else if (city === "ludhiana") {
            return "Best Laser Hair Removal at Home in Ludhiana | Avataar Skin"
        }
        else if (city === "ahmedabad") {
            return "Best Laser Hair Removal at Home in Ahmedabad | Avataar Skin"
        }
    }

    const description = (city) => {
        if (city === "delhi") {
            return "Discover the best laser hair removal at home in Delhi with Avataar Skin. Enjoy professional, clinic-quality results in the comfort of your home with our advanced technology and expert care. Book your appointment today!            "
        }
        else if (city === "mumbai") {
            return "Discover the best laser hair removal at home in Mumbai with Avataar Skin. Our expert treatments offer safe and effective hair removal, providing you with smooth, flawless skin without leaving your home. Book your session today for convenient, professional care."
        }
        else if (city === "pune") {
            return "Get the best laser hair removal at home in Pune with Avataar Skin. Enjoy safe, effective, and professional treatments for smooth, hair-free skin, all from the comfort of your home. Book your appointment today!"
        }
        else if (city === "bangalore") {
            return "Experience the best laser hair removal at home in Bangalore with Avataar Skin. Our expert treatments provide safe, effective, and lasting results, giving you smooth, hair-free skin in the comfort of your home. Book your appointment today!            "
        }
        else if (city === "hyderabad") {
            return "Enjoy the best laser hair removal at home in Hyderabad with Avataar Skin. Our professional treatments offer safe, effective, and long-lasting results, all in the comfort of your home. Book your session today for smooth, hair-free skin."
        }
        else if (city === "chandigarh") {
            return "Experience the best laser hair removal at home in Chandigarh with Avataar Skin. Our expert team provides safe, effective, and convenient treatments for smooth, hair-free skin in the comfort of your home. Book your session today!"
        }
        else if (city === "mohali") {
            return "Achieve smooth, hair-free skin with the best laser hair removal at home in Mohali from Avataar Skin. Our expert team offers safe, effective treatments delivered right to your doorstep. Book your appointment today for professional and convenient care."
        }
        else if (city === "panchukla") {
            return "Enjoy the best laser hair removal at home in Panchkula with Avataar Skin. Our specialized treatments offer effective, comfortable, and convenient hair removal solutions right at your doorstep. Experience flawless skin with our expert care."
        }
        else if (city === "gurgaon") {
            return "Get the best laser hair removal at home in Gurgaon with Avataar Skin. Our advanced treatments offer safe, effective, and comfortable hair removal right from your home. Enjoy smooth skin with our expert care and cutting-edge technology.            "
        }
        else if (city === "noida") {
            return "Enjoy the best laser hair removal at home in Noida with Avataar Skin. Our expert team uses advanced technology to deliver smooth, hair-free results in the comfort of your home. Experience convenience and quality with us today!            "
        }
        else if (city === "faridabad") {
            return "Achieve smooth, hair-free skin with the best laser hair removal at home in Faridabad from Avataar Skin. Our professional treatments offer safe and effective results in the comfort of your home. Schedule your appointment today for expert care and convenience.            "
        }
        else if (city === "ghaziabad") {
            return "Get the best laser hair removal at home in Ghaziabad with Avataar Skin. Enjoy safe, effective, and comfortable treatments from our expert team, right in the comfort of your home. Book your session now for smooth, hair-free skin.            "
        }
        else if (city === "indore") {
            return "Experience the best laser hair removal at home in Indore with Avataar Skin. Our advanced treatments provide safe, effective, and lasting results, all from the comfort of your home. Book your appointment today for smooth, hair-free skin.            "
        }
        else if (city === "ludhiana") {
            return "Get the best laser hair removal at home in Ludhiana with Avataar Skin. Our advanced treatments offer safe, effective, and convenient hair removal in the comfort of your home. Achieve smooth, hair-free skin today!            "
        }
        else if (city === "ahmedabad") {
            return "Enjoy the best laser hair removal at home in Ahmedabad with Avataar Skin. Our expert team provides safe and effective treatments for smooth, hair-free skin in the comfort of your home. Book your session today!            "
        }
    }

    const keywords = (city) => {
        if (city === "delhi") {
            return "best laser hair removal in Delhi,best laser hair removal at home in Delhi"
        }
        else if (city === "mumbai") {
            return "best laser hair removal in Mumbai,best laser hair removal at home in Mumbai"
        }
        else if (city === "pune") {
            return "best laser hair removal in Pune,best laser hair removal at home in Pune"
        }
        else if (city === "bangalore") {
            return "best laser hair removal in Bangalore,best laser hair removal at home in Bangalore"
        }
        else if (city === "hyderabad") {
            return "best laser hair removal in Hyderabad,best laser hair removal at home in Hyderabad"
        }
        else if (city === "chandigarh") {
            return "best laser hair removal in Chandigarh,best laser hair removal at home in Chandigarh"
        }
        else if (city === "mohali") {
            return "best laser hair removal in Mohali,best laser hair removal at home in Mohali"
        }
        else if (city === "panchukla") {
            return "best laser hair removal in Panchukla,best laser hair removal at home in Panchukla"
        }
        else if (city === "gurgaon") {
            return "best laser hair removal in Gurgaon,best laser hair removal at home in Gurgaon"
        }
        else if (city === "noida") {
            return "best laser hair removal in Noida,best laser hair removal at home in Noida"
        }
        else if (city === "faridabad") {
            return "best laser hair removal in Faridabad,best laser hair removal at home in Faridabad"
        }
        else if (city === "ghaziabad") {
            return "best laser hair removal in Ghaziabad,best laser hair removal at home in Ghaziabad"
        }
        else if (city === "indore") {
            return "best laser hair removal in Indore,best laser hair removal at home in Indore"
        }
        else if (city === "ludhiana") {
            return "best laser hair removal in Ludhiana,best laser hair removal at home in Ludhiana"
        }
        else if (city === "ahmedabad") {
            return "best laser hair removal in Ahmedabad,best laser hair removal at home in Ahmedabad"
        }
    }

    const FirstPara = (city) => {
        if (city === "delhi") {
            return "Revamp your beauty routine with laser hair removal, a state-of-the-art cosmetic procedure that offers long-term relief from unwanted hair. By harnessing precision light beams, this advanced technique effectively targets hair follicles to inhibit future growth. It outshines traditional hair removal methods in both efficiency and results, making it the preferred choice for various body areas. Whether it’s your face, arms, underarms, or another region, laser hair removal adapts seamlessly to all skin and hair types, delivering smoother, hair-free skin with each session. Embrace this innovative solution and enjoy the benefits of modern skincare technology.            "
        }
        else if (city === "mumbai") {
            return "Elevate your beauty routine with laser hair removal, an advanced cosmetic procedure that provides long-lasting relief from unwanted hair. This cutting-edge technique employs precision light beams to target hair follicles, effectively preventing future growth. Outshining traditional hair removal methods in both efficiency and results, laser treatment has become the preferred choice for various body areas. Whether it’s your face, arms, underarms, or any other region, laser hair removal adapts to all skin and hair types, offering smoother, hair-free skin with each session. Embrace this revolutionary solution and enjoy the advantages of modern skincare technology"
        }
        else if (city === "bangalore") {
            return "Revamp your beauty regimen with laser hair removal, an advanced cosmetic procedure designed to provide lasting relief from unwanted hair. This innovative method employs precision light beams to target hair follicles, effectively preventing future hair growth. With superior efficiency and results compared to traditional hair removal techniques, laser treatment has become the preferred choice for various body areas. Whether it’s your face, arms, underarms, or any other region, laser hair removal is adaptable to all skin and hair types, delivering smoother, hair-free skin with each session. Embrace this revolutionary skincare solution and experience the benefits of cutting-edge technology."
        }
        else if (city === "hyderabad") {
            return "Elevate your beauty routine with laser hair removal, an advanced cosmetic procedure designed to provide long-term relief from unwanted hair. By harnessing precision light beams, this cutting-edge method effectively targets hair follicles to prevent future growth. Surpassing traditional hair removal techniques in both efficiency and results, laser treatment has become the preferred choice for various body areas. Whether you’re focusing on your face, arms, underarms, or any other region, laser hair removal is adaptable to all skin and hair types, offering smoother, hair-free skin with each session. Embrace this transformative solution and enjoy the benefits of modern skincare technology.            "
        }
        else if (city === "chandigarh") {
            return "Revamp your beauty routine with laser hair removal, an advanced cosmetic procedure designed to offer long-term relief from unwanted hair. By utilizing precision light beams, this cutting-edge method targets hair follicles to effectively prevent future growth. Outperforming traditional hair removal techniques in both efficiency and results, laser treatment has become the preferred choice for various body areas. Whether it’s your face, arms, underarms, or other regions, laser hair removal adapts to all skin and hair types, providing smoother, hair-free skin with each session. Embrace this revolutionary solution and enjoy the benefits of modern skincare technology."
        }
        else if (city === "gurgaon") {
            return "Elevate your beauty regimen with laser hair removal—a state-of-the-art cosmetic procedure that provides long-lasting freedom from unwanted hair. This advanced technique uses precision light beams to target and disable hair follicles, effectively preventing future hair growth. Unlike traditional methods, laser hair removal offers superior efficiency and results, making it the preferred choice for various body areas. Whether treating the face, arms, underarms, or other regions, this versatile procedure is suitable for all skin and hair types, delivering smoother, hair-free skin with every session. Embrace this innovative solution and experience the benefits of cutting-edge skincare technology."
        }
        else if (city === "noida") {
            return "Revolutionize your beauty regimen with laser hair removal, a state-of-the-art cosmetic procedure designed to offer lasting relief from unwanted hair. By harnessing the power of precision light beams, this advanced technique targets hair follicles, effectively inhibiting future hair growth. Laser hair removal outperforms traditional methods in both efficiency and results, making it an ideal choice for various body areas. Whether you're focusing on the face, arms, underarms, or other regions, this treatment adapts to all skin and hair types, delivering smoother, hair-free skin with each session. Embrace this cutting-edge solution and experience the benefits of modern skincare technology."
        }
        else if (city === "faridabad") {
            return "Elevate your beauty routine with laser hair removal, a state-of-the-art cosmetic treatment that offers long-term solutions for unwanted hair. Using targeted light beams, this innovative method effectively disrupts hair growth at the follicle level. It outshines traditional hair removal methods in both efficiency and results, making it a popular choice for various body areas. From the face and arms to underarms and more, laser hair removal is suitable for all skin and hair types, ensuring smoother, hair-free skin with every session. Embrace this advanced skincare technology for lasting results."
        }
        else if (city === "indore") {
            return "Revamp your beauty routine with laser hair removal, a state-of-the-art cosmetic treatment designed to offer long-lasting relief from unwanted hair. This advanced technique utilizes focused light energy to precisely target hair follicles, effectively preventing future hair growth. Compared to traditional hair removal methods, laser treatment excels in both efficiency and results, making it an ideal choice for various body areas. Whether it's your face, arms, underarms, or any other region, laser hair removal is adaptable to all skin and hair types, delivering smoother, hair-free skin with each session. Embrace this revolutionary skincare technology and enjoy its transformative benefits."
        }
        else if (city === "ludhiana") {
            return "Revitalize your beauty routine with laser hair removal, a state-of-the-art cosmetic procedure that provides long-lasting relief from unwanted hair. This advanced technique employs precision light beams to target hair follicles, effectively hindering future growth. Outperforming traditional hair removal methods in both efficiency and results, laser hair removal has become the preferred choice for various body areas. Whether treating your face, arms, underarms, or other regions, this procedure is adaptable to all skin and hair types, offering smoother, hair-free skin with each session. Embrace this groundbreaking solution and enjoy the advantages of modern skincare technology."
        }
        else if (city === "ahmedabad") {
            return "Elevate your beauty routine with laser hair removal, an innovative cosmetic procedure designed to offer long-lasting relief from unwanted hair. This advanced technique utilizes precision light beams to target hair follicles, effectively preventing future growth. Outperforming traditional hair removal methods in both efficiency and results, it has become the preferred choice for various body areas. Whether you’re focusing on your face, arms, underarms, or any other region, laser hair removal adjusts to all skin and hair types, delivering smoother, hair-free skin with each session. Embrace this cutting-edge solution and reap the benefits of modern skincare advancements."
        }
        else if (city === "ghaziabad") {
            return "Elevate your grooming routine with laser hair removal, an advanced cosmetic procedure that offers a long-term solution for unwanted hair. This innovative technique uses precise light energy to target hair follicles, effectively preventing future hair growth. It outperforms traditional hair removal methods in both efficiency and results, making it an ideal choice for various body areas. Whether you're looking to treat your face, arms, underarms, or other regions, laser hair removal is adaptable to all skin and hair types, providing progressively smoother, hair-free skin with each session. Embrace this state-of-the-art solution and experience the advantages of cutting-edge skincare technology.            "
        }
        else if (city === "mohali") {
            return "Elevate your beauty routine with laser hair removal, a state-of-the-art cosmetic procedure designed to provide long-term relief from unwanted hair. By utilizing precision light beams, this advanced method effectively targets hair follicles to inhibit future growth. Surpassing traditional hair removal techniques in both efficiency and results, laser treatment has become the preferred choice for various body areas. Whether focusing on your face, arms, underarms, or any other region, laser hair removal adapts to all skin and hair types, delivering smoother, hair-free skin with each session. Embrace this revolutionary solution and experience the benefits of modern skincare technology."
        }
        else if (city === "panchukla") {
            return "Revitalize your beauty routine with laser hair removal, an advanced cosmetic procedure designed to provide long-lasting relief from unwanted hair. This cutting-edge technology uses precision light beams to target hair follicles, effectively preventing future growth. Surpassing traditional hair removal methods in both efficiency and results, laser treatment is the preferred choice for various body areas. Whether focusing on your face, arms, underarms, or any other region, laser hair removal adapts to all skin and hair types, delivering smoother, hair-free skin with each session. Embrace this revolutionary solution and enjoy the advantages of modern skincare technology."
        }
        else {
            return "Transform your beauty routine with laser hair removal, a cutting-edge cosmetic procedure offering long-term relief from unwanted hair. Utilizing precision light beams, this advanced method targets hair follicles to prevent future growth effectively. It surpasses traditional hair removal techniques in both efficiency and results, making it the go-to option for various body areas. Whether it's your face, arms, underarms, or any other region, laser hair removal adapts to all skin and hair types, providing smoother, hair-free skin with each session. Embrace this revolutionary solution and enjoy the benefits of modern skincare technology.";
        }
    }

    const benefits = (city) => {
        if (city === "delhi") {
            return ["Long-Term Hair Reduction: Achieve substantial and lasting hair reduction.",
                "Streamlined Maintenance: Significantly reduces the need for frequent shaving or waxing.",
                "Precision Targeting: Accurately targets hairs while protecting the surrounding skin.",
                "Versatile Application: Suitable for various body parts, including the face, legs, arms, and bikini area.",
                "Efficient Sessions: Each laser pulse treats multiple hairs, making sessions quick and efficient.",
                "Smooth, Refined Skin: Enjoy a smoother skin texture with each treatment.",
                "Ingrown Hair Prevention: Effectively reduces ingrown hairs better than other methods.",
                "Cost-Effective: Offers long-term savings by reducing the need for regular hair removal products and services."
            ]
        }
        else if (city === "mumbai") {
            return ["Long-Lasting Hair Reduction: Achieve significant and enduring reduction in hair growth.",
                "Reduced Maintenance: Minimize the need for frequent shaving or waxing.",
                "Precision Targeting: Effectively targets hair while protecting the surrounding skin.",
                "Versatile Application: Suitable for treating various body areas, including the face, legs, arms, and bikini line.",
                "Time Efficiency: Each laser pulse treats multiple hairs, ensuring quick and efficient sessions.",
                "Smooth Skin Texture: Experience a smoother, more refined skin surface.",
                "Ingrown Hair Prevention: Reduces ingrown hairs more effectively than other methods.",
                "Cost-Effective: Save money over time by reducing the need for regular hair removal products and services.",
            ]
        }
        else if (city === "bangalore") {
            return ["Long-Term Hair Reduction: Achieve a significant and lasting reduction in hair growth.",
                "Reduced Maintenance: Minimize the need for frequent shaving or waxing.",
                "Precision Targeting: Effectively targets hair while protecting the surrounding skin.",
                "Versatile Application: Suitable for treating various body areas, including the face, legs, arms, and bikini line.",
                "Time-Efficient: Each laser pulse treats multiple hairs, ensuring quick and efficient sessions.",
                "Smooth Skin Texture: Experience a refined, smoother skin surface.",
                "Prevention of Ingrown Hairs: More effective at preventing ingrown hairs than other methods.",
                "Cost-Effective: Save money over time by reducing the need for regular hair removal products and services."]
        }
        else if (city === "hyderabad") {
            return ["Long-Term Hair Reduction: Achieve a significant and lasting reduction in hair growth.",
                "Reduced Maintenance: Decrease the need for frequent shaving or waxing.",
                "Precision Targeting: Effectively targets hair while protecting the surrounding skin.",
                "Versatile Application: Suitable for treating various body areas, including the face, legs, arms, and bikini line.",
                "Efficient Sessions: Each laser pulse treats multiple hairs, ensuring quick and efficient sessions.",
                "Smooth Skin Texture: Experience a smoother, more refined skin surface.",
                "Prevention of Ingrown Hairs: More effective at preventing ingrown hairs than other methods.",
                "Cost-Effective: Save money over time by reducing the need for regular hair removal products and services.",]
        }
        else if (city === "chandigarh") {
            return ["Long-Lasting Hair Reduction: Significantly reduces hair growth over the long term.",
                " Reduced Maintenance: Minimizes the need for frequent shaving or waxing.",
                "Precision Targeting: Accurately targets hair while protecting the surrounding skin.",
                "Versatile Application: Suitable for treating various body areas, including the face, legs, arms, and bikini line.",
                "Efficient Sessions: Each laser pulse treats multiple hairs, ensuring quick and efficient sessions.",
                " Smooth Skin Texture: Yields a refined and smoother skin surface.",
                "Ingrown Hair Prevention: More effective at preventing ingrown hairs than other methods.",
                "Cost-Effective: Saves money over time by reducing the need for regular hair removal products and services."]
        }
        else if (city === "gurgaon") {
            return ["Long-Lasting Hair Reduction: Achieve a significant and enduring decrease in hair growth.",
                "Effortless Maintenance: Reduces the frequency of shaving or waxing.",
                "Accurate Targeting: Focuses on hairs while protecting the surrounding skin.",
                "Versatility: Effective for various body parts, including the face, legs, arms, and bikini area.",
                "Time Efficiency: Each laser pulse treats multiple hairs, leading to quick sessions.",
                'Smooth Skin: Results in a refined and smoother skin texture.',
                "Prevents Ingrown Hairs: More effective at reducing ingrown hairs than other methods.",
                "Cost-Effective: Over time, it’s more economical, reducing the need for regular hair removal products and services."]
        }
        else if (city === "noida") {
            return ["Long-Term Hair Reduction: Significantly reduce hair growth with lasting results.",
                "Maintenance Simplified: Cut down on the need for frequent shaving or waxing.",
                "Laser Accuracy: Targets hair precisely without harming the surrounding skin.",
                "Versatile Treatment: Suitable for various body areas, including the face, legs, arms, and bikini line.",
                "Time-Efficient: Each laser pulse treats multiple hairs, making sessions quicker.",
                " Smooth Skin Finish: Achieve a smoother and more refined skin texture.",
                " Prevents Ingrown Hairs: More effective at reducing ingrown hairs than other methods.",
                "Cost-Effective Over Time: Save money in the long run by reducing the need for regular hair removal products and treatments."]
        }
        else if (city === "faridabad") {
            return ["Long-Term Hair Reduction: Achieve substantial and lasting hair growth reduction.",
                "Minimal Maintenance: Significantly reduce the need for frequent shaving or waxing.",
                "Precise Targeting: Focuses on hairs while sparing the surrounding skin.",
                "Versatile Treatment: Suitable for various body parts, including the face, legs, arms, and bikini area.",
                "Time - Saving: Each laser pulse covers multiple hairs, leading to quick treatment sessions.",
                "Smooth Skin: Results in refined and smoother skin texture.",
                "Ingrown Hair Reduction: More effective at reducing ingrown hairs than other methods.",
                "Cost - Effective Over Time: Saves money by reducing the need for regular hair removal products and services."]
        }
        else if (city === "indore") {
            return ["Long-Term Hair Reduction: Significantly reduce hair growth over the long term.",
                "Lower Maintenance: Decrease the need for frequent shaving or waxing.",
                "Targeted Precision: Accurately targets hair follicles while safeguarding surrounding skin.",
                "Versatile Treatment: Suitable for various body areas, including the face, legs, arms, and bikini line.",
                "Quick Sessions: Each laser pulse covers multiple hairs, making treatments swift and efficient.",
                "Smooth Skin Texture: Achieve a refined and smoother skin surface.",
                "Reduced Ingrown Hairs: More effective at preventing ingrown hairs than other methods.",
                "Cost-Effective: Save money in the long run by reducing the need for regular hair removal products and services."]
        }
        else if (city === "ludhiana") {
            return ["Long-Lasting Hair Reduction: Achieve a significant and lasting reduction in hair growth.",
                "Reduced Maintenance: Decrease the need for frequent shaving or waxing.",
                "Precision Targeting: Effectively targets hair while safeguarding surrounding skin.",
                "Versatile Application: Suitable for treating various body areas, including the face, legs, arms, and bikini line.",
                "Time Efficiency: Each laser pulse treats multiple hairs, ensuring quick and efficient sessions.",
                " Smooth Skin Texture: Enjoy a refined, smoother skin surface.",
                "Prevention of In grown Hairs: More effective at preventing ingrown hairs than other methods.",
                "Cost-Effective: Save money over time by reducing the need for regular hair removal products and services."]
        }
        else if (city === "ahmedabad") {
            return ["Long-Lasting Hair Reduction: Achieve a significant reduction in hair growth that lasts.",
                " Reduced Maintenance: Minimize the need for frequent shaving or waxing.",
                "Precise Targeting: Effectively targets hair follicles while protecting the surrounding skin.",
                "Versatile Application: Suitable for treating various body areas, including the face, legs, arms, and bikini line.",
                "Efficient Sessions: Each laser pulse treats multiple hairs, making the process quick and efficient.",
                "Smooth Skin Texture: Enjoy a smoother, more refined skin texture.",
                "Prevention of Ingrown Hairs: More effective at preventing ingrown hairs than other hair removal methods.",
                "Cost-Effective: Save money over time by reducing the need for regular hair removal products and services."]
        }
        else if (city === "ghaziabad") {
            return ["Long-Lasting Hair Reduction: Achieve a significant, long-term reduction in hair growth.",
                "Reduced Maintenance: Minimize the need for frequent shaving or waxing.",
                "Precision Targeting: Effectively targets hair while preserving the surrounding skin.",
                "Versatile Treatment: Suitable for various body areas, including the face, legs, arms, and bikini line.",
                "Time-Efficient: Each laser pulse treats multiple hairs, making sessions quick and efficient.",
                "Smooth Skin: Results in a refined, smoother skin texture.",
                "Prevents Ingrown Hairs: More effective at reducing ingrown hairs than other methods.",
                "Cost-Effective: Offers long-term savings by reducing the need for regular hair removal products and services."]
        }
        else if (city === "mohali") {
            return ["Long-Lasting Hair Reduction: Achieve a significant and enduring reduction in hair growth.",
                "Reduced Maintenance: Minimize the need for frequent shaving or waxing.",
                "Precision Targeting: Effectively targets hairs while protecting the surrounding skin.",
                "Versatile Treatment: Suitable for various body areas, including the face, legs, arms, and bikini line.",
                "Time Efficiency: Each laser pulse treats multiple hairs, ensuring quick and efficient sessions.",
                "Smooth Skin Texture: Enjoy a more refined, smoother skin surface.",
                "Ingrown Hair Prevention: Reduces ingrown hairs more effectively than other methods.",
                "Cost-Effective: Save money over time by reducing the need for regular hair removal products and services."]
        }
        else if (city === "panchukla") {
            return ["Long-Lasting Hair Reduction: Achieve significant and enduring reduction in hair growth.",
                " Reduced Maintenance: Minimize the need for frequent shaving or waxing.",
                "Precision Targeting: Effectively targets hairs while protecting the surrounding skin.",
                "Versatile Treatment: Suitable for various body areas, including the face, legs, arms, and bikini area.",
                "Efficient Sessions: Each laser pulse treats multiple hairs, ensuring quick and efficient sessions.",
                "Smooth Skin Texture: Enjoy a more refined, smoother skin surface.",
                "Ingrown Hair Prevention: Reduces ingrown hairs more effectively than other methods.",
                "Cost-Effective: Save money over time by reducing the need for regular hair removal products and services."]
        }
        else {
            return ["Enduring Hair Reduction: Achieve significant and lasting reduction in hair growth.",
                "Streamlined Maintenance: Reduces the need for frequent shaving or waxing.",
                "Laser Precision: Targets hairs accurately while preserving surrounding skin.",
                "Versatility Unleashed: Treats various body parts, including face, legs, arms, and bikini area.",
                "Time Optimization: Each laser pulse treats multiple hairs, ensuring swift sessions.",
                "Silky Smooth Skin: Yields a refined and smoother skin texture.",
                "Ingrown Hair Prevention: Reduces ingrown hairs better than other methods.",
                "Economical Long-Term: Cost-effective over time, reducing the need for regular hair removal products and services."]
        }
    }

    const Paras = (city) => {
        if (city === "delhi") {
            return ["Start your journey with a personalized consultation, where our expert skincare specialist evaluates your unique skin and hair type. This detailed discussion includes a review of your medical history, ensuring the selection of the most effective and suitable laser treatment for your needs.",
                "Maximize your results with our detailed pre-treatment guidelines. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment helps achieve optimal results without affecting the skin’s surface.",
                "Enter the procedure with confidence, knowing your eyes are protected by specialized eyewear from the intense laser light, ensuring a safe and secure treatment experience.",
                "Observe as our skilled technician or dermatologist utilizes a handheld laser device. This focused light beam targets the pigment (melanin) in your hair, initiating the journey to smooth, hair-free skin.",
                "Feel the transformation as the absorbed light converts to heat, effectively damaging the hair follicles. This targeted method ensures optimal results while preserving the integrity of the surrounding skin.",
                "Experience added comfort with advanced laser devices equipped with cooling features, such as a cool tip or a soothing gel. These technologies minimize discomfort, providing a luxurious treatment that prioritizes your skin’s health and well-being."
            ]
        }
        else if (city === "mumbai") {
            return ["Your journey begins with a detailed consultation, where our expert skincare specialist thoroughly assesses your specific skin and hair type. This comprehensive evaluation also includes a review of your medical history, ensuring that the most effective and appropriate laser treatment plan is tailored to your needs.",
                "Maximize your results by following our thorough pre-treatment instructions. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment ensures optimal results without affecting the skin's surface.",
                "Step into your treatment with confidence, knowing that your eyes are safeguarded with protective eyewear designed to shield them from the intense laser light, ensuring a safe and secure procedure.",
                "During the procedure, our skilled technician or dermatologist expertly uses a handheld laser device to deliver concentrated light energy to the pigment (melanin) in your hair, beginning the process of achieving smooth, hair-free skin.",
                "Feel the transformative impact as the absorbed light converts to heat, precisely damaging the hair follicles. This targeted approach ensures effective results while preserving the integrity of the surrounding skin.",
                "Experience enhanced comfort with advanced laser devices that incorporate cooling features, such as a cool tip or soothing gel. These technologies help minimize discomfort, offering a luxurious treatment that prioritizes your skin's health and well-being."]
        }
        else if (city === "bangalore") {
            return ["Begin your laser hair removal journey with a personalized consultation, where our expert skincare specialist thoroughly assesses your skin and hair type. This in-depth discussion also includes a review of your medical history, ensuring the most effective and appropriate laser treatment is selected for you.",
                "Achieve optimal results by following our detailed pre-treatment instructions. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment ensures the best possible outcome without affecting the skin's surface.",
                "Step into the procedure with confidence, knowing that your eyes are protected with specialized eyewear designed to shield them from the intense laser light, ensuring a safe and secure treatment experience.",
                "During the procedure, our skilled technician or dermatologist will use a handheld laser device to deliver concentrated light energy to the pigment (melanin) in your hair, beginning the process of achieving smooth, hair-free skin.",
                "Experience the targeted impact as the light energy converts into heat, precisely damaging the hair follicles. This focused approach ensures effective results while maintaining the integrity of the surrounding skin.",
                "Enjoy enhanced comfort with advanced laser devices that feature cooling technologies, such as a cool tip or soothing gel. These innovations help minimize discomfort, making the procedure both effective and luxurious."]
        }
        else if (city === "hyderabad") {
            return ["Start your laser hair removal journey with a personalized consultation, where our expert skincare specialist carefully evaluates your specific skin and hair type. This thorough discussion also includes a review of your medical history, ensuring that the most effective and appropriate laser treatment plan is tailored to your needs.",
                "Achieve the best possible results by following our detailed pre-treatment instructions. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment is recommended to enhance outcomes without affecting the skin’s surface.",
                "Enter the procedure with confidence, knowing that your eyes are safeguarded with protective eyewear designed to shield them from the intense laser light, ensuring a secure and safe treatment experience.",
                "Watch as our skilled technician or dermatologist expertly uses a handheld laser device to deliver concentrated light energy to the pigment (melanin) in your hair, initiating the process of achieving smooth, hair-free skin.",
                "Feel the targeted effect as the light energy transforms into heat, precisely damaging the hair follicles. This focused approach ensures effective results while preserving the integrity of the surrounding skin.",
                "Enjoy added comfort with advanced laser devices that feature cooling mechanisms, such as a cool tip or soothing gel. These innovations help to minimize discomfort, making the treatment both effective and luxurious."]
        }
        else if (city === "chandigarh") {
            return [
                "Your journey begins with a thorough consultation, where our expert skincare specialist carefully evaluates your specific skin and hair type. This comprehensive assessment also includes a review of your medical history, ensuring that the most effective and appropriate laser treatment plan is tailored to your needs.",
                "Achieve optimal results by following our detailed pre-treatment instructions. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment is recommended to ensure the best outcomes without affecting the skin’s surface.",
                "Step into the procedure with confidence, knowing your eyes are safeguarded with protective eyewear that shields them from the intense laser light, ensuring a safe and secure treatment experience.",
                "Watch as our skilled technician or dermatologist expertly uses a handheld laser device to direct concentrated light energy at the pigment (melanin) in your hair, initiating the process that leads to smooth, hair-free skin.",
                "Feel the transformative effect as the light energy converts to heat, precisely targeting and damaging the hair follicles. This approach ensures effective results while preserving the surrounding skin’s integrity.",
                "Experience added comfort with advanced laser devices that incorporate cooling features, such as a cool tip or soothing gel. These technologies help minimize discomfort, providing a luxurious treatment that prioritizes your skin’s health and well-being."
            ]
        }
        else if (city === "gurgaon") {
            return ["Start your journey with a thorough consultation, where our experienced skincare specialist evaluates your unique skin and hair type. This detailed discussion also includes a review of your medical history to ensure that the most effective and appropriate laser treatment is selected for you.",
                "Follow our detailed pre-treatment guidelines to achieve the best outcomes. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment is crucial for optimal results while keeping the surface skin intact.",
                "Feel confident during the procedure, knowing your eyes are protected with specialized eyewear designed to shield them from the intense laser light, ensuring a safe treatment experience.",
                "Witness the expertise of our trained technician or dermatologist as they use a handheld laser device. The concentrated beam of light targets the melanin in your hair, beginning your journey toward smooth, hair-free skin.",
                "Experience the transformation as the absorbed light energy converts to heat, precisely targeting and disabling hair follicles. This focused approach ensures effective results while preserving the health of the surrounding skin.",
                "Enjoy added comfort with the latest laser devices that incorporate cooling features, such as a cooling tip or a soothing gel. These technologies help minimize discomfort, ensuring a luxurious experience that prioritizes your skin's health."]
        }
        else if (city === "noida") {
            return ["Your laser hair removal journey starts with a detailed consultation, where our experienced skincare specialist evaluates your skin and hair type. This thorough assessment includes a review of your medical history, ensuring that the laser treatment plan is customized to suit your individual needs.",
                "Maximize your results with our comprehensive pre-treatment guidelines. Safeguard your skin by avoiding sun exposure, tanning products, and specific skincare items. Shaving the treatment area the day before your session helps ensure the laser targets the hair follicles effectively without disturbing the skin's surface.",
                "Proceed with confidence, knowing your safety is our priority. Protective eyewear shields your vision from the intense laser light, ensuring a secure and safe treatment experience.",
                "Watch as our trained technician or dermatologist carefully uses a handheld laser device. This focused beam of light targets the pigment (melanin) in your hair, initiating the process toward smoother, hair-free skin.",
                "Feel the transformative power as the absorbed light converts to heat, precisely targeting and damaging the hair follicles. This method ensures effective results while maintaining the surrounding skin’s health.",
                "Experience added comfort with advanced laser devices featuring cooling mechanisms—either a cooling tip or a soothing gel. These technologies minimize any discomfort, making the treatment experience as pleasant as possible."]
        }
        else if (city === "faridabad") {
            return ["Your journey begins with a detailed consultation, where a skincare expert evaluates your skin and hair type. This session also includes a review of your medical history, ensuring that the chosen laser treatment is the best fit for your unique needs.",
                "To achieve the best outcome, follow our pre-treatment guidelines closely. Shield your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment ensures optimal results without impacting the surface skin.",
                "During the procedure, your eyes will be protected with specialized eyewear to shield them from the intense laser light, ensuring a safe treatment experience.",
                "Our skilled technician or dermatologist uses a handheld laser device to focus light beams on the melanin in your hair. This precise targeting sets the stage for your journey to smooth, hair-free skin.",
                "As the light converts to heat, the hair follicles are precisely damaged, ensuring effective results while preserving the surrounding skin's health.",
                "Experience enhanced comfort with advanced laser devices featuring cooling mechanisms, such as a cool tip or soothing gel. These technologies minimize discomfort, providing a luxurious treatment experience that prioritizes your skin's well-being."]
        }
        else if (city === "indore") {
            return ["Your journey begins with a detailed consultation, where our expert skincare specialist carefully evaluates your unique skin and hair type. This comprehensive discussion also includes a review of your medical history, ensuring that the most effective and suitable laser treatment plan is tailored just for you.",
                "Follow our thorough pre-treatment guidelines to achieve the best possible results. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment helps to ensure optimal outcomes without impacting the skin’s surface.",
                "Rest assured that your safety is a priority during the procedure. Protective eyewear is provided to shield your eyes from the intense laser light, ensuring a secure and worry-free experience.",
                "During the procedure, a trained technician or dermatologist will use a handheld laser device to deliver concentrated light energy to the pigment (melanin) in your hair, initiating the process that leads to smooth, hair-free skin.",
                "Experience the targeted impact as the absorbed light converts into heat, specifically damaging the hair follicles. This precise approach ensures effective results while preserving the surrounding skin’s health and integrity.",
                "Enjoy a more comfortable treatment with advanced laser devices equipped with cooling features, such as a cool tip or a soothing gel. These technologies help to minimize discomfort, offering a luxurious experience that prioritizes your skin’s well-being."]
        }
        else if (city === "ludhiana") {
            return ["Your journey begins with a personalized consultation, where our expert skincare specialist thoroughly evaluates your unique skin and hair type. This detailed discussion includes a review of your medical history, ensuring that the most effective and suitable laser treatment is selected for your needs.",
                "Achieve optimal results with our comprehensive pre-treatment guidelines. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment is recommended to enhance results without affecting the skin's surface.",
                "Step into the procedure with confidence, knowing that your eyes are protected with specialized eyewear designed to shield them from the intense laser light, ensuring a safe and secure treatment experience.",
                "Watch as our skilled technician or dermatologist uses a handheld laser device to direct concentrated light energy at the pigment (melanin) in your hair, beginning the process of achieving smooth, hair-free skin.",
                "Feel the targeted impact as the light energy transforms into heat, precisely damaging the hair follicles. This focused approach ensures effective results while preserving the integrity of the surrounding skin.",
                "Experience added comfort with advanced laser devices equipped with cooling features, such as a cool tip or a soothing gel. These technologies minimize discomfort, providing a luxurious experience that prioritizes your skin's health and comfort."]
        }
        else if (city === "ahmedabad") {
            return ["Your journey begins with a comprehensive consultation, where our experienced skincare specialist thoroughly assesses your unique skin and hair type. This detailed discussion also includes a review of your medical history, ensuring that the most effective and appropriate laser treatment is selected specifically for you.",
                "Follow our meticulous pre-treatment instructions to achieve optimal results. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment ensures the best outcome without affecting the surface of the skin.",
                "Rest assured that your safety is prioritized during the procedure. You’ll be provided with protective eyewear to shield your eyes from the laser light, ensuring a secure and comfortable experience.",
                "During the procedure, a skilled technician or dermatologist will use a handheld laser device to direct concentrated light energy at the pigment (melanin) in your hair, beginning the process of achieving smooth, hair-free skin.",
                "Experience the targeted impact as the light energy converts into heat, precisely damaging the hair follicles. This focused approach ensures effective results while preserving the integrity of the surrounding skin.",
                "Enjoy a more comfortable treatment experience with advanced laser devices that feature cooling technologies, such as a cool tip or a soothing gel. These innovations help to minimize discomfort, making the procedure both effective and luxurious.",]
        }
        else if (city === "ghaziabad") {
            return ["Start your laser hair removal journey with a personalized consultation where our expert skincare professionals thoroughly evaluate your skin and hair type. This detailed assessment, which includes reviewing your medical history, ensures that the most effective and appropriate laser treatment is selected for you.",
                "Maximize your treatment results with our clear pre-treatment guidelines. To protect your skin, avoid sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment helps achieve the best results without affecting the skin's surface.",
                "Enter the procedure with confidence, knowing that your eyes are protected with specialized eyewear designed to shield them from the intense laser light, ensuring a safe treatment experience.",
                "Watch as our trained technician or dermatologist uses a handheld laser device to direct concentrated light energy at the pigment (melanin) in your hair, initiating the process of achieving smooth, hair-free skin.",
                "Feel the precise effect as the light energy converts to heat, targeting the hair follicles. This focused approach ensures effective results while maintaining the surrounding skin's integrity.",
                "Experience additional comfort with advanced laser devices that incorporate cooling features, such as a cool tip or a soothing gel. These technologies minimize any discomfort, providing a luxurious treatment experience that prioritizes your skin's health and comfort."]
        }
        else if (city === "mohali") {
            return ["Your journey begins with an in-depth consultation, where our expert skincare specialist thoroughly evaluates your unique skin and hair type. This detailed discussion includes a review of your medical history, ensuring that the most effective and suitable laser treatment plan is tailored specifically to your needs.",
                "Achieve optimal results by following our comprehensive pre-treatment guidelines. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment helps ensure the best possible outcome without affecting the skin’s surface.",
                "Step into the procedure with confidence, knowing your eyes are safeguarded with protective eyewear that shields them from the intense laser light, ensuring a safe and secure experience throughout the treatment.",
                "During the procedure, our skilled technician or dermatologist will use a handheld laser device to deliver concentrated light energy directly to the pigment (melanin) in your hair, beginning the process of achieving smooth, hair-free skin.",
                "Experience the transformative effect as the light energy converts into heat, precisely targeting and damaging the hair follicles. This targeted approach ensures effective results while maintaining the integrity of the surrounding skin.",
                "Enjoy enhanced comfort with advanced laser devices that incorporate cooling features, such as a cool tip or soothing gel. These technologies help minimize discomfort, offering a luxurious experience that prioritizes your skin’s health and well-being."]
        }
        else if (city === "panchukla") {
            return ["Your journey begins with an in-depth consultation, where our expert skincare specialist thoroughly evaluates your unique skin and hair type. This detailed discussion includes a review of your medical history, ensuring that the most effective and suitable laser treatment plan is tailored specifically to your needs.",
                "Achieve optimal results by following our comprehensive pre-treatment guidelines. Protect your skin by avoiding sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment helps ensure the best possible outcome without affecting the skin’s surface.",
                "Step into the procedure with confidence, knowing your eyes are safeguarded with protective eyewear that shields them from the intense laser light, ensuring a safe and secure experience throughout the treatment.",
                "During the procedure, our skilled technician or dermatologist will use a handheld laser device to deliver concentrated light energy directly to the pigment (melanin) in your hair, beginning the process of achieving smooth, hair-free skin.",
                "Experience the transformative effect as the light energy converts into heat, precisely targeting and damaging the hair follicles. This targeted approach ensures effective results while maintaining the integrity of the surrounding skin.",
                "Enjoy enhanced comfort with advanced laser devices that incorporate cooling features, such as a cool tip or soothing gel. These technologies help minimize discomfort, offering a luxurious experience that prioritizes your skin’s health and well-being."]
        }
        else {
            return ["Begin your journey with an in-depth consultation, where our expert skincare specialist carefully assesses your specific skin and hair type. This comprehensive discussion includes a review of your medical history, ensuring the selection of the most effective and suitable laser treatment for you.",
                "Achieve the best results with our thorough pre-treatment instructions. Protect your skin by steering clear of sun exposure, tanning products, and certain skincare items. Shaving the treatment area the day before your appointment ensures optimal results without affecting the surface skin.",
                "Step into the procedure with confidence, knowing your eyes are safeguarded with protective eyewear that shields them from the intense laser light, ensuring a safe and secure experience throughout the treatment.",
                "Marvel as our expert technician or dermatologist uses a handheld laser device. This concentrated beam of light focuses on the pigment (melanin) in your hair, initiating the journey to smooth, hair-free skin.                ",
                "Feel the transformative effect as the absorbed light converts to heat, precisely damaging the hair follicles. This targeted approach ensures effective results while preserving the surrounding skin's integrity.                ",
                "Enjoy enhanced comfort with advanced laser devices that incorporate cooling features, such as a cool tip or soothing gel. These technologies help minimize discomfort, offering a luxurious experience that prioritizes your skin’s health and well-being."];
        }
    }

    return (
        <>
            <MetaTags
                metaTitle={title(city)}
                metaDescription={description(city)}
                keywords={keywords(city)}
            />
            <Navbar />
            <div className='seoImage'>
                <p className='seoImageTitle'
                >BEST LASER HAIR REMOVAL IN {city.toUpperCase()}: PROCEDURE, BENEFITS AND COST</p>
            </div>
            <div className='seoContent' style={{ background: "white" }}>


                <p className='seoTitle'>Introduction to Laser Hair Removal Treatment</p>
                <p>
                    {FirstPara(city)}
                </p>
                <p className='seoTitle'>
                    Understanding the Laser Hair Removal Procedure
                </p>
                <p className='seoSubTitle'>
                    Personalized Consultation: Tailored Just for You
                </p>
                <p>
                    {Paras(city)[0]}
                </p>
                <p className='seoSubTitle'>
                    Pre-Treatment Mastery | Preparing for Elegance
                </p>
                <p>
                    {Paras(city)[1]}
                </p>
                <p className='seoSubTitle'>
                    Safety Unveiled | Shielding Your Vision
                </p>
                <p>
                    {Paras(city)[2]}
                </p>
                <p className='seoSubTitle'>
                    Laser Brilliance | Precision in Every Pulse
                </p>
                <p>
                    {Paras(city)[3]}
                </p>
                <p className='seoSubTitle'>
                    Follicle Precision | Heat-Infused Transformation
                </p>
                <p>
                    {Paras(city)[4]}
                </p>
                <p className='seoSubTitle'>
                    Skin TLC | Cooling Mechanisms at Play
                </p>
                <p>
                    {Paras(city)[5]}
                </p>
                <p className='seoTitle'>
                    Benefits of Laser Hair Removal
                </p>
                {benefits(city).map((item) => <li>{item}</li>)}
                <p className='seoTitle' style={{ marginTop: "16px" }}>
                    Cost of Laser Hair Removal in {city.slice(0, 1).toUpperCase()}{city.slice(1)}
                </p>
                <p className='seoSubTitle'>
                    Cost of Laser Hair Reduction Treatment at Avataar Skin
                </p>
                <p>
                    Start your journey to smoother skin with Avataar Skin's Laser Hair Reduction. Our treatments are designed to deliver lasting results at prices you can afford. Say goodbye to unwanted hair and explore the cost of laser hair reduction in {city.slice(0, 1).toUpperCase()}{city.slice(1)} with Avataar Skin.
                </p>
                <div style={{ width: "100%", overflowX: "auto" }}>
                    <table>
                        <tr>
                            <th>Body Part</th>
                            <th>1 Session (₹)</th>
                            <th>6 Sessions (₹)</th>
                            <th>18 Sessions (₹)</th>
                        </tr>
                        <tr>
                            <td>Full Body</td>
                            <td>11,800</td>
                            <td>56,640</td>
                            <td>1,59,300</td>
                        </tr>
                        <tr>
                            <td>Full Arms</td>
                            <td>5,300</td>
                            <td>25,440</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>Half Arms</td>
                            <td>4,200</td>
                            <td>20,160</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>Full Legs</td>
                            <td>6,500</td>
                            <td>31,200</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>Half Legs</td>
                            <td>5,000</td>
                            <td>24,000</td>
                            <td>67,500</td>
                        </tr>
                        <tr>
                            <td>Full Front</td>
                            <td>5,500</td>
                            <td>26,400</td>
                            <td>74,250</td>
                        </tr>
                        <tr>
                            <td>Half Front</td>
                            <td>5,000</td>
                            <td>24,000</td>
                            <td>67,500</td>
                        </tr>
                        <tr>
                            <td>Full Front</td>
                            <td>5,500</td>
                            <td>26,400</td>
                            <td>74,250</td>
                        </tr>
                        <tr>
                            <td>Full Back</td>
                            <td>5,500</td>
                            <td>26,400</td>
                            <td>74,250</td>
                        </tr>
                        <tr>
                            <td>Half Back</td>
                            <td>5,000</td>
                            <td>24,000</td>
                            <td>67,500</td>
                        </tr>
                        <tr>
                            <td>Bikini Line</td>
                            <td>4,800</td>
                            <td>23,040</td>
                            <td>64,800</td>
                        </tr>
                        <tr>
                            <td>Full Face</td>
                            <td>4,500</td>
                            <td>21,600</td>
                            <td>60,750</td>
                        </tr>
                        <tr>
                            <td>Chin and Upper Neck</td>
                            <td>2,700</td>
                            <td>12,960</td>
                            <td>36,450</td>
                        </tr>
                        <tr>
                            <td>Upper Face or Lower Face</td>
                            <td>2,700</td>
                            <td>12,960</td>
                            <td>56,760</td>
                        </tr>
                        <tr>
                            <td>SideLocks</td>
                            <td>1,800</td>
                            <td>8,640</td>
                            <td>24,300</td>
                        </tr>
                        <tr>
                            <td>Upper Lips and Chin</td>
                            <td>3,000</td>
                            <td>14,400</td>
                            <td>40,500</td>
                        </tr>
                        <tr>
                            <td>Underarms</td>
                            <td>1,500</td>
                            <td>7,200</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
                <p className='seoTitle' style={{ marginTop: "16px" }}>
                    Laser Hair Reduction Journey with Avataarskin
                </p>
                <p className='seoSubTitle'>
                    Appointment Booking
                </p>
                <p>
                    Begin your journey to smoother skin by scheduling your appointment effortlessly through the 'Book Appointment' feature on the Avataar Skin website.
                </p>
                <p className='seoSubTitle'>
                    Expert Guidance
                </p>
                <p>
                    Receive comprehensive support from Avataar Skin's skilled team. Address your online queries and gain valuable insights to confidently navigate your laser hair removal treatment.
                </p>
                <p className='seoSubTitle'>
                    Conclusion
                </p>
                <p>
                    Transform your beauty routine with Avataar Skin's Laser Hair Reduction Treatment in {city}. Our state-of-the-art technology, expert advice, and convenient at-home service offer a seamless experience. Say goodbye to outdated methods and enjoy long-lasting results. Trust Avataar Skin for unparalleled expertise and a stress-free path to hair-free, radiant skin.
                </p>
                <p className='seoTitle'>
                    Frequently Asked Questions About Laser Hair Removal in {city.slice(0, 1).toUpperCase()}{city.slice(1)}
                </p>
                <p className='seoSubTitle' style={{ textDecoration: "underline" }}>
                    Q1. How does laser hair removal work?
                </p>
                <p>
                    A1. Laser hair removal uses focused light beams to target hair follicles, inhibiting future hair growth. It's a reliable and effective method for achieving long-lasting hair reduction.
                </p>
                <p className='seoSubTitle' style={{ textDecoration: "underline" }}>
                    Q2. Is laser hair removal painful?
                </p>
                <p>
                    A2. You might experience mild discomfort, but thanks to advanced cooling technologies, the procedure is generally quite comfortable and minimally painful.
                </p>
                <p className='seoSubTitle' style={{ textDecoration: "underline" }}>
                    Q3. How many sessions are needed for optimal results?
                </p>
                <p>
                    A3. To achieve the best results, you will typically need 6-8 sessions, spaced a few weeks apart, depending on your hair and skin type.
                </p>
                <p className='seoSubTitle' style={{ textDecoration: "underline" }}>
                    Q4. Are there any side effects?
                </p>
                <p>
                    A4. Some temporary redness or swelling might occur, but these effects are usually minor and resolve quickly.
                </p>
                <p className='seoSubTitle' style={{ textDecoration: "underline" }}>
                    Q5. Is laser hair removal suitable for all skin types?
                </p>
                <p>
                    A5. Yes, modern laser technology can cater to various skin types. A consultation will help determine the best approach for your specific needs.
                </p>
                <p className='seoTitle'>
                    Our Laser Hair Removal Treatment in Major Cities
                </p>
                <p>
                    Discover premier laser hair removal services across top cities in India. Our esteemed dermatologists in cities like Delhi, Mumbai, Kolkata, and Hyderabad offer expert care for all your skin needs. Whether you're dealing with hair growth concerns or seeking the latest in skin treatments, our specialists provide customized solutions to help you achieve smooth, radiant skin. Experience exceptional care and advanced technology with our top-rated dermatologists in {city}.
                </p>
            </div >

        </>
    )
}

export default CityWiseSEOContent