import React, { memo } from "react";
import appstoreicon from "assets/images/appleplay.webp";
import googleplayicon from "assets/images/googleplay.webp"
import logo from "assets/images/avataarLogo.png";

import "./footer.css";
import { useNavigate } from "react-router-dom";

const FooterNew = () => {
  const navigate = useNavigate();
  const handleClick = (city) => {
    navigate(`/best-laser-hair-removal-in-${city}`)
    window.scrollTo(0, 0);
  }

  return (
    <>
      <footer className="footerMain">

        <section className="row">
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-3">
            <div className="d-flex align-items-center">
              <img className="footerLogo " src={logo} alt="Avataar Logo" loading="lazy"/>
              <h5 className="mainHeading" id="headingMain">Avataar Skincare</h5>
            </div>
            <p className="content mt-3">
              At Avataar Skincare we bring world-class skin treatment at the
              comfort of your home. Book FREE video CONSULTATION to speak to our
              experts.
            </p>
            <p>
              <a
                className="btnIcon btn btn-floating m-1"
                href="https://www.facebook.com/avataarskincare"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>

              <a
                className="btnIcon btn btn-floating m-1"
                href="https://www.instagram.com/avataarskincare/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>

              <a
                className="btnIcon btn btn-floating m-1"
                href="https://www.linkedin.com/company/avataar-skincare-technologies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>

              <a
                className="btnIcon btn btn-floating m-1"
                href="https://youtube.com/@avataarskincaretechnologie812?si=dqkxadjOZpFSFyTf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </p>
          </div>

          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <h5 className="mainHeading mb-4">COMPANY</h5>
            <p>
              <a
                href="/privacy-policies"
                target="_blank"
                rel="noopener noreferrer"
                className="textColor"
              >
                Privacy and Policies
              </a>
            </p>
            <p>
              <a
                href="/terms-conditions"
                rel="noopener noreferrer"
                className="textColor"
              >
                Terms and Conditions
              </a>
            </p>
            <p>
              <a
                href="/contact-us"
                rel="noopener noreferrer"
                className="textColor"
              >
                Contact Us
              </a>
            </p>
          </div>

          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <h5 className="mainHeading mb-4 ">For Customers</h5>
            <p>
              <a
                href="https://apps.apple.com/in/app/avataar-skincare/id6468362044"
                target="_blank"
                rel="noreferrer"
                className="textColor"
              >
                Rating
              </a>
            </p>
            <p>
              <a
                href="mailto:admin@avataarskin.com"
                target="_blank"
                className="textColor" rel="noreferrer"
              >
                Help
              </a>
            </p>
            <p>
              <a href="/faq" rel="noopener noreferrer" className="textColor">
                FAQ
              </a>
            </p>
          </div>

          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <h5 className="mainHeading mb-4 ">For Partners</h5>
            <p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSehwzygQdeRgPWDTk8mBocTCuoCB65MMvvirsP-qxrcKsT-7g/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <span className="textColor">Become A Professional</span>
              </a>
            </p>
          </div>

          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <h5 className="mainHeading mb-2">Experience Our App</h5>

            <a
              href="https://apps.apple.com/in/app/avataar-skincare/id6468362044"
              target="_blank"
              rel="noopener noreferrer"
              onclick="return gtag_report_conversion('https://apps.apple.com/in/app/avataar-skincare/id6468362044');"
            // class="d-inline-block"
            >
              <img src={appstoreicon} alt="App Store" class="footerImg img-fluid mw-70 mt-2" loading="lazy"/>
            </a>
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.avataarskin.app"
              target="_blank"
              rel="noopener noreferrer"
              class="google-icon"
              onclick="return gtag_report_conversion('https://play.google.com/store/apps/details?id=com.avataarskin.app');"
            >
              <img src={googleplayicon} alt="Google Store" class="footerImg img-fluid mw-70" loading="lazy"/>
            </a>
            <br />
          </div>

          <hr className="hrLine" />
          <p style={{ marginTop: "10px" }}>
            <span onClick={() => handleClick("delhi")} className="footerLinks">best laser hair removal in Delhi</span> |&nbsp;
            <span onClick={() => handleClick("gurgaon")} className="footerLinks">best laser hair removal in Gurgaon</span> |&nbsp;
            <span onClick={() => handleClick("noida")} className="footerLinks">best laser hair removal in Noida</span> |&nbsp;
            <span onClick={() => handleClick("greater-noida")} className="footerLinks">best laser hair removal in Greater Noida</span> |&nbsp;
            <span onClick={() => handleClick("faridabad")} className="footerLinks">best laser hair removal in Faridabad</span> |&nbsp;
            <span onClick={() => handleClick("ghaziabad")} className="footerLinks">best laser hair removal in Ghaziabad</span> |&nbsp;
            <span onClick={() => handleClick("indore")} className="footerLinks">best laser hair removal in Indore</span> |&nbsp;
            <span onClick={() => handleClick("ahmedabad")} className="footerLinks">best laser hair removal in Ahmedabad</span> |&nbsp;
            <span onClick={() => handleClick("ludhiana")} className="footerLinks">best laser hair removal in Ludhiana</span> |&nbsp;
            <span onClick={() => handleClick("bangalore")} className="footerLinks">best laser hair removal in Bangalore</span> |&nbsp;
            <span onClick={() => handleClick("hyderabad")} className="footerLinks">best laser hair removal in Hyderabad</span> |&nbsp;
            <span onClick={() => handleClick("mumbai")} className="footerLinks">best laser hair removal in Mumbai</span> |&nbsp;
            <span onClick={() => handleClick("pune")} className="footerLinks">best laser hair removal in Pune</span> |&nbsp;
            <span onClick={() => handleClick("chandigarh")} className="footerLinks">best laser hair removal in Chandigarh</span> |&nbsp;
            <span onClick={() => handleClick("mohali")} className="footerLinks">best laser hair removal in Mohali</span> |&nbsp;
            <span onClick={() => handleClick("panchukla")} className="footerLinks">best laser hair removal in Panchkula</span>
          </p>
          <hr className="hrLine" />
          <div className="bottomText text-center p-3">
            Copyright ©:
            <a
              className="text-black"
              href="https://avataarskin.com/"
              target="_blank"
              rel="noopener noreferrer "
            >
              Misya Beauty Tech Private Limited.

            </a>{" "}
            All rights reserved.
          </div>
        </section>
      </footer>
    </>
  );
}
export default memo(FooterNew);