import React, { useState, useMemo, useEffect, useRef } from "react";
import scheduleCompleteIcon from "../../assets/images/scheduleCompleteIcon.svg";
import Navbar from "common/Navbar";
import CustomModal from "../../common/CustomModal";
import homeImage from "../../assets/images/home.svg";
import clockImage from "../../assets/images/clock.svg";
import infoIcon from "../../assets/images/infoIcon.svg";
import PopupStar from "../../assets/images/PopupStar.svg";
import doneicon from "../../assets/images/done-icon.svg";
import warning from "../../assets/images/warning.svg";
import * as authThunks from "../../redux/thunks/authThunk";
import { useDispatch, useSelector } from "react-redux";
import * as profileThunk from "../../redux/thunks/profileThunk";
import * as feedbackThunk from "../../redux/thunks/feedbackThunk";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import * as productThunk from "../../redux/thunks/productThunk";
import * as cartThunk from "../../redux/thunks/cartThunk";
import * as registerFormUiAction from "../../redux/reducer/ui/registerUserUiDux";
import { Oval } from "react-loader-spinner";
import Feedback from "components/Modals/Feedback";
import ThanksFeedbackModal from "components/Modals/ThanksFeedbackModal";
import CouponAlert from "components/CouponAlert/CouponAlert";
import axios from "axios";
import { IpAddressTracking, addHoursToTime } from "utils/helper";
import PaymentStatus from "components/PaymentStatus";
import ConsentForm from "components/Modals/ConsentForm";
import PreTreatmentForm from "components/Modals/PreTreatmentForm";
import { generateAndDownloadInvoiceForBookingPayment } from "pages/GeneratePaymentInvoice";
import { generateAndDownloadInvoiceForSessionPayment } from "pages/SessionPaymentpdf";
const loadRazorpayScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const Upcoming = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const { bookingDetails } = useSelector((state) => state.entities.profile);
  const { paymentUrl } = useSelector((state) => state.entities.product);
  const { isFetching, error } = useSelector((state) => state.ui.registerForm);
  const checkoutId = paymentUrl?.data?.map;
  const transactionId = paymentUrl?.data?.receipt;
  const orderDetailId = useSelector((state) => state.entities.product.paymentUrl.id);


  localStorage.setItem("paymentPathId", checkoutId);
  localStorage.setItem("paymentTransactionId", transactionId);

  const currenSessionId = localStorage.getItem("sessionId");
  const sessionStatus = bookingDetails.history;

  if (Array.isArray(sessionStatus) || sessionStatus?.length) {
    const sessionStep = sessionStatus.map((sessionStat) => sessionStat.status);
  }

  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [feedThanksPopup, setFeedThanksPopup] = useState(false);
  const [rating, setRating] = useState(0);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [userComment, setUserComment] = useState("");
  const [preFillLoading, setPreFillLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [cancelBtnLoading, setCancelBtnLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(0);
  const [res, setRes] = useState();
  const [razorpayPayment, setRazorpayPayment] = useState([])
  const [sessionPayment, setSessionPayment] = useState([])
  useEffect(async () => {
    const res = await IpAddressTracking();
    setRes(res);

  }, [])


  const fetchRazorPayInvoice = async (orderId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/get-order-payment/${orderId}`);
      setRazorpayPayment(res.data);
    } catch (error) {
      console.error('Error fetching RazorPay invoice:', error);
    }
  };

  const fetchSessionPaymentInvoice = async (sessionScheduleId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/get-session-payment/${sessionScheduleId}`);
      setSessionPayment(res.data);
    } catch (error) {
      console.error('Error fetching session payment invoice:', error);
    }
  };

  useEffect(() => {
    if (bookingDetails?.orderId && bookingDetails?.sessionScheduleId) {
      fetchRazorPayInvoice(bookingDetails.orderId);
      fetchSessionPaymentInvoice(bookingDetails.sessionScheduleId);
    }
  }, [bookingDetails]);

  const handleDownloadClick = (payment) => {
    generateAndDownloadInvoiceForBookingPayment(payment)
  };
  const handleSessionPaymnetDownloadClick = (payment) => {
    generateAndDownloadInvoiceForSessionPayment(payment)
  };

  const schedulerAllowedCity = [
    "Delhi",
    "Noida",
    "Gurgaon",
    "Pune",
    "Mumbai",
    "Hyderabad",
    "Bangalore",
    'Indore',
    'Ahmedabad',
    'Ludhiana',
    'Chandigarh',
  ];

  const handleRating1Change = (newRating) => {
    setRating1(newRating);
  };

  const handleRating2Change = (newRating) => {
    setRating2(newRating);
  };

  const handleUserCommentChange = (comment) => {
    setUserComment(comment);
  };
  const handlefeedbackSubmit = (data) => {
    dispatch(
      feedbackThunk.saveUserRatings({
        sessionScheduleId: bookingDetails.sessionScheduleId,
        partnerRating: data.rating1,
        sessionRating: data.rating2,
        feedback: data.userComment,
      })
    ).then((res) => {
      if (res.success) {
        setShowThanksModal(true);
      }
    });
  };

  const [consentPopup, setConsentPopup] = useState(false);
  const [preTreatmentForm, setPreTreatmentForm] = useState(false);
  const [active, setActive] = useState(false);
  const [activeTwo, setActiveTwo] = useState(false);

  const initialValues = { accept: false };
  const initval = {
    question: "",
    answer: "",
    type: "",
  };
  const initialValues2 = {
    sunTime: { ...initval, question: "How much time do you spend in the sun?" },
    sunScreen: { ...initval, question: "Do you wear sunscreen regularly?" },
    existingProduct: {
      ...initval,
      question: " What products are you using currently?",
    },
    hypothyroidism: { ...initval, question: "Do you have hypothyroidism?" },
    medication: {
      ...initval,
      question: "What medication are you currently using?",
    },
    pcod: { ...initval, question: "Do you have PCOD/PCOS?" },
    conditions: {
      ...initval,
      question:
        " Do you have/had any skin or medical conditions? If any ,please mention.",
    },
    pregnancy: { ...initval, question: "Are you pregnant?" },
    lactating: { ...initval, question: "Are you lactating?" },
    familyMedicalCondition: {
      ...initval,
      question:
        " Do anyone have a history of any skin /medical condition in your family? If yes, please mention.",
    },
    allergy: {
      ...initval,
      question:
        "Do you have any known allergies( food,drug etc) ? If yes please mention.",
    },
  };
  const initialValues3 = { aboutFeedback: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formValues2, setFormValues2] = useState(initialValues2);
  const [formValues3, setFormValues3] = useState(initialValues3);


  const [formErrors, setFormErrors] = useState({});

  const handleModalClose = () => {
    setConsentPopup(false);
    setPreTreatmentForm(false);
    setFeedbackPopup(false);
    setFeedThanksPopup(false);
    setShowFeedbackModal(false);
    setShowThanksModal(false);
    setPreFillLoading(false);
    setSuccessModal(false);
  };

  useEffect(
    () => {
      const data = {
        isConsent: formValues.accept,
      };
      dispatch(profileThunk.setUserConsent(currenSessionId, data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      dispatch(profileThunk.getBookingDetails(currenSessionId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reloadPage]
  );

  useEffect(() => {
    if (bookingDetails.sessionScheduleId) {
      dispatch(feedbackThunk.getUserRating(bookingDetails.sessionScheduleId));
    }
  }, [bookingDetails.sessionScheduleId]);

  const data = {
    sessionId: bookingDetails?.sessionId,
    orderDetailId: bookingDetails?.orderDetailId,
    ipInfo: res ? res : null,
  };

  const handleConsentSubmit = () => {
    const data = {
      isConset: formValues.accept,
    };
    dispatch(profileThunk.setUserConsent(currenSessionId, data));
    setActiveTwo(true);
    setConsentPopup(false);
  };
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    let field = value;
    if (name === "accept") {
      field = !formValues.accept;
    }
    setFormValues({ ...formValues, [name]: field });
  };

  const validateForm1 = (name, value) => {
    switch (name) {
      case "existingProduct": {
        let error = null;

        if (!value) {
          error = "This field is required!";
        }

        return {
          existingProduct: error,
        };
      }

      case "medication": {
        let error = null;

        if (!value) {
          error = "his field is required!";
        }

        return {
          medication: error,
        };
      }

      default:
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    const values = {
      question: formValues2[name].question,
      answer: value,
      type: type,
    };
    setFormValues2({ ...formValues2, [name]: values });
    setFormErrors({
      ...formErrors,
      ...validateForm1(name, value),
    });
  };

  const handleValueChange2 = (e) => {
    const { name, value, type } = e.target;
    console.log(value);
    let field = value;

    const values = {
      question: formValues2[name].question,
      answer: field,
      type: type,
    };
    setFormValues2({ ...formValues2, [name]: values });
  };


  const handleSubmit = () => {
    const data = {
      sessionScheduleId: bookingDetails.sessionScheduleId,
      answers: JSON.stringify([
        formValues2.sunTime,
        formValues2.sunScreen,
        formValues2.existingProduct,
        formValues2.medication,
        formValues2.hypothyroidism,
        formValues2.pcod,
        formValues2.conditions,
        formValues2.pregnancy,
        formValues2.lactating,
        formValues2.familyMedicalCondition,
        formValues2.allergy,
      ]),
    };

    setFormValues2(initialValues2);
    setPreFillLoading(true)
    console.log(data);

    dispatch(authThunks.userPreTreatmentInfo(data)).then((res) => {
      if (res.success) {
        setPreFillLoading(false);
        setPreTreatmentForm(false);
        handleModalClose();
        setActive(true);
        setSuccessModal(true);
        // handleModalClose();

      }
    });
  };

  const handleSubmitFeed = () => {
    const data = {
      sessionScheduleId: bookingDetails.sessionScheduleId,
      ratingOne: rating,
      ratingTwo: rating1,
      ratingThree: rating2,
      description: formValues3.aboutFeedback,
    };

    dispatch(profileThunk.userFeedback(data)).then((res) => {
      if (res.success) {
        setFeedbackPopup(false);
        setFeedThanksPopup(true);
      }
    });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormValues3({ ...formValues3, [name]: value });
  };


  const handlePayment = async (paymentDetailId) => {


    const user = localStorage.getItem("user");
    try {
      const orderResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/create-order`, {
        paymentItemId: paymentDetailId,
      });
      const orderData = await orderResponse.data;


      const options = {
        key_id: 'rzp_live_mxbPN506ZDEERo',
        amount: orderData.amount * 100,
        currency: 'INR',
        name: 'Avataar Skincare',
        description: 'Payment for Premium Skincare Services',
        image: "https://partner-app-s3.s3.ap-south-1.amazonaws.com/logo+(3).png",
        order_id: orderData.id,
        handler: async (response) => {
          console.log(response);
          localStorage.setItem('razorpayId', response.razorpay_order_id)
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          const result = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/payments/verify-payment`,
            paymentData,
          );

          console.log('result', result.data);
          localStorage.setItem("paymentSuccess", result.data.isVerified);
          const crmUpdateResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/orders/crm-update-payment`,
            {
              "id": paymentDetailId,
              "status": "success",
              "rzpPaymentId": response.razorpay_payment_id,
              "rzpOrderId": response.razorpay_order_id,
            }
          );

          const crmMessage = crmUpdateResponse.data.message;
          if (crmMessage === "Payment Status Updated !") {
            setPaymentDone(true)
          }
        },
        prefill: {
          name: user.name,
          email: '',

        },
        notes: {
          address: 'Avataar Skincare Services',
        },
        theme: {
          color: "#937940",
        },

      }

      const rzp = new (window).Razorpay(options)
      rzp.open();

    } catch (error) {
      console.log(error);
    }
  };

  const paymentGatewayHandler = () => {
    if (!bookingDetails.packageId) {
      console.log("payment for one item")
      dispatch(productThunk.getPaymentUrl(data));
    }
    else {

      const dataForPackages = {
        discount: Number(bookingDetails.discount),
        couponDiscount: Number(bookingDetails.couponDiscount),
        packageAmount: Number(bookingDetails.packageAmount),
        orderDetailId: bookingDetails.orderDetailId,
        sessionId: bookingDetails.sessionScheduleId,
        ipInfo: res ? res : null,
      }
      dispatch(productThunk.getPaymentUrlForPackages(dataForPackages));
    }
  };

  const closeErrorHandler = () => {
    dispatch(registerFormUiAction.clearUi());
  };

  const handleThanksModalClose = () => {
    setShowThanksModal(false);
    window.location.reload();
  };

  const cancelOrderHandler = async () => {
    let clientLat = localStorage.getItem("clientLat");
    let clientLong = localStorage.getItem("clientLong");
    clientLat = clientLat === "null" ? null : clientLat;
    clientLong = clientLong === "null" ? null : clientLong;
    const sessionScheduleId = bookingDetails.sessionScheduleId;
    const productId = bookingDetails.productId;
    const appointmentAtId = bookingDetails.appointmentAt;
    const city = bookingDetails.addressRegionalCity;
    const date = new Date(appointmentAtId);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const finalDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
      }`;
    const startTime = moment(
      bookingDetails.appointmentAt,
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    )
      .utcOffset("+00:00")
      .format(`HH:mm`);
    const [hours, minutes] = startTime.split(":");
    let newHours = parseInt(hours, 10) + 1;
    const endTime = `${newHours.toString().padStart(2, "0")}:${minutes}`;
    let userDetails = localStorage.getItem("user")
    userDetails = JSON.parse(userDetails)
    const appUserId = userDetails['id']
    const appUserName = userDetails['name']
    // if ((city !== "Delhi" || city !== "Gurgaon" || city !== "Noida" || city !== "Ghaziabad" || city !== "Faridabad" || city !== "Greater Noida") && (clientLat !== null && clientLong !== null)) {
    if (
      schedulerAllowedCity.includes(city) &&
      clientLat &&
      clientLong &&
      clientLat !== "" &&
      clientLong !== ""
    ) {
      // let apiUrl;
      setCancelBtnLoading(true);
      let apiUrl = `${process.env.REACT_APP_SCHEDULER_API}/allocate/cancel-booking`;
      const result = await axios
        .post(apiUrl, {
          productId: productId,
          clientLat,
          clientLong,
          city,
          ipInfo: res ? res : null,
          appUserId,
          appUserName,
          sessionScheduleId,
          slotDate: finalDate,
          slotTime: {
            startTime,
            endTime: addHoursToTime(startTime, 0, 30),
          },
        })
        .then((response) => {
          navigate("/my-bookings");
          window.location.reload();
        });
    } else {
      dispatch(cartThunk.cancelBooking(bookingDetails?.sessionScheduleId))
        .then((res) => {
          navigate("/my-bookings");
          window.location.reload();
          // console.log(res, "remove product success");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setCancelBtnLoading(false);
  };

  const viewInvoice = () => {
    navigate("/viewinvoice", { state: { bookingDetails } });
  };

  useEffect(() => {
    if (orderDetailId) {
      console.log(orderDetailId);
      loadRazorpayScript('https://checkout.razorpay.com/v1/checkout.js');
      handlePayment(orderDetailId);
    }
  }, [orderDetailId])

  return (
    <>
      {!error && paymentLoader ? (
        <div className="isFetching payment">
          <div className="fetching_oval">
            <Oval
              height={66}
              width={66}
              color="#000000"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            <p>
              Please wait while we are redirecting you to the payment gateway...
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      <CouponAlert />
      <ConsentForm
        consentPopup={consentPopup}
        formErrors={formErrors}
        formValues={formValues}
        handleConsentSubmit={handleConsentSubmit}
        handleModalClose={handleModalClose}
        handleValueChange={handleValueChange}
      />

      <PreTreatmentForm
        formErrors={formErrors}
        formValues2={formValues2}
        handleChange={handleChange}
        handleModalClose={handleModalClose}
        handleSubmit={handleSubmit}
        handleValueChange2={handleValueChange2}
        preFillLoading={preFillLoading}
        preTreatmentForm={preTreatmentForm}
        error={error}
      />

      <CustomModal
        show={successModal}
        onClose={handleModalClose}
        className="customPopup innerPopup orderSuccess"
      >
        <div className="modal_content form_wrapper feedsSubmitted">
          <div className="innerContent">
            <span>
              <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy" />
            </span>
            <p>Thank you for submitting the form.</p>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button onClick={() => setSuccessModal(false)} className="form_button">
            CONTINUE
          </button>
        </div>
      </CustomModal>

      <CustomModal
        show={feedbackPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper container_popup">
          <div className={`master-form-group long `}>
            <p>FEEDBACK</p>
          </div>

          <div className="feedbackContainer">
            <p>How would you rate the therapist who came for your treatment?</p>
            <div className="rating">
              <ul className="mid-1-check">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      key={index}
                      className={index <= rating ? "on" : "off"}
                      onClick={() => setRating(index)}
                    >
                      <span className="star"></span>
                    </button>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="feedbackContainer">
            <p>How would you rate the therapist who came for your treatment?</p>
            <div className="rating">
              <ul className="mid-1-check">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      key={index}
                      className={index <= rating1 ? "on" : "off"}
                      onClick={() => setRating1(index)}
                    >
                      <span className="star"></span>
                    </button>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="feedbackContainer">
            <p>How would you rate the therapist who came for your treatment?</p>
            <div className="rating">
              <ul className="mid-1-check">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      key={index}
                      className={index <= rating2 ? "on" : "off"}
                      onClick={() => setRating2(index)}
                    >
                      <span className="star"></span>
                    </button>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="feedbackContainer">
            <p>Other Feedback</p>
            <textarea
              rows="5"
              cols="10"
              placeholder="Comment Here"
              name="aboutFeedback"
              className={`form-input ${formErrors.aboutFeedback ? "master-input-error" : ""
                }`}
              value={formValues3.aboutFeedback}
              onChange={handleChange2}
            ></textarea>
          </div>
        </div>
        <div className="feedbackSubmit">
          <div className="master-form-group scheduleBox">
            <span onClick={() => setFeedbackPopup(false)}>Skip Feedback</span>
            <button
              onClick={handleSubmitFeed}
              className="form_button feedback-submit"
            >
              SUBMIT
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        show={feedThanksPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper feedsSubmitted">
          <div className="starImage">
            <span>
              <img src={PopupStar} alt="PopupStar" loading="lazy" />
            </span>
            <span>Thank You For Your Feedback</span>
          </div>

          <div className="aftercare">
            <h4>Aftercare tips</h4>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy" />
              </span>
              <p>Moisturise your feet regularly</p>
            </div>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy" />
              </span>
              <p>Trim your long nails regularly to prevent breakages</p>
            </div>
            <div className="tips">
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy" />
              </span>
              <p>Give your cuticles an oil massage for nourisment regularly</p>
            </div>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button
            onClick={() => navigate("/my-bookings")}
            className="form_button"
          >
            CONTINUE
          </button>
        </div>
      </CustomModal>

      {paymentDone && <PaymentStatus paymentDone={paymentDone} setPaymentDone={setPaymentDone} />}


      {error ? (
        <div className="customErrorMessage">
          <p>
            <img src={warning} alt="warning" loading="lazy" />
            Error! {error}.
          </p>
          <span onClick={closeErrorHandler}>x</span>
        </div>
      ) : (
        ""
      )}

      <Navbar />
      <div className="wrapper">
        <div className="wrapperContainer">
          <div className="innerContainer productStatusDiv">
            <div className="pretreatmentRequirements">
              <h4>Pre-Treatment Requirements</h4>
              <ul>
                <li>
                  <span>Fill Pre-Treatment Form</span>
                  {active || bookingDetails.preTreatment ? (
                    <span className="active">SUBMITTED</span>
                  ) : (
                    <span onClick={() => setPreTreatmentForm(true)}>
                      SUBMIT
                    </span>
                  )}
                </li>
                <li>
                  <span>Provide Consent For Treatment</span>
                  {activeTwo || bookingDetails.isConsent ? (
                    <span className="active">SUBMITTED</span>
                  ) : (
                    <span onClick={() => setConsentPopup(true)}>SUMBIT</span>
                  )}
                </li>
              </ul>
            </div>

            <div className="bookingDetails ">
              <h4>Booking Details</h4>
              <div className="bookingInner">
                <div className="address">
                  <span className="home_image">
                    <img src={homeImage} alt="Home" loading="lazy" />
                  </span>
                  <span>
                    <b>{bookingDetails.addressType}</b>,{" "}
                    {bookingDetails.formattedAddress}
                    <br />
                  </span>
                </div>
                <div className="date">
                  <span className="clock_image font_p">
                    <img src={clockImage} alt="Clock" loading="lazy" />
                  </span>
                  <span>
                    {moment(
                      bookingDetails.appointmentAt,
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    )
                      .utcOffset("+00:00")
                      .format(`ddd, MMM DD • hh:mm A`)}
                  </span>
                </div>
                {/* <span style={{ color: "#848282" }}>Total Amount - ₹{bookingDetails.packageId ? parseInt(bookingDetails.packageAmount).toLocaleString("en-in") : (parseInt(bookingDetails.productPrice) - parseInt(bookingDetails.discount)).toLocaleString("en-in")}</span>
                <span style={{ color: "#848282" }}>{!bookingDetails.packageId && `Amount Paid - ₹${parseInt(bookingDetails.productPrice - bookingDetails.discount - bookingDetails.remainingAmount).toLocaleString("en-in")}`}</span>
                <span style={{ color: "#848282" }}>{!bookingDetails.packageId && `Amount Payable - ₹${parseInt(bookingDetails.remainingAmount).toLocaleString("en-in")}`}</span> */}
                <div className="buttonBox">
                  {/* <button><a href="https://api.whatsapp.com/send/?phone=919810339778&amp;text=I+want+Skin+care+Treatment.&amp;type=phone_number&amp;app_absent=0" target="_blank" rel='noreferrer'><span>RESCHEDULE</span></a></button> */}
                  {error ? (
                    <button className="bd5" disabled>
                      <span>PAY NOW</span>
                    </button>
                  ) : (
                    ""
                  )}

                  {bookingDetails.status === "CANCELLED" ? (
                    <button className="bd5">
                      <span>CANCELLED</span>
                    </button>
                  ) : (
                    <>
                      {bookingDetails.paymentStatus ||
                        bookingDetails.remainingAmount === 0 ? (
                        <button className="bd5 paidBtn" disabled>
                          <span>PAID</span>
                        </button>
                      ) : (
                        <>
                          {/* <button
                            className="bd5"
                            onClick={paymentGatewayHandler}
                          >
                            <span>PAY NOW</span>
                          </button> */}
                         {bookingDetails.status!=="COMPLETED" && <button
                            className="bd5"
                            onClick={() => {
                              cancelOrderHandler();
                            }}
                            disabled={cancelBtnLoading}
                          >
                            <span>CANCEL</span>
                          </button>}
                          <Feedback
                            showFeedbackModal={showFeedbackModal}
                            handleModalClose={handleModalClose}
                            handlefeedbackSubmit={handlefeedbackSubmit}
                            onRating1Change={handleRating1Change}
                            onRating2Change={handleRating2Change}
                            onUserCommentChange={handleUserCommentChange}
                          />
                        </>
                      )}
                      {bookingDetails.paymentStatus && (
                        <button
                          className="bd5"
                          onClick={() => {
                            viewInvoice();
                          }}
                        >
                          <span>View Invoice</span>
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div>
               <h4>Booking Invoice</h4>
                 <div className="payment-list">
                    {razorpayPayment.length>0&&razorpayPayment?.map((payment, index) => (
                      <div
                        key={index}
                        className="payment-item"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap',
                          marginBottom: '10px'
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <p style={{ margin: '0' }}>Payment ID: {payment?.orderId?.slice(-10)}</p>
                          <p style={{ margin: '0' }}>Amount: ₹{payment?.payableAmount}</p>
                        </div>
                        <button
                          onClick={() => handleDownloadClick(payment)}
                          style={{
                            cursor: 'pointer',
                            color: '#fff',
                            backgroundColor: 'gray',
                            border: 'none',
                            padding: '5px 10px',
                            borderRadius: '14px',
                            fontSize: '14px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Download Invoice
                        </button>

                      </div>
                    ))}
                    {sessionPayment.length>0&&sessionPayment?.map((payment, index) => (
                      <div
                        key={index}
                        className="payment-item"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap',
                          marginBottom: '10px'
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <p style={{ margin: '0' }}>Payment ID: {payment?.orderId?.slice(-10)}</p>
                          <p style={{ margin: '0' }}>Amount: ₹{payment?.paidAmount}</p>
                        </div>
                        <button
                          onClick={() => handleSessionPaymnetDownloadClick(payment)}
                          style={{
                            cursor: 'pointer',
                            color: '#fff',
                            backgroundColor: 'gray',
                            border: 'none',
                            padding: '5px 10px',
                            borderRadius: '14px',
                            fontSize: '14px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Download Invoice
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <div>
                    <div className="boxFeed">
                    </div>
                  </div>
                  <div>

                  </div>
                </div>
              </div>
            </div>

            <div className="sessionTracker">

              <div className="referTerms">
                <ul>
                  <li>
                    <a
                      href="/terms-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policies" rel="noreferrer">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>
            <div className="policyContainer">
              <div className="policy_heading">
                <h4>Cancellation policy</h4>
              </div>
              <div className="policyNote">
                <ul>
                  <li>
                    <span className="infoIcon">
                      <img src={infoIcon} alt="Info_Icon" loading="lazy" />
                    </span>
                    <span>
                      No charge if you reschedule or cancel until 3 hours before
                      the service
                    </span>
                  </li>
                  <li>
                    <span className="infoIcon">
                      <img src={infoIcon} alt="Info_Icon" loading="lazy" />
                    </span>
                    {/* Once a professional is assigned, a non-refundable cancellation  */}
                    <span>
                      Cancellation within 3 hours of treatment is chargable upto
                      of Rs. 500
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bookingStatusWrapper">
            <div className="statusPreviewBox">
              <h5>{bookingDetails.productName}</h5>
              <span>
                {moment(
                  bookingDetails.appointmentAt,
                  "YYYY-MM-DDTHH:mm:ss.SSSZ"
                )
                  .utcOffset("+00:00")
                  .format(`ddd, MMM DD • hh:mm A`)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ThanksFeedbackModal
        showThanksModal={showThanksModal}
        handleModalClose={handleThanksModalClose}
      />
    </>
  );
};

export default Upcoming;
