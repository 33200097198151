import React from 'react';

const DynamicButton = ({ label, type, onClick }) => {
    return (
        <div className='d-flex'>
            <button
                type={type}
                onClick={onClick}
                style={{
                    height:'40px',
                    width: '100%',
                    fontSize: '16px',
                    lineHeight: '1.5',
                    cursor: 'pointer',
                    color:'white',
                    background:'black',
                    fontWeight:'bolder',
                    borderRadius:'5px',
                }}
            >
                {label}
            </button>
        </div>
    );
};

export default DynamicButton;