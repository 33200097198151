/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import CustomModal from "common/CustomModal";
import { FilterSlots } from "utils/helper";
import axios from "axios";
import * as profileThunk from "redux/thunks/profileThunk";
import { useDispatch, useSelector } from "react-redux";
import { getStaticSlots } from "utils/staticSlots";
import Refresh from "assets/images/refresh.svg";
import RefreshDis from "assets/images/refreshdis.svg";
import CalendarIcon from "assets/images/calendar_icon.png"
import Calendar from "react-calendar"; // Importing react-calendar package
import 'react-calendar/dist/Calendar.css'; // Importing calendar css

const SessionScheduleSlotModal = ({
  calenderPopUp,
  handleModalClose,
  slotsDate,
  activeOption,
  setActiveOption,
  activeOption1,
  setActiveOption1,
  setShowSelectTimeBox,
  setSlotDisabled,
  showSelectTimeBox,
  slotdisabled,
  handleSlotConfirmation,
  isPackage,
  isLoading
}) => {
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeSlotIndex, setActiveSlotIndex] = useState(null);
  const [timeSlot, setTimeSlot] = useState(false);
  const dispatch = useDispatch();
  const [finalDates, setFinalDates] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false); // State to handle calendar visibility
  const [calendarDate, setCalendarDate] = useState('');
  const [showUpcomingSlots, setShowUpcomingSlots] = useState(false)

  const SessionScheduleId = localStorage.getItem('sessionScheduleId');
  const selectedcity = localStorage.getItem("selectedcity");
  const clientId = localStorage.getItem("loggedIn");
  let clientLat = localStorage.getItem("clientLat");
  let clientLong = localStorage.getItem("clientLong");
  const appointmentType = localStorage.getItem("appointmentType");


  clientLat = clientLat === "null" ? null : clientLat;
  clientLong = clientLong === "null" ? null : clientLong;
  const schedulerAllowedCity = [
    "Delhi",
    "Noida",
    "Gurgaon",
    "Pune",
    "Mumbai",
    "Hyderabad",
    "Bangalore",
    'Indore',
    'Ahmedabad',
    'Ludhiana',
    'Chandigarh',
  ];
  const timeoutRef = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (schedulerAllowedCity.includes(selectedcity) && clientLat !== null && clientLong !== null && clientLat !== "" && clientLong !== "") {
      setFinalDates(true)
    }
    else {
      console.log("first")
      setFinalDates(false)
    }
  }, [selectedcity])

  useEffect(() => {
    dispatch(profileThunk.getBookingDetails(SessionScheduleId));
  }, [])

  const { bookingDetails } = useSelector((state) => state.entities.profile);

  let filteredSlots = FilterSlots(slotsDate);
  let schedulerSlots = getStaticSlots(7);

  const date = new Date();
  const hour = date.getHours();
  const finalSlots = filteredSlots[0]?.time?.every(
    (item) => item.disabled === true
  );
  schedulerSlots =
    hour >= 22 || hour < 7
      ? schedulerSlots.slice(1)
      : schedulerSlots;

  let startTime = 0;

  const fetchSlots = async (date) => {
    setLoading(true);
    setTimerExpired(false)
    clearTimeout(timeoutRef.current);

    const productId = localStorage.getItem("selectedProductId");
    const city = localStorage.getItem("selectedcity");
    const clientLat = localStorage.getItem("clientLat");
    const clientLong = localStorage.getItem("clientLong");
    const appointmentAtId = bookingDetails.appointmentAt;
    const date1 = new Date(appointmentAtId);

    const year = date1.getFullYear();
    const month = date1.getMonth() + 1;
    const day = date1.getDate();

    const finalDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
      }`;

    const startTime = moment(
      bookingDetails.appointmentAt,
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    )
      .utcOffset("+00:00")
      .format("HH:mm")
    const [hours, minutes] = startTime.split(':');
    let newHours = parseInt(hours, 10) + 1;
    const endTime = `${newHours.toString().padStart(2, '0')}:${minutes}`;
    localStorage.setItem("previousStartTime", startTime);
    localStorage.setItem("previousEndTime", endTime);
    let apiUrl = `${process.env.REACT_APP_SCHEDULER_API}/slot/get-client-slots`;

    const result = await axios
      .post(
        apiUrl,
        {
          slotDate: date,
          city: city,
          productId: parseInt(productId, 10),
          clientLat: clientLat,
          clientLong: clientLong,
          clientId: Number(clientId),
          isDashboard: false,
        }
      )
      .then((response) => response.data.slots);
    setSlots(
      result.sort((a, b) => {
        return a.clientSlotStartTime.localeCompare(b.clientSlotStartTime);
      })
    );
    setLoading(false);
    setTimeSlot(true);
    timeoutRef.current = setTimeout(() => {
      setTimerExpired(true);
    }, 30000);
  };

  const fetchUpcomingSlots = async (date) => {
    setLoading(true);
    setTimerExpired(false)
    clearTimeout(timeoutRef.current);

    const productId = localStorage.getItem("selectedProductId");
    const city = localStorage.getItem("selectedcity");
    const clientLat = localStorage.getItem("clientLat");
    const clientLong = localStorage.getItem("clientLong");
    const appointmentAtId = bookingDetails.appointmentAt;
    const date1 = new Date(appointmentAtId);

    const year = date1.getFullYear();
    const month = date1.getMonth() + 1;
    const day = date1.getDate();

    const finalDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
      }`;

    const startTime = moment(
      bookingDetails.appointmentAt,
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    )
      .utcOffset("+00:00")
      .format("HH:mm")
    const [hours, minutes] = startTime.split(':');
    let newHours = parseInt(hours, 10) + 1;
    const endTime = `${newHours.toString().padStart(2, '0')}:${minutes}`;
    localStorage.setItem("previousStartTime", startTime);
    localStorage.setItem("previousEndTime", endTime);
    let apiUrl = `${process.env.REACT_APP_SCHEDULER_API}/slot/get-upcoming-booking-slots`;

    const result = await axios
      .post(
        apiUrl,
        {
          slotDate: date,
          city: city,
          productId: parseInt(productId, 10),
          clientLat: clientLat,
          clientLong: clientLong,
          clientId: Number(clientId),
          isDashboard: false,
        }
      )
      .then((response) => response.data.slots);
    setSlots(
      result.sort((a, b) => {
        return a.clientSlotStartTime.localeCompare(b.clientSlotStartTime);
      })
    );
    setLoading(false);
    setTimeSlot(true);

    timeoutRef.current = setTimeout(() => {
      setTimerExpired(true);
    }, 30000);
  };
  let oneHourSlots = []


  for (let i = 0; i < slots?.length; i++) {
    const startHour = slots[i]?.clientSlotStartTime.split(':')[0];
    const endHour = `${parseInt(startHour, 10) + 1}:00`;
    const slotExists = oneHourSlots.some(slot => slot.clientSlotStartTime.split(':')[0] === startHour);
    if (!slotExists) {
      oneHourSlots.push({
        clientSlotStartTime: slots[i]?.clientSlotStartTime,
        clientSlotEndTime: endHour,
      });
    }
  }
  oneHourSlots = ((hour >= 22 || hour <= 12) && activeOption === 0) ? oneHourSlots.filter(slot => {
    const startTime = parseInt(slot.clientSlotStartTime.split(":")[0]);
    return startTime >= 12;
  }) : oneHourSlots;

  // Function to disable dates before the allowed start date
  // const disableDates = ({ date }) => {
  //   const today = new Date();
  //   const allowedStartDate = new Date(today.setDate(today.getDate() + 6));
  //   return date < allowedStartDate;
  // };

  const disableDates = ({ date }) => {
    const today = new Date();
    const allowedStartDate = new Date(today.setDate(today.getDate() + 6));
    const allowedEndDate = new Date(allowedStartDate);
    allowedEndDate.setDate(allowedEndDate.getDate() + 90);

    return date < allowedStartDate || date > allowedEndDate;
  };


  return (
    <div>
      <CustomModal
        show={calenderPopUp}
        onClose={handleModalClose}
        className="customPopup"
      >
        <div className="modal_content form_wrapper scheduleTimigModal machineSlots">
          <div className="master-form-group calendar_view">

            <p>Select A Date</p>
            {!loading && slots.length !== 0 &&
              <button disabled={!timerExpired}
                style={{
                  border: "0px",
                  backgroundColor: "white",
                  padding: "8px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
                className="refreshSlots"
                onClick={() => calendarDate === '' ? fetchSlots(localStorage.getItem('selectedDate')) : fetchUpcomingSlots(localStorage.getItem('selectedDate'))}
              >
                {!timerExpired ? <img src={RefreshDis} width={32} height={32} /> : <img src={Refresh} width={32} height={32} loading="lazy" />}
              </button>
            }

            <>
              {!showCalendar && calendarDate === '' && <ul className="mid-1-check dateDiv">
                {schedulerSlots?.map((item, index) => (
                  <li
                    id={index}
                    key={[index]}
                    className={`${activeOption === index && "active"}`}
                    onClick={() => {
                      setActiveOption(index);
                      setShowSelectTimeBox(false);
                      schedulerAllowedCity.includes(selectedcity) &&
                        clientLat &&
                        clientLong &&
                        clientLat !== "" &&
                        clientLong !== "" &&
                        fetchSlots(item.date);
                      localStorage.setItem("selectedDate", item.date);
                    }}
                  >
                    <span>
                      {moment(item.date, "YYYY-MM-DDT.SSS[Z]").format(
                        "ddd DD"
                      )}
                    </span>

                  </li>
                ))
                }
              </ul>}
              {
                calendarDate !== '' && <ul className="mid-1-check dateDiv">
                  <li
                    className="active"
                  >
                    <span>
                      {moment(calendarDate, "YYYY-MM-DDT.SSS[Z]").format(
                        "ddd DD"
                      )}
                    </span>
                  </li>
                </ul>
              }
              {/* <div style={{
                position: "absolute",
                top: "10px",
                right: "10px"
              }}>
                <button
                  onClick={() => setShowCalendar(true)}
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Open Calendar
                </button>
              </div> */}

              {appointmentType !== "Reschedule" && <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                {/* <button
                  onClick={() => setShowCalendar(true)}
                  style={{
                    color: "white",
                    marginTop: "18px",
                    padding: "3px 6px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "flex", // Aligns the icon and text horizontally
                    alignItems: "center",

                  }}
                >
                  <span role="img" aria-label="calendar" style={{
                    fontSize: "32px", // Increase the icon size independently
                  }}
                  >
                    📅
                  </span>
                </button> */}
                <button
                  onClick={() => setShowCalendar(true)}
                  style={{
                    // color: "white",
                    marginTop: "18px",
                    border:"3px solid #cbad5b",
                    padding: "3px 3px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={CalendarIcon} // Replace this with the actual path to your SVG file
                    alt="calendar"
                    style={{
                      width: "36px", // Adjust the size of the icon independently
                      height: "36px",
                    }}
                  />
                </button>

              </div>}


              {showCalendar && (
                <div style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20px"
                }}>
                  <button
                    onClick={() => setShowCalendar(false)}
                    style={{
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      background: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </button>
                  <Calendar
                    onChange={(date) => {
                      setCalendarDate(date);
                      localStorage.setItem("selectedDate", date);
                      fetchUpcomingSlots(moment(date).format("YYYY-MM-DD"));
                      setShowCalendar(false);
                      setShowUpcomingSlots(true)
                    }}
                    tileDisabled={disableDates}
                  />
                </div>
              )}

              {loading && finalDates && (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              {timeSlot && finalDates && !showUpcomingSlots && (
                <>
                  <ul>
                    {slots.length !== 0 && !loading && !timerExpired && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p style={{ textAlign: "center" }}>Select Start Time</p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            rowGap: "12px",
                            columnGap: "8px",
                            justifyContent: "center",
                          }}
                        >
                          {oneHourSlots.map((item, index) => {
                            return (
                              <>
                                <li
                                  id={item.id}
                                  key={item.id}
                                  style={{
                                    backgroundColor:
                                      activeSlotIndex === index
                                        ? "#fdf3e4"
                                        : "",
                                    border: "2px solid black",
                                    borderRadius: "12px",
                                    padding: "8px 4px",
                                    width: "103px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    localStorage.setItem(
                                      "selectedTime",
                                      item.clientSlotStartTime
                                    );
                                    setActiveSlotIndex(index);
                                    setSlotDisabled(false);
                                  }}
                                >
                                  <span>
                                    {item.clientSlotStartTime}-
                                    {item.clientSlotEndTime}
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {slots.length === 0 && !loading && (
                      <p style={{ textAlign: "center" }}>
                        Sorry, there is no slots available for this date.
                      </p>
                    )}
                  </ul>
                </>
              )}

              {showUpcomingSlots && (
                <>
                  <ul>
                    {slots.length !== 0 && !loading && !timerExpired && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p style={{ textAlign: "center" }}>Select Start Time</p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            rowGap: "12px",
                            columnGap: "8px",
                            justifyContent: "center",
                          }}
                        >
                          {oneHourSlots.map((item, index) => {
                            return (
                              <>
                                <li
                                  id={item.id}
                                  key={item.id}
                                  style={{
                                    backgroundColor:
                                      activeSlotIndex === index
                                        ? "#fdf3e4"
                                        : "",
                                    border: "2px solid black",
                                    borderRadius: "12px",
                                    padding: "8px 4px",
                                    width: "103px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    localStorage.setItem(
                                      "selectedTime",
                                      item.clientSlotStartTime
                                    );
                                    setActiveSlotIndex(index);
                                    setSlotDisabled(false);
                                  }}
                                >
                                  <span>
                                    {item.clientSlotStartTime}-
                                    {item.clientSlotEndTime}
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {slots.length === 0 && !loading && (
                      <p style={{ textAlign: "center" }}>
                        Sorry, there is no slots available for this date.
                      </p>
                    )}
                  </ul>
                </>
              )}

              {(showSelectTimeBox && calendarDate === '') && (
                <p className="dateSelectionRemider">
                  Please select a date first!
                </p>
              )}
            </>
          </div>
        </div>

        <div className="master-form-group scheduleBox">
          <button
            className="form_button"
            disabled={slotdisabled}
            onClick={handleSlotConfirmation}
          >
            {isLoading ?
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              : "Confirm your slot"}
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default SessionScheduleSlotModal;
