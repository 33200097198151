import { get, patch, post, remove } from "../../utils/httpUtils";
import * as registerUserUiActions from "../reducer/ui/registerUserUiDux";
import * as profileActions from "../reducer/profileDux";
import * as profileUiActions from "../reducer/ui/profileUiDux";
import * as cartActions from "../reducer/cartDux";


export const addAdress = (data) => (dispatch) => {
  // dispatch(registerUserUiActions.setFetching(true));
  return post("users/address", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setUserNewAddress(response));
      localStorage.setItem("address", response.id);
      return {
        success: true,
      };
    }
  });
};




export const getAddress = () => (dispatch) => {
  return get("users/address").then((response) => {
    if (!!response.error) {
      dispatch(profileUiActions.setError(response.message));

      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setUserAddress(response));

      return {
        success: true,
      };
    }
  });
};


export const getFaqs = () => (dispatch) => {
  return get("orders/faqs").then((response) => {
    if (!!response.error) {
      dispatch(profileUiActions.setError(response.message));

      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setFaqs(response?.faqs));

      return {
        success: true,
      };
    }
  });
};

export const getAddressId = (id) => (dispatch) => {
  return get(`users/address/${id}`).then((response) => {
    if (!!response.error) {
      dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setUserAddresssId(response));

      return {
        success: true,
      };
    }
  });
};

export const updateAddress = (id, data) => (dispatch) => {
  return patch(`users/address/${id}`, data).then((response) => {
    if (!!response.error) {
      dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setUpdatedAddresss(response));
      return {
        success: true,
      };
    }
  });
};

export const addToCart = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("carts", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));
      return {
        success: false,
      };
    } else {
      return {
        success: true,
      };
    }
  });
};

export const createOrder = (data) => (dispatch) => {
  dispatch(registerUserUiActions.setFetching(true));
  return post("orders", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));
      return {
        success: false,
      };
    } else {
      return {
        success: true,
      };
    }
  });
};

export const userFeedback = (data) => (dispatch) => {
  return post("products/feedback", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));
      return {
        success: false,
      };
    } else {
      return {
        success: true,
      };
    }
  });
};

export const getMyBookings = () => (dispatch) => {
  return get("orders/booking").then((response) => {
    if (!!response.error) {
      dispatch(profileUiActions.setError(response.message));

      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setUserBookings(response));
      
      return {
        success: true,
      };
    }
  });
};

export const getBookingDetails = (id) => (dispatch) => {
  return get(`orders/booking/${id}`).then((response) => {
    if (!!response.error) {
      dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setBookingDetails(response));
      return {
        success: true,
      };
    }
  });
};

export const setUserConsent = (id, data) => (dispatch) => {
  return patch(`orders/consent/${id}`, data).then((response) => {
    if (!!response.error) {
      dispatch(profileUiActions.setError(response.message));

      return {
        success: false,
      };
    } else {
      dispatch(profileUiActions.setFetching(false));

      return {
        success: true,
      };
    }
  });
};

export const getCartList = () => (dispatch) => {
  return get("carts").then((response) => {
    if (!!response.error) {
      // dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(cartActions.setCartList(response));
      return {
        success: true,
      };
    }
  });
};

export const getNotifications = () => (dispatch) => {
  return get("users/notification").then((response) => {
    if (!!response.error) {
      // dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setNotificationList(response));
      return {
        success: true,
      };
    }
  });
};

export const readNotification = (id) => (dispatch) => {
  return patch(`users/notification/${id}`).then((response) => {
    if (!!response.error) {
      // dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      dispatch(profileActions.setNotificationList(response));
      return {
        success: true,
      };
    }
  });
};

export const deleteUser = () => (dispatch) => {
  return remove(`users/delete-user`).then((response) => {
    if (!!response.error) {
      // dispatch(profileUiActions.setError(response.message));
      return {
        success: false,
      };
    } else {
      localStorage.clear();
      window.location.href = "/";
      return {
        success: true,
      };
    }
  });
};