import axios from 'axios';
import React from 'react';

const Leftcontainer = ({ cart, handleDeleteCartItem }) => {
    const discountedPercent = (originalPrice, discountedPrice) => {
        return parseInt(((originalPrice - discountedPrice) / originalPrice) * 100);
    }

    // Helper function to group products by package and calculate package price if not provided
    const groupProductsByPackage = (products) => {
        const packages = {};
        console.log(products)
        const individualProducts = [];
        products.forEach((product) => {
            if (product.packageId) {
                if (!packages[product.packageId]) {
                    packages[product.packageId] = {
                        packageName: product.packageName,
                        packagePrice: product.packagePrice,
                        packageFinalPrice: product.packageFinalPrice,
                        products: [],
                        bodyparts: product.bodyParts || []
                    };
                }
                packages[product.packageId].products.push(product);

                // Calculate package price if not provided
                if (packages[product.packageId].bodyparts.length !== 0) {
                    packages[product.packageId].packagePrice = packages[product.packageId].products.reduce((total, p) => total + parseInt(p.total), 0);
                    packages[product.packageId].packageFinalPrice = packages[product.packageId].products.reduce((total, p) => total + (parseInt(p.discount)), 0);

                }
            } else {
                individualProducts.push(product);
            }
        });

        return { packages, individualProducts };
    };

    // Function to handle package deletion
    const handleDeletePackage = async (packageId, orderId) => {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/carts/package-product/${orderId}`,{ data: { packageId: +packageId } })
        window.location.reload();
    };
    const { packages, individualProducts } = groupProductsByPackage(cart.category.products);

    console.log(packages)

    return (
        <div className="left">
            <div className="cartItemDetails">
                <ul>
                    {Object.keys(packages).map(packageId => (
                        <li key={packageId}>
                            <>

                                <div className="description">
                                    <span>{packages[packageId].bodyparts.length === 0 ? <span className='packageType'>Fix Package</span> : <span className='packageType custom'>Custom Package</span>}</span>
                                    <p>{packages[packageId].packageName}</p>
                                    <ul>
                                        {packages[packageId].products.map((item) => (
                                            <span>• {item.productName} x {item.count} sessions</span>
                                        ))}
                                    </ul>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                        <div className="ctaButtons">
                                            <span onClick={() => handleDeletePackage(packageId,cart.category.id)}>
                                                DELETE
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='prices' style={{ width: "max-content" }}>
                                    <div className='finalPrice' style={{ color: "black" }}>
                                        ₹{packages[packageId].packageFinalPrice}
                                    </div>

                                    <div>
                                        <div className='discountPrice'>
                                            <div className='saveAmount'>Save {discountedPercent(packages[packageId].packagePrice, packages[packageId].packageFinalPrice)}%</div>
                                            <s className='numberofSessions' style={{ color: "#a9a9a9" }}> ₹{packages[packageId].packagePrice}</s>
                                        </div>

                                    </div>
                                </div>
                            </>


                        </li>
                    ))}
                    {individualProducts.map(item => (
                        <li key={item.cartItemId}>
                            <div className="description">
                                <p>{item.productName || "title"}</p>
                                <span>• {item.count} Sessions</span>
                                <div className="ctaButtons">
                                    <span onClick={() => handleDeleteCartItem(item.id, cart.category.id)}>
                                        DELETE
                                    </span>
                                </div>
                            </div>
                            <div className='prices' style={{ width: "max-content" }}>
                                <div className='finalPrice' style={{ color: "black" }}>
                                    ₹{item.total}
                                </div>

                                <div>
                                    <div className='discountPrice'>
                                        {discountedPercent(item.price, item.total) > 0 && <div className='saveAmount'>₹ Save {discountedPercent(item.price, item.total)}%</div>}
                                        {discountedPercent(item.price, item.total) > 0 && <s className='numberofSessions' style={{ color: "#a9a9a9" }}> ₹{item.price}</s>}
                                    </div>

                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Leftcontainer;
