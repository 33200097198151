import React from 'react'
import CustomModal from 'common/CustomModal'

import { Map, Marker } from "google-maps-react";
import MyLocation from "../../../../assets/images/my-location.svg";

const ShowMapModal = ({ showMap,
    loading,
    userLocation,
    randomTimer,
    finalAddressSave,
    google,
    warning,
    handleCurrentAddressBtn,
    handleMarkerDragend,
    handleModalClose }) => {
    return (
        <CustomModal
            show={showMap}
            onClose={handleModalClose}
            className="custom_modal customPopup innerPopup authPopup"
        >
            <div>
                <div style={{ width: "100%", height: "380px", position: "relative" }}>
                    {
                        (showMap && !randomTimer) &&
                        <Map
                            google={google}
                            initialCenter={userLocation}
                            center={userLocation}
                            zoom={14}
                            style={{ width: "100%", height: "100%" }}
                            draggable={true}
                        >
                            <Marker
                                position={userLocation}
                                draggable={true}
                                onDragend={handleMarkerDragend}
                                title="Drag on your location"
                            />
                        </Map>
                    }
                    <button
                        onClick={handleCurrentAddressBtn}
                        style={{
                            position: "absolute",
                            background: "white",
                            top: "90%",
                            left: "70%",
                            transform: "translate(-50%, -50%)",
                            color: "blue",
                            border: "1.5px solid blue",
                            borderRadius: "5px",
                            padding: "8px 8px",
                            fontSize: "10px",
                            cursor: "pointer",
                            display:"flex",
                        }}
                    >
                        <div style={{ width: "16px" }}><img src={MyLocation} width={16} height={16} loading="lazy"/></div>Use Current Location
                    </button>
                </div>
                {!loading && !warning && (
                    <div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
                {(loading || warning) && (
                    <div>
                        <button
                            onClick={finalAddressSave}
                            style={{
                                display: "block",
                                margin: "20px auto",
                                background: "#f83f3f",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                cursor: "pointer",
                            }}
                        >
                            Save Address
                        </button>
                    </div>
                )}
            </div>
        </CustomModal>
    )
}

export default ShowMapModal