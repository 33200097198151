import React, { useState } from "react";
import CustomModal from "common/CustomModal";
import { Rating } from "react-simple-star-rating";
import { MdError } from "react-icons/md";

const Feedback = ({
  showFeedbackModal,
  handleModalClose,
  handlefeedbackSubmit,
  onRating1Change,
  onRating2Change,
  onUserCommentChange,
}) => {
  const [userComment, setUserComment] = useState("");
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [showError, setShowError] = useState(false);

  const handleRating1 = (rate) => {
    setRating1(rate);
    onRating1Change(rate);
  };
  const handleRating2 = (rate) => {
    setRating2(rate);
    onRating2Change(rate);
  };
  const handleUserComment = (e) => {
    setUserComment(e.target.value);
    onUserCommentChange(e.target.value);
  };
  const clearState = () => {
    setRating1(0);
    setRating2(0);
    setUserComment("");
    handleModalClose();
  };

  const handleFeedbackSubmitData = () => {
    if (!rating1 || !rating2) {
      setShowError(true);
      return;
    }
    handlefeedbackSubmit({
      rating1,
      rating2,
      userComment,
    });
    clearState();
  };
  const skipModal = () => {
    setShowError(false);
    handleModalClose();
  };
  return (
    <div>
      <CustomModal
        show={showFeedbackModal}
        onClose={clearState}
        className="customPopup innerPopup"
        t
      >
        <div className="modal_content form_wrapper container_popup">
          <div className={`master-form-group long `}>
            <p style={{ textAlign: "center" }}>FEEDBACK</p>
          </div>
          {showError ? (
            <div className="text-danger">
              <p style={{ textAlign: "center" }}>
                <i style={{ marginRight: "5px" }}>
                  <MdError />
                </i>
                Please select the ratings for both therapist and treatment
                session.
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="feedbackContainer">
            <p className="feedHeading">
              How would you rate the therapist who came for your treatment?
            </p>
            <p>
              <Rating
                rating={rating1}
                widgetDimensions="25px"
                widgetRatedColors="gold"
                widgetEmptyColors="lightgray"
                onClick={handleRating1}
              />
            </p>
          </div>

          <div className="feedbackContainer">
            <p className="feedHeading">
              How would you rate the treatment experience?
            </p>
            <p>
              <Rating
                rating={rating2}
                widgetDimensions="25px"
                widgetRatedColors="gold"
                widgetEmptyColors="lightgray"
                onClick={handleRating2}
              />
            </p>
          </div>

          <div className="feedbackContainer">
            <p className="feedHeading">How do you like Avataar services?</p>
            <textarea
              value={userComment}
              onChange={handleUserComment}
              placeholder="Write your Feedback (Optional)"
              rows="4"
              cols="30"
            />
          </div>
        </div>
        <div className="feedbackSubmit">
          <div className="master-form-group scheduleBox">
            <span onClick={skipModal} style={{ cursor: "pointer" }}>
              Skip Feedback
            </span>
            <button
              className="form_button feedback-submit"
              onClick={handleFeedbackSubmitData}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Feedback;
