import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Homepage";
import Register from "./components/register/Register";
import ReactGA from "react-ga";
import Couples from "./components/mainCategories/Couples";
import CategoryListing from "./components/CategoryListing";
import ReferPage from "./pages/ReferPage";
import CartPage from "./pages/CartPage";
import Bookings from "./pages/MyBookings";
import Completed from "./components/Bookings/Completed";
import Upcoming from "./components/Bookings/Upcoming";
import Ongoing from "./components/Bookings/Ongoing";
import Notifications from "./components/Bookings/Notifications";
import Profile from "./components/Profile";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProductDetails from "./components/ProductDetails";
import PaymentStatus from "./components/PaymentStatus";
import CollectionListing from "./components/CollectionListing";
import AddPost from "./components/AddPost";
import ContactUs from "pages/ContactUs";
import BlogDetail from "pages/BlogDetail";
// import AvataarClub from "./pages/AvataarClub";
import Blog from "pages/Blog";
import FAQ from "pages/Faq";
import ViewInvoice from "pages/ViewInvoice";
import FooterNew from "components/Footer";
import PackagesPage from "pages/packages";
import Whatsapp from "../src/assets/images/whatsAppIco.svg";
import Call from "../src/assets/images/call.svg";
import Message from "../src/assets/images/message.svg";
import CityWiseSEOContent from "containers/CityWiseSeoContent";
import AddToCredits from "containers/AddCredits";
import LeadForm from "containers/LeadForm";
import { WixPage } from "containers/Leads";
import PackageDetails from "containers/PackageDetails";

ReactGA.initialize({ trackingId: process.env.GOOGLE_ANALYTICS_TRACK_ID });
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const currentPath = window.location.pathname;
  console.log(currentPath)

  const handleclick = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?43393";

    script.onload = () => {
      const options = {
        enabled: true,
        chatButtonSetting: {
          backgroundColor: "#00e785",
          borderRadius: "25",
          marginLeft: "10",
          marginRight: "0",
          marginBottom: "75",
          ctaIconWATI: false,
          position: "left",
        },
        brandSetting: {
          brandName: "Avataar Skincare",
          brandSubTitle: "undefined",
          brandImg: "https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg",
          welcomeText: "Hi there!\nHow can I help you?",
          messageText: "Hi, I wanted to enquire about the right treatment for my skin/body.",
          backgroundColor: "#00e785",
          ctaText: "Chat with us",
          borderRadius: "25",
          autoShow: true,
          phoneNumber: "919773992904",
        },
      };
      window.CreateWhatsappChatWidget(options);
    };
    document.head.appendChild(script);
  };

  const [dialog, setDialog] = useState(false);
  const [callText, setCallText] = useState(false);


  return (
    <Router>
      <main>
        {(currentPath !== "/lhr-male" &&
          currentPath !== "/lhr-female" &&
          currentPath !== "/wl-female" &&
          currentPath !== "/wl-male" &&
          currentPath !== "/pigmentation-female" &&
          currentPath !== "/iv-female" &&
          currentPath !== "/hair-treatment-female" &&
          currentPath !== "/hair-treatment-male" &&
          currentPath !== "/anti-aging-female" &&
          currentPath !== "/acne-female") && <>
            <p
              onClick={() => { setDialog(!dialog); if (callText) { setCallText(false); setDialog(false) } }}
              style={{
                position: "fixed", marginLeft: "7px",
                bottom: "48px", zIndex: 10
              }}><img src={Whatsapp} width={80} height={80} loading="lazy" /></p>
            {dialog &&
              <div style={{
                position: "fixed", marginLeft: "7px",
                bottom: "135px", border: "1px solid black", borderRadius: "20px", padding: "24px",
                boxShadow: "3px 5px #00E784", backgroundColor: "#fff", zIndex: 10
              }}
              ><p onClick={() => { setCallText(true); setDialog(false) }} style={{
                cursor: "pointer",
                backgroundColor: "#000",
                padding: "8px",
                borderRadius: "10px",
                display: "flex",
                gap: "12px",
                color: "#fff",
                whiteSpace: "nowrap"
              }}>
                  <div style={{ width: "20px" }}>
                    <img src={Call} width={20} height={20} loading="lazy" />
                  </div>
                  <span>Query on existing booking</span>
                </p>
                <p onClick={() => {
                  handleclick();
                  setDialog(false)
                }}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#000",
                    padding: "8px",
                    borderRadius: "10px",
                    display: "flex",
                    gap: "12px",
                    color: "#fff",
                    whiteSpace: "nowrap"
                  }}>
                  <div style={{ width: "20px" }}>
                    <img src={Message} width={20} height={20} loading="lazy" />
                  </div>
                  <span>Book a new Treatment</span>
                </p>
              </div>}
            {callText &&
              <div style={{
                position: "fixed", marginLeft: "7px",
                bottom: "135px", border: "1px solid black", borderRadius: "20px", padding: "24px",
                boxShadow: "3px 5px #00E784", backgroundColor: "#fff", zIndex: 10
              }}
              >Please Call on <a href="tel:9289360104">+91 9289360104</a>
                <div style={{
                  position: "absolute",
                  top: "-10px", right: "-5px", borderRadius: "40px", color: "#fff",
                  width: "24px", height: "24px", textAlign: "center",
                  backgroundColor: "#000", cursor: "pointer"
                }}
                  onClick={() => setCallText(false)}
                >x</div></div>}
          </>}
        <Routes>
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/women" element={<Home />} />
          <Route exact path="/men" element={<Home />} />
          <Route exact path="/lhr-female" element={<WixPage />} />
          <Route exact path="/lhr-male" element={<WixPage />} />
          <Route exact path="/wl-female" element={<WixPage />} />
          <Route exact path="/wl-male" element={<WixPage />} />
          <Route exact path="/pigmentation-female" element={<WixPage />} />
          <Route exact path="/iv-female" element={<WixPage />} />
          <Route exact path="/hair-treatment-female" element={<WixPage />} />
          <Route exact path="/hair-treatment-male" element={<WixPage />} />
          <Route exact path="/anti-aging-female" element={<WixPage />} />
          <Route exact path="/acne-female" element={<WixPage />} />

          <Route exact path="home" element={<Home />} />

          {/* Blog */}
          <Route>
            <Route path="/blog" element={<Blog />} />
          </Route>

          <Route path="/home">
            <Route path="couples" element={<Couples />} />
          </Route>
          <Route
            exact
            path="/category-listing/:id"
            element={<CategoryListing />}
          />
          <Route
            exact
            path="/advanced-laser-treatments/:slug"
            element={<CollectionListing />}
          />
          <Route
            exact
            path="/laser-treatments/:slug"
            element={<ProductDetails />}
          />

          <Route exact path="/add-credits" element={<AddToCredits />} />
          <Route exact path="/payment-status" element={<PaymentStatus />} />
          <Route exact path="/cart" element={<CartPage />} />
          <Route exact path="/my-bookings" element={<Bookings />} />
          <Route exact path="/add-post" element={<AddPost />} />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/blog-detail/:id" element={<BlogDetail />} />
          <Route exact path="/my-profile" element={<Profile />} />
          <Route exact path="/terms-conditions" element={<TermsConditions />} />
          <Route exact path="/privacy-policies" element={<PrivacyPolicy />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/packages" element={<PackagesPage />} />
          <Route path="/:slug" element={<CityWiseSEOContent />} />
          <Route path="/lead-form/:slug" element={<LeadForm />} />
          <Route path="/packages/:id" element={<PackageDetails />} />



          <Route path="/my-bookings">
            <Route path="completed" element={<Completed />} />
            <Route path="upcoming" element={<Upcoming />} />
            <Route path="ongoing" element={<Ongoing />} />
          </Route>
          <Route path="/viewinvoice" element={<ViewInvoice />} />
        </Routes>
      </main>

      {(currentPath !== "/lhr-male" &&
        currentPath !== "/lhr-female" &&
        currentPath !== "/wl-female" &&
        currentPath !== "/wl-male" &&
        currentPath !== "/pigmentation-female" &&
        currentPath !== "/iv-female" &&
        currentPath !== "/hair-treatment-female" &&
        currentPath !== "/hair-treatment-male" &&
        currentPath !== "/anti-aging-female" &&
        currentPath !== "/acne-female") && <FooterNew />}
    </Router>
  );
}
export default App;
