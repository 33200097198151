import React from "react";
import Navbar from "common/Navbar";
import { useNavigate } from "react-router-dom";

import laserTreatment from "../../assets/images/laserTreatment.svg";
import Advancefacial from "../../assets/images/Advancefacial.svg";
import menCat from "../../assets/images/menCat.svg";
import WeightReduction from "../../assets/images/WeightReduction.svg";
import BodyPolish from "../../assets/images/BodyPolish.svg";
import ChemicalPeels from "../../assets/images/ChemicalPeels.svg";
import SkinTreatment from "../../assets/images/SkinTreatment.svg";


const Couples = () => {
  let navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="wrapper">
        {/* refer and earn */}
        <div className="centerSlider referBanner mb-10 mt-10">
          <div className="slider_content">
            <div className="left">
              <div className="content mainCategory">
                <h4>MEN</h4>
              </div>
              <span>
                <img src={menCat} alt="referImage" loading="lazy"/>
              </span>
            </div>
          </div>
        </div>

        {/* treatments just for you */}
        <div className="wrapperContainer Services">
          <h2 className="heading">Treatments Just for you</h2>
          <ul className="categorySelection">
            <li
              id="1"
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/1`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Laser Treatment"
            >
              <span>
                <img src={laserTreatment} alt="laserTreatment" loading="lazy"/>
              </span>
              <span>
                Laser
                <br />
                Treatment
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/2`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Advance Facials"
            >
              <span>
                <img src={Advancefacial} alt="Advancefacial" loading="lazy"/>
              </span>
              <span>
                Advance
                <br />
                Facials
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/3`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Weight Reduction"
            >
              <span>
                <img src={WeightReduction} alt="WeightReduction" loading="lazy"/>
              </span>
              <span>
                Weight
                <br />
                Reduction
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/4`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Skin Treatment"
            >
              <span>
                <img src={SkinTreatment} alt="SkinTreatment" loading="lazy"/>
              </span>
              <span>
                Skin
                <br />
                Treatment
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/5`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Chemical Peels"
            >
              <span>
                <img src={ChemicalPeels} alt="ChemicalPeels"loading="lazy"/>
              </span>
              <span>
                Chemical
                <br />
                Peels
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/6`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Body Polish"
            >
              <span>
                <img src={BodyPolish} alt="BodyPolish" loading="lazy"/>
              </span>
              <span>
                Body
                <br />
                Polish
              </span>
            </li>
          </ul>
        </div>

        <div className="wrapperContainer Services">
          <h2 className="heading">Treatments Just for you</h2>
          <ul className="categorySelection">
            <li
              id="1"
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/1`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Laser Treatment"
            >
              <span>
                <img src={laserTreatment} alt="laserTreatment" loading="lazy"/>
              </span>
              <span>
                Laser
                <br />
                Treatment
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/2`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Advance Facials"
            >
              <span>
                <img src={Advancefacial} alt="Advancefacial" loading="lazy"/>
              </span>
              <span>
                Advance
                <br />
                Facials
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/3`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Weight Reduction"
            >
              <span>
                <img src={WeightReduction} alt="WeightReduction" loading="lazy"/>
              </span>
              <span>
                Weight
                <br />
                Reduction
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/4`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Skin Treatment"
            >
              <span>
                <img src={SkinTreatment} alt="SkinTreatment" loading="lazy"/>
              </span>
              <span>
                Skin
                <br />
                Treatment
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/5`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Chemical Peels"
            >
              <span>
                <img src={ChemicalPeels} alt="ChemicalPeels" loading="lazy"/>
              </span>
              <span>
                Chemical
                <br />
                Peels
              </span>
            </li>
            <li
              className="Category"
              onClick={(e) => {
                navigate(`/category-listing/6`);
                localStorage.setItem("categoryName", e.currentTarget.title);
              }}
              title="Body Polish"
            >
              <span>
                <img src={BodyPolish} alt="BodyPolish" loading="lazy"/>
              </span>
              <span>
                Body
                <br />
                Polish
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Couples;
