import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import warning from "assets/images/warning.svg";

import * as profileThunk from "redux/thunks/profileThunk";
import * as registerFormUiAction from "redux/reducer/ui/registerUserUiDux";
import * as productActions from "redux/reducer/productDux";
import * as productThunk from "redux/thunks/productThunk";

import Navbar from "common/Navbar";
import PageLayout from "common/PageLayout";
import moment from "moment";

import NoDataAvailable from "components/NoDataAvailable";
import SessionScheduleSuccessModal from "components/Modals/SessionScheduleSuccessModal";
import SessionScheduleSlotModal from "components/Modals/SessionScheduleSlotModal";
import Feedback from "components/Modals/Feedback";
import CouponAlert from "components/CouponAlert/CouponAlert";

import {
  IpAddressTracking,
  addHoursToTime,
  converDateFormat,
  converTimeFormat,
  isDateOutsideNext7Days,
  subtractHoursFromTime,
} from "utils/helper";
import { getStaticSlots } from "utils/staticSlots";
import BookingDetails from "./BookingDetails";
import axios from "axios";

const Bookings = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { myBookings } = useSelector((state) => state.entities.profile);
  const { error } = useSelector((state) => state.ui.product);
  const { machineSlots } = useSelector((state) => state.entities.product);

  const [activeOption, setActiveOption] = useState();
  const [activeOption1, setActiveOption1] = useState();
  const [showSelectTimeBox, setShowSelectTimeBox] = useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [calenderPopUp, setCalenderPopUp] = useState(false);
  const [slotdisabled, setSlotDisabled] = useState(true);
  const [isReschedule, setIsReschedule] = useState(false);
  const [rateUs, setRateUs] = useState(false);
  const [isPackage, setPackage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedRemarks, setSelectedRemarks] = useState();

  const sessionId = localStorage.getItem("sessionId");
  const sessionScheduleId = localStorage.getItem("sessionScheduleId");
  const slotSelectedTime = localStorage.getItem("selectedTime");
  const slotSelectedDate = localStorage.getItem("selectedDate");
  const clientId = localStorage.getItem("loggedIn");
  const schedulerAllowedCity = [
    "Delhi",
    "Noida",
    "Gurgaon",
    "Pune",
    "Mumbai",
    "Hyderabad",
    "Bangalore",
    "Indore",
    "Ahmedabad",
    "Ludhiana",
    "Chandigarh",
  ];
  const obj = machineSlots;
  const slotsDate = Object.values(obj || {});

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const closeErrorHandler = () => {
    dispatch(registerFormUiAction.clearUi());
  };

  const convertDate = (date1) => {
    const date = new Date(date1);
    date.setUTCDate(date.getUTCDate() + 1);
    date.setUTCHours(14);
    date.setUTCMinutes(0);
    date.setUTCSeconds(33);
    date.setUTCMilliseconds(0);

    const isoString = date.toISOString();
    console.log(isoString)
    return isoString
  }
  const handleModalClose = () => {
    setCalenderPopUp(false);
    setActiveOption(false);
    setActiveOption1(false);
    setSuccessModal(false);
    setShowFeedbackModal(false);
    window.location.reload();
  };

  const scheduleHandler = ({ productId, city, longitude, latitude }) => {
    localStorage.setItem("selectedProductId", productId);
    localStorage.setItem("selectedcity", city);
    localStorage.setItem("clientLong", longitude);
    localStorage.setItem("clientLat", latitude);
    const slots = getStaticSlots(8);
    dispatch(productActions.setProductMachineSlots(slots));
  };

  const handleSlotConfirmation = async () => {
    const res = await IpAddressTracking();
    if (isReschedule) {
      const productId = localStorage.getItem("selectedProductId");
      const city = localStorage.getItem("selectedcity");
      let clientLat = localStorage.getItem("clientLat");
      const slotSelectedTime = localStorage.getItem("selectedTime");

      let clientLong = localStorage.getItem("clientLong");
      clientLat = clientLat === "null" ? null : clientLat;
      clientLong = clientLong === "null" ? null : clientLong;

      // if ((city !== "Delhi" || city !== "Gurgaon" || city !== "Noida" || city !== "Ghaziabad" || city !== "Faridabad" || city !== "Greater Noida") && (clientLat !== null && clientLong !== null)) {
      if (
        schedulerAllowedCity.includes(city) &&
        clientLat &&
        clientLong &&
        clientLat !== "" &&
        clientLong !== ""
      ) {
        const previousDate = localStorage.getItem("previousDate");
        const previousStartTime = localStorage.getItem("previousStartTime");
        // const previousEndTime = localStorage.getItem("previousEndTime");
        const [prevhours, prevminutes] = previousStartTime.split(":");
        let prevnewHours = parseInt(prevhours, 10);
        const prevnewSlotEndTime = `${prevnewHours
          .toString()
          .padStart(2, "0")}:${prevminutes}`;
        const [hours, minutes] = slotSelectedTime.split(":");
        let newHours = parseInt(hours, 10) + 1;
        const newSlotEndTime = `${newHours
          .toString()
          .padStart(2, "0")}:${minutes}`;
        setLoading(true)
        let apiUrl = `${process.env.REACT_APP_SCHEDULER_API}/allocate/reschedule-booking`;
        let userDetails = localStorage.getItem("user")
    userDetails = JSON.parse(userDetails)
    const clientId = userDetails['id']
    const clientName = userDetails['name']
        await axios.post(apiUrl, {
          productId: parseInt(productId, 10),
          remarkId: typeof selectedRemarks === "number" ? selectedRemarks : null,
          customRemarks: typeof selectedRemarks !== "number" ? selectedRemarks : null,
          sessionScheduleId,
          city,
          ipInfo: res ? res : null,
          clientLat,
          clientLong,
          isClient: true,
          clientId,
          clientName,
          previousSlotDate: previousDate,
          previousSlotTime: {
            startTime: previousStartTime,
            endTime: addHoursToTime(prevnewSlotEndTime, 0, 30),
          },
          newSlotDate: slotSelectedDate,
          newSlotTime: {
            startTime: slotSelectedTime,
            endTime: subtractHoursFromTime(newSlotEndTime, 0, 30),
          },
        });

      } else {
        const data = {
          id: Number(sessionScheduleId),
          appointmentDate:
            converDateFormat(slotSelectedDate) +
            " " +
            converTimeFormat(slotSelectedTime),
        };
        const data1 = {
          id: Number(sessionScheduleId),
          status: "PENDING",
        };
        dispatch(productThunk.bookingRescheduleStatus(data1));
        dispatch(productThunk.bookingRescheduleSlots(data));
      }
    } else {
      const isAfter7Days = isDateOutsideNext7Days(slotSelectedDate)
      if (isAfter7Days) {
        const data = {
          id: Number(sessionId),
          appointmentDate:
            converDateFormat(slotSelectedDate) +
            " " +
            converTimeFormat(slotSelectedTime),
          ipInfo: res ? res : null,
        };


        dispatch(productThunk.upcomingBookingScheduleSlots(data));
      } else {
        const data = {
          id: Number(sessionId),
          appointmentDate:
            converDateFormat(slotSelectedDate) +
            " " +
            converTimeFormat(slotSelectedTime),
          ipInfo: res ? res : null,
        };


        dispatch(productThunk.bookingScheduleSlots(data));
      }

    }
    setLoading(false)
    setCalenderPopUp(false);
    setActiveOption(false);
    setActiveOption1(false);
    setSuccessModal(true);
  };

  const scheduleSuccessHandler = () => {
    setSuccessModal(false);

    setTimeout(() => {
      dispatch(profileThunk.getMyBookings());
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    dispatch(profileThunk.getMyBookings());
    return () => {
      // Example cleanup if needed
      window.location.reload();
    };
  }, [dispatch]);

  return (
    <>
      <CouponAlert />
      <Navbar />

      <div className="wrapper myBookings pageHeading">
        <h2>MY BOOKINGS</h2>
        <h6>
          <span onClick={() => window.location.href = ("/")}>Home</span> <span>/</span>{" "}
          <span>My Bookings</span>
        </h6>
        <PageLayout>
          {myBookings?.length === 0 || (myBookings.length === 1 && Object.keys(myBookings[0]).length === 0) ? (
            <NoDataAvailable
              heading="No Bookings"
              subHeading="Check out treatments that best suit your skin needs."
            />
          ) : (
            <div className="left">
              <div className="cartItemDetails listStyles">
                <ul>
                  <BookingDetails
                    scheduleHandler={scheduleHandler}
                    setCalenderPopUp={setCalenderPopUp}
                    setIsReschedule={setIsReschedule}
                    rateUs={rateUs}
                    setRateUs={setRateUs}
                    activeOption={activeOption}
                    activeOption1={activeOption1}
                    calenderPopUp={calenderPopUp}
                    setActiveOption={setActiveOption}
                    setActiveOption1={setActiveOption1}
                    setShowSelectTimeBox={setShowSelectTimeBox}
                    showSelectTimeBox={showSelectTimeBox}
                    setSlotDisabled={setSlotDisabled}
                    slotdisabled={slotdisabled}
                    slotsDate={slotsDate}
                    isReschedule={isReschedule}
                    setSuccessModal={setSuccessModal}
                    setPackage={setPackage}
                    isPackage={isPackage}
                    setSelectedRemarks={setSelectedRemarks}
                  />
                </ul>
              </div>
            </div>
          )}
        </PageLayout>

        {error && (
          <div className="customErrorMessage">
            <p>
              <img src={warning} alt="warning" loading="lazy" />
              Error! {error}.
            </p>
            <span onClick={closeErrorHandler}>x</span>
          </div>
        )}

        <SessionScheduleSuccessModal
          successModal={successModal}
          handleModalClose={handleModalClose}
          scheduleSuccessHandler={scheduleSuccessHandler}
        />

        <SessionScheduleSlotModal
          calenderPopUp={calenderPopUp}
          handleModalClose={handleModalClose}
          slotsDate={slotsDate}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
          setShowSelectTimeBox={setShowSelectTimeBox}
          activeOption1={activeOption1}
          setActiveOption1={setActiveOption1}
          setSlotDisabled={setSlotDisabled}
          showSelectTimeBox={showSelectTimeBox}
          slotdisabled={slotdisabled}
          handleSlotConfirmation={handleSlotConfirmation}
          isPackage={isPackage}
          isLoading={isLoading}
        />
        <Feedback
          showFeedbackModal={showFeedbackModal}
          handleModalClose={handleModalClose}
        />
      </div>
    </>
  );
};

export default Bookings;
