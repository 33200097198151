import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// import logo from "../assets/images/avataarLogo.png";
// import logo from "../assets/images/avataarNewLogo.svg"
import logo from "../assets/images/logoInvoice.png";
export const generateAndDownloadInvoiceForSessionPayment = (payment) => {
    const stateInvoiceNumber =
    {
        "Delhi": "07AAQCM5470M1ZR",
        "Uttar Pradesh": "09AAQCM5470M1ZN",
        "Haryana": "06AAQCM5470M1ZT",
        "Karnataka": "29AAQCM5470M1ZL",
        "Hyderabad": "36AAQCM5470M1ZQ",
        "Maharashtra": "27AAQCM5470M1ZP",
        "Madhya Pradesh": "23AAQCM5470M1ZX",
        "Punjab": "03AAQCM5470M1ZZ",
        "Gujarat":"07AAQCM5470M1ZR",
        "Chandigarh":"03AAQCM5470M1ZZ"
    }
    const paymentStatus={
        'pending':'Pending',
        'partial_paid':'Partial Paid',
        'paid':'Paid'
    }
    const taxAmount = Math.round(parseInt(payment?.payableAmount) * 0.09)

    const productDiscount = parseInt(payment?.productDiscount) || 0;
    const couponDiscount = parseInt(payment?.couponDiscount) || 0;
    const totalDiscount = productDiscount + couponDiscount;
    const totalPendingAmount=parseInt(payment?.payableAmount)-totalDiscount-parseInt(payment?.paidAmount)
    const today = new Date();
    const date = new Date();
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    const randomFourDigits = Math.floor(1000 + Math.random() * 9000);
    const invoiceNumber = `${payment?.state?.slice(0, 3)?.toUpperCase() || ""}${formattedDate}${randomFourDigits}`;


    const container = document.createElement('div');
    container.style.width = '210mm';
    container.style.padding = '20px';
    container.style.background = '#fff';

    container.innerHTML = `
    <div style="font-family:Verdana, sans-serif; color: #333; font-size: 14px; padding: 20px; border: 20px solid gray; border-top: 10px solid gray; border-bottom: 10px solid gray; width: 100%; height: 100%">
        <h3 style="text-align: center; margin-top: 20px;">Original Tax Invoice</h3>
        <div style="display: flex; align-items: center;padding:5px">
            <div style="flex-shrink: 0;">
                <img src="${logo}" alt="Company Logo" style="height: 50px; width: 50px; border-radius: 50%; object-fit: cover;"/>
            </div>
        </div>
        <div style="display: flex; justify-content: space-between; padding: 10px; border-bottom: 2px solid grey;">
            <div>
                <strong style="font-size: 1.3em;">Misya Beauty Tech Pvt. Ltd.</strong>
                <p style="margin: 0;">GSTIN: ${stateInvoiceNumber[payment?.state]}</p>
                <p style="margin: 0;">2, Shambhu Dyal Marg, Okhla Phase III.</p>
                <span style="margin: 0;">Delhi 110065</span>
            </div>
            <div>
                <p>Invoice No: ${invoiceNumber}</p>
                <p>Date:${payment?.paymentDate ? new Date(payment.paymentDate).toLocaleDateString() : 'N/A'}</p>
            </div>
        </div>
     
        
        <!-- Customer Details -->
        <div style="margin-top: 20px; padding-bottom: 20px;">
            <strong style="font-size: 1.3em;">Customer Details</strong>
            <p style=" margin-top: 20px; display: flex; padding:10px; margin: 0; padding-bottom: 10px; border-bottom: 2px solid gray; border-top: 2px solid grey;">
                <span>Name</span> <strong>: ${payment?.userName}</strong>
            </p>
            <p style="display: flex; padding:10px; margin: 0; padding-bottom: 10px; border-bottom: 2px solid grey;">
                <span>Mobile</span> <strong>: ${payment?.phoneNumber}</strong>
            </p>
            <p style="display: flex; padding:10px; margin: 0; padding-bottom: 10px; border-bottom: 2px solid grey;">
                <span>Address</span> <strong>: ${payment?.address}</strong>
            </p>
        </div>

        <!-- Booking/Package Details -->
        <div style="margin-top: 10px; padding-bottom: 20px; border-bottom: 2px solid black;">
            <strong style="font-size: 1.3em;">Order Details</strong>
            <p style="display: flex; justify-content: space-between; margin-top: 20px;">
                <span>Product Name(sessions):</span> <strong>${payment?.serviceName}(${payment?.productSession?payment?.productSession:''})</strong>
            </p>
        </div>

        <!-- Invoice Details 
        <div style="margin-top: 20px; padding-bottom: 20px; border-bottom: 2px solid black;">
            <strong style="font-size: 1.3em; margin-bottom: 10px">Invoice Details</strong>
            <p style="display: flex; justify-content: space-between; margin-top: 20px;">
                <span>Invoice Number:</span> <strong>${stateInvoiceNumber[payment?.state]}-${formattedDate}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Invoice Date:</span> <strong>${payment?.paymentDate ? new Date(payment?.paymentDate).toLocaleDateString() : 'N/A'}</strong>
            </p>
        </div>
        <p style="display: flex; justify-content: space-between;">
        <span style="margin-top: 20px;">Product Sessions:</span> <strong>${payment?.productSession}</strong>
    </p>
    <div style="margin-left: 10px;">
    <strong style="font-size: 1.2em;">Avataar Skincare</strong>
</div>
        -->
        <!-- Cost Summary -->
        <div style="margin-top: 20px; padding-bottom: 20px; border-bottom: 2px solid black;">
            <strong style="font-size: 1.3em; margin-bottom: 10px;">Order Summary</strong>
            <p style="display: flex; justify-content: space-between; margin-top: 20px;">
                <span>Total Order Amount:</span> <strong>₹${payment?.actualProductPrice}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Total Order Discount:</span> <strong>₹${totalDiscount}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Coupon Discount:</span> <strong>₹${payment?.couponDiscount?payment?.couponDiscount:0}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Credits Used:</span> <strong>₹${payment?.walletAmount ? payment?.walletAmount : 0}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
            <span>Total Amount Pending:</span> <strong>₹${totalPendingAmount}</strong>
          </p>
          <p style="display: flex; justify-content: space-between;">
          <span>Paid Amount:</span> <strong>₹${payment?.paidAmount?payment?.paidAmount:0}</strong>
         </p>
          <p style="display: flex; justify-content: space-between;">
            <span>Total Amount Payable (incl. taxes):</span> <strong>₹${payment?.payableAmount}</strong>
          </p>
            <p style="display: flex; justify-content: space-between;">
                <span>CGST (9.0%):</span> <strong>₹${taxAmount}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>SGST (9.0%):</span> <strong>₹${taxAmount}</strong>
            </p>
        </div>

        <!-- Payment Details -->
        <div style="margin-top: 20px; padding-bottom: 20px; border-bottom: 2px solid gray;">
            <strong style="font-size: 1.3em; margin-bottom: 10px;">Payment Details</strong>
            <p style="display: flex; justify-content: space-between; margin-top: 20px;">
                <span>Payment Method:</span> <strong>${payment?.modeOfPayment ?payment.modeOfPayment: "Cash Payment"}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Payment status:</span> <strong>${payment?.paymentStatus ?paymentStatus[payment?.paymentStatus] : "NA"}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Transaction ID:</span> <strong>${payment?.orderId?.slice(-10)}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Transaction Date:</span> <strong>${payment?.paymentDate ? new Date(payment.paymentDate).toLocaleDateString() : 'N/A'}</strong>
            </p>
        </div>

        <!-- Footer for Disclaimers -->
        <div style="margin-top: 20px; padding: 10px 0;">
            <strong style="font-size: 1.2em;">Please Note</strong>
            <p style="font-size: 12px; margin-top: 20px">This is an electronically generated invoice and does not require a digital signature.</p>
            <p style="font-size: 12px;">The invoice shall be valid for a period of 60 days from the date of the invoice.</p>
            <p style="font-size: 12px;">All transactions with Avataar will comply with the company Standards & T&Cs and Privacy Policy.</p>
        </div>
    </div>
`;


    // Append the container to the body
    document.body.appendChild(container);

    // Generate the PDF
    // html2canvas(container, { scale: 4 }).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/jpeg', 0.95);
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const imgWidth = 210; 
    //     const imgHeight = (canvas.height * imgWidth) / canvas.width; 
    //     pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, null, 'FAST');
    //     pdf.save(`invoice.pdf`);
    //     document.body.removeChild(container);
    // });

    html2canvas(container, { scale: 3 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.55); // Compressing image with 85% quality
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297, null, 'FAST');
        pdf.save(`invoice.pdf`);

        document.body.removeChild(container);
    });
    
    // html2canvas(container, { scale: 3 }).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
    //     pdf.save(`invoice.pdf`);

    //     // Clean up
    //     document.body.removeChild(container);
    // });
};

