import CustomModal from 'common/CustomModal'
import React from 'react'
import PinInput from 'react-pin-input'

const OTPVerification = (
    {
        otpPopup,
        handleModalClose,
        formErrors,
        formValues5,
        error,
        timer,
        isNotValidated5,
        handlePinChange,
        profileSetupHandler,
        resendOTP,
        handleResendOtp
    }
) => {
  return (
    <CustomModal
        show={otpPopup}
        onClose={handleModalClose}
        className="customPopup authPopup"
      >
        <div className="modal_content form_wrapper">
          <span className="bannerBox"></span>
          <div
            className={`pin-error master-form-group ${formErrors.otp ? "master-input-error" : ""
              }`}
          >
            <label htmlFor="otp">Enter Verification Code</label>
            <span>
              We have sent you a 4 digit verification code on the given mobile
              number
            </span>
            <PinInput
              length={4}
              initialValue={formValues5.otp}
              onChange={handlePinChange}
              type="numeric"
              inputMode="number"
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />

            {formErrors.otp && (
              <div className="message-error">
                <span className="text-danger1">{formErrors.otp}</span>
              </div>
            )}

            {error && (
              <div className="password-error">
                <span className="text-danger1">{error}</span>
              </div>
            )}

            <div className="master-form-group timer">
              <p>
                Didn’t get an OTP?
                {timer === 0 ? (
                  <button onClick={handleResendOtp}> Resend</button>
                ) : (
                  <span className="m-0 text-center">
                    &nbsp;00:{timer < 10 ? `0${timer}` : timer}{" "}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="master-form-group scheduleBox">
          {!resendOTP && <button
            onClick={profileSetupHandler}
            disabled={isNotValidated5}
            className="form_button"
          >
            SIGN UP / LOGIN
          </button>}

          {resendOTP && <button
            onClick={profileSetupHandler}
            className="form_button"
            style={{ backgroundColor: "green" }}
          >
            OTP Send Successfully
          </button>}

          <div className="cartTerms">
            <p>
              By proceeding, you agree to our <br />
              <a href="/">T&amp;C, Privacy Cancellation policy</a>
            </p>
          </div>
        </div>
      </CustomModal>
  )
}

export default OTPVerification