import React, { useRef, useState, useEffect, useMemo } from "react";
import Navbar from "common/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "common/CustomModal";
import { useDispatch, useSelector } from "react-redux";
// import { Oval } from 'react-loader-spinner';
import doneicon from "../assets/images/done-icon.svg";
import * as moment from "moment";

const PaymentStatus = ({paymentDone,setPaymentDone}) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const handleModalClose = () => {
    setPaymentDone(false);
    localStorage.removeItem("checkoutUrl");
    window.location.reload();
  };

  const { isFetching, error } = useSelector((state) => state.ui.registerForm);

  const transactionId = localStorage.getItem("razorpayId");
  const paymentSuccess=localStorage.getItem("paymentSuccess");

  return (
    <>
      <CustomModal
        show={paymentDone}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper feedsSubmitted">
          <div className="starImage">
            {paymentSuccess ? (
              <span>
                <img src={doneicon} alt="doneicon" loading="lazy"/> Payment Successful!
              </span>
            ) : (
              <span> Payment Failed</span>
            )}
          </div>

          <div className="aftercare paymentInfoBox">
            <h4>Payment Details</h4>
            <div className="tips">
              <span>OrderId:{transactionId}</span>
              <p></p>
            </div>
            <div className="tips">
              <span>
                Date/Time:{" "}
                {moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSZ")
                  .utcOffset("+05:30")
                  .format(`ddd, MMM DD • hh:mm A`)}
              </span>
              <p></p>
            </div>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button
            onClick={() => window.location.reload()}
            className="form_button"
          >
            CONTINUE
          </button>
        </div>
      </CustomModal>

      <div className="wrapper">
        <div className="wrapperContainer">
          <div className="innerContainer productStatusDiv"></div>
        </div>
      </div>
    </>
  );
};

export default PaymentStatus;
