import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
} from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";

import scheduleCompleteIcon from "assets/images/scheduleCompleteIcon.svg";

import * as profileThunks from "redux/thunks/profileThunk";
import * as cartThunks from "redux/thunks/cartThunk";
import * as registerUserUiActions from "redux/reducer/ui/registerUserUiDux";
import * as profileActions from "redux/reducer/profileDux";

import PageLayout from "common/PageLayout";
import CustomModal from "common/CustomModal";
import Navbar from "common/Navbar";
import CouponAlert from "components/CouponAlert/CouponAlert";

import SelectAddressModal from "containers/CartPage/Modals/SelectAddress";
import AddCityModal from "containers/CartPage/Modals/AddCity";
import ShowMapModal from "containers/CartPage/Modals/ShowMap";
import CouponModal from "containers/CartPage/Modals/Coupons";
import AddAddress from "containers/CartPage/Modals/AddAddress/AddAddress";

import "react-phone-input-2/lib/style.css";
import Leftcontainer from "containers/CartPage/LeftContainer";
import RightContainer from "containers/CartPage/RightContainer";
import NoDataAvailable from "components/NoDataAvailable";
import { IpAddressTracking } from "utils/helper";

const CartPage = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const userId = localStorage.getItem("loggedIn");

  const initialValues = { type: "" };
  const initialValues1 = {
    pincode: "",
    state: "",
    address: "",
    city: "",
    town: "",
    type1: "",
    floorNumber: "",
    hasLift: false,
  };
  const initialValues2 = { type: "" };
  const initialValues4 = { couponValue: "" };

  const [addressPopup, setAddressPopup] = useState(false);
  const [regionalCityModal, setRegionalCityModal] = useState(false)
  const [regionalCity, SetRegionalCity] = useState("")
  const [formValues] = useState(initialValues);
  const [formValues1, setFormValues1] = useState(initialValues1);
  const [formValues2, setFormValues2] = useState(initialValues2);
  const [formValues4, setFormValues4] = useState(initialValues4);
  const [appliedCoupon, setAppliedCoupon] = useState(false);
  const [notAppliedCoupon, setNotAppliedCoupon] = useState(false)
  const [appliedCoupon2, setAppliedCoupon2] = useState(false);
  const [active, setActive] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [addAnotherAddressPopup, setAddAnotherAddressPopup] = useState(false);
  const [couponListPopup, setCouponListPopup] = useState(false);
  const [haveAddress, setHaveAddress] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [addTempData, setAddTempData] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState(false);
  const [orderSuccessPopup, setOrderSuccessPopup] = useState(false);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [currentAddressIndex, setCurrentAddressIndex] = useState();
  const [reqType, setReqType] = useState("ADD");
  const [addressFromModal, setAddressFromModal] = useState(null)
  const [showMap, setShowMap] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [creditUsed, setCreditUsed] = useState(0);
  const [google, setGoogle] = useState(null);
  const [userLocation, setUserLocation] = useState({
    lat: "",
    lng: "",
  });
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  const { error } = useSelector((state) => state.ui.product);
  const { error1 } = useSelector((state) => state.ui.forgotPassword);


  const handleMarkerDragend = (t, map, coord) => {
    if (coord) {
      const { latLng } = coord;
      if (latLng) {
        const lat = latLng.lat();
        const lng = latLng.lng();
        setUserLocation({ lat, lng });
        setLoading(true);
        setWarning(true)
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    script.async = true;
    script.onload = () => {
      setGoogle(window.google);
    };
    document.head.appendChild(script);
    const counter = localStorage.getItem("map_loader")
    if (counter) {
      const value = counter + 1
      localStorage.setItem("map_loader", value)
    } else {
      localStorage.setItem("map_loader", 1)
    }
  }, []);

  const [cartList] = useState({
    title: "",
    session: "",
    price: "",
    actualPrice: "",
    date: "",
    time: "",
  });

  const { userAddress, userAddressId } =
    useSelector((state) => state.entities.profile);

  const { cart } = useSelector((state) => state.entities);
  const { couponList, couponDetails } = useSelector(
    (state) => state.entities.cart
  );

  const { cities } = useSelector((state) => state.entities.cart)
  const filteredCouponList = couponList.filter(
    item => !['AVATAAR10', 'AVATAAR15', 'AVATAAR20'].includes(item.code)
  ).sort((a, b) => a.discount - b.discount);
  const CouponListCount = filteredCouponList?.length;
  const id = Number(formValues2?.type);
  const token1 = localStorage.getItem("token");

  const addressHandler = () => {
    setAddressPopup(true);
    dispatch(profileThunks.getAddress());
  };

  const handleModalClose = () => {
    setRegionalCityModal(false)
    setAddressPopup(false);
    setAddAnotherAddressPopup(false);
    setCouponListPopup(false);
    setPaymentSuccess(false);
    setOrderSuccessPopup(false);
    setIsManualAddress(false);
    setShowMap(false)
    // setShowMap(false)
  };

  const handleAddressModalClose = () => {
    handleModalClose();
    dispatch(profileActions.setCurrentLocation({}));
    setFormValues1(initialValues1);
  };

  const handleAddAddressHandler = () => {
    setAddressPopup(false);
    setAddAnotherAddressPopup(false);
    setHaveAddress(true);
    dispatch(profileThunks.getAddressId(id));
  };

  const validateForm = (name, value) => {
    switch (name) {
      case "mobile": {
        let error = null;

        if (!value) {
          error = "Mobile number is required!";
        }

        if (
          value.slice(formValues.countryCode.lenrgth).length !== 10 &&
          value.slice(formValues.countryCode.length).length !== 11
        ) {
          error = "This is not a valid phone number";
        }

        return {
          mobile: error,
        };
      }

      case "couponValue": {
        let error = null;

        if (!value) {
          error = "Coupon code is required!";
        }
        return {
          couponValue: error,
        };
      }

      default:
        break;
    }
  };

  const handleCouponChange = (e) => {
    const { name, value } = e.target;
    setFormValues4({ ...formValues4, [name]: value });
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
    if (value.length > 3) {
      if (value === "AVATAAR10") {
        if ((cart.category.total - cart.category.advanceBalance >= 30000))
          setActive(true);
        else
          setActive(false);
      }
      else if (value === "AVATAAR15") {
        if ((cart.category.total - cart.category.advanceBalance >= 40000))
          setActive(true);
        else
          setActive(false);
      }
      else if (value === "AVATAAR20") {
        if ((cart.category.total - cart.category.advanceBalance >= 50000))
          setActive(true);
        else
          setActive(false);
      }
      else {
        setActive(true);
      }
    } else {
      setActive(false);
      setAppliedCoupon(false);
    }
  };

  const handleTouch = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      ...formErrors,
      ...validateForm(name, value),
    });
  };

  const addOrdinalSuffix = (num) => {
    if (num === "0") {
      return "Ground";
    }
    if (num === "1") {
      return `${num}st`;
    } else if (num === "2") {
      return num + "nd";
    } else if (num === "3") {
      return num + "rd";
    } else {
      return num + "th";
    }
  }

  const isNotValidated2 = useMemo(
    () =>
      Object.values(formErrors).filter((value) => !!value).length !== 0 ||
      Object.values(formValues2).filter((value) => !!value).length < 1,
    [formErrors, formValues2]
  );

  const addAnotherAddressHandler = () => {
    setReqType("EDIT")
    setAddAnotherAddressPopup(true);
    setAddressPopup(false);
    console.log(userAddressId)
    setAddTempData({
      ...userAddressId,
    });
  };

  const coupleDetailsHandler = () => {
    setCouponListPopup(true);
  };

  const handleCurrentAddressBtn = () => {
    getCurrentLocationHandler();
    // setLoading(false);
  };

  const handleAddressRequest = async (type, addressData) => {
    const searchAdress = localStorage.getItem("searchAddress");
    const latLongData = {
      lat: addressData.latitude,
      lng: addressData.longitude
    }

    setUserLocation({
      ...latLongData
    })

    setReqType(type)
    if (reqType === "EDIT") {
      const { building, floor, postalCode } = addressData
      // const floorStr = addOrdinalSuffix(floor)
      const data = {
        ...addressData,
        formattedAddress: `${floor} floor, Building - ${building}, ${searchAdress} ${postalCode}`,
        postalCode: String(postalCode),
        latitude: userLocation.lat,
        longitude: userLocation.lng
      }
      setAddressFromModal(data)
      handleModalClose()
      setShowMap(true)
    } else {
      const { postalCode, building, floor } = addressData || {}
      const data = {
        ...addressData,
        formattedAddress: `${floor} floor, Building - ${building}, ${searchAdress} ${postalCode}`,
        postalCode: String(postalCode),
      }
      setAddressFromModal(data)
      setIsManualAddress(false)
      setShowMap(true)
    }
    setWarning(true);

    // setTimeout(() => setrandomTimer(false), 2000);
  }

  const finalAddressSave = () => {
    if (reqType === "EDIT") {
      finalEditSaveReq()
    } else {
      console.log("ADD CALLED")
      finalSaveReq()
    }
  }


  const finalEditSaveReq = () => {
    const data = {
      ...addressFromModal,
      latitude: userLocation.lat,
      longitude: userLocation.lng
    }
    console.log(data)
    dispatch(profileThunks.updateAddress(addressFromModal.id, data))
    setTimeout(() => {
      const addId = localStorage.getItem("address");
      // console.log(addId);
      dispatch(profileThunks.getAddressId(Number(addId))).then((response) => {
        if (response.success) {
          dispatch(profileThunks.getAddress());
        }
      });
      setHaveAddress(true);
      handleModalClose()
    }, 1000);
  }

  const finalSaveReq = async () => {
    const res = await IpAddressTracking();

    const data = {
      ...addressFromModal,
      latitude: userLocation.lat,
      longitude: userLocation.lng,
      ipInfo: res ? res : null,
    }
    dispatch(profileThunks.addAdress(data));
    setTimeout(() => {
      const addId = localStorage.getItem("address");
      // console.log(addId);
      dispatch(profileThunks.getAddressId(Number(addId))).then((response) => {
        if (response.success) {
          dispatch(profileThunks.getAddress());
        }
      });
      setHaveAddress(true);
      handleModalClose()
    }, 1000);

  }

  const savedAddressHandler = () => {
    dispatch(profileThunks.getAddress());
    dispatch(profileThunks.getAddressId(userAddressId.id));
    setAddAnotherAddressPopup(false);
    setUpdatedAddress(false);
  };

  const paymentGatewayHandler = async () => {
    const addressId = await localStorage.getItem("address");
    const res = await IpAddressTracking();
    cart?.category.products.sort((a, b) => a.packageId - b.packageId);
    let data = {
      cartId: cart?.category?.id,
      addressId: userAddress[currentAddressIndex]?.id || Number(addressId),
      type: "postpaid",
      ipInfo: res ? res : null,
      payableAmount: payableAmount,
      couponDiscount: couponDiscount,
      creditsUsed: creditUsed,
    };

    dispatch(profileThunks.createOrder(data))
      .then((res) => {
        console.log(res, "product add success");
        setOrderSuccessPopup(true);

        setTimeout(() => {
          setOrderSuccessPopup(false);
          navigate("/my-bookings")
        }, 1000)
      })
      .catch((error) => {
        console.log(error, "product add error");
      });
  };

  const handleCitySelection = (cityName) => {
    SetRegionalCity(cityName);
    handleCitySubmit(cityName)
  };

  const handleValueChange2 = (e) => {
    const { name, value } = e.target;
    let field = value;
    // console.log(field);

    setFormValues2({ ...formValues2, [name]: field });
  };

  const handleDeleteCartItem = (productID, cartID) => {
    console.log(productID, cartID)
    dispatch(cartThunks.deleteCartItem(productID, cartID))
      .then((res) => {
        console.log(res, "remove product success");
        window.location.reload();
        setAppliedCoupon(false);
        setAppliedCoupon2(false);
        dispatch(cartThunks.getCartList());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addNewAddressHandler = () => {
    setAddressPopup(false);
    setRegionalCityModal(true)

  };

  const closeErrorHandler = () => {
    dispatch(registerUserUiActions.clearUi());
  };

  const getCurrentLocationHandler = () => {
    let lat, long;
    setWarning(false);
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        // if (location) dispatch(getUserData(location.coords));
        dispatch(profileActions.setCurrrentCordinates(location.coords));

        localStorage.setItem(
          "latitude",
          JSON.stringify(location.coords.latitude)
        );
        lat = location.coords.latitude;
        localStorage.setItem(
          "longitude",
          JSON.stringify(location.coords.longitude)
        );
        long = location.coords.longitude;
        setUserLocation({ lat: lat, lng: long });
        //
        const counter = localStorage.getItem("dragger")
        if (counter) {
          const value = counter + 1
          localStorage.setItem("dragger", value)

        } else {
          localStorage.setItem("dragger", 1)
        }
        console.log(lat, long);
        setTimeout(() => {
          const axios = require("axios");
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://crm.avataarskin.com/app/api/?type=search_location_by_coordinates&latlng=${lat},${long}`,
            headers: {
              "Content-Type": "application/json",
            },
          };

          axios
            .request(config)
            .then((response) => {
              setTimeout(() => {
                if (response.status === 200) {
                  dispatch(profileActions.setCurrentLocation(response));
                  setWarning(true)
                } else {
                  alert("hiii");
                }
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }, 5000);

      });
    }
  };

  const selectCouponHandler = () => {
    const data = {
      cartId: cart?.category?.id,
      code: formValues4.couponValue,
    };

    dispatch(cartThunks.couponDetails(data)).then((response) => {
      if (response.success) {
        if (error1 === null) {
          setCouponListPopup(false);
          setAppliedCoupon(true);
        }
      }
      else {
        setNotAppliedCoupon(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      }
    });
  };

  const selectPreCouponHandler = () => {
    setTimeout(() => {
      const couponCode = localStorage.getItem("couponCode");

      const data = {
        cartId: cart?.category?.id,
        code: couponCode,
      };
      dispatch(cartThunks.couponDetails(data)).then((response) => {
        if (response.success) {
          setAppliedCoupon2(true);
        }
      });
    }, 1000);
    setCouponListPopup(false);
  };

  const handleCitySubmit = (city) => {
    dispatch(profileActions.setRegionalCity(city))
    localStorage.setItem('citySelected', city)
    setAddressPopup(false);//   // setAddNewAddressPopup(true);
    setRegionalCityModal(false)
    setIsManualAddress(true);
  }

  useEffect(
    () => {
      dispatch(cartThunks.getCartList());
    }, // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (formValues1?.address !== null) {
    }

    if (userAddress !== null) {
      setHaveAddress(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      dispatch(cartThunks.getCouponList());
    }, // eslint-disable-next-line
    []
  );

  useEffect(() => {
    dispatch(cartThunks.getCities())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {error ? (
        <div className="customErrorMessage">
          <p>
            <img src={warning} alt="warning" loading="lazy" />
            Error! {error}.
          </p>
          <span onClick={closeErrorHandler}>x</span>
        </div>
      ) : (
        ""
      )}

      {!token1 && <Navigate to="/" />}
      <CouponAlert />
      <SelectAddressModal
        addNewAddressHandler={addNewAddressHandler}
        addressPopup={addressPopup}
        handleAddAddressHandler={handleAddAddressHandler}
        handleModalClose={handleModalClose}
        handleValueChange2={handleValueChange2}
        isNotValidated2={isNotValidated2}
        setCurrentAddressIndex={setCurrentAddressIndex}
        userAddress={userAddress} />

      <AddCityModal
        cities={cities}
        handleCitySelection={handleCitySelection}
        // handleCitySubmit={handleCitySubmit}
        handleModalClose={handleModalClose}
        regionalCity={regionalCity}
        regionalCityModal={regionalCityModal}
      />

      <CustomModal
        show={addAnotherAddressPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        {updatedAddress ? (
          <>
            <div className="modal_content form_wrapper">
              <div className="innerContent">
                <span>
                  <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy" />
                </span>
                <p>Address Successfully Updated!</p>
              </div>
            </div>
            <div className="master-form-group scheduleBox">
              <button onClick={savedAddressHandler} className="form_button">
                CONTINUE
              </button>
            </div>
          </>
        ) :
          <div>
            <AddAddress
              editAddress={true}
              addressData={addTempData}
              handleAddressRequest={handleAddressRequest}
              finalAddressSave={finalAddressSave}
            />
          </div>
        }
      </CustomModal>

      <ShowMapModal
        finalAddressSave={finalAddressSave}
        handleModalClose={handleModalClose}
        userLocation={userLocation}
        warning={warning}
        showMap={showMap}
        handleMarkerDragend={handleMarkerDragend}
        google={google}
        handleCurrentAddressBtn={handleCurrentAddressBtn}
      />

      <CustomModal
        show={isManualAddress}
        onClose={handleAddressModalClose}
        className="custom_modal customPopup innerPopup authPopup">
        <AddAddress handleAddressRequest={handleAddressRequest} finalAddressSave={finalAddressSave} />
      </CustomModal>
      {/* Modal to take current address from user */}

      <CouponModal
        active={active}
        appliedCoupon={appliedCoupon}
        notAppliedCoupon={notAppliedCoupon}
        couponList={couponList}
        cart={cart}
        couponListPopup={couponListPopup}
        error={error}
        error1={error1}
        formErrors={formErrors}
        formValues4={formValues4}
        handleCouponChange={handleCouponChange}
        handleModalClose={handleModalClose}
        handleTouch={handleTouch}
        selectCouponHandler={selectCouponHandler}
        selectPreCouponHandler={selectPreCouponHandler} />

      <CustomModal
        show={paymentSuccess}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper">
          <div className="innerContent">
            <span>
              <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy" />
            </span>
            <p>Payment Successful</p>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button className="okayBtn" onClick={handleModalClose}>
            Continue
          </button>
        </div>
      </CustomModal>

      <CustomModal
        show={orderSuccessPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup orderSuccess"
      >
        <div className="modal_content form_wrapper feedsSubmitted">
          <div className="innerContent">
            <span>
              <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy" />
            </span>
            <p>Thank you for your Order!</p>
            <span>
              We will be now redirecting you to your bookings page, please
              select a slot as per your convenience.
            </span>
          </div>
        </div>
        {/* <div className="master-form-group scheduleBox">
          <button
            onClick={() => navigate("/my-bookings")}
            className="form_button"
          >
            CONTINUE
          </button>
        </div> */}
      </CustomModal>

      <Navbar
        show={showLoginModal}
        closeHandler={() => setShowLoginModal(false)}
      />
      <div className="wrapper">
        <span onClick={() => navigate(-1)} className="backArrow">
          <span>
            <Icon path={mdiArrowLeft} size={1} />
          </span>
          Cart Summary
        </span>
        {cart?.category?.products?.length > 0 ? (
          <PageLayout>
            <>
              <Leftcontainer cart={cart} handleDeleteCartItem={handleDeleteCartItem} />
              <RightContainer
                CouponListCount={CouponListCount}
                addAnotherAddressHandler={addAnotherAddressHandler}
                addressHandler={addressHandler}
                appliedCoupon={appliedCoupon}
                appliedCoupon2={appliedCoupon2}
                cart={cart}
                cartList={cartList}
                coupleDetailsHandler={coupleDetailsHandler}
                couponDetails={couponDetails}
                haveAddress={haveAddress}
                paymentGatewayHandler={paymentGatewayHandler}
                userAddressId={userAddressId}
                userId={userId}
                setCouponDiscount={setCouponDiscount}
                setCreditUsed={setCreditUsed}
                setPayableAmount={setPayableAmount} />
            </>
          </PageLayout>
        ) : (
          <NoDataAvailable
            heading="Your cart is empty!"
            subHeading="Check out treatments that best suit your skin needs." />)}
      </div>
    </>
  );
};

export default CartPage;
