import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import "../faq.css";

const FaqDetails = (faqData) => {
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const renderAccordionItems = () => (
    <Accordion.Item className="item" eventKey={faqData.question}>
      <Accordion.Header className="accordion-header" tabIndex={0} onClick={toggleInfo}>
        <p className="btnIcon" onClick={toggleInfo}>
          {showInfo ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </p>
        <p className="faqQuestion">
          {faqData.question}
        </p>
      </Accordion.Header>

      <Accordion.Body className="accordion-body">
        <p className="faqAnswer">{faqData.answer}</p>
      </Accordion.Body>
    </Accordion.Item>
  );

  return (
    <div>
      <Accordion className="custom-accordion" defaultActiveKey={null} flush>
        <div className="faq-column">{renderAccordionItems()}</div>
      </Accordion>
    </div>
  );
};

export default FaqDetails;