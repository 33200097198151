import { get, post } from "../../utils/httpUtils";
import * as feedbackActions from "../reducer/feedbackDux";
import * as registerUserUiActions from "../reducer/ui/registerUserUiDux";

export const saveUserRatings = (data) => (dispatch) => {
  // dispatch(registerUserUiActions.setFetching(true));
  return post("orders/submit-nps", data).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      dispatch(feedbackActions.setUserRating(response));
      return {
        success: true,
      };
    }
  });
};

export const getUserRating = (sessionScheduleId) => (dispatch) => {
  return get(`orders/get-nps/${sessionScheduleId}`).then((response) => {
    if (!!response.error) {
      dispatch(registerUserUiActions.setError(response.message));
      dispatch(registerUserUiActions.setCode(response.code));
      dispatch(registerUserUiActions.setFetching(false));

      return {
        success: false,
      };
    } else {
      dispatch(feedbackActions.saveUserRating(response));
      return {
        success: true,
      };
    }
  });
};
