import React from "react";
import { Modal } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const CustomModal = ({ show, onClose, className, children }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        className={`custom_modal ${className}`}
        animation={true}
        backdrop={true}
      >
        <button type="button" className="btn close_modal" onClick={onClose}>
          <Icon path={mdiClose} size={1} />
        </button>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
