import GoogleAutocomplete from "components/GoogleAutocomplete";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { stateHandler } from "utils/helper";

const AddAddress = ({ handleAddressRequest, addressData, finalAddressSave, editAddress }) => {
  const { regionalCity } = useSelector((state) => state.entities.profile);
  const stateValue = stateHandler(regionalCity);

  const [addressState, setAddressState] = useState({
    building: addressData && addressData.building ? addressData.building : "",
    area: addressData && addressData.area ? addressData.area : "",
    floor: addressData && addressData.floor > -1 ? addressData.floor : "",
    city: regionalCity ? regionalCity : (addressData && addressData.city ? addressData.city : ""),
    state: stateValue,
    postalCode: addressData && addressData.postalCode ? addressData.postalCode : "",
    lift: addressData && addressData.lift ? addressData.lift : false,
    type: "home",
    formattedAddress: addressData && addressData.formattedAddress ? addressData.formattedAddress : "",
    isPrimary: true,
    compoundCode: addressData && addressData.compoundCode ? addressData.compoundCode : "",
    placeId: addressData && addressData.placeId ? addressData.placeId : "",
    latitude: addressData && addressData.latitude ? addressData.latitude : "",
    longitude: addressData && addressData.longitude ? addressData.longitude : "",
  });

  const [errState, setErrState] = useState({
    cityErr: "",
    stateErr: "",
    postalCodeErr: "",
    areaErr: "",
    floorErr: "",
    buildingErr: "",
    liftErr: "",
  });

  const resetErrObj = {
    cityErr: "",
    stateErr: "",
    postalCodeErr: "",
    areaErr: "",
    floorErr: "",
    buildingErr: "",
    liftErr: "",
  };

  const [searchHandle, setSearchHandle] = useState(true);

  const handleSubmit = () => {
    if (!addressState.building) {
      setErrState((prevState) => ({
        ...prevState,
        buildingErr: "Please fill building details.",
      }));
      return;
    }
    if (!addressState.area) {
      setErrState((prevState) => ({
        ...prevState,
        areaErr: "Please fill area details.",
      }));
      return;
    }
    if (!addressState.floor) {
      setErrState((prevState) => ({
        ...prevState,
        floorErr: "Please fill floor number.",
      }));
      return;
    }
    if (!addressState.city) {
      setErrState((prevState) => ({
        ...prevState,
        cityErr: "Please fill city.",
      }));
      return;
    }
    if (!addressState.state) {
      setErrState((prevState) => ({
        ...prevState,
        stateErr: "Please fill state.",
      }));
      return;
    }
    if (!addressState.postalCode) {
      setErrState((prevState) => ({
        ...prevState,
        postalCodeErr: "Please fill pincode.",
      }));
      return;
    }
    if (addressState.lift==="") {
      setErrState((prevState) => ({
        ...prevState,
        liftErr: "Please select an option for lift.",
      }));
      return;
    }

    const payload = addressData
      ? {
        ...addressState,
        id: addressData.id,
      }
      : {
        ...addressState,
      };
    const type = editAddress ? "EDIT" : "ADD";
    handleAddressRequest(type, payload);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrState(resetErrObj);
    // console.log(name);
    if (name === "lift") {
      setAddressState({
        ...addressState,
        state: stateValue,
        [name]: value === "not required" ? null : value === "yes" ? true : false,
      });
    }
    else {
      setAddressState({
        ...addressState,
        state: stateValue,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const setAddressFromGoogle = ({ googleObj, latLongObj }) => {
    console.log("Google selected address", googleObj, latLongObj);
    setAddressState({
      ...addressState,
      area: googleObj.area,
      postalCode: googleObj.postal_code ? googleObj.postal_code : "",
      placeId: googleObj.placeId ? googleObj.place_id : "ChIJPUd0FnGpqagR6D3nqP5298I",
      compoundCode: googleObj.compound_code ? googleObj.compound_code : "ChIJgTEg9XIZDTkRqq0wIO3ulCg",
      latitude: latLongObj.lat,
      longitude: latLongObj.lng,
    });
    setSearchHandle(false);
  };

  return (
    <div>
      <>
        <div className="modal_content form_wrapper addressPopup">
          <div className={`master-form-group4 long `}>
            <h4>ADD ADDRESS</h4>
          </div>
          <div className="address-form">
            <div className="addressBox">
              <p>{addressData ? "EDIT ADDRESS" : "Enter Your Address"}</p>
              <div className={`master-form-group`}>
                <GoogleAutocomplete
                  setAddressFromGoogle={setAddressFromGoogle}
                  addressState={addressState}
                  setSearchHandle={setSearchHandle}
                  setAddressState={setAddressState}
                />
              </div>
              <div className={`master-form-group`}>
                <input
                  type="text"
                  name="building"
                  placeholder="Building No. *"
                  className="form-input"
                  disabled={searchHandle}
                  onChange={handleChange}
                  value={addressState.building}
                />
                {errState.buildingErr && <small className="form-text text-danger">{errState.buildingErr}</small>}
              </div>
              <div className={`master-form-group`}>
                <input
                  type="text"
                  name="area"
                  placeholder="Area *"
                  className="form-input"
                  onChange={handleChange}
                  value={addressState.area}
                  disabled={searchHandle}
                />
                {errState.areaErr && <small className="form-text text-danger">{errState.areaErr}</small>}
              </div>
              <div className={`master-form-group`}>
                <input
                  type="number"
                  name="floor"
                  disabled={searchHandle}
                  placeholder="Floor number *"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  value={addressState.floor}
                  required
                />
                {errState.floorErr && <small className="form-text text-danger">{errState.floorErr}</small>}
              </div>
              <div className="toggleButton d-flex" style={{ width: "100%", flexDirection: "column", gap: "10px" }}>
                <div>
                  <label className="toggleHeading" htmlFor="checkedInput">
                    Do you have a lift in your building? *
                  </label>
                </div>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="lift"
                      id="liftYes"
                      value="yes"
                      checked={addressState.lift}
                      onChange={handleChange}
                      disabled={searchHandle}
                    />
                    <label className="form-check-label" htmlFor="liftYes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="lift"
                      id="liftNo"
                      value="no"
                      checked={!addressState.lift}
                      onChange={handleChange}
                      disabled={searchHandle}
                    />
                    <label className="form-check-label" htmlFor="liftNo">
                      No
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="lift"
                      id="liftNotRequired"
                      value="not required"
                      checked={addressState.lift === null}
                      onChange={handleChange}
                      disabled={searchHandle}
                    />
                    <label className="form-check-label" htmlFor="liftNotRequired">
                      Not required
                    </label>
                  </div>
                </div>
              </div>
              {errState.liftErr && <p style={{ margin: "0px" }}>
                <small className="form-text text-danger">{errState.liftErr}</small>
              </p>}

              <div className={`master-form-group`}>
                <input
                  type="text"
                  name="city"
                  placeholder="City/District *"
                  className="form-input"
                  onChange={handleChange}
                  value={addressState.city}
                  disabled
                />
                {errState.cityErr && <small className="form-text text-danger">{errState.cityErr}</small>}
              </div>
              <div className={`master-form-group`}>
                <input
                  type="text"
                  name="state"
                  placeholder="State *"
                  className="form-input"
                  onChange={handleChange}
                  value={addressState.state}
                  disabled
                />
                {errState.stateErr && <small className="form-text text-danger">{errState.stateErr}</small>}
              </div>
              <div className={`master-form-group`}>
                <input
                  type="text"
                  name="postalCode"
                  placeholder="Postal Code *"
                  className="form-input"
                  onChange={handleChange}
                  value={addressState.postalCode}
                  disabled={searchHandle}
                />
                {errState.postalCodeErr && <small className="form-text text-danger">{errState.postalCodeErr}</small>}
              </div>
              <div className="addBox"></div>
            </div>
          </div>
        </div>
        <div className="master-form-group scheduleBox">
          <button onClick={handleSubmit} disabled={searchHandle} className="form_button">
            Continue
          </button>
        </div>
      </>
    </div>
  );
};

export default AddAddress;
