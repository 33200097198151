import CustomModal from 'common/CustomModal'
import React from 'react'

const SelectAddressModal = ({
    addNewAddressHandler,
    addressPopup,
    handleAddAddressHandler,
    handleModalClose,
    userAddress,
    handleValueChange2,
    isNotValidated2,
    setCurrentAddressIndex
}) => {
    // console.log(userAddress[0].formattedAddress.split(',').slice(2).join(','));
    return (
        <CustomModal
            show={addressPopup}
            onClose={handleModalClose}
            className="customPopup innerPopup"
        >
            <div className="modal_content form_wrapper innherLayout">
                <div className="master-form-group long">
                    <p>Select Address</p>
                    <div className="addBox">
                        <span onClick={addNewAddressHandler}>Add address</span>
                        {userAddress.length !== 0 ? (
                            <ul className="mid-1-check fixedHeight">
                                {userAddress.map(
                                    (
                                        { id, type,formattedAddress },
                                        index
                                    ) => (
                                        <li key={[id]} className="chkinput">
                                            <label htmlFor={[id]}>
                                                <input
                                                    type="radio"
                                                    id={[id]}
                                                    onChange={handleValueChange2}
                                                    name="type"
                                                    value={[id]}
                                                    onClick={() => setCurrentAddressIndex(index)}
                                                />
                                                <span>
                                                    <b>{type}</b>
                                                    {formattedAddress}
                                                </span>
                                            </label>
                                        </li>
                                    )
                                )}
                            </ul>
                        ) : (
                            <p className="addAddText">
                                You don't have an address added, please add an addresss to
                                continue!
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="master-form-group scheduleBox">
                <button
                    onClick={handleAddAddressHandler}
                    disabled={isNotValidated2}
                    className="form_button"
                >
                    PROCEED TO CHECKOUT
                </button>
            </div>
        </CustomModal>
    )
}

export default SelectAddressModal