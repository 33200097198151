/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import Confetti from 'react-confetti';
import * as moment from "moment";

import Navbar from 'common/Navbar'
import CouponAlert from 'components/CouponAlert/CouponAlert'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import CustomModal from 'common/CustomModal';
import Razorpay from "assets/images/razorpay.png";
import { useSelector } from 'react-redux';
import SuccessPayment from "assets/images/success.png"
import AddWallet from 'components/Modals/AddWallet';
import { AnalyseSkin } from 'components/AnalyseSkin';
import SharkTankBanner from 'containers/HomePage/SharkTankBanner';

const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
    });
};

const AddToCredits = () => {
    const [currentBalance, setCurrentBalance] = useState(0);
    const [credits, setCredits] = useState(0);
    const [showPayments, setShowPayments] = useState(false);
    const [addVendorId, setVendorId] = useState(null);
    const [paymentDone, setPaymentDone] = useState(false);
    const [signupPopup, setSignupPopup] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    const token = localStorage.getItem('token');
    const user = useSelector((state) => state.user);


    const handleCreditsSubmit = () => {
        setShowPayments(true);
    }

    const signupModaCloselHandler = () => {
        setSignupPopup(false);
    };

    const handleModalClose = () => {
        window.location.href = "/";
    }

    const signupModalHandler = () => {
        setSignupPopup(true);
    };

    const handleCreditsPayment = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/add-credit-vendor`,
                {
                    amount: parseInt(credits, 10),
                    paymentFrom: 'razorpay',
                    status: 'initiated',
                },
            )
            setVendorId(response.data.id)
        }
        catch (error) {
            console.log(error)
        }
    }

    const razorpay = async () => {
        const reponse1 = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/create-razorpay-order`,
            {
                amount: parseInt(credits, 10),
                vendorPaymentId: addVendorId
            })
        const options = {
            key_id: 'rzp_test_63uXsHrRF4yBqV',
            amount: credits * 100,
            currency: 'INR',
            name: 'Avataar Skincare',
            description: 'Payment for Premium Skincare Services',
            image: "https://partner-app-s3.s3.ap-south-1.amazonaws.com/logo+(3).png",
            order_id: reponse1.data.id,
            handler: async (response) => {
                localStorage.setItem('razorpayId', response.razorpay_order_id)
                const paymentData = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                };

                await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/payments/verify-payment`,
                    paymentData,
                );

                await axios.patch(
                    `${process.env.REACT_APP_BACKEND_URL}/payments/update-credit-vendor`,
                    {
                        "vendorCreditId": addVendorId,
                        "status": "success",
                        "paymentId": response.razorpay_payment_id,
                    }
                );

                const addAdvancePaymentCredits = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/payments/add-advance-payment`,
                    {
                        amount: parseInt(credits, 10),
                        modeOfPayment: "card",
                        dateOfPayment: moment(new Date()).format("YYYY-MM-DD"),
                        orderId: localStorage.getItem("razorpayId")
                    }
                );
                if (addAdvancePaymentCredits.data.addedPayment) {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/payments/get-advance-payment`)
                    setCurrentBalance(response.data.balance)
                    setPaymentDone(true);
                }
            },


            prefill: {
                name: user.name,
                email: '',

            },
            notes: {
                address: 'Avataar Skincare Services',
            },
            theme: {
                color: "#937940",
            },

        }

        const rzp = (window).Razorpay(options)
        rzp.open();
    }

    useEffect(async () => {
        if (token) {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/payments/get-advance-payment`)
            setCurrentBalance(response.data.balance);
        }
        else {
            signupModalHandler();
        }
    }, [])

    useEffect(() => {
        if (addVendorId) {
            loadRazorpayScript('https://checkout.razorpay.com/v1/checkout.js');
            razorpay();
        }
    }, [addVendorId])

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <CouponAlert />
            <Navbar onSignup={signupPopup} onSignupClose={signupModaCloselHandler} />
            <div className="wrapper HomeWrapper">
                <SharkTankBanner isMobileView={isMobileView} />

                <AnalyseSkin />
            </div>

            <CustomModal
                show={showPayments}
                onClose={handleModalClose}
                className="customPopup innerPopup authPopup"
            >
                <div style={{ padding: "32px", height: "300px" }}>
                    <ul>
                        <li style={{ fontWeight: "bold" }}>Select Payment Gateway</li>
                    </ul>
                    <ul>
                        <li style={{
                            cursor: "pointer", border: "1px dashed #cba05b", padding: "8px 16px", borderRadius: "10px",
                            background: "rgba(255,255,255,1)",
                            background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(203,173,91,0.6) 100%)"
                        }} onClick={handleCreditsPayment}>
                            <img src={Razorpay} className="razpayImage" loading="lazy" />
                            Razorpay
                        </li>
                    </ul>

                </div>
            </CustomModal>

            <CustomModal
                show={paymentDone}
                onClose={handleModalClose}
                className="customPopup innerPopup"
            >
                <Confetti
                    width={800}
                    height={900}
                    numberOfPieces={400}
                    gravity={0.2}
                    recycle={false}
                    colors={["#FAFAD2", "#EEE8AA", "#F0E68C", "#DAA520", "#FFD700", "#FFA500", "#FF8C00"]}
                />
                <div className="modal_content form_wrapper backgroundModal">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "32px" }}>
                        <span>
                            <img src={SuccessPayment} alt="schedule complete" className="successPaymentImage" loading="lazy" />
                        </span>
                        <p className="paymentSuccessfull">Payment Successful</p>
                        <p className="creditsSuccessText">You have Successfully added {credits} credits</p>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", border: "1px solid black", borderRadius: "20px", padding: "16px", flexWrap: "wrap", gap: "20px" }}>
                            <div>
                                <p className="creditsSuccessText" style={{ fontWeight: "bolder" }}>OrderId</p>
                                <p className="creditsSuccessText">{localStorage.getItem('razorpayId')}</p>
                            </div>
                            <div>
                                <p className="creditsSuccessText" style={{ fontWeight: "bolder" }}>Date</p>
                                <p className="creditsSuccessText">{moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSZ")
                                    .utcOffset("+05:30")
                                    .format(`ddd, MMM DD • hh:mm A`)}</p>
                            </div>
                        </div>
                    </div>
                    <button
                        style={{ background: "#65CB98", border: "none", marginTop: "24px", padding: "8px 16px ", borderRadius: "10px", color: "#fff" }} onClick={handleModalClose}>
                        Continue
                    </button>
                </div>

            </CustomModal>
            <AddWallet
                credits={credits}
                currentBalance={currentBalance}
                handleCreditsSubmit={handleCreditsSubmit}
                handleModalClose={handleModalClose}
                setCredits={setCredits}
                showCredits={token}
            />
        </>
    )
}

export default AddToCredits