import React, { useState } from 'react';
import DynamicButton from 'common/DynamicButton';
import avataarNewLogo from '../assets/images/avataarNewLogo.svg';
import axios from 'axios';
import sharkTankPopupSvg from "../assets/images/sharkTankPopup.svg"
const HomeScreenPopup = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        city: "",
        detail: "",
        email: "",
        detail2: ""
    });
    const [showCode, setShowCode] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            "name": formData.name,
            "mobile": formData.phone,
            "email": "",
            "CITY": formData.city,
            "detail2": "Home-page-modal"
        }
        try {
            const response = await axios.post('https://api.neodove.com/integration/custom/1e27389a-886e-47cf-b983-d1c027d68eb4/leads', data);
            if (response.status >= 200 && response.status < 300) {
                localStorage.setItem('isPopupSeen', true);
                // onClose();
                setShowCode(true)
            } else {
                console.error('Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handleCloseModal = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    return (
        <div className="modal fade show d-block" role="dialog" style={{ backdropFilter: 'blur(5px)' }} onClick={handleCloseModal}>
            <div className="modal-dialog modal-lg modal-dialog-centered" style={{ padding: '36px' }} role="document">
                <div className="modal-content" style={{ boxShadow: 'none', border: 'none' }}>
                    <div className="modal-body p-0">
                        <div className="row m-0" style={{ transform: "rotate(0deg)" }}>
                            <div className="col-lg-6 order-2 order-lg-1 p-3 p-lg-3" style={{ background: '#9CC6D9', borderRadius: "8px", transform: "rotate(0deg)" }}>
                                <div className="d-flex justify-content-center align-items-center mb-3 flex-column">
                                    <img src={avataarNewLogo} alt="Logo" className="img-fluid d-block d-lg-none" style={{ maxWidth: '35%', marginBottom: '10px' }} loading="lazy"/>
                                    <img src={avataarNewLogo} alt="Logo" className="img-fluid d-none d-lg-block" style={{ maxWidth: '60%', marginBottom: '10px' }} loading="lazy" />
                                    <div className="font-weight-bold text-black">
                                        Take a bite from the <strong className="fw-bold">Shark Tank Deal </strong>
                                        Get additional 15% off on Cart Value.
                                        <br />
                                        {/* <span className="d-block text-center font-weight-bold" style={{ color: '#000', fontWeight: 'bolder' }}>Use code SHARKTANK15</span> */}
                                    </div>
                                </div>
                                {!showCode ? <form onSubmit={handleSubmit}>
                                    <div>
                                        <div className='form-group'>
                                            <label htmlFor="name">Name</label>
                                            <input
                                                name="name"
                                                type="text"
                                                required
                                                pattern="^(?!\s).*"
                                                title="First character cannot be a blank space"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className='form-control mb-3 rounded-3'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="phone">Phone Number</label>
                                            <input
                                                name="phone"
                                                type="tel"
                                                pattern="[5-9][0-9]{9}"
                                                required
                                                minLength="10"
                                                maxLength="10"
                                                title="Please enter a 10-digit phone number starting with a digit between 5 and 9"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                className='form-control mb-3 rounded-3'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor="city">City</label>
                                            <input
                                                name="city"
                                                type="text"
                                                required
                                                pattern="^(?!\s).*"
                                                title="First character cannot be a blank space"
                                                value={formData.city}
                                                onChange={handleChange}
                                                className='form-control mb-3 rounded-3'
                                            />
                                        </div>
                                    </div>
                                    <DynamicButton label={'Reveal Code'} />
                                </form> :
                                    <div className="font-weight-bold text-black p-1 p-3 mb-5">
                                        <br />
                                        <br />
                                        <storng className="d-block text-center fw-bold  mb-10">Use code SHARKTANK15</storng>
                                        <DynamicButton label={'Close'} onClick={handleCloseModal} />
                                    </div>
                                }
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 p-0" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', overflow: "hidden", }}>
                                <div className="d-lg-none" style={{ backgroundImage: `url(${sharkTankPopupSvg})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '110%', height: '25vh', }} />
                                <div style={{ backgroundImage: `url(${sharkTankPopupSvg})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '140%' }} />
                            </div>
                            <div className="d-flex justify-content-end order-3 position-fixed top-0 end-0 p-1">
                                <button type="button" className="btn btn-dark text-black font-weight-bold rounded-2" style={{ backgroundColor: 'white' }} onClick={onClose}>
                                    <strong>&times;</strong>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default HomeScreenPopup;