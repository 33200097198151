import React, { useState, useEffect } from "react";
import Navbar from "common/Navbar";
import PageLayout from "common/PageLayout";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import * as profileThunks from "../../redux/thunks/profileThunk";
import { useDispatch, useSelector } from "react-redux";
import NoDataAvailable from "components/NoDataAvailable";

const Notifications = () => {
  let navigate = useNavigate();
  const [haveNotifications, setHaveNotifications] = useState(false);

  const { notificationsList } = useSelector((state) => state.entities.profile);

  let dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(profileThunks.getNotifications());
    }, // eslint-disable-next-line
    []
  );

  console.log(notificationsList?.length);

  useEffect(
    () => {
      if (notificationsList?.length !== 0) {
        setHaveNotifications(true);
      }
    }, // eslint-disable-next-line
    [notificationsList]
  );

  const readNotificationHandler = (id) => {
    dispatch(profileThunks.readNotification(id));
    // window.location.reload()
  };

  return (
    <>
      <Navbar />
      <div className="wrapper notificationsWrapper">
        <span onClick={() => navigate(-1)} className="backArrow">
          <span>
            <Icon path={mdiArrowLeft} size={1} />
          </span>
          Notifications
        </span>
        {/* {cart?.category?.products?.length > 0 ? ( */}
        {haveNotifications ? (
          <PageLayout>
            <>
              <div className="notifications">
                <ul>
                  {notificationsList?.map(
                    ({
                      id,
                      notificationTitle,
                      notificationText,
                      deeplink,
                      isRead,
                    }) => (
                      <li
                        key={id}
                        className={` ${isRead === false ? "active" : ""}`}
                        onClick={() => {
                          readNotificationHandler(id);
                        }}
                      >
                        <a href={deeplink}>
                          <h5>{notificationTitle}</h5>
                          <span>{notificationText}</span>
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </>
          </PageLayout>
        ) : (
          <NoDataAvailable
            heading="You don't have notification(s) for now!"
            subHeading="Check out treatments that best suit your skin needs." />
        )}
      </div>
    </>
  );
};

export default Notifications;
