import React, { memo } from 'react'

const Certifications = () => (
    <div className="wrapperContainer mb-5 mt-10">
        <h2 className="heading" id="certificateHeading">
            Globally Certified
        </h2>
        <div className="row">
            <div className="col-sm-6 col-md-5 d-flex justify-content-center flex-column align-items-center">
                <img
                    className="certiImg1"
                    src="https://avataarv2content.s3.ap-south-1.amazonaws.com/images/FDA-Logo.png"
                    alt="certificate"
                    loading="lazy"
                />
                <span className="certText">US FDA Approved</span>
            </div>
            <div className="col-sm-6 col-md-5 d-flex justify-content-center flex-column align-items-center">
                <img
                    className="certiImg2"
                    src="https://avataarv2content.s3.ap-south-1.amazonaws.com/images/Certification-Europe-Logo-Colour-RGB-Screen-Transparent-Background+1+(1).png"
                    alt="certificate"
                    loading="lazy"
                    
                />
                <span className="certText">Certification Europe</span>
            </div>
            <div className="col-sm-12 col-md-2 d-flex flex-column justify-content-center  align-items-center">
                <img
                    className="certiImg3"
                    src="https://avataarv2content.s3.ap-south-1.amazonaws.com/images/pngwing+1.png"
                    alt="certificate"
                    loading="lazy"
                />
                <span className="certText">ISO Certified</span>
            </div>
        </div>
    </div>
)


export default memo(Certifications)