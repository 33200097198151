
import React from 'react'
import scheduleCompleteIcon from "assets/images/scheduleCompleteIcon.svg";

import CustomModal from 'common/CustomModal'

const ConsultationConfirmation = () => {
    const scheduleComplete = localStorage.getItem('consultation')
    const handleModalClose = () => {
        localStorage.removeItem('consultation')
        window.location.reload()

    }
    return (
        <CustomModal
            show={scheduleComplete}
            onClose={handleModalClose}
            className="customPopup"
        >
            <div className="modal_content form_wrapper">
                <div className="innerContent" >
                    <span>
                        <img src={scheduleCompleteIcon} alt="schedule complete" loading="lazy"/>
                    </span>
                    <p>Thank you for your interest. Our team will reach out to you at the earliest.</p>
                </div>
            </div>
            <div className="master-form-group scheduleBox">
                <button className="okayBtn" onClick={handleModalClose}>
                    Continue
                </button>
            </div>
        </CustomModal>
    )
}

export default ConsultationConfirmation