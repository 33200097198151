import React from 'react';
import './ViewInvoice.css';
import { useLocation } from 'react-router-dom';
import logo from "../assets/images/avataarLogo.png";
import { Table } from 'react-bootstrap';

const ViewInvoice = () => {
    const location = useLocation();
    const stateInvoiceNumber =
    {
        "Delhi": "07AAQCM5470M1ZR",
        "Uttar Pradesh": '09AAQCM5470M1ZN',
        "Haryana": "06AAQCM5470M1ZT"
    }
    const bookingDetails = location?.state?.bookingDetails;
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const netAmount = Math.round((parseInt(bookingDetails?.total) / 1.18) - parseInt(bookingDetails?.discount));
    const totalAmount = Math.round(netAmount + (netAmount * 0.18))
    const userId = localStorage.getItem("loggedIn");

    const isLocal = bookingDetails?.addressPostalCode?.slice(0, 2) === '11';
    const taxRate = isLocal ? "9%" : "18%";
    const taxAmount = isLocal ? Math.round(netAmount * 0.09) : Math.round(netAmount * 0.18);
    return (
        <div className="invoice-container">
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img className="footerLogo" src={logo} alt="Avataar Logo" />
                <h2 className='text-center'>Misya Beauty Tech Pvt. Ltd.</h2>
            </div>
            <br />
            <h4 className='text-center'>Tax Invoice</h4>
            <br />
            <Table striped bordered hover>
                <tbody>
                    <tr className="mb-3">
                        <th>Client Name </th>
                        <td>{user?.name}</td>
                    </tr>
                    <tr className="mb-3">
                        <th>Company Name </th>
                        <td>Misya Beauty Tech Pvt. Ltd.</td>
                    </tr>
                    <tr className="mb-3">
                        <th>Company Address </th>
                        <td>
                            Building No 60.Wazir Nagar Bhishma Pitamah
                            Marg Near Royal Enfiled Showroom
                            Kotla Mubarakpur, New Delhi 110003
                        </td>
                    </tr>
                    <tr className="mb-3">
                        <th>Company GST No </th>
                        <td>{stateInvoiceNumber[bookingDetails?.addressState]}</td>
                    </tr>
                    <tr className="mb-3">
                        <th>Invoice No.</th>
                        <td>AVT{userId}{bookingDetails.sessionId}{bookingDetails.sessionScheduleId}</td>
                    </tr>
                    <tr className="mb-3">
                        <th>Invoice Date</th>
                        <td>{new Date().toLocaleDateString('en-GB')}</td>
                    </tr>
                    <tr className="mb-3">
                        <th>Appointment At</th>
                        <td>{new Date(bookingDetails?.appointmentAt).toLocaleString('en-GB', { timeZone: 'UTC', day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}</td>
                    </tr>
                    <tr className="mb-3">
                        <th>Appointment Address</th>
                        <td>{bookingDetails?.formattedAddress}</td>
                    </tr>
                    <tr className="mb-3">
                        <th>City & Session</th>
                        <td>{bookingDetails?.addressCity},{bookingDetails?.addressState}</td>
                    </tr>
                    <tr>
                        <th>Service Name</th>
                        <td>{bookingDetails?.productName}</td>
                    </tr>
                </tbody>
            </Table>

            <Table bordered>
                <thead>
                    <tr>
                        <th className="border">Item & Quantity</th>
                        <th className="border" >HSN/SAC</th>
                        <th className="border">Net Amount</th>
                        {isLocal && <th className="border">CGST(Rate)</th>}
                        {isLocal && <th className="border">CGST(INR)</th>}
                        <th className="border">{`${isLocal ? 'SGST (Rate)' : 'IGST (Rate)'}`}</th>
                        <th className="border">{`${isLocal ? 'SGST (INR)' : 'IGST (INR)'}`}</th>
                        <th className="border">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border">{`${bookingDetails?.productName} - ${bookingDetails?.count}`}</td>
                        <td className="border">99972</td>
                        <td className="border">{netAmount}</td>
                        {isLocal && <td className="border">9%</td>}
                        {isLocal && <td className="border">{Math.round(netAmount * 0.09)}</td>}
                        <td className="border">{taxRate}</td>
                        <td className="border">{taxAmount}</td>
                        <td className="border">{totalAmount}</td>
                    </tr>
                </tbody>
            </Table>
            <br />
            <p>Misya Beauty Tech Pvt Ltd</p>
            <p style={{ display: "flex", justifyContent: 'flex-end' }}> Authorised Signatory</p>
        </div>
    );
};

export default ViewInvoice;
