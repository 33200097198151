import CustomModal from 'common/CustomModal'
import React from 'react'

const AddNewAddress = ({
    addNewAddressPopup,
    handleModalClose,
    formErrors,
    formValues6,
    handleChange3,
    handleTouch,
    fetchAddressText,
    getCurrentLocationHandler,
    map_pin,
    currentLocation,
    autoAddressBtn,
    handleAddSubmit,
    isNotValidated2,
    isNotValidated6
}) => {
  return (
    <CustomModal
        show={addNewAddressPopup}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <>
          <div className="modal_content form_wrapper">
            <div className={`master-form-group4 long `}>
              <h4>ADD ADDRESS</h4>
            </div>
            <div className="address-form">
              <p className="locationPicker" onClick={getCurrentLocationHandler}>
                <span>
                  <img src={map_pin} alt="map_pin" loading="lazy"/>
                </span>
                Get my current location
              </p>
              {fetchAddressText && (
                <span>please wait while we are fetching your location...</span>
              )}

              <div className="addressBox">
                <p>Address</p>
                <div
                  className={`master-form-group ${formErrors.address ? "master-input-error" : ""
                    }`}
                >
                  <input
                    type="text"
                    name="address"
                    placeholder="Address(House No, Building/Flat) *"
                    className={`form-input ${formErrors.address ? "master-input-error" : ""
                      }`}
                    onChange={handleChange3}
                    value={formValues6.address}
                    onBlur={handleTouch}
                  />
                  {formErrors.address && (
                    <div className="message-error">
                      <span className="text-danger1">{formErrors.address}</span>
                    </div>
                  )}
                </div>
                <div
                  className={`master-form-group ${formErrors.town ? "master-input-error" : ""
                    }`}
                >
                  <input
                    type="text"
                    name="town"
                    placeholder="Street, Area *"
                    className={`form-input ${formErrors.town ? "master-input-error" : ""
                      }`}
                    onChange={handleChange3}
                    value={
                      formValues6.town || currentLocation?.data?.data?.area
                    }
                    onBlur={handleTouch}
                  />
                  {formErrors.town && (
                    <div className="message-error">
                      <span className="text-danger1">{formErrors.town}</span>
                    </div>
                  )}
                </div>

                <div
                  className={`master-form-group ${formErrors.pincode ? "master-input-error" : ""
                    }`}
                >
                  <input
                    type="number"
                    name="pincode"
                    placeholder="Pincode *"
                    className={`form-input ${formErrors.pincode ? "master-input-error" : ""
                      }`}
                    onChange={handleChange3}
                    value={
                      formValues6.pincode ||
                      currentLocation?.data?.data?.postal_code
                    }
                    onBlur={handleTouch}
                  />
                  {formErrors.pincode && (
                    <div className="message-error">
                      <span className="text-danger1">{formErrors.pincode}</span>
                    </div>
                  )}
                </div>

                <div className="formWrapper">
                  <div
                    className={`master-form-group ${formErrors.city ? "master-input-error" : ""
                      }`}
                  >
                    <input
                      type="text"
                      name="city"
                      placeholder="City/District *"
                      className={`form-input ${formErrors.city ? "master-input-error" : ""
                        }`}
                      onChange={handleChange3}
                      value={
                        formValues6.city || currentLocation?.data?.data?.city
                      }
                      onBlur={handleTouch}
                    />
                    {formErrors.city && (
                      <div className="message-error">
                        <span className="text-danger1">{formErrors.city}</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`master-form-group ${formErrors.state ? "master-input-error" : ""
                      }`}
                  >
                    <input
                      type="text"
                      name="state"
                      placeholder="State *"
                      className={`form-input ${formErrors.state ? "master-input-error" : ""
                        }`}
                      onChange={handleChange3}
                      value={
                        formValues6.state || currentLocation?.data?.data?.state
                      }
                      onBlur={handleTouch}
                    />
                    {formErrors.state && (
                      <div className="message-error">
                        <span className="text-danger1">{formErrors.state}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="master-form-group scheduleBox">
            {autoAddressBtn ? (
              <button
                onClick={handleAddSubmit}
                disabled={isNotValidated2}
                className="form_button"
              >
                SAVE ADDRESS
              </button>
            ) : (
              <button
                onClick={handleAddSubmit}
                disabled={isNotValidated6}
                className="form_button"
              >
                SAVE ADDRESS
              </button>
            )}
          </div>
        </>
      </CustomModal>
  )
}

export default AddNewAddress