import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const headers = {
  accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Headers": "*",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "*",
};

export const token = localStorage.getItem("token");

export const put = (url, data = {}) => {
  return axios
    .put(`${apiUrl}/${url}`, data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { data } = error.response;
      if (data.statusCode === 401) {
        localStorage.clear();
      }
      return {
        error: true,
        message: data.messages || data.message,
        code: data?.statusCode,
      };
    });
};

export const patch = (url, data = {}) => {
  return axios
    .patch(`${apiUrl}/${url}`, data, { headers })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      const { data } = error?.response;
      if (data.statusCode === 401) {
        localStorage.clear();
      }
      return {
        error: true,
        message: data?.messages || data?.message,
        code: data?.statusCode,
      };
    });
};

export const post = (url, data = {}) => {
  return axios
    .post(`${apiUrl}/${url}`, data, { headers })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      const { data } = error?.response;
      if (data.statusCode === 401) {
        localStorage.clear();
      }
      return {
        error: true,
        message: data?.message,
        code: data?.statusCode,
      };
    });
};

export const get = (url, params = {}) => {
  return axios
    .get(`${apiUrl}/${url}`, { params, headers })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      const { data } = error?.response;
      if (data.statusCode === 401) {
        localStorage.clear();
      }
      return {
        error: true,
        message: data?.message || data?.message,
        code: data?.statusCode,
      };
    });
};

export const remove = (url, data) => {
  return axios
    .delete(`${apiUrl}/${url}`, { data: data }, { headers })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      const { data } = error?.response;
      if (data.statusCode === 401) {
        localStorage.clear();
      }
      return {
        error: true,
        message: data?.message || data?.message,
        code: data?.statusCode,
      };
    });
};

export function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}
