import React from "react";

const PageLayout = ({ children }) => {
  return (
    <>
      <div className="pageLayout">{children}</div>
    </>
  );
};

export default PageLayout;
