import React, { useState, useEffect } from "react";
import Navbar from "common/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";
import PageLayout from "common/PageLayout";
import shoppingcart from "assets/images/shoppingcart.svg";
import CustomModal from "common/CustomModal";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-responsive-tabs/styles.css";
import appstoreicon from "../assets/images/app-store-icon.webp";
import googleplayicon from "../assets/images/play-store-icon.webp";
import * as profileThunk from "../redux/thunks/profileThunk";
import { useDispatch, useSelector } from "react-redux";
import * as productThunk from "../redux/thunks/productThunk";
import * as registerFormUiAction from "../redux/reducer/ui/registerUserUiDux";
import warning from "../assets/images/warning.svg";

import MetaTags from "./MetaTags";
import CouponAlert from "./CouponAlert/CouponAlert";
import { IpAddressTracking } from "utils/helper";
import axios from "axios";

const CategoryListing = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [cartPopUp, setCartPopUp] = useState(false);
  const [signupPopup, setSignupPopup] = useState(false);
  const { error } = useSelector((state) => state.ui.product);
  const params = useParams();
  const [prodObj, setProdObj] = useState({
    productId: "",
    sessionId: "",
    date: "",
    time: "",
  });

  const slug = params.slug;


  useEffect(
    () => {
      dispatch(productThunk.getProductCollectionList(slug));
      dispatch(productThunk.getCollectionDetailsBySlug(slug));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const token1 = localStorage.getItem("token");

  const {
    // productList,
    productDetails,
    productCollectionList,
    productSessionList,
    // productSlots,
    collectionDetails,
  } = useSelector((state) => state.entities.product);


  const productVideo = productDetails.video?.pop();

  const cartHanlder = async (id) => {
    setProdObj((prevState) => ({
      ...prevState,
      productId: id,
    }));

    // call sessesion api handler
    getSessionList(id);
    localStorage.setItem("prodID", id);
    // } else {
    //   console.log("opne login modal");
    // }
  };

  const getSessionList = (id) => {
    dispatch(productThunk.getProductSessionById(id))
      .then((res) => {
        setCartPopUp(true);
        // setDetailPopUp(false);
      })
      .catch((error) => {
        console.log(error, "get session error ");
      });
  };

  const handleModalClose = () => {
    // setDetailPopUp(false);
    setCartPopUp(false);
    // setCalenderPopUp(false);
    setIsActive(true);
  };


  const detailOverviewHandler = (slug) => {

    navigate(`/laser-treatments/${slug}`);
    window.scroll(0, 0);
    generateSlider();
  };

  const faqs1 = productDetails?.faq;


  let collectionName = null;
  if (productCollectionList) {
    productCollectionList.forEach((data) => {
      collectionName = data.collectionTitle;
    });
  }

  useEffect(() => {
    const bars = document.querySelectorAll(".progress-bar__outter-line");
    const COUNT_STARS = 12;
    bars.forEach((el) => {
      let rating = el.dataset.rating;
      let percent = (100 * rating) / COUNT_STARS;
      el.querySelector(".progress-bar__inner-line").style.width = `${percent}%`;
    });
    // ScrollReveal().reveal(".headline");
  }, []);
  const handleAddToCart = async (updatedProdObj) => {
    const res = await IpAddressTracking();

    localStorage.setItem("prodObj", JSON.stringify(updatedProdObj));
    let productId = {
      productId: updatedProdObj.productId,
      sessionId: updatedProdObj.sessionId,
      ipInfo: res ? res : null,
    };
    dispatch(profileThunk.addToCart(productId))
      .then((res) => {
        navigate("/cart");
      })
      .catch((error) => {
        console.log(error, "product add error");
      });
  };

  const signupModalHandler = () => {
    setSignupPopup(true);
  };

  const signupModaCloselHandler = () => {
    setSignupPopup(false);
  };

  const closeErrorHandler = () => {
    dispatch(registerFormUiAction.clearUi());
  };

  const generateSlider = () => {
    const renderedItems = productDetails?.image?.map((item) => (
      <div key={item} className="slider_content">
        <img className="img-responsive" src={item} alt="model 1" loading="lazy" />
      </div>
    ));

    return renderedItems;
  };

  const [isActive, setIsActive] = useState(true);

  const sessionSelectionHandler = () => {
    if (prodObj !== null) {
      setIsActive(false);
    }
  };

  const { productCollection } = useSelector((state) => state.entities.product);
  const userGender = localStorage.getItem("userGender");

  useEffect(
    () => {
      if (userGender === "male") {
        dispatch(productThunk.getProductCollectionByGender("male"));
      } else {
        dispatch(productThunk.getProductCollectionByGender("female"));
      }

      // generateSliderOne()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [stickyClass] = useState("relative");


  const generateSliderOne = (value) => {
    const filteredCollection =
      productCollection &&
      productCollection.filter((item) => item.type === value);

    const collectionDetail = filteredCollection[0]?.collectionDetail;
    const renderedItems = collectionDetail?.map((item) => (
      <li
        key={item.id}
        onClick={() => {
          localStorage.setItem("bestProductId", item?.id);
          localStorage.setItem("collectionName", item?.title);
          navigate(`/advanced-laser-treatments/${item.slug}`);
          window.location.reload();
          window.scroll(0, 0);
        }}
      >
        <img className="img-responsive" src={item.image} alt="model 1" loading="lazy" />
      </li>
    ));

    return renderedItems;
  };

  return (
    <>
      {error ? (
        <div className="customErrorMessage">
          <p>
            <img src={warning} alt="warning" loading="lazy" />
            Error! {error}.
          </p>
          <span onClick={closeErrorHandler}>x</span>
        </div>
      ) : (
        ""
      )}
      <MetaTags
        metaTitle={collectionDetails?.metaTitle}
        metaDescription={collectionDetails?.metaDescription}
      />
      <CouponAlert />
      <CustomModal
        show={cartPopUp}
        onClose={handleModalClose}
        className="customPopup innerPopup"
      >
        <div className="modal_content form_wrapper">
          <div className="master-form-group  addBox session">
            <p>Select Session</p>

            <ul className="mid-1-check ">
              {productSessionList.map((item) => (
                <li
                  key={item.id}
                  className="chkinput"
                  onClick={async () => {
                    setProdObj((prevState) => ({
                      ...prevState,
                      sessionId: item.sessionId,
                    }));
                    const updatedProdObj = {
                      ...prodObj,
                      sessionId: item.sessionId,
                    };
                    sessionSelectionHandler();
                    await handleAddToCart(updatedProdObj);
                  }}
                >
                  <label htmlFor={item.id}>
                    <input type="radio" id={item.id} name="session" value="" />
                    {item.count > 1 ? (
                      <span>
                        <b>{item.count} sessions</b>₹
                        {Math.ceil(item.perSession)} per session
                      </span>
                    ) : (
                      <span>
                        <b>{item.count} session</b>₹{Math.ceil(item.perSession)}{" "}
                        per session
                      </span>
                    )}
                  </label>
                  <div className="couponConatiner">
                    <div className="price">
                      <p> ₹{item.finalPrice}</p>
                    </div>

                    <div className="coupon">
                      {item.discount > 0 ? (
                        <>
                          <p>₹ Save {item.discount}%</p>
                          <p>
                            <s> ₹{item.price}</s>
                          </p>
                        </>
                      ) : (
                        <p className="no-discount"></p>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="master-form-group scheduleBox ">
            <button
              className={`form_button ${isActive ? "disabled" : ""}`}
              onClick={handleAddToCart}
            >
              SELECT SESSION
            </button>
          </div> */}
        </div>
      </CustomModal>
      <Navbar onSignup={signupPopup} onSignupClose={signupModaCloselHandler} />

      <div className="wrapper cartListingPage">
        <h1>{collectionName ? collectionName : ""}</h1>
        <h6>
          <span onClick={() => navigate("/")}>Home</span> <span>/</span>{" "}
          <span onClick={() => window.location.reload()}>
            {collectionName ? collectionName : ""}
          </span>
        </h6>
        <PageLayout>
          <div className="left">
            <div className="cartItemDetails listStyles">
              <ul>
                {productCollectionList?.map(
                  ({
                    name,
                    description,
                    id,
                    image,
                    rating,
                    price,
                    duration,
                    slug,
                  }) => (
                    <li key={id}>
                      <div className="description itemListingPage">
                        <div className="info">
                          <h5>{name}</h5>
                          <span>
                            <Icon path={mdiStar} size={0.8} /> {rating}
                          </span>
                          <p>
                            ₹ {price}
                            <span> • {duration} mins</span>{" "}
                          </p>
                        </div>

                        <span
                          dangerouslySetInnerHTML={{ __html: description }}
                        ></span>

                        <div className="action">
                          <span
                            onClick={async () => {
                              const res = await IpAddressTracking();
                              await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/save-ip-details`, {
                                type: "product-view",
                                ipInfo: res ? res : null,
                              })
                              detailOverviewHandler(slug);
                              localStorage.setItem(
                                "productDetailHeading",
                                productDetails.name
                              );

                            }}
                          >
                            View Details
                          </span>
                          {token1 ? (
                            <span onClick={() => cartHanlder(id)}>
                              Add To Cart
                            </span>
                          ) : (
                            <span onClick={signupModalHandler}>
                              Add To Cart
                            </span>
                          )}
                        </div>
                      </div>
                      <div className=" category">
                        <span>
                          <img
                            src={image}
                            alt="hair reduction"
                            loading="lazy"
                          />
                        </span>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>

          <div className="right">
            <div className={`discounts  ${stickyClass}  `}>
              <ul>
                <li className="active">
                  <div className="left">
                    <img
                      src={shoppingcart}
                      alt="shopping cart icon"
                      className="cartImg"
                      loading="lazy"
                    />
                    <h5>
                      Introductory App pricing
                      <br />
                      <span>
                        Flat 20% discount on 6 sessions or more when you
                        download the Avataar Skincare App.
                        <br />
                      </span>

                      <div className="app-icons-container">
                        <a
                          href="https://apps.apple.com/in/app/avataar-skincare/id6468362044"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="d-inline-block"
                          onclick="return gtag_report_conversion('https://apps.apple.com/in/app/avataar-skincare/id6468362044');"
                        >
                          <img
                            src={appstoreicon}
                            alt="App Store"
                            class="mw-70 image-class"
                            loading="lazy"
                          />
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.avataarskin.app"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="d-inline-block"
                          onclick="return gtag_report_conversion('https://play.google.com/store/apps/details?id=com.avataarskin.app');"
                        >
                          <img
                            src={googleplayicon}
                            alt="Google Store"
                            class="mw-70 icon-class image-class"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </h5>
                  </div>
                </li>
              </ul>
              <h4 className="heading">Best Package Deals</h4>
              <ul className="listpagePackages">
                {generateSliderOne("slider_grid_view")}
              </ul>
            </div>
          </div>
        </PageLayout>
        {slug.includes("body-contouring-for-women") &&
          <>
            <p style={{ width: "100%", padding: "0px 100px", marginBottom: "0px" }} className="seoSubTitle">FACTS:</p>
            <p style={{ padding: "24px 100px", marginBottom: "0px" }}>
              Achieve your desired silhouette with Avataar Skin's specialized body contouring treatments for women. Our advanced laser procedures are tailored to sculpt, tone, and enhance your figure effectively. Whether you aim to trim stubborn areas or define your curves, trust Avataar Skin to deliver personalized care and visible results. Book your consultation today and discover the transformative benefits of our body contouring services.
            </p>
          </>}
        {slug.includes("anti-ageing-for-women") &&
          <><p style={{ width: "100%", padding: "0px 100px", marginBottom: "0px" }} className="seoSubTitle">FACTS:</p><p style={{ padding: "24px 100px", marginBottom: "0px" }}>
            Experience Avataar Skin's advanced anti-aging treatments, meticulously designed for women seeking to rejuvenate and restore youthful vibrancy. Our specialized laser procedures are tailored to target fine lines, wrinkles, and age spots with precision, stimulating collagen production and improving skin elasticity. Each session offers personalized care, ensuring visible results and a revitalized, firmer complexion. Trust Avataar Skin's expertise in anti-aging solutions to enhance your confidence and embrace a smoother, more radiant appearance. Discover our comprehensive approach to timeless beauty today.
          </p></>}
        {slug.includes("tan-removal-for-women") &&
          <><p style={{ width: "100%", padding: "0px 100px", marginBottom: "0px" }} className="seoSubTitle">FACTS:</p><p style={{ padding: "24px 100px", marginBottom: "0px" }}>
            Achieve flawless skin with Avataar Skin's advanced laser treatment for tan removal, tailored specifically for women. Our procedures effectively diminish tan lines, revealing a smooth and even complexion. Whether you're seeking the best facial for tan removal or exploring laser tan removal options near you, trust Avataar Skin for reliable results. Experience the confidence of radiant skin with our expert services. Discover affordable laser tan removal costs in India and book your session today for a rejuvenated appearance.
          </p></>}
        {slug.includes("pigmentation-reduction-for-women") &&
          <><p style={{ width: "100%", padding: "0px 100px", marginBottom: "0px" }} className="seoSubTitle">FACTS:</p><p style={{ padding: "24px 100px", marginBottom: "0px" }}>
            Achieve brighter, even-toned skin with Avataar Skin's advanced pigmentation reduction treatments for women. Our specialized laser procedures target and reduce dark spots effectively, revealing a radiant complexion. Whether you're in Delhi or searching for pigmentation treatment near you, trust Avataar Skin for expert care and visible results. Experience the confidence of flawless skin with our tailored approach to pigmentation reduction. Schedule your consultation today and embrace a luminous, rejuvenated appearance.
          </p></>}
        {slug.includes("acne-control-for-women") &&
          <><p style={{ width: "100%", padding: "0px 100px", marginBottom: "0px" }} className="seoSubTitle">FACTS:</p><p style={{ padding: "24px 100px", marginBottom: "0px" }}>
            Discover effective acne control with Avataar Skin's advanced laser treatments tailored for women. Our targeted therapies near you include acne laser treatment and chemical peels for acne scars at home. Trust Avataar Skin for personalized care and visible results, reducing breakouts and acne scars effectively. Whether you're concerned about acne scar removal costs or seeking nearby acne treatments, book your consultation today for clear, confident skin.
          </p></>}
        {slug.includes("ultra-hydration-for-women") &&
          <><p style={{ width: "100%", padding: "0px 100px", marginBottom: "0px" }} className="seoSubTitle">FACTS:</p><p style={{ padding: "24px 100px", marginBottom: "0px" }}>
            Revitalize your skin with Avataar Skin's Ultra Hydration treatment, designed exclusively for women seeking deep hydration and rejuvenation. Our state-of-the-art Hydrafacial, renowned as the best in Delhi, combines cutting-edge technology with expert care to deliver unmatched results. This treatment not only hydrates your skin but also addresses multiple skin concerns, leaving you with a radiant and youthful complexion.<br /><br />
            For those looking for convenience, we offer the Hydrafacial at home. Enjoy the luxury of professional skincare without leaving your house. Our team ensures a seamless and comfortable experience, bringing the spa to you.<br /><br />
            In addition to our Hydrafacial, we provide the best laser treatment for facial hair removal. Say goodbye to unwanted facial hair with our precise and effective laser technology. Whether you're searching for "facial hair removal laser treatment near me" or "facial laser treatment near me," Avataar Skin is your trusted solution.<br /><br />
            Our Ultra Hydration treatment not only enhances your skin's moisture levels but also promotes a smoother, clearer complexion. Experience the transformative power of advanced laser treatments and premium skincare with Avataar Skin. Book your session today and discover the secret to glowing, flawless skin.
          </p></>}
      </div>
    </>
  );
};

export default CategoryListing;
