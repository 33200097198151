import { useEffect, useCallback } from "react";
import "./styles.css";

const EmojiRating = ({ handleTherapistRatingChange }) => {
  const handleFaceHover = (face) => {
    const hint = document.querySelector(".faces-hint");
    const hintText =
      face?.getAttribute("data-hint") ||
      hint?.getAttribute("data-default-hint");
    hint.innerText = hintText;
  }

  const handleFacesHover = useCallback((e) => {
    const face = e.target.parentElement.querySelector(".face");
    handleFaceHover(face);
  }, []);

  const handleRateInputChange = (e) => {
    handleTherapistRatingChange(e)
    document.querySelectorAll(".rate-input").forEach((input) => {
      input.parentElement.querySelector(".face").classList.add("grayscale");
    });
    e.target.parentElement.querySelector(".face").classList.remove("grayscale");
  }

  useEffect(() => {
    document.querySelectorAll(".rate-input").forEach((input) => {
      input.addEventListener("click", handleRateInputChange);
    });
    document
      .querySelector(".feedback-faces")
      .addEventListener("mousemove", handleFacesHover);

    return () => {
      document.querySelectorAll(".rate-input").forEach((input) => {
        input.removeEventListener("click", handleRateInputChange);
        document
          .querySelector(".feedback-faces")
          .addEventListener("mousemove", handleFacesHover);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFacesHover]);

  return (
    <div className="d-flex flex-row justify-content-center mt-2">
      <div className="feedback-wrapper">
        <div className="feedback-content">
          <div className="feedback-faces">
            <div className="row">
              <div className="emoji">
                <div >
                  <div className="face-wrapper firstChild">
                    <div
                      className="face-counter invisible"
                    ></div>
                    <input
                      className="rate-input hidden"
                      id="rate-0"
                      type="radio"
                      name="rating"
                      value="1"
                    />
                    <label
                      className="face grayscale face-wtf"
                      htmlFor="rate-0"
                      data-hint="Disappointing"
                    >
                      <div className="eyes-wrapper">
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                      </div>
                      <div className="mouth-wrapper">
                        <div className="mouth"></div>
                      </div>
                    </label>
                  </div>
                </div>
                <p className="text-center ratingText">Disappointing</p>
              </div>
              <div className="emoji">
                <div >
                  <div className="face-wrapper">
                    <div
                      className="face-counter invisible"
                    ></div>
                    <input
                      className="rate-input hidden"
                      id="rate-1"
                      type="radio"
                      name="rating"
                      value="2"
                    />
                    <label
                      className="face grayscale face-disappointed"
                      htmlFor="rate-1"
                      data-hint="Mediocre"
                    >
                      <div className="eyes-wrapper">
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                      </div>
                      <div className="mouth-wrapper">
                        <div className="mouth"></div>
                      </div>
                    </label>
                  </div>
                </div>
                <p className="text-center ratingText">Mediocre</p>
              </div>
              <div className="emoji">
                <div >
                  <div className="face-wrapper">
                    <div
                      className="face-counter invisible"
                    ></div>
                    <input
                      className="rate-input hidden"
                      id="rate-2"
                      type="radio"
                      name="rating"
                      value="3"
                    />
                    <label
                      className="face grayscale face-sad"
                      htmlFor="rate-2"
                      data-hint="Acceptable"
                    >
                      <div className="eyes-wrapper">
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                      </div>
                      <div className="mouth-wrapper">
                        <div className="mouth"></div>
                      </div>
                    </label>
                  </div>
                </div>
                <p className="text-center ratingText">Acceptable</p>
              </div>
              <div className="emoji">
                <div >
                  <div className="face-wrapper">
                    <div
                      className="face-counter invisible"
                    ></div>
                    <input
                      className="rate-input hidden"
                      id="rate-3"
                      type="radio"
                      name="rating"
                      value="4"
                    />
                    <label
                      className="face grayscale face-happy"
                      htmlFor="rate-3"
                      data-hint="Impressive"
                    >
                      <div className="eyes-wrapper">
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                      </div>
                      <div className="mouth-wrapper">
                        <div className="mouth"></div>
                      </div>
                    </label>
                  </div>
                </div>
                <p className="text-center ratingText">Impressive</p>
              </div>
              <div className="emoji">
                <div >
                  <div className="face-wrapper">
                    <div
                      className="face-counter invisible"
                    ></div>
                    <input
                      className="rate-input hidden"
                      id="rate-4"
                      type="radio"
                      name="rating"
                      value="5"
                    />
                    <label
                      className="face grayscale face-love"
                      htmlFor="rate-4"
                      data-hint="Delighted"
                    >
                      <div className="eyes-wrapper">
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                        <div className="eye">
                          <div className="pupil">
                            <div className="eyelid"></div>
                          </div>
                        </div>
                      </div>
                      <div className="mouth-wrapper">
                        <div className="mouth"></div>
                      </div>
                    </label>
                  </div>
                </div>
                <p className="text-center ratingText">Delighted</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EmojiRating;