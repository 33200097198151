import React from 'react'
import { useNavigate } from 'react-router-dom';

import emptyBox from "assets/images/emptyBox.svg";

const NoDataAvailable = ({heading,subHeading}) => {
    const navigate= useNavigate();
    return (
        <div className="left conditionOther">
            <div className="noDataBox">
                <span>
                    <img src={emptyBox} alt="no data" loading="lazy"/>
                </span>
                <h5>{heading}</h5>
                <span>{subHeading}</span>
                <span
                    className="btn"
                    onClick={() => {
                        navigate("/");
                        window.scroll(0, 0);
                    }}
                >
                    EXPLORE
                </span>
            </div>
        </div>
    )
}

export default NoDataAvailable
