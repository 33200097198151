import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// import logo from "../assets/images/avataarLogo.png"; 
// import logo from "../assets/images/avataarNewLogo.svg"
import logo from "../assets/images/logoInvoice.png";
export const generateAndDownloadInvoice = (payment) => {
    const stateInvoiceNumber =
    {
        "Delhi": "07AAQCM5470M1ZR",
        "Uttar Pradesh": "09AAQCM5470M1ZN",
        "Haryana": "06AAQCM5470M1ZT",
        "Karnataka": "29AAQCM5470M1ZL",
        "Hyderabad": "36AAQCM5470M1ZQ",
        "Maharashtra": "27AAQCM5470M1ZP",
        "Madhya Pradesh": "23AAQCM5470M1ZX",
        "Punjab": "03AAQCM5470M1ZZ",
        "Gujarat":"07AAQCM5470M1ZR",
        "Chandigarh":"03AAQCM5470M1ZZ"
    }

    const netAmount = Math.round((parseInt(payment?.total) / 1.18) - parseInt(payment?.discount));
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getFullYear()}`;

    // Create a hidden container for invoice rendering
    const container = document.createElement('div');
    container.style.width = '210mm';
    container.style.padding = '20px';
    container.style.background = '#fff';
    container.innerHTML = `
    <div style="font-family: Verdana, sans-serif; color: #333; font-size: 14px; padding: 20px; border: 20px solid gray; border-top: 10px solid gray; border-bottom: 10px solid gray;">
        <h3 style="text-align: center; margin-top: 20px;">Original Tax Invoice</h3>
        <div style="display: flex; align-items: center;padding:5px">
        <div style="flex-shrink: 0;">
            <img src="${logo}" alt="Company Logo" style="height: 50px; width: 50px; border-radius: 50%; object-fit: cover;"/>
        </div>
    </div>
        <div style="display: flex; justify-content: space-between; padding: 10px; border-bottom: 2px solid gray;">
        <div>
        <strong style="font-size: 1.3em;">Misya Beauty Tech Pvt. Ltd.</strong>
        <p>Date:${payment?.paymentDate ? new Date(payment.paymentDate).toLocaleDateString() : 'N/A'}</p>
        <p style="margin: 0;">2, Shambhu Dyal Marg, Okhla Phase III.</p>
        <span style="margin: 0;">Delhi 110065</span>
    </div>
        </div>
        <!-- Customer Details -->
        <div style="margin-top: 20px; padding-bottom: 20px;">
            <strong style="font-size: 1.3em;">Customer Details</strong>
            <p style="display: flex; padding:10px; margin: 0; padding-bottom: 10px; border-bottom: 2px solid gray; border-top: 2px solid grey;">
                <span>Name</span> <strong>: ${payment?.userName}</strong>
            </p>
            <p style="display: flex; padding:10px; margin: 0; padding-bottom: 10px; border-bottom: 2px solid grey;">
                <span>Mobile</span> <strong>: ${payment?.phoneNumber}</strong>
            </p>
            <p style="display: flex; padding:10px; margin: 0; padding-bottom: 10px; border-bottom: 2px solid grey;">
            <span>Address:</span> <strong>${payment.address !== null ? payment.address : ''}</strong>
            </p>
        </div> 
        <!-- Cost Summary -->
        <div style="margin-top: 20px; padding-bottom: 20px; border-bottom: 2px solid black;">
            <strong style="font-size: 1.3em;">Wallet Summary</strong>
            <p style="display: flex; justify-content: space-between;">
                <span>Wallet Amount:</span> <strong>₹${payment?.amount}</strong>
            </p>
        </div>

        <!-- Payment Details -->
        <div style="margin-top: 20px; padding-bottom: 20px; border-bottom: 2px solid gray;">
            <strong style="font-size: 1.3em;">Payment Details</strong>
            <p style="display: flex; justify-content: space-between;">
                <span>Payment Method:</span> <strong>${payment?.modeOfPayment}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Transaction ID:</span> <strong>${payment?.orderId}</strong>
            </p>
            <p style="display: flex; justify-content: space-between;">
                <span>Transaction Date:</span> <strong>${payment?.paymentDate ? new Date(payment.paymentDate).toLocaleDateString() : 'N/A'}</strong>
            </p>
        </div>
    </div>
`;

    // Append the container to the body
    document.body.appendChild(container);


    html2canvas(container, { scale: 4 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.95);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; 
        const imgHeight = (canvas.height * imgWidth) / canvas.width; 
        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, null, 'FAST');
        pdf.save(`invoice.pdf`);
        document.body.removeChild(container);
    });

    // Generate the PDF
    // html2canvas(container, { scale: 3 }).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
    //     pdf.save(`invoice.pdf`);

    //     // Clean up
    //     document.body.removeChild(container);
    // });
};



