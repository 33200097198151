import { createAction, createReducer } from "redux-act";
export const setFormData = createAction("RESET/SET_FORM_DATA");
export const resetFormData = createAction("RESET/RESET_FORM_DATA");
export const setToken = createAction("RESET/SET_TOKEN");

const handlers = {
  [setFormData](state, payload) {
    return {
      ...state,
      formData: payload,
    };
  },
  [resetFormData](state) {
    return {
      ...state,
      formData: {},
    };
  },
  [setToken](state, payload) {
    return {
      ...state,
      token: payload,
    };
  },
};

export default createReducer(handlers, {});
