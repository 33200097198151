import React from 'react'
import SuccessPayment from "assets/images/success.png"
import Confetti from 'react-confetti';
import CustomModal from 'common/CustomModal'

import * as moment from "moment";

const AdvancePaymentSuccess = ({
    paymentDone, handleModalClose, credits
}) => {
    return (
        <CustomModal
            show={paymentDone}
            onClose={handleModalClose}
            className="customPopup innerPopup"
        >
            <Confetti
                width={800}
                height={900}
                numberOfPieces={400}
                gravity={0.2}
                recycle={false}
                colors={["#FAFAD2", "#EEE8AA", "#F0E68C", "#DAA520", "#FFD700", "#FFA500", "#FF8C00"]}
            />
            <div className="modal_content form_wrapper backgroundModal">
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "32px" }}>
                    <span>
                        <img src={SuccessPayment} alt="schedule complete" className="successPaymentImage" loading="lazy" />
                    </span>
                    <p className="paymentSuccessfull">Payment Successful</p>
                    <p className="creditsSuccessText">You have Successfully added {credits} credits</p>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", border: "1px solid black", borderRadius: "20px", padding: "16px", flexWrap: "wrap", gap: "20px" }}>
                        <div>
                            <p className="creditsSuccessText" style={{ fontWeight: "bolder" }}>OrderId</p>
                            <p className="creditsSuccessText">{localStorage.getItem('razorpayId')}</p>
                        </div>
                        <div>
                            <p className="creditsSuccessText" style={{ fontWeight: "bolder" }}>Date</p>
                            <p className="creditsSuccessText">{moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSZ")
                                .utcOffset("+05:30")
                                .format(`ddd, MMM DD • hh:mm A`)}</p>
                        </div>
                    </div>
                </div>
                <button
                    style={{ background: "#65CB98", border: "none", marginTop: "24px", padding: "8px 16px ", borderRadius: "10px", color: "#fff" }} onClick={handleModalClose}>
                    Continue
                </button>
            </div>

        </CustomModal>
    )
}

export default AdvancePaymentSuccess