import React, { memo } from 'react'

const Conditions = ({ checkConditionData, collectionId5, generateConditionsSlider }) => (
    <div
        className="wrapperContainer moreServices conditions"
        id="conditions"
    >
        <h2 className="heading">Conditions</h2>

        {checkConditionData(collectionId5)
            ? generateConditionsSlider(collectionId5, "eight")
            : ""}
    </div>
)


export default memo(Conditions)