/* eslint-disable eqeqeq */
import React, { memo, useEffect, useMemo, useState } from 'react'
import * as moment from "moment";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as profileThunk from "redux/thunks/profileThunk";
import * as productThunk from "redux/thunks/productThunk";
import Confetti from 'react-confetti';
import SuccessPayment from "assets/images/success.png"

import * as feedbackThunk from "redux/thunks/feedbackThunk";
import Completed from 'components/Bookings/Completed';
import CustomModal from 'common/CustomModal';
import axios from 'axios';
import SessionScheduleSlotModal from 'components/Modals/SessionScheduleSlotModal';
import { IpAddressTracking, addHoursToTime, converDateFormat, converTimeFormat, subtractHoursFromTime } from 'utils/helper';

const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
    });
};

const BookingDetails = ({
    setCalenderPopUp,
    setIsReschedule,
    scheduleHandler,
    rateUs,
    setRateUs,
    activeOption,
    activeOption1,
    setActiveOption,
    setActiveOption1,
    setShowSelectTimeBox,
    showSelectTimeBox,
    slotdisabled,
    slotsDate,
    isReschedule,
    setSlotDisabled,
    setSuccessModal,
    setPackage,
    setSelectedRemarks,
    isPackage
}) => {
    const navigate = useNavigate();
    const { myBookings } = useSelector((state) => state.entities.profile);
    const handleRateUs = () => {
        setRateUs(true);
    }
    const [res, setRes] = useState();

    useEffect(async () => {
        const res = await IpAddressTracking();
        setRes(res);

    }, [])

    const [PackageModal, setPackageModal] = useState(false);
    const [packageProducts, setProducts] = useState([])
    const [success, setSuccess] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState([]);
    const [rules, setRules] = useState([])
    const [disabledProducts, setDisabledProducts] = useState([]);
    const [slotsModal, setSlotsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [packageRateUs, setPackageRateUs] = useState(false);
    const [packageSessionScheduleId, setPackageSessionScheduleId] = useState();
    const [productList, setProductList] = useState([])
    const [rulesProductName, setRulesProductName] = useState([])
    const [productName, setProductName] = useState();
    const [therapistRating, setTherapistRating] = useState(null);
    const [recommendationRating, setRecommendationRating] = useState(0);
    const [count, setCount] = useState(0);
    const [paymentSuccessFul, setPaymentSuccessfull] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [orderData, setOrderData] = useState();
    const [showBreakup, setShowBreakUp] = useState(false);
    const [remarks, setRemarks] = useState(false);
    const [remarksData, setRemarksData] = useState([]);
    const [selectedReason, setSelectedReason] = useState(null);
    const [otherReason, setOtherReason] = useState('');
    const [isContinueDisabled, setIsContinueDisabled] = useState(true);

    const handleReasonChange = (item) => {
        setSelectedReason(item);
        setOtherReason('');
        setIsContinueDisabled(false);
    };

    const handleOtherReasonChange = (e) => {
        setOtherReason(e.target.value);
        setSelectedReason(null);
        setIsContinueDisabled(e.target.value.trim() === '');
    };

    const currenSessionId = localStorage.getItem("sessionScheduleId");
    const currentId = localStorage.getItem("sessionId");

    const memoizedMyBookings = useMemo(() => myBookings.filter((item) => currentId == item.sessionId), [currentId, myBookings]);

    const productName1 = packageRateUs ? "" : memoizedMyBookings[0]?.productName;
    const sessionScheduleId = memoizedMyBookings[0]?.sessionSchedule?.findIndex((item) => currenSessionId == item.id) + 1;

    let dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(profileThunk.getBookingDetails(currenSessionId));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const handleTherapistRatingChange = (e) => {
        setTherapistRating(parseInt(e.target.value, 10));
    };

    const handleRecommendationRatingChange = (e) => {
        setRecommendationRating(parseInt(e.target.value, 10));
    };

    const handleSubmit = () => {
        dispatch(
            feedbackThunk.saveUserRatings({
                sessionScheduleId: packageRateUs ? parseInt(packageSessionScheduleId, 10) : parseInt(currenSessionId, 10),
                partnerRating: therapistRating,
                sessionRating: recommendationRating,
                avataarRating: recommendationRating,
                feedback: "",
            })
        ).then((res) => {
            if (res.success) {
                setRateUs(false)
                setSuccess(true)
            }
        });
    }

    const handleModalClose = () => {
        window.location.reload();
    };

    const handleScheduleNow = async (products, packageId, city, long, lat) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/packages/get-rules/${packageId}`);
        localStorage.setItem("packageCity", city);
        localStorage.setItem("packageLong", long);
        localStorage.setItem("packageLat", lat);
        setRules(res.data[0].rules);
        setProducts(products)
        setPackageModal(true)
    }

    const updateDisabledProducts = async (selectedIds) => {
        let disabledIds = [];
        let removedIndices = [];
        rules?.map(rule => {
            const { filter, productId } = rule;
            if (selectedIds.includes(productId)) {
                filter?.forEach(f => disabledIds.push(f.id));
            }
        });

        let selectedArray = selectedProductId?.filter((value, index) => {
            if (disabledIds.includes(value)) {
                removedIndices.push(index);
                return false;
            }
            return true;
        });
        let selectedSessions = selectedProducts.filter((_, index) => !removedIndices.includes(index))
        setRulesProductName([])
        const res = productList.filter(item => disabledIds.includes(item.id)).map(item => item.name);
        setRulesProductName(res)
        setDisabledProducts(disabledIds);
        setSelectedProductId(selectedArray);
        setSelectedProducts(selectedSessions);

    }
    const handleSlotConfirmation = async () => {
        const slotSelectedTime = localStorage.getItem("selectedTime");
        console.log(slotSelectedTime)
        const slotSelectedDate = localStorage.getItem("selectedDate");
        const packageProductSessionid = localStorage.getItem("packageProductSessionsId");

        setLoading(true)

        if (isReschedule) {
            const city = localStorage.getItem("packageCity");
            const longitude = localStorage.getItem("packageLong");
            const latitude = localStorage.getItem("packageLat");
            const productId = localStorage.getItem("packageProductId")
            const previousDate = localStorage.getItem("packagePreviousDateTime").split("T")[0];
            const previousTimed = localStorage.getItem("packagePreviousDateTime");
            const previousTimes = new Date(previousTimed);
            const localTime = previousTimes.toLocaleTimeString();
            const [hours, minutes] = localTime.split(':').map(Number);
            const previousTime = `${hours}:${minutes}`;
            let apiUrl = `${process.env.REACT_APP_SCHEDULER_API}/allocate/reschedule-booking`;
            await axios.post(apiUrl, {
                productId: parseInt(productId, 10),
                sessionScheduleId: packageProductSessionid,
                city,
                ipInfo: res ? res : null,
                clientLat: latitude,
                clientLong: longitude,
                isClient: true,
                previousSlotDate: previousDate,
                previousSlotTime: {
                    startTime: previousTime,
                    endTime: addHoursToTime(previousTime, 0, 30),
                },
                newSlotDate: slotSelectedDate,
                newSlotTime: {
                    startTime: slotSelectedTime,
                    endTime: addHoursToTime(slotSelectedTime, 0, 30),
                },
            });
            // const packageSessions = localStorage.getItem("packageSessions");
            // const data = {
            //     id: parseInt(packageProductSessionid),
            //     status: 'PENDING'
            // };
            // const data1 = {
            //     id: parseInt(packageProductSessionid),
            //     appointmentDate:
            //         converDateFormat(slotSelectedDate) +
            //         " " +
            //         converTimeFormat(slotSelectedTime),
            // }
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders/update-schedule-status`, data)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders/update-schedule-date`, data1)
        }
        else {
            const data = {
                sessionIds: selectedProducts,
                appointmentDate:
                    converDateFormat(slotSelectedDate) +
                    " " +
                    converTimeFormat(slotSelectedTime),
                packageProductSessionId: parseInt(packageProductSessionid)
            };
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders/create-package-session-schedule`, data)
        }

        localStorage.setItem("packageProductSessionsId", null)
        localStorage.setItem("packageSessions", null);
        setLoading(false);
        setSlotsModal(false);
        setSuccessModal(true);
    }

    const handleDiscountForCustom = (price, disocunt) => {
        const originalPrice = parseInt(price);
        const discountPrice = parseInt(originalPrice * (parseInt(disocunt) / 100));
        return parseInt(originalPrice - discountPrice).toLocaleString("en-IN")
    }
    const showData = async (index) => {
        const bookingDetails = myBookings?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[index];
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/get-order-products/${bookingDetails.orderId}`);
        console.log(res.data.data)
        setOrderData(res.data.data)
        setShowOrder(true);
        localStorage.setItem('index', index);
    }
    const paymentGatewayHandler = async () => {
        const user = localStorage.getItem("user");
        const index = localStorage.getItem('index');

        loadRazorpayScript('https://checkout.razorpay.com/v1/checkout.js');
        const bookingDetails = myBookings?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[index]
        // if (!bookingDetails.packageId) {
        console.log("payment for one item")
        const data = {
            sessionId: bookingDetails?.sessionId,
            orderDetailId: bookingDetails?.orderDetailId,
            ipInfo: res ? res : null,
        };
        const orderResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/create-order`, {
            paymentItemId: bookingDetails.orderId,
        });
        const orderData = await orderResponse.data;

        const options = {
            key_id: 'rzp_test_63uXsHrRF4yBqV',
            // amount: bookingDetails.leftAmount * 100,
            amount: orderData.amount_due,
            currency: 'INR',
            name: 'Avataar Skincare',
            description: 'Payment for Premium Skincare Services',
            image: "https://partner-app-s3.s3.ap-south-1.amazonaws.com/logo+(3).png",
            order_id: orderData.id,
            handler: async (response) => {
                localStorage.setItem('razorpayId', response.razorpay_order_id)
                const paymentData = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                };

                const result = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/payments/verify-payment`,
                    paymentData,
                );

                localStorage.setItem("paymentSuccess", result.data.isVerified);
                const crmUpdateResponse = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/orders/crm-update-payment`,
                    {
                        "id": bookingDetails.orderId,
                        "status": "paid",
                        "rzpPaymentId": response.razorpay_payment_id,
                        "rzpOrderId": response.razorpay_order_id,
                        // "paidAmount": parseInt(bookingDetails.leftAmount, 10),
                        "paidAmount": orderData.amount_due / 100,
                        "modeOfPayment": "razorpay"
                    }
                );

                const crmMessage = crmUpdateResponse.data.message;
                if (crmMessage === "Payment Status Updated !") {
                    setPaymentSuccessfull(true)
                }
            },
            prefill: {
                name: user.name,
                email: '',

            },
            notes: {
                address: 'Avataar Skincare Services',
            },
            theme: {
                color: "#937940",
            },

        }
        const rzp = new (window).Razorpay(options)
        rzp.open();
        // }
        // else {

        // const dataForPackages = {
        //     discount: Number(bookingDetails.discount),
        //     couponDiscount: Number(bookingDetails.couponDiscount),
        //     packageAmount: Number(bookingDetails.packageAmount),
        //     orderDetailId: bookingDetails.orderDetailId,
        //     sessionId: bookingDetails.sessionScheduleId,
        //     ipInfo: res ? res : null,
        // }
        // dispatch(productThunk.getPaymentUrlForPackages(dataForPackages));
        // }
    };

    const handleRemarksSubmit = () => {
        const selectedId = selectedReason ? selectedReason.id : 'other';
        const response = selectedId === 'other' ? otherReason : selectedReason.id;
        console.log('Selected Response:', response);
        setSelectedRemarks(response);
        setCalenderPopUp(true);
    };

    useEffect(() => {
        updateDisabledProducts(selectedProductId);
    }, [count]);

    const sliderEl3 = document.querySelector(".slider");

    sliderEl3?.addEventListener("input", (event) => {
        const tempSliderValue = Number(event.target.value);
        const progress = (tempSliderValue * 10);

        sliderEl3.style.background = `linear-gradient(to right, #cbad5b ${progress}%, #ccc ${progress}%)`;

        sliderEl3.style.setProperty("--thumb-rotate", `${(tempSliderValue / 100) * 2160}deg`)
    })

    useEffect(async () => {
        const productList = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/list`);
        setProductList(productList.data);
    }, [])

    // const finalcouponDiscount

    const totalCouponDiscount = orderData?.products.reduce((sum, product) => {
        return sum + Number(product.couponDiscount);
    }, 0);

    useEffect(async () => {
        const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/remark-tags`);
        setRemarksData(data.data);
    }, [])


    return (
        <>
            <Completed
                handleModalClose={handleModalClose}
                handleRecommendationRatingChange={handleRecommendationRatingChange}
                handleSubmit={handleSubmit}
                handleTherapistRatingChange={handleTherapistRatingChange}
                productName1={productName1}
                rateUs={rateUs}
                recommendationRating={recommendationRating}
                sessionScheduleId={sessionScheduleId}
                success={success}
                therapistRating={therapistRating} />

            <CustomModal
                show={paymentSuccessFul}
                onClose={handleModalClose}
                className="customPopup innerPopup"
            >
                <Confetti
                    width={800}
                    height={900}
                    numberOfPieces={400}
                    gravity={0.2}
                    recycle={false}
                    colors={["#FAFAD2", "#EEE8AA", "#F0E68C", "#DAA520", "#FFD700", "#FFA500", "#FF8C00"]}
                />
                <div className="modal_content form_wrapper backgroundModal">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "32px" }}>
                        <span>
                            <img src={SuccessPayment} alt="schedule complete" className="successPaymentImage" loading="lazy" />
                        </span>
                        <p className="paymentSuccessfull">Payment Successful</p>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", border: "1px solid black", borderRadius: "20px", padding: "16px", flexWrap: "wrap", gap: "20px" }}>
                            <div>
                                <p className="creditsSuccessText" style={{ fontWeight: "bolder" }}>OrderId</p>
                                <p className="creditsSuccessText">{localStorage.getItem('razorpayId')}</p>
                            </div>
                            <div>
                                <p className="creditsSuccessText" style={{ fontWeight: "bolder" }}>Date</p>
                                <p className="creditsSuccessText">{moment(new Date(), "YYYY-MM-DDTHH:mm:ss.SSSZ")
                                    .utcOffset("+05:30")
                                    .format(`ddd, MMM DD • hh:mm A`)}</p>
                            </div>
                        </div>
                    </div>
                    <button
                        style={{ background: "#65CB98", border: "none", marginTop: "24px", padding: "8px 16px ", borderRadius: "10px", color: "#fff" }} onClick={() => window.location.reload()}>
                        Continue
                    </button>
                </div>

            </CustomModal>

            <CustomModal
                show={remarks}
                onClose={handleModalClose}
                className="customPopup innerPopup"
            >

                <div className="modal_content form_wrapper">
                    <div>
                        {remarksData.map((item) => (
                            <label key={item.id} style={{ display: 'block', marginBottom: '8px' }}>
                                <input
                                    type="radio"
                                    name="reason"
                                    value={item.id}
                                    onChange={() => handleReasonChange(item)}
                                    checked={selectedReason && selectedReason.id === item.id}
                                />
                                &nbsp;&nbsp;{item.tag}
                            </label>
                        ))}
                        <label style={{ display: 'block', marginBottom: '8px' }}>
                            <input
                                type="radio"
                                name="reason"
                                value="other"
                                onChange={() => handleReasonChange(null)}
                                checked={selectedReason === null}
                            />
                            &nbsp;&nbsp;Others
                        </label>
                        {selectedReason === null && (
                            <input
                                type="text"
                                placeholder="Please specify"
                                value={otherReason}
                                onChange={handleOtherReasonChange}
                                style={{ marginTop: '8px', padding: '8px', width: '100%' }}
                            />
                        )}
                    </div>
                    <button
                        style={{
                            background: isContinueDisabled ? '#ccc' : '#000',
                            border: 'none',
                            marginTop: '24px',
                            padding: '8px 16px',
                            borderRadius: '10px',
                            color: '#fff',
                            cursor: isContinueDisabled ? 'not-allowed' : 'pointer'
                        }}
                        disabled={isContinueDisabled}
                        onClick={handleRemarksSubmit}
                    >
                        Continue
                    </button>
                </div>

            </CustomModal>

            <CustomModal
                show={showOrder}
                onClose={handleModalClose}
                className="customPopup innerPopup"
            >
                <div className="modal_content form_wrapper backgroundModal">
                    <h5>Order summary</h5>
                    <hr />
                    {orderData?.products.map((item) =>
                        <div style={{ marginBottom: "16px" }}>
                            <p className='productPrice'>{item.productName}</p>
                            <p className='productSummaryPrice'>Product Price: ₹ {parseInt(item.price).toLocaleString("en-in")}</p>
                            <p className='productSummaryPrice'>Product Discounted Price: ₹ {parseInt(item.productTotal).toLocaleString("en-in")}</p>
                        </div>
                    )}
                    <p style={{ textAlign: "right", color: "grey", marginTop: "32px" }}>Total Order Amount: ₹ {orderData?.totalOrderPrice.toLocaleString("en-in")}</p>
                    <p style={{ textAlign: "right", color: "grey" }}>Total Order Discount: ₹ {orderData?.totalOrderDiscount.toLocaleString("en-in")} <span style={{ cursor: "pointer" }} onClick={() => setShowBreakUp(!showBreakup)}>{showBreakup ? "🔼" : "🔽"}</span></p>
                    {showBreakup &&
                        <>
                            <p className='productSummaryPrice' style={{ textAlign: "right", marginTop: "-10px" }}>( - ) Total Product Discount: ₹ {(orderData?.totalOrderDiscount - totalCouponDiscount).toLocaleString("en-in")}</p>
                            <p className='productSummaryPrice' style={{ textAlign: "right" }}>( - ) Coupon discount: ₹ {(parseInt(totalCouponDiscount)).toLocaleString("en-in")}</p>
                        </>}

                    <p style={{ textAlign: "right", color: "grey" }}>( - ) Credits Used: ₹ {parseInt(orderData?.products[0].walletAmount).toLocaleString("en-in")}</p>
                    <p style={{ textAlign: "right", color: "grey" }}>Total Amount Paid: ₹ {parseInt(orderData?.products[0].amountPaid).toLocaleString("en-in")}</p>
                    <p style={{ textAlign: "right", color: "grey" }}>Total Payable Amount: ₹ {parseInt(orderData?.products[0].leftAmount).toLocaleString("en-in")}</p>
                    <button type="button" onClick={() => paymentGatewayHandler()} style={{
                        background: "#047858",
                        padding: "6.4px 0px",
                        textAlign: "center",
                        color: "white",
                        borderRadius: "10px",
                        border: "0"
                    }}>PAY FOR THE ORDER</button>
                </div>

            </CustomModal>

            <SessionScheduleSlotModal
                calenderPopUp={slotsModal}
                handleModalClose={handleModalClose}
                slotsDate={slotsDate}
                activeOption={activeOption}
                setActiveOption={setActiveOption}
                setShowSelectTimeBox={setShowSelectTimeBox}
                activeOption1={activeOption1}
                setActiveOption1={setActiveOption1}
                setSlotDisabled={setSlotDisabled}
                showSelectTimeBox={showSelectTimeBox}
                slotdisabled={slotdisabled}
                handleSlotConfirmation={handleSlotConfirmation}
                isPackage={isPackage}
                isLoading={loading}
            />

            <CustomModal
                show={PackageModal}
                onClose={handleModalClose}
                className="customPopup innerPopup"
            >
                <div className="modal_content form_wrapper">
                    <h3 style={{
                        color: "#cbad5b",
                        borderBottom: "1px solid grey",
                        marginBottom: "32px",
                        fontSize: "20px"
                    }}>SELECT PRODUCT</h3>
                    {packageProducts.map((item, index) => {
                        const prod = localStorage.getItem("scheduleNowIndex");
                        const isScheduled = myBookings[prod].sessionSchedule.some(schedule =>
                            schedule.sessionId === item.sessionId && (schedule.status === "SCHEDULED" || schedule.status === "CANCELLED")
                        );

                        // Disable condition: product is already scheduled or sessionCount equals productSessionCount
                        const isDisabled = productName || isScheduled ||
                            (myBookings[prod].products.filter(a => a.productName === item.productName)[0]?.sessionCount ===
                                myBookings[prod].productSessionCount.filter(a => a.name === item.productName)[0]?.count);
                        return (
                            <div key={index}>
                                <input
                                    type="checkbox"
                                    id={item.productId}
                                    name={item.productName}
                                    disabled={isDisabled}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedProducts([...selectedProducts, item.sessionId])
                                            setSelectedProductId([...selectedProductId, item.productId])
                                            localStorage.setItem('packageProductId', [...selectedProductId, item.productId])

                                            setProductName(item.productName)
                                            setCount(count + 1);
                                        }
                                        else {
                                            const filterProducts = selectedProducts.filter((session) => session != item.sessionId);
                                            const filterProductsId = selectedProductId.filter((session) => session != item.productId);
                                            localStorage.setItem('selectedSessions', filterProducts)

                                            setSelectedProducts(filterProducts);
                                            setSelectedProductId(filterProductsId)
                                            setCount(count - 1);

                                        }
                                    }} />
                                <b>&nbsp;&nbsp;{item.productName} - {item.gender}</b>
                            </div>)
                    }
                    )}
                    {/* <p>
                        {rulesProductName.length > 0 && <p style={{ fontWeight: "bolder", marginTop: "10px", marginBottom: "5px" }} >INSTRUCTIONS</p>}
                        {rulesProductName.length > 0 && <p style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px" }}>You Cannot add below products with {productName}:</p>}
                        <p style={{ display: "flex", flexDirection: "column" }}>
                            {rulesProductName.map((item, index) => <p style={{ marginBottom: "0px" }}>{index + 1}.{item}</p>)}
                        </p>
                    </p> */}
                    {/* Conditionally render the "SCHEDULE SESSION" button */}
                    {packageProducts.some(item => {
                        const prod = localStorage.getItem("scheduleNowIndex");
                        const isScheduled = myBookings[prod].sessionSchedule.some(schedule =>
                            schedule.sessionId === item.sessionId && (schedule.status === "SCHEDULED" || schedule.status === "CANCELLED")
                        );
                        return !isScheduled;
                    }) ? (
                        <button
                            type='button'
                            style={{
                                backgroundColor: "black",
                                color: "white",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                width: "max-content",
                                fontSize: "14px",
                                marginTop: "16px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                setSlotsModal(true);
                                const city = localStorage.getItem("packageCity");
                                const longitude = localStorage.getItem("packageLong");
                                const latitude = localStorage.getItem("packageLat");
                                const selectedProduct = localStorage.getItem("packageProductId");

                                scheduleHandler({ productId: selectedProduct, city, longitude, latitude });
                            }}
                        >
                            SCHEDULE SESSION
                        </button>
                    ) : <p style={{ color: "red", marginTop: "10px" }}>Please complete your previous session of the products.</p>}
                </div>
            </CustomModal>

            {myBookings?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(
                ({
                    productName,
                    sessionId,
                    price,
                    orderId,
                    total,
                    image,
                    description,
                    sessionSchedule,
                    count,
                    productId,
                    city,
                    leftAmount,
                    longitude,
                    latitude,
                    packageId,
                    packagePrice,
                    packageName,
                    products,
                    packageImage,
                    discount,
                    packageProductSessionsId,
                    productSessionCount,

                }, index) => {
                    return (
                        <>
                            {packageId &&
                                <li key={[sessionId]} id={[sessionId]} style={{ display: "flex", flexDirection: "column" }}>

                                    <div className="description itemListingPage">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>
                                                    {packageName}
                                                </div>
                                                <div className="info">
                                                    <h5>{productName}</h5>
                                                    {parseInt(price) !== 0 && <p style={{ margin: 0 }}>
                                                        Price - ₹{parseInt(packagePrice).toLocaleString("en-In")}
                                                        <span></span>{" "}
                                                    </p>}

                                                    {parseInt(price) !== 0 && <p style={{ margin: 0 }}>
                                                        <b>Amount Payable - ₹{parseInt(price).toLocaleString("en-IN")}</b>
                                                        <span></span>{" "}
                                                    </p>}

                                                    {parseInt(price) === 0 && <p style={{ margin: 0 }}>
                                                        <b>Price- ₹{parseInt(products.reduce((total, p) => total + parseInt(p.productFinalPrice), 0)).toLocaleString("en-In")}</b>
                                                        <span></span>{" "}
                                                    </p>}

                                                    {parseInt(price) === 0 && <p style={{ margin: 0 }}>
                                                        <b>Amount Payable - ₹{handleDiscountForCustom(parseInt(products.reduce((total, p) => total + parseInt(p.productFinalPrice), 0)), discount)}</b>
                                                        <span></span>{" "}
                                                    </p>}

                                                </div>
                                                <p>Total number of Sessions Taken: {productSessionCount.reduce((sum, item) => { return sum + item.count }, 0)}/{products.reduce((sum, item) => { return sum + item.sessionCount }, 0)}</p>
                                                <div style={{ marginTop: "16px", display: "flex", flexDirection: "column" }}>
                                                    {products.sort((a, b) => a.productName.localeCompare(b.productName)).map((item) => <span>• {item.productName} - {item.gender}: {productSessionCount.filter((prod => prod.name === item.productName))[0]?.count}/{item.sessionCount}</span>)}</div>
                                            </div>
                                            <span style={{
                                                width: "150px",
                                                height: "150px",
                                            }}>
                                                <img
                                                    src={packageImage}
                                                    alt={packageName}
                                                    style={{
                                                        borderRadius: "8px",
                                                        height: "120px",
                                                        width: "120px",
                                                    }}
                                                    loading="lazy"
                                                />
                                            </span>
                                        </div>
                                        {(productSessionCount.reduce((sum, item) => { return sum + item.count }, 0) < products.reduce((sum, item) => { return sum + item.sessionCount }, 0)) && <span style={{
                                            backgroundColor: "black",
                                            color: "white",
                                            borderRadius: "8px",
                                            padding: "8px 16px",
                                            width: "max-content",
                                            fontSize: "14px",
                                            marginTop: "16px",
                                            cursor: "pointer"
                                        }}
                                            onClick={() => {
                                                handleScheduleNow(products, packageId, city, longitude, latitude)
                                                localStorage.setItem("scheduleNowIndex", index);
                                                // eslint-disable-next-line no-undef
                                                localStorage.setItem("packageProductSessionsId", packageProductSessionsId)
                                                localStorage.setItem("package", true)
                                                setPackage(true)
                                            }}
                                        >SCHEDULE NOW</span>}
                                        {parseInt(leftAmount, 10) != 0 && <button
                                            className="bd5"
                                            onClick={() => showData(index)}
                                            style={{ backgroundColor: "#047858", color: "#fff", border: "none", borderRadius: "10px", padding: "6.4px 16px", marginTop: "16px", width: "140px" }}

                                        >
                                            <span>PAY NOW</span>
                                        </button>}
                                        {parseInt(leftAmount, 10) === 0 && <button
                                            className="bd5"
                                            disabled
                                            style={{ background: "#fff", color: "#000", border: "1px solid #000", borderRadius: "10px", padding: "6.4px 16px", marginTop: "16px", width: "100px" }}

                                        >
                                            <span>PAID</span>
                                        </button>}

                                        {productSessionCount.reduce((sum, item) => { return sum + item.count }, 0) === products.reduce((sum, item) => { return sum + item.sessionCount }, 0) && sessionSchedule[sessionSchedule.length - 1].status !== "COMPLETED" &&
                                            <span style={{
                                                backgroundColor: "rgba(4,120,88)",
                                                color: "white",
                                                borderRadius: "8px",
                                                padding: "8px 16px",
                                                width: "max-content",
                                                fontSize: "14px",
                                                marginTop: "16px",
                                                cursor: "pointer"
                                            }}
                                            >COMPLETED</span>}
                                        {sessionSchedule.length !== 0 && sessionSchedule[sessionSchedule.length - 1].status !== "COMPLETED" &&
                                            <div style={{ display: "flex", gap: "20px", overflow: "scroll" }}>
                                                <div style={{ border: sessionSchedule[sessionSchedule.length - 1].status === "CANCELLED" ? "2px solid #C61111" : "2px solid black", borderRadius: "20px", display: "flex", padding: "12px", width: "max-content", marginTop: "20px" }}>
                                                    <span style={{
                                                        width: "150px",
                                                        height: "150px",
                                                    }}>
                                                        <img
                                                            src={packageImage}
                                                            alt={packageName}
                                                            style={{
                                                                borderRadius: "8px",
                                                                width: "100px",
                                                                height: "100px",
                                                            }}
                                                            loading="lazy"
                                                        />
                                                    </span>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div style={{ display: 'flex', flexDirection: "column", width: "250px" }}>{sessionSchedule[sessionSchedule.length - 1]?.productsArray?.map((item) =>
                                                            <span style={{ color: sessionSchedule[sessionSchedule.length - 1].status === "CANCELLED" ? "#C61111" : "black" }}>• {item.productName}</span>
                                                        )}
                                                        </div>
                                                        {sessionSchedule.length !== 0 && <p>
                                                            <span style={{ color: sessionSchedule[sessionSchedule.length - 1].status === "CANCELLED" ? "#C61111" : "black" }}>
                                                                {moment(
                                                                    sessionSchedule[sessionSchedule.length - 1]?.appointmentAt,
                                                                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                                                )
                                                                    .utcOffset("+00:00")
                                                                    .format(
                                                                        `ddd, MMM DD • hh:mm A`
                                                                    )}
                                                            </span>
                                                        </p>}
                                                        <span style={{ color: sessionSchedule[sessionSchedule.length - 1].status === "CANCELLED" ? "#C61111" : "black" }}>{sessionSchedule[sessionSchedule.length - 1]?.status}</span>


                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                            {sessionSchedule[sessionSchedule.length - 1]?.status !== "COMPLETED" && sessionSchedule[sessionSchedule.length - 1]?.status !== "SESSION_START" && <button
                                                                type="button"
                                                                className="btn btn-dark"
                                                                style={{
                                                                    height: "2rem",
                                                                    width: "6rem",
                                                                    padding: "0px",
                                                                    fontSize: "0.9rem",
                                                                    marginRight: "0.4rem",
                                                                    marginTop: "10px",
                                                                }}
                                                                onClick={async () => {
                                                                    setSlotsModal(true)
                                                                    setIsReschedule(true);
                                                                    localStorage.setItem("packageCity", city);
                                                                    localStorage.setItem("packageLong", longitude);
                                                                    localStorage.setItem("packageLat", latitude);

                                                                    localStorage.setItem("packageProductId", sessionSchedule[sessionSchedule.length - 1]?.productsArray[0]?.productId)
                                                                    localStorage.setItem("packagePreviousDateTime", sessionSchedule[sessionSchedule.length - 1]?.appointmentAt)

                                                                    localStorage.setItem("packageProductSessionsId", sessionSchedule[sessionSchedule.length - 1].id)
                                                                    localStorage.setItem("packageSessions", sessionSchedule[sessionSchedule.length - 1].status)
                                                                    // scheduleHandler({ productId: sessionSchedule[sessionSchedule.length - 1].productsArray[0].productId, city, longitude, latitude });
                                                                    // setPackage(true)
                                                                }}
                                                            >
                                                                Reschedule
                                                            </button>}
                                                            {(sessionSchedule[sessionSchedule.length - 1]?.status === "SESSION_START" || sessionSchedule[sessionSchedule.length - 1]?.status === "COMPLETED")
                                                                && <button
                                                                    disabled={sessionSchedule[sessionSchedule.length - 1]?.isRated}
                                                                    type="button"
                                                                    className="btn btn-dark"
                                                                    style={{
                                                                        height: "2rem",
                                                                        width: "6rem",
                                                                        padding: "0px",
                                                                        fontSize: "0.9rem",
                                                                        marginRight: "0.4rem",
                                                                        marginTop: "10px",
                                                                    }}
                                                                    onClick={async () => {
                                                                        setRateUs(true)
                                                                        setPackageRateUs(true)
                                                                        setPackageSessionScheduleId(sessionSchedule[sessionSchedule.length - 1]?.id)
                                                                    }}
                                                                >
                                                                    Rate Us
                                                                </button>
                                                            }
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark"
                                                                style={{
                                                                    height: "2rem",
                                                                    width: "6rem",
                                                                    padding: "0px",
                                                                    fontSize: "0.9rem",
                                                                    marginRight: "0.4rem",
                                                                    marginTop: "10px",
                                                                }}
                                                                onClick={() => {
                                                                    navigate(
                                                                        "/my-bookings/upcoming"
                                                                    );
                                                                    localStorage.setItem(
                                                                        "sessionId",
                                                                        sessionSchedule[sessionSchedule.length - 1]?.id
                                                                    );
                                                                    window.location.reload();
                                                                    window.scroll(0, 0);
                                                                }
                                                                }

                                                            >
                                                                View Details
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {sessionSchedule.slice(0, sessionSchedule.length - 1).map((prod, index) =>
                                                    <>
                                                        <div style={{ border: sessionSchedule[index].status === "CANCELLED" ? "2px solid #C61111" : "2px solid black", borderRadius: "20px", display: "flex", padding: "12px", width: "max-content", marginTop: "20px" }}>
                                                            <span style={{
                                                                width: "150px",
                                                                height: "150px",
                                                            }}>
                                                                <img
                                                                    src={packageImage}
                                                                    alt={packageName}
                                                                    style={{
                                                                        borderRadius: "8px",
                                                                        width: "120px",
                                                                        height: "120px"
                                                                    }}
                                                                    loading="lazy"
                                                                />
                                                            </span>
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <div style={{ display: 'flex', flexDirection: "column", width: "250px" }}>{prod?.productsArray?.map((item) =>
                                                                    <span style={{ color: sessionSchedule[index].status === "CANCELLED" ? "#C61111" : "black" }}>• {item.productName}</span>
                                                                )}
                                                                </div>
                                                                {sessionSchedule.length !== 0 && <p>
                                                                    <span style={{ color: sessionSchedule[index].status === "CANCELLED" ? "#C61111" : "black" }}>
                                                                        {moment(
                                                                            prod?.appointmentAt,
                                                                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                                                        )
                                                                            .utcOffset("+00:00")
                                                                            .format(
                                                                                `ddd, MMM DD • hh:mm A`
                                                                            )}
                                                                    </span>
                                                                </p>}
                                                                <span style={{ color: sessionSchedule[index].status === "CANCELLED" ? "#C61111" : "black" }}>{prod?.status}</span>
                                                                {(prod?.status === "COMPLETED")
                                                                    && <div style={{ display: "flex", gap: "10px" }}>
                                                                        <button
                                                                            disabled={prod?.isRated}
                                                                            type="button"
                                                                            className="btn btn-dark"
                                                                            style={{
                                                                                height: "2rem",
                                                                                width: "6rem",
                                                                                padding: "0px",
                                                                                fontSize: "0.9rem",
                                                                                marginRight: "0.4rem",
                                                                                marginTop: "10px",
                                                                            }}
                                                                            onClick={async () => {
                                                                                setRateUs(true)
                                                                                setPackageRateUs(true)
                                                                                setPackageSessionScheduleId(prod?.id)

                                                                            }}
                                                                        >
                                                                            Rate Us
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-dark"
                                                                            style={{
                                                                                height: "2rem",
                                                                                width: "6rem",
                                                                                padding: "0px",
                                                                                fontSize: "0.9rem",
                                                                                marginRight: "0.4rem",
                                                                                marginTop: "10px",
                                                                            }}
                                                                            onClick={() => {
                                                                                navigate(
                                                                                    "/my-bookings/upcoming"
                                                                                );
                                                                                localStorage.setItem(
                                                                                    "sessionId",
                                                                                    prod?.id
                                                                                );
                                                                                window.location.reload()
                                                                                window.scroll(0, 0);

                                                                            }}
                                                                        >
                                                                            View Details
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {prod.status !== "COMPLETED" &&
                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                        <button
                                                                            disabled={prod?.isRated}
                                                                            type="button"
                                                                            className="btn btn-dark"
                                                                            style={{
                                                                                height: "2rem",
                                                                                width: "6rem",
                                                                                padding: "0px",
                                                                                fontSize: "0.9rem",
                                                                                marginRight: "0.4rem",
                                                                                marginTop: "10px",
                                                                            }}
                                                                            onClick={async () => {
                                                                                setSlotsModal(true)
                                                                                setIsReschedule(true);
                                                                                localStorage.setItem("packageCity", city);
                                                                                localStorage.setItem("packageLong", longitude);
                                                                                localStorage.setItem("packageLat", latitude);
                                                                                console.log("hjevwrbvhckw")
                                                                                console.log(sessionSchedule[index]?.productsArray[0]?.productId)
                                                                                localStorage.setItem("packageProductId", sessionSchedule[index]?.productsArray[0]?.productId)
                                                                                localStorage.setItem("packagePreviousDateTime", sessionSchedule[index]?.appointmentAt)
                                                                                localStorage.setItem("selectedProductId", sessionSchedule[index]?.productsArray[0]?.productId)
                                                                                localStorage.setItem("packageProductSessionsId", sessionSchedule[index].id)
                                                                                localStorage.setItem("packageSessions", sessionSchedule[index].status)
                                                                                // scheduleHandler({ productId: sessionSchedule[sessionSchedule.length - 1].productsArray[0].productId, city, longitude, latitude });
                                                                                // setPackage(true)
                                                                            }}
                                                                        >
                                                                            Reschedule
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-dark"
                                                                            style={{
                                                                                height: "2rem",
                                                                                width: "6rem",
                                                                                padding: "0px",
                                                                                fontSize: "0.9rem",
                                                                                marginRight: "0.4rem",
                                                                                marginTop: "10px",
                                                                            }}
                                                                            onClick={() => {
                                                                                navigate(
                                                                                    "/my-bookings/upcoming"
                                                                                );
                                                                                localStorage.setItem(
                                                                                    "sessionId",
                                                                                    prod?.id
                                                                                );
                                                                                window.location.reload()
                                                                                window.scroll(0, 0);

                                                                            }}
                                                                        >
                                                                            View Details
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>)}
                                            </div>
                                        }

                                    </div>
                                </li>
                            }
                            {!packageId &&
                                <li key={[sessionId]} id={[sessionId]}>
                                    <div className="description itemListingPage">
                                        <div className="info">
                                            <h5>{productName}</h5>
                                            <p style={{ margin: 0 }}>
                                                Total Session(s): {count}
                                                <span></span>{" "}
                                            </p>

                                            <p style={{ margin: 0 }}>
                                                <b>OrderId - {orderId}</b>
                                                <span></span>{" "}
                                            </p>
                                        </div>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: description }}
                                        />
                                    </div>
                                    <div className=" category">
                                        <span>
                                            <img src={image} alt="hair reduction" loading="lazy" />
                                        </span>
                                    </div>
                                    <div className="bookingBottom">
                                        <ul>
                                            <>
                                                {(sessionSchedule?.length === 0 ||
                                                    (sessionSchedule &&
                                                        sessionSchedule[sessionSchedule?.length - 1]
                                                            .status === "PAID") ||
                                                    (sessionSchedule &&
                                                        sessionSchedule[sessionSchedule?.length - 1]
                                                            .status === "COMPLETED")) &&
                                                    sessionSchedule.length !== count ? (
                                                    <>
                                                        <li
                                                            key={[sessionId]}
                                                            id={[sessionId]}
                                                            className="yetScehduleBox"
                                                        >
                                                            <div className="bookingBox">
                                                                <div className="leftSide">
                                                                    <span>
                                                                        <img src={image} alt="" loading="lazy" />
                                                                    </span>
                                                                </div>
                                                                <div className="rightSide">
                                                                    <span>
                                                                        Book Session:{" "}
                                                                        {count -
                                                                            (count -
                                                                                sessionSchedule.length -
                                                                                1)}
                                                                    </span>
                                                                    <span
                                                                        onClick={() => {
                                                                            setCalenderPopUp(true);
                                                                            scheduleHandler({ productId, city, longitude, latitude });
                                                                            localStorage.setItem(
                                                                                "sessionId",
                                                                                sessionId
                                                                            );
                                                                            localStorage.setItem(
                                                                                "appointmentType",
                                                                                "Schedule"
                                                                            );
                                                                            setPackage(false)

                                                                        }}
                                                                    >
                                                                        <span>Schedule Now </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                ) : (
                                                    <>
                                                        {sessionSchedule?.map(
                                                            ({ status, id, appointmentAt, isRated }, index) => (
                                                                <>
                                                                    <li
                                                                        key={[id]}
                                                                        id={[id]}
                                                                        className={status}
                                                                    >
                                                                        <div className="bookingBox">
                                                                            <div className="leftSide">
                                                                                <span>
                                                                                    <img src={image} alt="" loading="lazy" />
                                                                                </span>
                                                                            </div>
                                                                            <div className="rightSide">
                                                                                <span>
                                                                                    Session:{" "}
                                                                                    {count - (count - index) + 1}
                                                                                </span>
                                                                                <>
                                                                                    <p>
                                                                                        <span>
                                                                                            {moment(
                                                                                                appointmentAt,
                                                                                                "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                                                                            )
                                                                                                .utcOffset("+00:00")
                                                                                                .format(
                                                                                                    `ddd, MMM DD • hh:mm A`
                                                                                                )}
                                                                                        </span>
                                                                                    </p>
                                                                                    <span>{status}</span>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            marginTop: "1rem",
                                                                                        }}
                                                                                    >

                                                                                        {status !== "COMPLETED" &&
                                                                                            status !== "SESSION_START" &&
                                                                                            moment(appointmentAt).isSameOrBefore(moment().add(6, 'days'), 'day') && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-dark"
                                                                                                    style={{
                                                                                                        height: "2rem",
                                                                                                        width: "6rem",
                                                                                                        padding: "0px",
                                                                                                        fontSize: "0.9rem",
                                                                                                        marginRight: "0.4rem",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        // setCalenderPopUp(true);
                                                                                                        setRemarks(true);
                                                                                                        setIsReschedule(true);
                                                                                                        scheduleHandler({ productId, city, longitude, latitude });
                                                                                                        localStorage.setItem("sessionId", sessionId);
                                                                                                        localStorage.setItem("sessionScheduleId", id);
                                                                                                        localStorage.setItem("appointmentType", "Reschedule");
                                                                                                        localStorage.setItem(
                                                                                                            "previousDate",
                                                                                                            moment(appointmentAt, "YYYY-MM-DDTHH:mm:ss.SSSZ")
                                                                                                                .utcOffset("+00:00")
                                                                                                                .format("YYYY-MM-DD")
                                                                                                        );
                                                                                                        setPackage(false);
                                                                                                    }}
                                                                                                >
                                                                                                    Reschedule
                                                                                                </button>
                                                                                            )}

                                                                                        {(status === "COMPLETED" || status === "SESSION_START") &&
                                                                                            <button
                                                                                                className={`${isRated ? "btn btn-secondary" : "btn btn-dark"}`}
                                                                                                style={{
                                                                                                    height: "2rem",
                                                                                                    width: "6rem",
                                                                                                    padding: "0px",
                                                                                                    fontSize: "0.9rem",
                                                                                                    marginRight: "0.4rem",
                                                                                                }}
                                                                                                disabled={isRated}
                                                                                                onClick={() => {
                                                                                                    handleRateUs()
                                                                                                    localStorage.setItem(
                                                                                                        "sessionId",
                                                                                                        sessionId
                                                                                                    );
                                                                                                    localStorage.setItem(
                                                                                                        "sessionScheduleId",
                                                                                                        id
                                                                                                    );
                                                                                                }}
                                                                                                type='button'>
                                                                                                Rate Us
                                                                                            </button>
                                                                                        }
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-dark"
                                                                                            style={{
                                                                                                height: "2rem",
                                                                                                width: "6rem",
                                                                                                padding: "0px",
                                                                                                fontSize: "0.9rem",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                navigate(
                                                                                                    "/my-bookings/upcoming"
                                                                                                );
                                                                                                localStorage.setItem(
                                                                                                    "sessionId",
                                                                                                    id
                                                                                                );
                                                                                                window.location.reload();
                                                                                                window.scroll(0, 0);
                                                                                            }}
                                                                                        >
                                                                                            View Details
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        </ul>
                                    </div>

                                    {parseInt(leftAmount, 10) != 0 && <button
                                        className="bd5"
                                        onClick={() => showData(index)}
                                        style={{ backgroundColor: "#047858", color: "#fff", border: "none", borderRadius: "10px", padding: "6.4px 16px", marginTop: "16px" }}

                                    >
                                        <span>PAY NOW</span>
                                    </button>}
                                    {parseInt(leftAmount, 10) === 0 && <button
                                        className="bd5"
                                        disabled
                                        style={{ background: "#fff", color: "#000", border: "1px solid #000", borderRadius: "10px", padding: "6.4px 16px", marginTop: "16px" }}

                                    >
                                        <span>PAID</span>
                                    </button>}
                                </li>
                            }
                        </>
                    )
                }
            )}
        </>

    )
}

export default memo(BookingDetails);