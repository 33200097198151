import { createAction, createReducer } from "redux-act";
export const setFetching = createAction("FORGOT_PASSWORD/SET_FETCHING");
export const setError = createAction("FORGOT_PASSWORD/SET_ERROR");
// export const setToken = createAction('FORGOT_PASSWORD/SET_TOKEN')
export const clearUi = createAction("FORGOT_PASSWORD/CLEAR_UI");
export const setCode = createAction("REGISTER_USER/SET_CODE");

const handlers = {
  [setFetching](state, payload) {
    return {
      ...state,
      isFetching: payload,
    };
  },

  [setError](state, payload) {
    return {
      ...state,
      error1: payload,
    };
  },

  [setCode](state, payload) {
    return {
      ...state,
      code: payload,
    };
  },

  // [setToken](state, payload) {
  //     return {
  //         ...state,
  //         token: payload
  //     }
  // },

  [clearUi]() {
    return {
      isFetching: false,
      error1: null,
      code: null,
    };
  },
};

export default createReducer(handlers, {});
