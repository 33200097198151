import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col } from "react-bootstrap";
import MetaTags from "./MetaTags";

function BlogCard(props) {
  const navigate = useNavigate();
  const {
    image,
    title,
    id,
    date,
    description,
    metaTitle,
    metaDescription,
    slug,
  } = props.detail;
  const handleClick = (id) => {
    navigate(`/blog-detail/${id}`, {
      state: { metaData: { metaTitle, metaDescription } },
    });
  };

  return (
    <Col key={id} lg={4} md={6} sm={12} className="mb-4" >
      <MetaTags
        metaTitle="Expert Tips for a Glowing & Healthy Skin | Avataar Skincare Blog"
        metaDescription="Transform your complexion with proven routines, product recommendations, and holistic approaches for radiant, healthy skin. Visit the website and learn more!"
      />
      <Card className="h-100 d-flex flex-column" style={{ width: "100%", cursor: "pointer",}}
        onClick={() => handleClick(slug)}>
        <Card.Img
          className="mx-auto mt-4"
          style={{
            height: "200px",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          variant="top"
          src={image}
        />
        <Card.Body>
          <Card.Title
            style={{ fontWeight: "500", lineHeight: "1.5" }}
          >
            {title}
          </Card.Title>
          <time
            style={{ fontSize: "14px", color: "gray", letterSpacing: "3px" }}
          >
            {date}
          </time>
          <Card.Text
            style={{
              color: "gray",
              paddingTop: "10px",
              letterSpacing: "1.3px",
            }}
          >
            {description}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default BlogCard;