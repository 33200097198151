import React from 'react'
import OwlCarousel from "react-owl-carousel";

const PackageCarasuel = ({
    productCollection,
    options,
    generateFunction,
    heading,
    className,
    mainClassName,
    view }) => {
    return (
        <div className={`wrapperContainer bestSellers ${mainClassName}`}>
            <h2 className="heading">{heading}</h2>

            <div className={className}>
                {productCollection?.length > 1 && (
                    <OwlCarousel
                        className="owl-theme owl-carousel"
                        id="carousel-1"
                        {...options}
                    >
                        {generateFunction(view)}
                    </OwlCarousel>
                )}
            </div>
        </div>
    )
}

export default PackageCarasuel