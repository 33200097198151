const reviewsData = [
  {
    name: "Sonal Chauhan",
    initial: "SC",
    rating: "5",
    review:
      "It was a wonderful experience. Therapist was trained and ensured my comfort through out the session. She explained each step in detail and I am happy with the services. Glad to see the result of my 1st collagen boost session. Looking forward to following sessions :)",
  },
  {
    name: "Shubhra Varma",
    initial: "SV",
    rating: "5",
    review:
      "The service was good. Courteous therapist. Though she was a bit late. But the treatment seems fine. Hope the results are equally good. This was my first experience with Avataar skincare.",
  },
  {
    name: "Deepika Daksh",
    initial: "DD",
    rating: "5",
    review:
      "Wow this season is really really amazing and painless thankyou         This is my second session, I just loved it.. the way they have set the ambience with fragrance was mind blowing. Took proper hygiene and explained each and every process very well! Thank you Avataar for the service looking forward for more sessions!  and it was amazing as always!",
  },
  {
    name: "Tasneem Bohra",
    initial: "TB",
    rating: "5",
    review:
      "This was my first session of Hydra facial. It was a good experience. Therapist was polite and session went smoothly. It is a convenient service as it’s done at home. I hope to see really good result within next 24-48hours of time.",
  },
  {
    name: "Mahesh Singh",
    initial: "MS",
    rating: "5",
    review:
      "We were attended by the therapist who were well spoken and well behaved and soft spoken. They explained us the entire procedure and carried out the work professionally. We are really happy by the services offered by them appreciated n keep up the good work.",
  },
  {
    name: "Puja Yadav",
    initial: "PY",
    rating: "5",
    review:
      "Experience was good... I tried this Derma facial first time.. I was very scared before meeting therapist... But she console me and make me comfortable.. Very polite and humble behaviour... Overall service was good",
  },
  {
    name: "Anjali Rai",
    initial: "AR",
    rating: "5",
    review:
      "Today i went through my 1st hydration boast facial. It was amazing experience. Therapist was so humble and kind. She did fabulous job. Im happy with this service.",
  },
  {
    name: "Diksha Gupta",
    initial: "DG",
    rating: "5",
    review:
      "This was my first session of laser hair removal treatment. It was a good experience. Therapist was polite and did her job very well, very convenient service as it’s done at home.",
  },
  {
    name: "Divya Singh",
    initial: "DS",
    rating: "5",
    review:
      "Today was my first session with Avatar skin I wanted to express my gratitude for the excellent service during my first Hydration boost session. The therapist was well-behaved, and the facility was clean and well-organized. Thank you for a positive experience. What I wanted I got it. 😊😊",
  },
];
export default reviewsData;
