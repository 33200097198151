import CustomModal from 'common/CustomModal'
import React from 'react'
import Wallet from "assets/images/wallet.svg";


const AddWallet = ({
    showCredits,
    handleModalClose,
    currentBalance,
    credits,
    setCredits,
    handleCreditsSubmit,
}) => {
  return (
    <CustomModal
        show={showCredits}
        onClose={handleModalClose}
        className="customPopup innerPopup authPopup"
      >
        <div style={{ padding: "32px 16px" }} className="backgroundModal">

          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <img src={Wallet} className="walletImg" loading="lazy"/>
                <p style={{ fontWeight: "bold" }}>Available Credits</p>
              </div>
              <p style={{ background: "green", color: "white", padding: "8px", borderRadius: "20px" }}>{currentBalance.toLocaleString("en-IN")}</p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "20px", justifyContent: "space-between" }}>
              <p style={{ paddingTop: "16px" }}>Add Credits: </p>
              <input
                type="number"
                placeholder="Enter Amount"
                value={credits}
                style={{ background: "#fff", border: "0", padding: "8px", borderRadius: "10px" }}
                onChange={(e) => setCredits(e.target.value)}
              />
            </div>

            <div style={{ display: "flex", gap: "16px", marginTop: "32px", flexWrap: "wrap" }}>
              <p className="creditsText"
                onClick={() => setCredits(5000)}
              >5,000</p>
              <div>
                <div className="creditsText2" onClick={() => setCredits(10000)}>
                  <p className="abc">
                    10,000
                  </p>
                  <p className="popularText">Popular</p>
                </div>
              </div>
              <p className="creditsText" onClick={() => setCredits(15000)}
              >15,000</p>
              <p className="creditsText" onClick={() => setCredits(20000)}
              >20,000</p>
              <p className="creditsText" onClick={() => setCredits(25000)}
              >25,000</p>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <button
                style={{ background: "#cba05b", marginTop: "24px", border: "0", padding: "8px 16px ", borderRadius: "10px", color: "#fff" }}
                disabled={credits <= 0}
                onClick={handleCreditsSubmit}
              >
                Proceed to Add Credits
              </button>
            </div>
          </>

        </div>
      </CustomModal>
  )
}

export default AddWallet