import React, { memo } from 'react'

const ServicesGrid = ({ generateProductCollection, collection, heading, numberOfItems }) => {
    return (
        <div className="wrapperContainer Services">
            <h2 className="heading">{heading}</h2>
            <div className={`categorySelection ${heading === "Most Booked Services" ? "mostbooked" : ""}`}
                style={{ width: "100%", margin: "auto",paddingLeft: numberOfItems === "six" ? "" : "35px" }}>
                {generateProductCollection(collection, numberOfItems)}
            </div>
        </div>
    )
}

export default memo(ServicesGrid)