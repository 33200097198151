/* eslint-disable */
window.fbAsyncInit = function () {
    FB.init({
        appId: '740247644119637',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0'
    });
};

// Load the SDK asynchronously
(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

/* eslint-enable */